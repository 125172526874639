<section *ngIf="mostrarAlgo">
  <div class="row mt-5">
      <div class="col-lg-12 col-sm-12 col-xl-12 col-xs-12 col-md-12">
          <p class="title_section">Lista de capacitadores</p>
          <a data-toggle="modal" data-toggle="modal" data-target="#exampleModal"><i class="fa-solid fa-square-plus add_icon"></i></a>
          <table class="table" id="table_trainers">
              <thead>
                  <tr>
                    <th>No</th>
                    <th>Nombre Completo</th>
                    <th>Documento</th>
                    <th>Tipo Capacitador</th>
                    <th>Acciones</th>
                    <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let dato of datos; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ dato.firstname + ' ' + dato.flastname }}</td>
                  <td>{{ dato.document }}</td>
                  <td>{{ getDescriptions(dato.capacitation) }}</td>
                  <td><div class="d-flex" style="justify-content: center;"><i data-toggle="modal" data-toggle="modal" data-target="#exampleModal" (click)="updateTrainerButton(dato,i)" class="bi bi-eye-fill" style="font-size: 35px; color: #004884;"></i></div></td>
                  <td><div class="d-flex" style="justify-content: center;"><i class="fa-solid fa-trash" style="font-size: 30px; color: #004884; margin-top: 10px;" (click)="eliminarElemento(i)"></i></div></td>
                </tr>
              </tbody>
          </table>
      </div>
  </div>
<div class="modal fade" data-backdrop="false"  tabindex="-1" role="dialog" id="exampleModal">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Adicionar capacitador</h5>
                  <button type="button" class="close" (click)="this.resetForm()"data-dismiss="modal">
                      <span>&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <form [formGroup]="trainerForm"  >
                      <p style="margin-top: -34px;">*Campos obligatorios</p>
                      <div class="form-row">
                          <div class="form-group col-lg-6">
                              <label for="nombre">Primer Nombre *</label>
                              <input (input)="change($event)" type="text" formControlName="firstname"   class="form-control" id="nombre" placeholder="Primer Nombre" name="nombre" [(ngModel)]="nuevoDato.firstname"  autocomplete="off" [ngClass]="{'campo-vacio': !nuevoDato.firstname && enviado}" />
                            <span style="color: red;"
                              class="error-texto-govco alert-entradas-de-texto-govco" id="firstname-note" role="alert"
                              aria-live="assertive">
                            {{ getErrorMessage('firstname') }}
                                 </span>
                          </div>
                          <div class="form-group col-lg-6">
                              <label for="valor">Segundo Nombre</label>
                              <input (input)="change($event)" type="text" formControlName="secondname" class="form-control" id="secondname" placeholder="Segundo Nombre" name="second"  [(ngModel)]="nuevoDato.secondname"  autocomplete="off"  [ngClass]="{'campo-vacio': !nuevoDato.secondname && enviado}" />
                            <span style="color: red;"
                              class="error-texto-govco alert-entradas-de-texto-govco" id="secondname-note" role="alert"
                              aria-live="assertive">
                            {{ getErrorMessage('secondname') }}
                                 </span>
                          </div>
                      </div>
                      <div class="form-row mt-3">
                        <div class="col">
                          <label for="lastnamefirstInput">Primer apellido *</label>
                          <input (input)="change($event)" type="text" formControlName="surname"class="form-control" placeholder="Primer apellido" id="fistlastname" name="firstname" [(ngModel)]="nuevoDato.flastname"  autocomplete="off"  [ngClass]="{'campo-vacio': !nuevoDato.flastname && enviado}" />
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="surname-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('surname') }}
                                 </span>
                        </div>
                        <div class="col">
                          <label for="lastnamesecondInput">Segundo apellido</label>
                          <input (input)="change($event)" type="text" formControlName="secondsurname"class="form-control" placeholder="Segundo apellido"  name="secondname" [(ngModel)]="nuevoDato.slastname"  autocomplete="off" [ngClass]="{'campo-vacio': !nuevoDato.slastname && enviado}" />
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="secondsurname-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('secondsurname') }}
                                 </span>
                        </div>
                      </div>

                      <div class="form-row mt-3">
                        <div class="col">
                          <label for="typeIdentity">Tipo de identificación *</label>
                          <select (change)="change($event)" class="form-control" formControlName="idtype" [(ngModel)]="nuevoDato.stypeidentity" name="stypeidentity" [ngClass]="{'campo-vacio': !nuevoDato.stypeidentity && enviado}">
                            <option *ngFor="let opcion of selectdni" [value]="opcion.idParametroDetalle">{{ opcion.vcNombre }}</option>
                          </select>
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="idtype-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('idtype') }}
                                 </span>
                        </div>
                        <div class="col">
                          <label for="numberidentity">Número de identificación *</label>
                          <input (input)="change($event)" type="text" class="form-control" formControlName="idnumber" placeholder="Número de identificación" id="document" name="document" [(ngModel)]="nuevoDato.document"  autocomplete="off" [ngClass]="{'campo-vacio': !nuevoDato.document && enviado}" />
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="idnumber-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('idnumber') }}
                                 </span>
                        </div>
                      </div>
                      <div class="form-row mt-3">
                        <div class="col">
                          <label for="titleprofesional">Título profesional *</label>
                          <input (input)="change($event)" type="text" class="form-control" formControlName="profession" placeholder="Título profesional" id="titleprofesional" name="titleprofesional" autocomplete="off" [(ngModel)]="nuevoDato.title" [ngClass]="{'campo-vacio': !nuevoDato.title && enviado}" />
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="profession-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('profession') }}
                                 </span>
                        </div>
                        <div class="col">
                          <label for="numbertarget">Número de tarjeta profesional *</label>
                          <input (input)="change($event)" type="text" class="form-control" formControlName="professionalnumber" placeholder="Número de tarjeta profesional" id="numbertitle" name="numbertitle" [(ngModel)]="nuevoDato.numbertitle" autocomplete="off" [ngClass]="{'campo-vacio': !nuevoDato.numbertitle && enviado}"/>
                          <span style="color: red;"
                          class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note" role="alert"
                          aria-live="assertive">
                            {{ getErrorMessage('professionalnumber') }}
                        </span>
                        </div>
                      </div>
                      <div class="form-row mt-3">
                        <div class="col">
                          <label for="phonecontact">Teléfono de contacto *</label>
                          <input (input)="change($event)" type="text" class="form-control"  formControlName="telephone" placeholder="Teléfono de contacto" id ="phone" name="poneUser" autocomplete="off"  [(ngModel)]="nuevoDato.contact" [ngClass]="{'campo-vacio': !nuevoDato.contact && enviado}" />
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="telephone-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('telephone') }}
                                 </span>
                        </div>
                        <div class="col">
                          <label for="email">Correo electrónico *</label>
                          <input (input)="change($event)" type="email" class="form-control" formControlName="email" placeholder="Correo electrónico"  id="emai" name="emailuser" [(ngModel)]="nuevoDato.email"  required autocomplete="off" [ngClass]="{'campo-vacio': !nuevoDato.email && enviado}" />
                          <span style="color: red;"
                            class="error-texto-govco alert-entradas-de-texto-govco" id="email-note" role="alert"
                            aria-live="assertive">
                            {{ getErrorMessage('email') }}
                                 </span>
                        </div>
                      </div>
                  </form>
                      <div class="form-row">
                        <div class="col">
                          <div>
                            <h5 class="modal-title mt-5" >Tipo de capacitador</h5>
                          </div>
                          <div class="d-inline-block mr-4" *ngFor="let opcion of checkcapacitation">
                            <label>
                              <input (input)="change($event)" type="checkbox"  name="capacitation_{{opcion.idTipoCapacitacion}}" [(ngModel)]="opcion.selected"   #selected>{{ opcion.vcDescripcion }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <form [formGroup]="fileForm" class="mt-5">
                        <div class="files">
                          <h5 class="modal-title mt-3" >Adjuntar capacitador</h5>
                          <span class="mb-5 d-block">* Campos obligatorios</span>
                          <div class="contenedor mx-5" style="margin:10px;margin-top: -32px; margin-left: -43px !important;" [ngStyle]="{'grid-template-rows':'1fr'}">
                            <div class="box1 mx-5">
                              <div *ngFor="let d of documents; let i = index">
                                <div class="mt-3" style="display: grid;grid-template-columns: 60% 40%;">
                                  <div style="display: grid;grid-template-columns: 25% 75%;">
                                    <span class="d-block"><b>{{d.mandatory?'*':null}}{{d.name}}</b></span>
                                    <input (input)="change($event)" formControlName={{d.initials}} accept=".pdf" (change)="onChange({file:$event,document:d}, d)" style="height: 35px;width: 70%; margin-left: 15px;"
                                    class="form-control gov-co-form-control d-block" id="formFileLg" type="file" #replenishmentDocument>
                                  </div>
                                  <div
                                    style="display: grid; grid-template-columns: 20% 50% 30%;background-color: #F4F4F4;padding-bottom: 15px; padding-top: 5px;">
                                    <div (dblclick)="!isUpdating? seeDocument(replenishmentDocument, null):seeDocument(replenishmentDocument, this.fileForm.get([d.initials]).value)">
                                      <i class="bi bi-file-earmark-pdf icon"></i>
                                    </div>
                                    <div style="cursor: pointer;" class="ml-3">
                                      <span class="d-block">{{!isUpdating? replenishmentDocument.files.length==0 ? 'no file' : replenishmentDocument.files[0].name: replenishmentDocument.files.length==0 ? nameFile[d.initials] : replenishmentDocument.files[0].name}}</span>
                                      <span class="d-block">{{!isUpdating? replenishmentDocument.files.length==0 ? '0 Kb' : (replenishmentDocument.files[0].size/1000) + ' Kb':replenishmentDocument.files.length==0 ? (sizeFile[d.initials]/1000) + ' Kb' : (replenishmentDocument.files[0].size/1000) + ' Kb'}}</span>
                                    </div>

                                    <div (click)="replenishmentDocument.value = null; delete(d)">
                                      <i class="bi bi-trash3-fill icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="documentViewer">
                            <app-document-viewer [isVisible]="viewDocument" [document]="urlSafeDocument"
                              (closeButtonEvent)="closeDocument()"></app-document-viewer>
                          </div>
                        </div>
                      </form>
                      <div class="text-center mt-4">
                        <button [disabled]="isUpdating?(!updateFlag):false" type="button" class="btn btn-primary mx-auto mb-3" (click)="agregarDato()" id="send">Guardar</button>
                      </div>

              </div>
          </div>
      </div>
  </div>
</section>
