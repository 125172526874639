import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { AppBaseComponent } from 'src/app/core/utils';
import Swal from "sweetalert2";
import {environment} from "../../../../environments/environment";
const { PROCEDURE_FILE_MANAGER_URI } = environment;

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent extends AppBaseComponent implements OnInit{

  public modalTrainerForm: any;

  @Input() observacionesSubsanacion:string;

  @Input() observacionesCancelacion:string;

  @Input() documentTypes: any;

  @Input() type: string;

  @Input() isVisible:boolean = false;

  @Output() closeButtonEvent = new EventEmitter<any>();

  @Output() acceptButtonEvent = new EventEmitter<any>();

  @Output() acceptButtonTrainerEvent = new EventEmitter<any>();

  @Input() trainingDataTypes: any;

  @Output() trainingDataTypesSelectedEvent = new EventEmitter<any>();

  @Input() isLoading: boolean = true;


  documentsdisabled:boolean=false;

  subsanacion:boolean=false;

  urlSafe: SafeResourceUrl;

  mostrarIframe: boolean = true;

  /**
   * Listado e documentación a mostrar
   */

  @Input() documentation: any;

  @Input() flag: boolean = false;

  @Output() approvedDocumentationEvent = new EventEmitter<any>;



  constructor(private controlContainer: ControlContainer, private documentService:DocumentsService, public sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    this.modalTrainerForm = this.controlContainer.control;
    this.modalTrainerForm = this.modalTrainerForm.controls['modalTrainerForm'];

    console.log(localStorage.getItem('tipo'))

    if(localStorage.getItem('tipo')!=null)
    {
      if(localStorage.getItem('tipo')=="subsanacion")
      {
        this.subsanacion=true;
      }

      this.documentsdisabled=true;
    }

  }

  closeButton(): void {
    this.urlSafe = "";
    this.closeButtonEvent.emit('cerrar modal');
  }

  acceptButton(e:any):void {
    this.acceptButtonEvent.emit(e);
  }

  acceptButtonTrainer = () => {
    this.urlSafe = "";
    this.acceptButtonTrainerEvent.emit("trainer--");
  }

  trainingDataTypesSelected = (e:any, documentNumber: any) => {
    this.trainingDataTypesSelectedEvent.emit({e, documentNumber});
  }

  public verDocumento( path: string, fileNumber:string){
    const contenedor = "manipalimentos";
    //const oid = "31ab2d61-65b8-4c7d-a793-79cdba31b235";
    const oid = fileNumber;

    this.documentService.GetBlob(contenedor, oid, path).subscribe(resp => {
      var reader = new FileReader();
      reader.readAsDataURL(resp.body);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        const documentData = base64data;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${oid}/${path}`);
        this.mostrarIframe=true;
      };
    })
  }

  approvedDocumentation = (e:any, v:string, documentNumber: any) => {
    if(this.subsanacion)
    {
      if(v=="delete")
      { this.urlSafe = null;
        this.mostrarIframe=false;
        this.approvedDocumentationEvent.emit({
          document:e,
          file:documentNumber,
          delete:'si'
        });
      }
      else {

        this.approvedDocumentationEvent.emit({
          document:e,
          file:documentNumber,
          delete:'no'
        });
      }

    }
    else {
      this.approvedDocumentationEvent.emit({
        id: documentNumber,
        record: e,
        value: v
      });
    }


  }

  seeDocument = (e:any) => {


    console.log('entro?')
    if(e.files.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Sin archivo',
        text: 'No se ha cargado un archivo',
      })
    }else{
      if (e.files[0].type != 'application/pdf') {

        Swal.fire({
          icon: 'error',
          title: 'Tipo de archivo incorrecto',
          text: 'El archivo cargado no es un pdf',
        })

      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.files[0]);

        reader.onloadend = () => {
          const base64data = reader.result as string;
          const documentData = base64data;
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(documentData);
          this.mostrarIframe=true;
        };
      }
    }
  }


}
