import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '@core-app/services/usuario';
import { PersonService } from '@core-app/services/person.service';
import { ROUTES } from '../../../../core/enums';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent implements OnInit {
  selecttypePerson = '';
  userData: any;
  hasSolicitudes: boolean = false;

  constructor(
    private router: Router,
    private usuario: Usuario,
    private person: PersonService
  ) {}

  advanceLineData = [
    {
      name: 'Inicio',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.Menu
    },
    {
      name: 'Hago mi solicitud',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.REQUEST_CITIZEN
    },
    {
      name: 'Procesan mi solicitud',
      link: ''
    },
    {
      name: 'Respuesta',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.SearchRequest
    }
  ]

  ngOnInit(): void {
    this.getTypePerson();
    this.optionList();
  }

  firstime() {
      this.person.setSelecttypePerson(this.selecttypePerson);
      this.router.navigateByUrl(
      ROUTES.MainPage + '/' + ROUTES.Request + '/' + ROUTES.REQUEST_CITIZEN
    );
  }

    inbox() {
      this.router.navigateByUrl(
      ROUTES.MainPage + '/' + ROUTES.Request + '/' + ROUTES.SearchRequest
    );
    }

    getTypePerson() {
    const UserId = JSON.parse(localStorage.getItem('currentUser')).userId;
    console.log("mi id", UserId);
    this.usuario.getInfoperson(UserId).subscribe((data: any) => {
        this.usuario.getTotalperson(data['data']).subscribe((valores: any) => {
        this.selecttypePerson = valores.data.numeroIdentificacionRL;
        this.userData = valores.data;
        this.person.setUserData(this.userData);
        console.log(JSON.stringify(valores.data.numeroIdentificacionRL));
        localStorage.setItem("selecttypePerson", JSON.stringify(valores.data.numeroIdentificacionRL));
        localStorage.setItem("userData", JSON.stringify(valores.data));
        });
      });
    }

    optionList() {
    /*
      const UserId = JSON.parse(localStorage.getItem('currentUser')).userId;
      this.usuario.getRequestList(UserId).subscribe((response) => {
        this.hasSolicitudes = Array.isArray(response) && response.length > 0;
      });

     */
    }

}
