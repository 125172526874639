<form [formGroup]="basicDataForm">
    <div class="row">
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="primerNombre">Primer Nombre </label>
        <input class="form-control gov-co-form-control" formControlName="primerNombre" type="text" id="primerNombre" placeholder="Primer nombre"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="segundoNombre">Segundo Nombre</label>
        <input class="form-control gov-co-form-control" formControlName="segundoNombre" type="text" id="segundoNombre" placeholder="Segundo nombre"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="primerApellido">Primer Apellido </label>
        <input class="form-control gov-co-form-control" formControlName="primerApellido" type="text" id="primerApellido" placeholder="Primer apellido"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="segundoApellido">Segundo Apellido</label>
        <input class="form-control gov-co-form-control" formControlName="segundoApellido" type="text" id="segundoApellido" placeholder="Segundo apellido"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="tipoDocumento">Tipo de Identificación</label>
        <select formControlName="tipoDocumento" class="selectpicker form-control" id="tipoDocumento">
            <option *ngFor="let dt of documentTypes" value={{dt.idTipoIdentificacion}} >{{dt.descripcion}}</option>
           </select>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="numeroDocumento">Número de Identificación </label>
        <input class="form-control gov-co-form-control" formControlName="numeroDocumento" type="text" id="numeroDocumento" placeholder="Número de documento"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="numeroContacto">Teléfono de contacto</label>
        <input class="form-control gov-co-form-control" formControlName="numeroContacto" type="text" id="numeroContacto" placeholder="Número de contacto"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="correoElectronico">Correo electrónico</label>
        <input class="form-control gov-co-form-control" formControlName="correoElectronico" type="text" id="correoElectronico" placeholder="Correo electrónico"/>
      </div>
    </div>
    <div class="row mt-3" *ngIf="mostrarDireccion">
      <label for="direccion" class="headline-m-govco">Dirección </label>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-12">
        <input class="form-control gov-co-form-control" formControlName="direccion" type="text" id="direccion" placeholder="Direccion"/>
      </div>
    </div>
</form>
