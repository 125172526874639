import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRequestComponent } from './pages/user-request/user-request.component';
import { UserDeclarationComponent } from './pages/user-declaration/user-declaration.component';
import {DemoNgZorroAntdModule} from "../../core/modules/ng-antd.module";
import {SharedModule} from "../../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { RequestDataComponent } from './components/request-data/request-data.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { ResumeRequestSavedComponent } from './components/resume-request-saved/resume-request-saved.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import {PendingChangesGuard} from "../../core/guards/pending-changes.guard";
import {MatPaginatorModule} from "@angular/material/paginator";
import { RequestClarificationComponent } from './components/request-clarification/request-clarification.component';
import { UserRequestManagementComponent } from './pages/user-request-management/user-request-management.component';
import { DocumentationAssociatedWithTheReviewComponent } from './components/documentation-associated-with-the-review/documentation-associated-with-the-review.component';
import { ApplicationInformationComponent2 } from './components/application-information/application-information.component';
import { ApplicantDetailsComponent } from './components/applicant-details/applicant-details.component';
import { RequirementDocumentationComponent } from './components/requirement-documentation/requirement-documentation.component';
import { TrainerDetailsComponent } from './components/trainer-details/trainer-details.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RequestRoutingModule } from './request-routing.module';
import { RequestComponent } from './request.component';
import { CitizenFormComponent } from './pages/citizen/citizen-form/citizen-form.component';
import { BasicDataComponent } from './components/basic-data/basic-data.component';
import { TrainersComponent } from './components/trainers/trainers.component';
import { MenuComponent } from './pages/menu/menu.component';
import { OptionsComponent } from './components/options/options.component';
import { SuccessRequestComponent } from './components/success-request/success-request.component';
import { RequestSearchComponent } from './components/request-search/request-search.component';
import { TrainingRecordComponent } from './components/training-record/training-record.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DataRequestComponent } from './components/data-request/data-request.component';
import { RecordCapacitationComponent } from './components/record-capacitation/record-capacitation.component';
import { CoreModule } from "../../core/core.module";
import { ReportsComponent } from './components/reports/reports.component';
import { EditCapacitationComponent } from './components/edit-capacitation/edit-capacitation.component';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";




@NgModule({
    declarations: [
        UserRequestComponent,
        UserDeclarationComponent,
        RequestDataComponent,
        AttachmentsComponent,
        ResumeRequestSavedComponent,
        UserDashboardComponent,
        RequestClarificationComponent,
        UserRequestManagementComponent,
        DocumentationAssociatedWithTheReviewComponent,
        ApplicationInformationComponent2,
        ApplicantDetailsComponent,
        RequirementDocumentationComponent,
        TrainerDetailsComponent,
        RequestComponent,
        CitizenFormComponent,
        BasicDataComponent,
        TrainersComponent,
        MenuComponent,
        OptionsComponent,
        SuccessRequestComponent,
        RequestSearchComponent,
        TrainingRecordComponent,
        DataRequestComponent,
        RecordCapacitationComponent,
        ReportsComponent,
        EditCapacitationComponent,
    ],
    exports: [
        RequestDataComponent
    ],
  imports: [
    CommonModule,
    DemoNgZorroAntdModule,
    SharedModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    PdfViewerModule,
    RequestRoutingModule,
    DashboardModule,
    FormsModule,
    CoreModule,
    MatIconModule,
    MatMenuModule
  ]
})
export class RequestModule { }
