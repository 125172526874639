import { Authentication } from './../../../core/models/auth.model';
import {
  ErrorMessage,
  HasErrorForm,
} from './../../../core/enums/errorMessage.enum';
import { Router } from '@angular/router';
import { ROUTES } from './../../../core/enums/routes.enum';
import { Rol } from './../../../core/enums/rol.enum';
import { CustomValidators } from './../../../core/utils/custom-validators';
import {Component, Inject, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PopUpService } from 'src/app/core/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppBaseComponent } from 'src/app/core/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends AppBaseComponent implements OnInit{
  public loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private popupAlert: PopUpService,
    @Inject(AuthService) private authService: AuthService
  ) {
    super();
    this.cargarventanilla = this.cargarventanilla.bind(this);
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, CustomValidators.formatEmail]],
      password: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {

    const metodoExterno = this.cargarventanilla;
    window.addEventListener('message', function(event) {
      console.log('aca entro evento login')
      // Verifica el origen del mensaje

      if(event.origin==environment.VUDTS_URL_LOCAL || event.origin == environment.VUDTS_URL)
      {

        const datos = event.data;

        localStorage.setItem('datosventanilla',JSON.stringify(datos));
        metodoExterno();
        const respuesta = datos.oid;
        // @ts-ignore
        event.source.postMessage(respuesta, event.origin);
      }
      else {
        console.log('origen no admitido');
      }
    });
  }

  public submitLoginForm(): void {
    try {
      if (!this.loginForm.valid) {
        this.popupAlert.errorAlert(
          'Hay errores en el formulario, revise por favor.',
          4000
        );
        console.log('FORMULARIO PROCESADO');
        console.log(this.loginForm.value);
        console.log('ERRORES FORMULARIO');
        console.log(super.getAllErrors(this.loginForm));
        this.loginForm.markAllAsTouched();
        return;
      }

      const payload: Authentication = {
        userName: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
      };
      this.popupAlert.infoAlert('Iniciando sesión...', 4000);

      this.authService.internalLogin(payload).subscribe({
        next: (value) => {
          if (value.rol == Rol.Citizen) {
            this.router.navigateByUrl(
              `${ROUTES.AUT_TITULOS}/${ROUTES.CITIZEN}`
            );
          } else {
            this.router.navigateByUrl(
              `${ROUTES.AUT_TITULOS}/${ROUTES.ValidatorDashboard}`
            );
          }
          this.popupAlert.successAlert(
            'Bienvenido(a) a la Secretaría de Salud Bogotá.',
            4000
          );
        },
      });
    } catch (e) {
      this.popupAlert.errorAlert('Ocurrió un error al iniciar sesión.', 4000);
    }
  }
  public submitLoginFormB2C(): void {
    try {
      console.log('va a entrar');
      this.authService.B2CLogin();
    } catch (e) {
      this.popupAlert.errorAlert('Ocurrió un error al iniciar sesión.', 4000);
    }
  }
  public Register(): void {
    this.router.navigateByUrl(`${ROUTES.AUT_TITULOS}/${ROUTES.REGISTER}`);
  }
  public validacion(): void {
    this.router.navigateByUrl(
      ROUTES.AUT_TITULOS + '/' + ROUTES.DOCUMENTS_VALID
    );
  }

  public getErrorMessage(field: string): string {
    let message;
    const required: Array<string> = ['email', 'password'];
    const email: Array<string> = ['email'];

    if (this.isTouchedField(this.loginForm, field)) {
      if (
        required.includes(field) &&
        this.loginForm?.get(field).hasError(HasErrorForm.REQUIRED)
      ) {
        message = ErrorMessage.IS_REQUIRED;
      } else if (
        email.includes(field) &&
        this.loginForm?.get(field).hasError(HasErrorForm.EMAIL)
      ) {
        message = ErrorMessage.FORMAT_EMAIL;
      }
    }

    return message;
  }

  cargarventanilla()
  {
    this.authService.loginventanilla();
  }

}
