<footer class="gov-co-footer" style="margin-top: 5%;">
  <div class="gov-co-footer-presetacion gov-co-footer-tramites">
      <div class="gov-co-footer-autoridad">
          <div class="footer-titulo">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col">
                          <h5 class="titulo-sede-gov-co">Secretaría Distrital de Salud</h5>
                      </div>
                  </div>
              </div>
          </div>
          <div class="footer-logo-autoridad">
              <div class="containder-fluid">
                  <div class="row">
                      <div class="col">
                          <div class="logo-gov-co" style="box-shadow: none !important;">
                              <img src="./assets/images/logoSDS.png" alt="Secretaria Distrital de Salud" style="margin-top: -10px;"/>
                              <!-- ./assets/images/logoSDS.png -->
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="footer-presentacion">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col">
                          <h5 class="sub-titulo-sede-gov-co">Sede principal</h5>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col">
                          <div class="contenido-sede-gov-co gov-detalles">
                              <p>Dirección: Carrera 32 No. 12 - 81</p>
                              <p>Código Postal: 111611</p>
                              <p>Horario de Atención: <a style="text-decoration: underline;" href="http://www.saludcapital.gov.co/Documents/Horarios_Atencion.pdf" target="_blank" rel="noopener">Consulte aquí los horarios</a></p>
                              <p>Teléfono Conmutador: (601) 3649090</p>
                              <p>Correo Institucional: <a style="font-size: 14px;" href="mailto:secretariadesalud@secretariadesalud.gov.co"></a>contactenos&commat;saludcapital.gov.co
                              </p>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col">
                          <div class="gov-co-redes-sociales">
                              <a class="gov-co-link-presentacion" href="https://twitter.com/SectorSalud"> <span class="govco-icon govco-icon-twiter-cp" id="icon"></span>Twitter </a>
                              <a class="gov-co-link-presentacion" href="https://www.instagram.com/sectorsalud"> <span class="govco-icon govco-icon-instagram-cp" id="icon"></span>Instragram </a>
                              <a class="gov-co-link-presentacion" href="https://www.facebook.com/secretariadistritaldesalud/"> <span class="govco-icon govco-icon-facebook-cp" id="icon"></span>Facebook </a>
                              <a class="gov-co-link-presentacion" href="https://www.youtube.com/@SecretariaDistritaldeSalud/videos"> <span class="govco-icon govco-icon-play-cn" id="icon"></span>YouTube </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="footer-politicas poli-tramites">
              <div class="container-fluid">
                  <div class="row p-0">
                      <div class="col p-0">
                          <div class="gov-co-politicas">
                              <a class="goc-co-link-poli" href="http://www.saludcapital.gov.co/Documents/Politicas_Sitios_Web.pdf" target="_blank" rel="noopener">Políticas</a>
                              <a class="goc-co-link-poli" href="http://saludcapital.gov.co/Paginas2/MapaSitio.aspx" target="_blank" rel="noopener">Mapa del sitio</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="gov-co-footer-pie">
      <div [ngClass]="'gov-co-footer-auto'">
          <a href="https://www.gov.co/" target="_blank" rel="noopener">
              <img class="img-fluid gov-co-logo-pie-mesa" src="./assets/images/colombia_CO.png" alt="" srcset="" />
              <img class="img-fluid gov-co-logo-pie-blanco" src="./assets/images/logoGov.svg" alt="" srcset="" />
          </a>
      </div>
  </div>
</footer>
