<nz-modal nzWidth="900px" [nzBodyStyle]="{height:'900px'}" [(nzVisible)]="isVisible" nzCancelText={{null}} style="z-index: 1052;"
  nzClosable="false">
  <nz-spin style="display: block;width: 100%; height: 100%;" [nzSpinning]="isLoadingDocument" [nzSize]="'large'"
    nzTip="Se estan cargando los datos, por favor espere un momento....">
    <ng-container *nzModalContent>
      <div class="w-100">
        <h4 class="text-center" style="font-weight: bold">{{title.toUpperCase()}}</h4>
      </div>
      <div class="w-100 h-100">
        <iframe [src]="document" class="w-100 h-100" frameBorder='0' scrolling='auto'></iframe>
      </div>
    </ng-container>
  </nz-spin>
  <div *nzModalFooter>
    <button (click)="closeButton()" class="btn btn-round btn-high mx-3"
      style="color: white; font-size: 17px;padding:5px 40px;">
      CERRAR
    </button>
  </div>
</nz-modal>
