<br><br>
<div class="row mt-4" style="margin-top:30px !important">
  <div class="col">
    <p class="title" style="font-size:25px; margin-top: 20px;">Tipo de capacitación</p>
  </div>
  <div class="col d-flex flex-column">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Carnes y productos carnicos comestibles
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
      <label class="form-check-label" for="flexCheckChecked">
        Leche cruda
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
      <label class="form-check-label" for="flexCheckChecked">
        Alimentos en vía pública
      </label>
    </div>
  </div>
</div>
