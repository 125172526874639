import { Data } from '@angular/router';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RequestService } from 'src/app/core/services/request.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-application-information2',
  templateUrl: './application-information.component.html',
  styleUrls: ['./application-information.component.scss']
})
export class ApplicationInformationComponent2 {

  @Input() applicationInformationForm: any;

  tiposSolicitud: any;

  tipoSolicitud: any;

  constructor(private localStorageService: LocalStorageService) {

    this.tiposSolicitud = JSON.parse(this.localStorageService.getData("tiposSolicitud"));

  }

  public cambioTipoSolicitud(){
    this.tipoSolicitud = this.applicationInformationForm.get("tipoSolicitud").value.toUpperCase();
  }



}
