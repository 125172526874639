<form [formGroup]="trainerDetailsForm" action="">
  <div *ngIf="applicantType==null" class="solicitante-natural">
    <div class="row">
      <div class="col-sm-12 col-md-12 mb-4">
        <div class="entradas-de-texto-govco col-sm-12 col-md-12 mb-1">
          <label for="tipoCapacitacion">Tipo de capacitación</label>
        </div>
        <div *ngFor="let tc of tiposCapacitacion" class="d-inline-block col-sm-12 col-md-4">
          <input formControlName="tipoCapacitacion" id="tipoCapacitacion{{tc.id}} " type="checkbox"/> {{tc.name}}
        </div>
      </div>

      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="tituloProfesional">Titulo profesional</label>
        <input formControlName="tituloProfesional" type="text" id="tituloProfesional" placeholder="Titulo profesional"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="numeroTarjetaProfesional">Número de tarjeta profesional</label>
        <input formControlName="numeroTarjetaProfesional" type="text" id="numeroTarjetaProfesional" placeholder="Número de tarjeta profesional"/>
      </div>
    </div>
  </div>
  <div *ngIf="applicantType!=null" class="solicitante-juridico">
  </div>
</form>
