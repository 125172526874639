<div class="row mt-3">
  <div class="col-lg-10 col-sm-12">
      <app-advance-line [width]="123" [step]="2" [currentProgress]="100" [options]="advanceLineData"></app-advance-line>
  </div>
</div>
<div class="row">
  <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-8">
      <div class="content-info">
          <p>Por favor verifique su mensaje de confirmación en su correo electrónico, para hacerle seguimiento a este trámite no olvide anotar el ID del ticket descrito a coninuación. <br /></p>
          <p>Número de ticket</p>
          <p>{{fileNumber}}</p>
          <p class="text-danger font-weight-bold">El proceso de respuesta puede tardar un máximo de quince (15) días hábiles</p>
          <button type="button" class="btn btn-outline-primary info" id="cancel"  (click)="exit()">Salir</button>
        </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-2 col-xl-3 float-right">
    <div class="custom-alert">
        <a href="https://aulavirtual.saludcapital.gov.co/sicap/" style="text-decoration: none !important;" target="_blank">
            <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                Te explicamos con tutoriales
            </div>
        </a>
        <a type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                ¿Tienes dudas?
            </div>
        </a>
        <div class="collapse" id="collapseExample">
            <div class="card card-body" id="cardi_info_contact">
              <p id="colorphone" style="margin-left: 18px;">Teléfono:(571) 3649090</p>
              <p id="colorphone" style="margin-left: 18px; margin-top: -10px;">contactenos&commat;aludcapital.gov.co</p>
            </div>
        </div>
    </div>
</div>
</div>
