/**
 * Enumeracion con las rutas de la aplicación
 */
export enum ROUTES {

  /**
   * General
   */

  MainPage = 'aut-cap',
  Login = 'iniciar-sesion',


  /**
   * Dashboard
   */

  Dashboard = 'dashboard',


  /**
   * Request
   */

  Request = 'request',

  User_Request = 'user_request',
  /**
   * Validator
   */

  REQUEST_MANAGEMENT_VALIDATOR = 'request_management_validator',
  DASHBOARD_VALIDATOR = 'dashboard_validator',

	/**
   * Citizen
   */

  REQUEST_CITIZEN = 'request_citizen',

  Menu= 'options',

  /**
   * Coordinator
   */

  REQUEST_MANAGEMENT_COORDINATOR = 'request_management_coordinator',
  DASHBOARD_COORDINATOR = 'dashboard_coordinator',

  /**
   * Assistant manager
   */

  REQUEST_MANAGEMENT_ASSISTANT_MANAGER = 'request_management_assistant_manager',
  DASHBOARD_ASSISTANT_MANAGER = 'dashboard_assistant_manager',

  //Capacitation

  REQUEST_CAPACITATION = 'created_capacitation',
  DASHBOARD_CAPACITATION = 'list_capacitation',
  // Gestionar solicitud
  REQUEST_MANAGEMENT = 'request_management',

  //Bandeja de trámites
  REQUEST_DASHBOARD = 'request_dashboard',



   //FUNCIONARIOS
   VALIDATOR_REQUEST_MANAGEMENT = 'validator-request-management',


   Replenishment = 'resource',

   //REQUEST STATUS

   Success = 'success-status',

   SearchRequest = 'searchdata',

   RECORDTRAINING = 'record-training',

   //ADICIONAL HU1

    REQUEST_DATA = 'complete_data',
    REQUEST_CANCEL = 'cancel_data',
    REQUEST_INFO = 'info_solicitud',
    REQUEST_PERSON = 'person_info',
    REQUEST_DOCUMENT = 'document_info',
    FIRST_NATURAL = 'person_natural',
    RENEWAL_REQUEST = 'renewal',
    MODIFY_REQUEST = 'update_request',
    TRAINING = 'type_trainng',

  //-----------------------

  AUT_TITULOS = 'aut-titulos',

  DOCUMENTS_VALID = 'doc-valid',

  //REGISTRO / LOGIN

  REGISTER = 'registro',
  REGISTRATION_NATURAL = 'persona-natural',
  REGISTRATION_LEGAL = 'persona-juridica',

  ACEPTAR_POLITICA_DATOS = 'aceptar-politica-datos',

  //RecoverPassword = 'recover/password',


  //CIUDADANO

  CITIZEN = 'ciudadano',
  CREATE_REQUEST = 'crear-solicitud',
  PERSON_DASHBOARD = 'listado-solicitudes',

  //FUNCIONARIOS
  ValidatorDashboard = 'validador/bandeja',

  Prueba = 'prueba',

  Validation = 'validation',

  CoordinatorDashboard = 'coordinador/bandeja',

  DirectorDashboard = 'director/bandeja',

  //REPORTES
  ReportsDashboard = 'reportes/dashboard',
  ReportsCanceled = 'reportes/anulados',
  ReportsReturned = 'reportes/devueltos',
  ReportsRevoked = 'reportes/revocados',
  ReportsLicensesGenerated = 'reportes/licencias-generadas',
  ReportsMinistry = 'reportes/ministerio',
  ReportsCCSST = 'reportes/reporte-por-cedula-SST',
  ReportsSolicitudes = 'reportes/reporte-solicitudes',

  /**Reporte t22 */

  Reports = 'generalReports',

}
