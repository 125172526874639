<form [formGroup]="applicationInformationForm">
  <div class="row">
    <div class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="numeroRadicado">Número de radicado</label>
      <input formControlName="numeroRadicado" type="text" id="numeroRadicado" placeholder="Número de radicado"/>
    </div>
    <div class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="tipoTramite">Tipo de trámite</label>
      <input formControlName="tipoTramite" type="text" id="tipoTramite" placeholder="Tipo de trámite"/>
    </div>
    <div class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="estado">Estado</label>
      <input formControlName="estado" type="text" id="estado" placeholder="Estado"/>
    </div>
    <div class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="usuarioAsignado">Usuario asignado</label>
        <select class="form-select" id="usuarioAsignado"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="1" >Opcion 1</option>
          <option value="2" >Opcion 2</option>
         </select>
    </div>
    <div class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="fechaSolicitud">Fecha Trámite</label>
      <input formControlName="fechaSolicitud" type="text" id="fechaSolicitud" placeholder="Fecha de solicitud"/>
    </div>
    <div *ngIf="applicationInformationForm.controls.tipoSolicitud.value == '1a62b199-cae2-43e8-8371-0564edf6c011'" class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="motivoCancelacion">Motivo de cancelación <span class="required">*</span></label>
        <select class="form-select" id="tipoSolicitud"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="1" >Opcion 1</option>
          <option value="2" >Opcion 2</option>
         </select>
    </div>
    <div *ngIf="applicationInformationForm.controls.tipoSolicitud.value == '9a62b199-cae2-43e8-8371-0564edf6c011'" class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="motivoModificacion">Motivo de modificación <span class="required">*</span></label>
        <select class="form-select" id="tipoSolicitud"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="1" >Opcion 1</option>
          <option value="2" >Opcion 2</option>
         </select>
    </div>
    <div *ngIf="applicationInformationForm.controls.tipoSolicitud.value == '9a62b199-cae2-43e8-8371-0564edf6c011'"  class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="numeroResolucion">Número de resolución actual <span class="required">*</span></label>
      <input type="text" id="numeroResolucion" placeholder="Número de resolución actual"/>
    </div>
    <div *ngIf="applicationInformationForm.controls.tipoSolicitud.value == '1a62b199-cae2-43e8-8371-0564edf6c011'"  class="entradas-de-texto-govco col-sm-12 col-md-6">
      <label for="numeroResolucion">Número de resolución actual <span class="required">*</span></label>
      <input type="text" id="numeroResolucion2" placeholder="Número de resolución actual"/>
    </div>
    <div *ngIf="applicationInformationForm.controls.tipoSolicitud.value == '9a62b199-cae2-43e8-8371-0564edf6c011'" class="entradas-de-texto-govco col-sm-12 col-md-12" style="height: 150px;">
      <textarea class="form-control h-100" placeholder="Observaciones de la modificación" id="floatingTextarea"></textarea>
    </div>
    <div *ngIf="applicationInformationForm.controls.tipoSolicitud.value == '1a62b199-cae2-43e8-8371-0564edf6c011' || applicationInformationForm.controls.tipoSolicitud.value == 'c'" class="entradas-de-texto-govco col-sm-12 col-md-12" style="height: 150px;">
      <textarea class="form-control h-100" placeholder="Observaciones de la cancelación" id="floatingTextarea2"></textarea>
    </div>
  </div>
</form>
