import { Component } from '@angular/core';

@Component({
  selector: 'app-first-natural',
  templateUrl: './first-natural.component.html',
  styleUrls: ['./first-natural.component.scss']
})
export class FirstNaturalComponent {
  botonEnviarBloqueado = true;

  bloquearBotonEnviar(formularioEstaVacio: boolean): void {
    this.botonEnviarBloqueado = formularioEstaVacio;
  }
}
