import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {

  @Input() type: string;

  @Input() title: string;

  @Input() description: string;

  @Input() buttonName: string;

  @Input() buttonName2: string;

  @Output() confirmButtonEvent = new EventEmitter<any>();


  confirm = (e:any) => {
    this.confirmButtonEvent.emit(e);
  }

}
