import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { RequestService } from '@core-app/services/request.service';
import { Usuario } from '@core-app/services/usuario';
import { AppBaseComponent } from 'src/app/core/utils';

@Component({
  selector: 'app-validation-section',
  templateUrl: './validation-section.component.html',
  styleUrls: ['./validation-section.component.scss']
})
export class ValidationSectionComponent extends AppBaseComponent implements OnInit {
  resultvalidate:any[]=[];
  /**
   * Lista de resultados de la validación
   */
  @Input() validationResults: any;

  /**
   * Lista de resultados de la validación
   */
  @Input() generateVisibleDocument: boolean;

  /**
   * Lista de auditoria
   */
  @Input() auditoria: any;

  /**
   * Formulario reactivo
   */
  public validationSectionForm: any;

  @Output() generateDocumentButtonEvent = new EventEmitter<any>();

  @Output() monitoringButtonEvent = new EventEmitter<any>();


  generateDocument = () => {
    this.generateDocumentButtonEvent.emit("Generar documento");
  }

  monitoring = () => {
    this.monitoringButtonEvent.emit("auditar la solicitud");
  }

  constructor(private controlContainer: ControlContainer, private usuario: Usuario, private requestService: RequestService) {
    super();
  }

  ngOnInit(): void {
    this.validationSectionForm = this.controlContainer.control;
    this.validationSectionForm = this.validationSectionForm.controls['validationSectionForm'];
  }

}
