import {Component, Inject, Renderer2} from '@angular/core';
import { DOCUMENT } from "@angular/common";

/**
 * Component encargado de la accesibilidad de la página
 */
@Component({
  selector: 'app-accesibility',
  templateUrl: './accesibility.component.html',
  styleUrls: ['./accesibility.component.scss']
})
export class AccesibilityComponent {

  font: number = 16;
  colorBG: any;
  activeContrast: any = 0;

  constructor(@Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2) {
  }


  ngOnInit(): void {
    this.moveContrast();
  }

  up() {
    this.font = this.font === 100 ? this.font : this.font + 1;
    // @ts-ignore
    document.querySelector("html").style.fontSize = this.font + "px";
  }

  down() {
    this.font = this.font === 1 ? this.font : this.font - 1;
    // @ts-ignore
    document.querySelector("html").style.fontSize = this.font + "px";
  }

  moveContrast() {
    //this.activeContrast = parseInt(localStorage.getItem('contrasteActivo') || '0');
    if (this.activeContrast == 1) {
      this.activeContrast = false;
      //localStorage.setItem('contrasteActivo', '0');
      setTimeout(() => {
        this.renderer.addClass(this.document.body, 'body-cs');
        // document.body.classList.add("body-cs");
      }, 200);
    } else {
      this.activeContrast = true;
      //localStorage.setItem('contrasteActivo', '1');
      setTimeout(() => {
        this.renderer.removeClass(this.document.body, 'body-cs');
        // document.body.classList.remove("body-cs");
      }, 200);
    }
  }
}
