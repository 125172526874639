<section class="mt-4">
  <app-request-data></app-request-data>
</section>
<section class="mt-4">
  <app-request-info (formularioVacio)="bloquearBotonEnviar($event); manejarEstadoFormulario()"></app-request-info>
  <div *ngIf="botonEnviarBloqueado" class="text-danger">
  </div>
</section>

<section class="mt-4">
  <app-request-person></app-request-person>
</section>
<section class="mt-4">
  <app-document-request></app-document-request>
</section>

<div class="row">
  <div class="col">
    <button class="btn btn-primary button_option">Cancelar</button>
    <button class="btn btn-primary button_option" [disabled]="botonEnviarBloqueado" >Enviar</button>
  </div>
</div>
