import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { RequestService } from '@core-app/services/request.service';
import { Usuario } from '@core-app/services/usuario';
import { AppBaseComponent } from '@core-app/utils';


@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.scss'],
})

export class BasicDataComponent extends AppBaseComponent implements OnInit {
  documentTypes:any = [];
  datos: any[] = [];
  selecttypePerson: string = '';
  mostrarAlgoPN: boolean = false;
  mostrarAlgoPJ: boolean = false;
  userData: any;
  selectedCapacitacion: number;
  @Input() formRequest: any;
  selectdni:any[]=[];
  router: any;
  checkcapacitation: any[] = [];
  tiposCapacitaciones:any[] = [];
  flags:boolean[] = [true,true,true];

  constructor(private requestService: RequestService, private usuario: Usuario, private fb: FormBuilder) {
    super();
    localStorage.setItem("tiposCapacitaciones",JSON.stringify([]));
    this.selecttypePerson = JSON.parse(localStorage.getItem("selecttypePerson"));
    console.log("soy persona" , this.selecttypePerson);
    console.log(localStorage.setItem("tiposCapacitaciones",JSON.stringify([])));
    this.readInputPerson();


    this.requestService.GetDocumentTypesShared().subscribe((result) => {
      console.log(result);
      console.log(this.formRequest);
      this.documentTypes = result.data;
    })
    this.conditionalPerson();
  }

  ngOnInit(): void {
    this.formRequest.get('tipoDocumento').disable();
    console.log("----------000-----------");
    this.listCapacitation();
    if(this.mostrarAlgoPJ){
      this.formRequest.get("razon").setValue(this.userData.razonSocial);
      this.formRequest.get("nit").setValue(this.userData.numeroIdentificacionRL);
      this.formRequest.get("tipoDocumento").setValue(this.userData.tipoIdentificacionRL);
      this.formRequest.get("code").setValue("101231");
    }
    this.formRequest.get("primerNombre").setValue(this.userData.primerNombre);
    this.formRequest.get("segundoNombre").setValue(this.userData.segundoNombre);
    this.formRequest.get("primerApellido").setValue(this.userData.primerApellido);
    this.formRequest.get("segundoApellido").setValue(this.userData.segundoApellido);
    this.formRequest.get("tipoDocumento").setValue(this.userData.tipoIdentificacion);
    this.formRequest.get("numeroDocumento").setValue(this.userData.numeroIdentificacion);
    this.formRequest.get("numeroContactoN").setValue(this.userData.telefonoCelular);
    this.formRequest.get("correoElectronicoN").setValue(this.userData.email);
    this.formRequest.get("address").setValue(this.userData.direResi);
    console.log("--datos compartidos--");
    console.log(this.selecttypePerson);
    console.log(this.userData,"datos personales");
    //console.log(this.controlContainer.control);
   // console.log("---------------------");
    //console.log(this.formDataAux);
    //this.formDataAux = this.formDataAux.controls['formDataAux'];
  }

  readInputPerson(){
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  conditionalPerson() {
    if (parseInt(this.selecttypePerson) == 0 || this.selecttypePerson==null) {
      this.mostrarAlgoPN = true;
    } else {
      this.mostrarAlgoPJ = true;
    }

  }

  listDni() {
    this.usuario.getTypeDni('bTipoIdentificacion').subscribe((values) => {
      this.selectdni = values.data;
      console.log("datos identi", this.selectdni);
    });
  }
  listCapacitation() {
    this.usuario.getTiposCapacitacion().subscribe((response) => {
      this.checkcapacitation = response.data;
    });
  }

  capacitacion(e:any){

    let aux:any[] = [];
    aux = this.tiposCapacitaciones.find((tc) => tc.idTipoCapacitacion == e.idTipoCapacitacion);

   ;

    console.log(e);
    console.log(aux);
    console.log(this.flags[e.idTipoCapacitacion-1])

    switch(e.idTipoCapacitacion){
      case 1:
      console.log("caso 1");
        if (this.flags[e.idTipoCapacitacion-1]) {
          console.log("marcado");
          if(aux == undefined){
            this.tiposCapacitaciones.push(e);
          }
        }else{
          console.log("desmarcado")
          if(aux != undefined){
            this.tiposCapacitaciones = this.tiposCapacitaciones.filter((tc) => tc.idTipoCapacitacion != e.idTipoCapacitacion);
            if (this.tiposCapacitaciones == undefined) {
              this.tiposCapacitaciones = [];
            }
          }
        }
      break;
      case 2:
        console.log("caso 2");
        if (this.flags[e.idTipoCapacitacion-1]) {
          console.log("marcado");
          if(aux == undefined){
            this.tiposCapacitaciones.push(e);
          }
        }else{
          console.log("desmarcado")
          if(aux != undefined){
            this.tiposCapacitaciones = this.tiposCapacitaciones.filter((tc) => tc.idTipoCapacitacion != e.idTipoCapacitacion);
            if (this.tiposCapacitaciones == undefined) {
              this.tiposCapacitaciones = [];
            }
          }
        }
      break;
      case 3:
        console.log("caso 3");
        if (this.flags[e.idTipoCapacitacion-1]) {
          console.log("marcado");
          if(aux == undefined){
            this.tiposCapacitaciones.push(e);
          }
        }else{
          console.log("desmarcado")
          if(aux != undefined){
            this.tiposCapacitaciones = this.tiposCapacitaciones.filter((tc) => tc.idTipoCapacitacion != e.idTipoCapacitacion);
            if (this.tiposCapacitaciones == undefined) {
              this.tiposCapacitaciones = [];
            }
          }
        }
      break;
      default:
      break;
    }
    this.flags[e.idTipoCapacitacion-1] = !this.flags[e.idTipoCapacitacion-1];
    console.log(this.tiposCapacitaciones);
    localStorage.setItem("tiposCapacitaciones",JSON.stringify(this.tiposCapacitaciones));
  }

  public getErrorMessage(field: string): string {



    let message;
    const onlyLetters: Array<string> = ['titulo'];
    const onlyNumbers: Array<string> = [''];



    if (this.isTouchedField(this.formRequest,field)) {
      if (this.formRequest?.get(field).hasError('required')) {
        message = 'Es Requerido';
      } else if (onlyLetters.includes(field)) {

        if (this.formRequest?.get(field).hasError('pattern')) {
          message = 'Solo se admiten letras';
        } else if (this.formRequest?.get(field).hasError('maxlength')) {
          message = `Excede el numero de caracteres permitidos`;
        }
        else if (this.formRequest?.get(field).hasError('minlength')) {
          message = `Debe tener al menos 10 caracteres`;
        }


      } else if (onlyNumbers.includes(field)) {
        if (this.formRequest?.get(field).hasError('pattern')) {
          message = 'Solo se admiten números';
        } else if (this.formRequest?.get(field).hasError('minlength')) {
          message = `Debe tener al menos 10 caracteres`;
        } else if (this.formRequest?.get(field).hasError('maxlength')) {
          message = `Permite hasta 11 caracteres`;
        }

      } else if (this.formRequest?.get(field).hasError('email')) {
        message = 'No tiene el formato de un email';
      }  else if (this.formRequest?.get(field).hasError('pattern')) {
        message = `Caracter no permitido ` ;
      }

    }

    return message;
  }
}
