import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Properties } from 'src/app/core/services/properties';

@Component({
  selector: 'app-document-request',
  templateUrl: './document-request.component.html',
  styleUrls: ['./document-request.component.scss']
})
export class DocumentRequestComponent {

  @Output() formularioVacio = new EventEmitter<boolean>();

  requestDocument: FormGroup;
  elementosSelect: { idTipoSolicitud: string, descripcion: string }[] = [];
  elementosSelectCancel: { idMotivoCancelacion: string, descripcion: string }[] = [];


  constructor(public fb: FormBuilder, public properties: Properties) {
    this.requestDocument = this.fb.group({
      requestDocument: [''],
    });

    this.requestDocument.valueChanges.subscribe(() => {
      this.verificarFormularioVacio();
    });
  }

  ngOnInit() {

  }

  verificarFormularioVacio(): void {
    const formularioEstaVacio = this.requestDocument.invalid;
    this.formularioVacio.emit(formularioEstaVacio);
  }

  notificarEstadoFormulario(): void {
    const formularioEstaVacio = this.requestDocument.invalid;
    this.formularioVacio.emit(formularioEstaVacio);
  }


}
