import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  mostrarAlgo: boolean = true;
  private userData: any;
  sharedData: any;
  private selecttypePersonValue: string = '';

  constructor() { }


  setSelecttypePerson(value: string) {
    this.selecttypePersonValue = value;
  }

  getSelecttypePerson(): string {
    return this.selecttypePersonValue;
  }

  setUserData(data: any) {
    this.userData = data;
  }

  getUserData(): any {
    return this.userData;
  }

  getMostrarAlgo(): boolean {
    return this.mostrarAlgo;
  }

  setMostrarAlgo(value: boolean): void {
    this.mostrarAlgo = value;
  }

  setSharedData(data: any) {
    this.sharedData = data;
  }

}
