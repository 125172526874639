import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario } from '@core-app/services/usuario';
import { ROUTES } from '@core-app/enums';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentsService } from '@core-app/services/documents.service';
import { RequestService } from '@core-app/services/request.service';
import { environment } from 'src/environments/environment';
const {PROCEDURE_FILE_MANAGER_URI} = environment;


@Component({
  selector: 'app-capacitation-tab',
  templateUrl: './capacitation-tab.component.html',
  styleUrls: ['./capacitation-tab.component.scss']
})

export class CapacitationTabComponent implements OnInit{
  public selectOptionFilterForm: FormGroup;
  isLoading:boolean = true;
  public dataFilterTable: any;
  public dataTableManagement: any = [];
  public dataFilterTableManagement: any = [];
  public dataFilterTableTrainingsFollowUp: any;
  viewDocument:boolean = false;
  urlSafeDocument: SafeResourceUrl;
  idSolicituCapacitation: string = '';
  base64PDF:string = "";

   /**
   * datos de la bandeja del validador
   */


   public searchbar:boolean=true;
  public datebar:boolean=false;
  public status:boolean=false;

   public dataTable: any = [
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 5,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    }
   ];


   /**
    * Columnas de la bandeja del logistico
    */

   public structureTable: any = [
    {
      name: 'Número de resolución',
      type: 'texto',
      value: 'intNumeroResolucion'
    },
    {
      name: 'Fecha de resolución',
      type: 'texto',
      value: 'fechaResolucion'
    },
    {
      name: 'Tipo de autorización',
      type: 'texto',
      value: 'vcNombre'
    },
    {
      name: 'Estado de autorización',
      type: 'texto',
      value: 'bEstado'
    },
    {
      name: 'Documento',
      type: 'documento'
    },
    {
      name: 'Acciones permitidas',
      type: 'enlaceMultiple',
      state: 'bEstado',
      links: [
        {
          name: 'Registrar capacitación',
          states: [
            'Activas'
          ]
        },
        {
          name: 'Editar capacitación',
          states: [
            'Activa'
          ]
        },
      ]
    },


  ];




   constructor(private requestService: RequestService, private fb:FormBuilder, private usuario : Usuario,private router: Router,private documentService: DocumentsService,private sanitizer: DomSanitizer, private request: RequestService){
      this.isLoading = true;
      this.selectOptionFilterForm = this.fb.group({
      selectOptionFilterInput: ['0'],
      valueFilterInput: ['', [Validators.pattern('[a-zA-Z0-9/ ]*'), Validators.maxLength(20)]],
        fecha:"",
        estado:""
      });
     const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;
      this.usuario.getCapacitation(idUser).subscribe((values) => {
        console.log("datos en la solicitud", values);
        this.idSolicituCapacitation = values.data.idSolicitud;
        this.dataTableManagement = values.data.map((item: any) => {
          if (item.bEstado === true) {
            item.bEstado = 'Activa';
          }else{
            item.blEstado = 'Inactiva';
          }
          return item;
        });
        const ids = values.data.map((obj: any) => obj.idSolicitud);
        console.log("Valores de idSolicitud:", ids);
        console.log("datos encontrados para filtrar", this.dataTableManagement);
        this.dataFilterTableManagement = this.dataTableManagement;
        this.dataFilterTable = this.dataTableManagement;
        this.isLoading = false;
      });
      this.dataFilterTable = this.dataTableManagement;
  }

  ngOnInit(){

  }

  redirectToViewDetail(e: any) {
    localStorage.setItem('Rol',"capacitador");

      const idSolicitud = e.record.idSolicitud;
      localStorage.setItem('idSolicitud', idSolicitud.toString());
      this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.REQUEST_CAPACITATION}/${idSolicitud}`);


  }

   viewApprovalDocument = (e:any) =>{

    console.log(e);

    this.requestService.GetRequest(e.record.idSolicitud).subscribe(result => {
      console.log(result);

      const contenedor = "manipalimentos";

      const oid = result.data.vcRadicado;

      const path = e.record.vcPath;

      this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${oid}/${path}`);

      this.viewDocument = true;
    });

  }

  changestatus(){

    if(this.selectOptionFilterForm.get('selectOptionFilterInput').value=="2")
    {
      this.datebar=true;
      this.searchbar =false;
      this.status =false;
    }
    else {
      if(this.selectOptionFilterForm.get('selectOptionFilterInput').value=="4")
      {
        this.datebar=false;
        this.searchbar =false;
        this.status =true;
      }
      else {
        this.datebar=false;
        this.searchbar =true;
        this.status =false;
      }

    }
  }
  filter(){
    console.log('Cantidad de datos: '+this.dataFilterTable.length);
    console.log(this.dataFilterTable);

    switch (this.selectOptionFilterForm.get('selectOptionFilterInput').value) {
      case '1':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { intNumeroResolucion: any; }) => d.intNumeroResolucion.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
        break;
      case '2':

        let fecha="";
        if(this.selectOptionFilterForm.get('fecha').value!=null)
        {
          fecha=this.selectOptionFilterForm.get('fecha').value.toString().split('-')
          fecha=fecha[2]+"/"+fecha[1]+"/"+fecha[0]
        }

        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { fechaResolucion: any; }) => d.fechaResolucion == fecha);
        break;
      case '3':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombre: any; }) => d.vcNombre.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '4':
        let status=this.selectOptionFilterForm.get('estado').value!;
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { bEstado: any; }) => d.bEstado == status);
      break;
      default:
      break;
    }
    console.log('Resultados filtrados');
    console.log('Cantidad de datos filtrados: '+this.dataFilterTable.length);
    console.log(this.dataFilterTableManagement);
  }

  closeDocument = () => {
    this.viewDocument = false;
  }


}
