<!--Succes alert-->
<div *ngIf="type=='sucess'" class="modal fade" id="mdSucess" tabindex="-1" role="dialog" aria-labelledby="mdSucessLabel"
  aria-hidden="true" #sucessAlert>
  <div class="modal-dialog gov-co-modal gov-co-modal-sucess" role="document" style="height: 500px;">
    <div class="modal-content" style="padding:15px">
      <div class="modal-body">
        <div class="gov-co-modal-type">
          <span class="govco-icon govco-icon-tramite-en-linea"></span>
          <h4 style="padding: 0px;">{{title}}</h4>
        </div>
        <div class="gov-co-modal-message">
          <p>{{description}}</p>
        </div>
      </div>
      <div class="modal-footer center">
        <button type="button" class="btn btn-high" style="color: white; font-size: 14px;padding:10px 40px;"
          data-dismiss="modal">{{buttonName}}</button>
      </div>
    </div>
  </div>
</div>

<!--Warning alert-->
<div *ngIf="type=='warning'" class="modal fade" id="mdWarning" tabindex="-1" role="dialog"
  aria-labelledby="mdWarningLabel" aria-hidden="true">
  <div class="modal-dialog gov-co-modal gov-co-modal-warning" role="document" style="height: 500px;">
    <div class="modal-content">
      <div class="modal-body">
        <div class="gov-co-modal-type">
          <span class="govco-icon govco-icon-exclamation-cn"></span>
          <h2>{{title}}</h2>
        </div>
        <div class="gov-co-modal-message">
          {{description}}
        </div>
      </div>
      <div class="modal-footer center">
        <button (click)="confirm(type)" type="button" class="btn btn-high" style="color: white; font-size: 14px;padding:10px 40px;"
          data-dismiss="modal">{{buttonName}}</button>
        <button type="button" class="btn btn-high" style="color: white; font-size: 14px;padding:10px 40px;"
          data-dismiss="modal">{{buttonName2}}</button>
      </div>
    </div>
  </div>
</div>
