import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-of-trainers',
  templateUrl: './list-of-trainers.component.html',
  styleUrls: ['./list-of-trainers.component.scss']
})
export class ListOfTrainersComponent  implements OnInit{
  mostrarTabla: boolean = false;
  newData: any[];




  @Input() titulo: string;
  @Input() trainers: any;

  @Input() canBeApprovedFlag: boolean = false;

  @Input() flag: boolean = false;

  @Output() viewDetailEvent = new EventEmitter<any>();

  @Output() approvedTrainerEvent = new EventEmitter<any>();

  cancelacion:boolean=false;


  viewDetail = (e:any) => {
    console.log(e);
    this.viewDetailEvent.emit(e);
  }

  approvedTrainer = (record:any, value: any) => {
    this.approvedTrainerEvent.emit({record,value});
  }

  ngOnInit(): void {
    this.hide();
    var datosComoJSON = localStorage.getItem('datosDelPadre');
    var datosDelPadre = JSON.parse(datosComoJSON);
    this.newData = datosDelPadre;

    console.log(localStorage.getItem('estado'));
    this.cancelacion=localStorage.getItem('estado') =='cancelacion';

  }


  hide(){
    if (this.titulo === 'Revisión') {
      this.mostrarTabla = false;
    } else {
      this.mostrarTabla = true;
    }
  }

}
