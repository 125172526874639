<nav class="navbar fixed-top navbar-expand-lg navbar-gov-co" style="padding: 0px;">
  <div class="navbar-gov-co-pri container-fluid">
      <div class="navbar-logo float-left" id="navbarlogo">
          <a class="navbar-brand" href="https://www.gov.co/" target="_blank" rel="noopener" class="logohead">
              <img src="https://cdn.www.gov.co/assets/images/logo.png" height="30" alt="Logo Gov.co" id="logoheader" class="logogov" />
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapsible" aria-controls="navbarCollapsible" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
      </div>
      <div class="collapse navbar-collapse navbar-first-menu float-right">
          <div class="nav-primary mx-auto">
              <ul class="navbar-nav ml-auto nav-items nav-items-desktop"></ul>
          </div>
      </div>

      <div class="nav-item-primary ml-auto mr-2 is-scroll">
        <div class="d-flex" style="color: white; font-weight: bold; padding: 5px;">
          <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" id="content-info-user">
            <ng-container *ngIf="currentuser">
              <img class="default-img-profile" src="./assets/icons/default_user.jpg" alt="name-citizen" />
            </ng-container>
            <span class="profile-user-name"> {{ this.currentuser?.fullName}} </span>
          </button>
          <mat-menu class="user-menu" #menu="matMenu" xPosition="before">
            <button mat-menu-item class="optionname">
              <span> {{ this.currentuser?.fullName}} </span>
            </button>
            <button mat-menu-item (click)="logout()">
              <span>Cerrar sesión</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <!-- user-->
  </div>
</nav>

<div class="navbar-gov-co-search">
  <div class="content-logo">
      <img src="./assets/images/logoSDS.png" alt="Secretaria Distrital de Salud" class="logosds" />
      <!-- ./assets/images/logoSDS.png -->
  </div>
</div>
