<div class="row w-50 justify-content-center align-items-center mx-auto border ">

  <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-xxl-10  ">

    <br><br>
    <h2 class="text-center">Registro Ventanilla Unica</h2>
    <br><br>
    <div role="article" style="text-align: justify">
      <p>Tenga en Cuenta que para realizar nuestros trámites en línea, es obligatorio diligenciar previamente el
        REGISTRO DEL CIUDADANO (persona natural o jurídica), el cual servirá para la realización de trámites posteriores
        ante la Secretaria Distrital de Salud.</p>
    </div>
    <br><br>
    <div class="row mx-auto" style="text-align: center">
      <div class="col-sm-6 col-md-6 ">
        <button (click)="Natural()" type="button" class="btn-govco fill-btn-govco">Registro Persona Natural</button>
      </div>

      <div class="col-sm-6 col-md-6">
        <button (click)="Juridica()" type="button" class="btn-govco fill-btn-govco">Registro Persona Jurídica</button>
      </div>
    </div>

    <br><br>
  </div>

</div>
