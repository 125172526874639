import { Component } from '@angular/core';

@Component({
  selector: 'app-training-record',
  templateUrl: './training-record.component.html',
  styleUrls: ['./training-record.component.scss']
})
export class TrainingRecordComponent {
  showDiv = false;
  showDivHour = true;

  toggleDiv() {
    this.showDiv = !this.showDiv;
  }

  toggleDivHour() {
    this.showDivHour = !this.showDivHour;
  }
}
