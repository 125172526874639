import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-trainer-details',
  templateUrl: './trainer-details.component.html',
  styleUrls: ['./trainer-details.component.scss']
})
export class TrainerDetailsComponent {

  @Input() trainerDetailsForm: any;

  /**
   * applicantType determina el tipo de usuario, natural -> persona natural y legal -> persona juridica
   */
  @Input() applicantType: string;

  tiposCapacitacion = [
    {
      name: 'Carnes y productos carnicos comestibles',
      id: 1
    },
    {
      name: 'Leche cruda',
      id: 2
    },
    {
      name: 'Alimentos en vía pública',
      id: 3
    }
  ]

}
