import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from '@core-app/enums';
import { emailDTO } from '@core-app/models/emailDto';
import { RequestService } from '@core-app/services/request.service';
import { Usuario } from '@core-app/services/usuario';
import { DocumentsService } from 'src/app/core/services/documents.service';
import Swal from 'sweetalert2';
import {lastValueFrom} from "rxjs";
import {id} from "date-fns/locale";
import {RegisterService} from "@core-app/services/register.service";
const conversor = require('conversor-numero-a-letras-es-ar');

@Component({
  selector: 'app-request-management-assistant-manager',
  templateUrl: './request-management-assistant-manager.component.html',
  styleUrls: ['./request-management-assistant-manager.component.scss']
})
export class RequestManagementAssistantManagerComponent {

  meses = ["Enero", "Febrero", "Marzo","Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre","Octubre", "Noviembre", "Diciembre"];

  viewDocumentButton:boolean = true;

  showSuccesAlert: boolean = false;

  Signature:string = "";

  canBeApprovedFlag = false;

  canBeApprovedObj:any = null;

  documentoSolicitud: any;

  documentVisualizationTitle:string = "";

  solicitud:any;

  idCapacitadorJ:any[];

  auditoria: any = [];

  observacionTemporal = "";

  observacionId = 0;

  detailDocumentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = [];

  detailTrainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];

  documentTypes22: any = [];

  isLoadingTrainer:boolean = true;

  fileNumber: string = "";

  isLoading:boolean = true;

  isNatural:boolean = false;

  idSolicitud:number;

  documentationSolicitud:any;

  urlSafeDocument: SafeResourceUrl;

  base64PDF:string = "";

  isResourceReview = false;

  observacionesCancelacion:string = "";

   /**
   * Ver detalle de capacitador
   */
   viewTrainerDetailModal:boolean = false;

  /**
   * Ver documento
   */
  viewDocument:boolean = false;

  updateDocumentation:boolean = true;

  mostrar:boolean = false;

  /**
   * Formulario principal de la interfaz de gestión de solicitudes
   */
  public requestManagementAssistantManagerForm: FormGroup;

  /**
   * Lista de opciones de la linea de avance
   */
  advanceLineData = [
    {
      name: 'Bandeja de trámites',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_ASSISTANT_MANAGER
    },
    {
      name: 'Revisar solicitud',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_ASSISTANT_MANAGER
    },
    {
      name: 'Verificar solicitud',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_ASSISTANT_MANAGER
    },
    {
      name: 'Para firma',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_ASSISTANT_MANAGER
    }
  ]

  /**
   * Lista de capacitadores
   */
  trainers:any = [];

  trainersCopy:any = [];

  /**
   * Lista de tipos de documento
   */

    documentTypes:any = []

    /**
   * Listado de la documentación requerida
   */

    documentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = []

  documentationSend: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = []


  /**
   *
   * Listado de capacitaciones y sus opciones seleccionadas
   */

  trainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];

  /**
   *
   * Listado de los resultados de la validación
   */
  validationResults = [
    {
      vcNombre: 'Aprobar y firmar',
      idParametroDetalle: 1
    },
    {
      vcNombre: 'Devolver a Coordinador',
      idParametroDetalle: 2
    }
  ]

  usuariointerno = "";

  constructor(private router: Router, private usuario: Usuario, private fb: FormBuilder, private documentService: DocumentsService,
    private sanitizer: DomSanitizer, private requestService: RequestService, private activateRoute: ActivatedRoute,
              private registerService:RegisterService){

      this.isLoading = true;

      this.isNatural = this.activateRoute.snapshot.paramMap.get('type')=="P. Natural";

      this.requestManagementAssistantManagerForm = this.fb.group({
      applicationInformationForm: this.fb.group({
        numeroRadicado: "",
        tipoTramite: "",
        estado: "",
        tipoSolicitud: "",
        fechaSolicitud: "",
        usuarioAsignado:"0"
        //dateRequest: [formatDate(new Date('2023-03-04'), 'yyyy-MM-dd', 'en'), [Validators.required]],
      }),
      basicDataForm: this.fb.group({
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        tipoDocumento: 1,
        numeroDocumento: "",
        numeroContacto: "",
        correoElectronico: "",
        direccion: ""
      }),
      validationSectionForm: this.fb.group({
        resultadoValidacion: "",
        observaciones: ""
      }),
      applicationDataForm: this.fb.group({
        tituloProfesional: '',
        numeroTarjetaProfesional: ''
      }),
      modalTrainerForm: this.fb.group({
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        tipoDocumento: "",
        numeroDocumento: "",
        tituloProfesional: "",
        numeroTarjetaProfesional: "",
        numeroContacto: "",
        correoElectronico: "",
        revision:""
      })
    });

    this.requestService.GetDocumentTypesShared().subscribe((result) => {
      console.log(result);
      this.documentTypes = result.data;
    })


    const idReq = this.activateRoute.snapshot.paramMap.get('idRequest');

    this.requestService.GetRequest(parseInt(idReq)).subscribe((result) => {
      console.log("datos de la solicitud")
      console.log(result);
      if (result!=null) {

        this.idSolicitud = result.data.idSolicitud;
        this.solicitud = result.data;
        this.documentationSolicitud = result.data;

        const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

        this.solicitud.seguimientoAuditoriaSolicitud.forEach((element: { blSeguimientoDefinitivo: any; vcObservacion: string; usuarioId: string; idObservacion: number }) => {

          if(!element.blSeguimientoDefinitivo && element.usuarioId == idUser && this.solicitud.vcEstado != "Aprobado"){
            this.observacionTemporal = element.vcObservacion;
            this.observacionId = element.idObservacion;
          }
        });

        //Visibilidad de boton de ver documentos

        //Aprobar solicitud o Negar solicitud
        if (result.data.resultadoValidacionId == 6 || result.data.resultadoValidacionId == 8 || result.data.resultadoValidacionId == 9 || result.data.resultadoValidacionId == 10 || result.data.resultadoValidacionId == 7) {
          this.viewDocumentButton = false;
          this.requestService.GetSignature(1).subscribe((result:any) => {
            this.Signature = result.data.vcFirma;
            localStorage.setItem('estado','flujo normal')
            this.mostrar=true;
          })
        }

        if(result.data.resultadoValidacionId == 10){
          this.requestService.GetDataResolution(this.solicitud.idSolicitud).subscribe((e:any) => {
            console.log(e.data.cancelacionPorIncumplimiento);
            this.observacionesCancelacion = e.data.cancelacionPorIncumplimiento;
            //localStorage.setItem('estado','cancelacion');
            this.mostrar=true;
          });
        }

        //información de la solicitud

        /*
        this.requestService.getValidationResults(result.data.idSolicitud).subscribe((values) => {
          console.log("datos resultado validacion");
          console.log(values.data);
          this.validationResults = values.data;
        })
        */




        /*
        let resultList:any;

        switch (this.solicitud.vcEstado) {
          case "En Revisión":
            console.log("Solicitud - en revisión");
            if (this.solicitud.vcTipoTramite == "Primera vez") {
              console.log("Solicitud - Primera vez");

              resultList = [
                {
                  idParametroDetalle: 6,
                  vcNombre: 'Aprobar solicitud'
                },
                {
                  idParametroDetalle: 8,
                  vcNombre: "Negar solicitud"
                },
                {
                  idParametroDetalle: 9,
                  vcNombre: "Para Subsanación"
                }
              ];
            }
          break;
          case "Aprobado":
            console.log("Solicitud - Aprobada");
            resultList = [
              {
                idParametroDetalle: 10,
                vcNombre: "Cancelar por incumplimiento"
              }
            ];
          break;
        }

        this.validationResults = resultList;

        */


        this.auditoria = result.data.seguimientoAuditoriaSolicitud;
        this.fileNumber = result.data.vcRadicado;
        this.usuariointerno=result.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;;
        this.isResourceReview = result.data.vcEstado=='En revisión - recurso';

        this.requestManagementAssistantManagerForm.get("validationSectionForm").get("observaciones").setValue(this.observacionTemporal);
        this.requestManagementAssistantManagerForm.get('applicationInformationForm').get('numeroRadicado').setValue(result.data.vcRadicado);
        this.requestManagementAssistantManagerForm.get('applicationInformationForm').get('tipoTramite').setValue(result.data.vcTipoTramite);
        this.requestManagementAssistantManagerForm.get('applicationInformationForm').get('estado').setValue(result.data.vcEstado);
        this.requestManagementAssistantManagerForm.get('applicationInformationForm').get('fechaSolicitud').setValue(result.data.vcFechaSolicitud);

        console.log(result.data.usuarioId)
        this.usuario.getInfoperson(result.data.usuarioId).subscribe((data: any) => {
          this.usuario.getTotalperson(data['data']).subscribe((valores: any) => {

            console.log("hey!");
            console.log(valores);

            //Datos del solicitante
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').setValue(valores.data.primerNombre);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('segundoNombre').setValue(valores.data.segundoNombre);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').setValue(valores.data.primerApellido);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('segundoApellido').setValue(valores.data.segundoApellido);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroContacto').setValue(valores.data.telefonoCelular);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').setValue(valores.data.email);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('direccion').setValue(valores.data.direResi);
            if (this.isNatural) {
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacion);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacion);
              //Datos de la solicitud

              //this.idCapacitadorN = result.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;

              let obj = null;

              this.requestService.GetTraining().subscribe((result2) => {
                console.log(result2);
                result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

                  obj = {
                    id: r.idTipoCapacitacion,
                    name: r.vcDescripcion,
                    selected: false
                  }

                  this.trainingDataTypes.push(obj);
                });

                this.trainingDataTypes.forEach((t) => {
                  console.log("forEach trainings");
                  let aux = result.data.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

                  if (aux != undefined) {
                    t.selected = true;
                  }
                })
              })
              this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').setValue(result.data.capacitadoresSolicitud[0].vcTituloProfesional);
              this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').setValue(result.data.capacitadoresSolicitud[0].vcNumeroTarjetaProfesional);

              //Documentation



              let doc = null;

              result.data.capacitadoresSolicitud[0].documentosSolicitud.forEach((ds: any) => {

                console.log(ds);
                doc = {
                  id: ds.idDocumento,
                  descripcion: ds.vcTipoDocumento,
                  path: ds.vcPath,
                  aprovado: ds.blIsValid,
                  fileNumber: this.fileNumber,
                  mandatory:ds.vcTipoDocumento!='Pensum de pregrado'
                }
                this.documentation.push(doc);
                this.documentationSend.push(doc);
              });



            }else{
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacionRL);
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacionRL);
              this.idCapacitadorJ = result.data.capacitadoresSolicitud;
              //Trainers

              let t = null;
              let index = 1;



              result.data.capacitadoresSolicitud.forEach((cs: any) => {

                console.log(cs);
                t = {
                  id: index,
                  firstName: cs.vcPrimerNombre,
                  secondName: cs.vcSegundoNombre,
                  surname: cs.vcPrimerApellido,
                  secondSurname: cs.vcSegundoApellido,
                  documentType: cs.vcTipoIdentificacion,
                  identificationNumber: cs.intNumeroIdentificacion,
                  professionalTitle: cs.vcTituloProfesional,
                  professionalCardNumber: cs.vcNumeroTarjetaProfesional,
                  telephoneContact: cs.intTelefono,
                  emailAddress: cs.vcEmail,
                  approved: cs.blCapacitadorActivo,
                  disabledFlag: this.allBeApproved(cs.intNumeroIdentificacion)
                }
                this.trainers.push(t);
                this.trainersCopy.push(t);
                index++
              });

            }
            this.isLoading = false;
          });
        });
      }
    })

  }

  addSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar");
    console.log(e);
  }

  closeButton(): void {
    this.showSuccesAlert = false;
    this.router.navigateByUrl(
      ROUTES.MainPage + '/' + ROUTES.Dashboard + '/' + ROUTES.DASHBOARD_ASSISTANT_MANAGER
    );
  }

  addApprovedDocumentation = (e:any) => {
    console.log("lista de documentacion a asignar");
    console.log(e);

    this.documentationSend.forEach((element) => {

      if (element.id == e.record.id) {
        element.aprovado = e.value == 'si';
      }

    })
    this.updateDocumentation = false;
  }

  monitoring = (e:any) => {
    console.log(e);
  }

  send = () => {

    //this.viwDocumentAction(false, );

    if (this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value != "" ) {



        this.isLoading = true;

        let idUser:string = JSON.parse(localStorage.getItem('currentUser')).userId;
        const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;

        let obj:any = null;

        //let subsanacionSolicitud = null;
        //let cancelacionSolicitud = null;

          console.log("persona juridica");
          console.log(this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value);
          let capacitadoresSolicitud: any[] = [];

          if (this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value == 2) {
            //Devolver al coordinador


            capacitadoresSolicitud = this.trainersCopy.map((e:any) => {
              return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
            })

            let documentosArray: any[] = [];
            this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
              documentosArray = documentosArray.concat(element.documentosSolicitud);
            });

            this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

            let seguimiento = {
              idObservacion: this.observacionId,
              vcObservacion: this.requestManagementAssistantManagerForm.get('validationSectionForm').get('observaciones').value,
              usuarioId: idUser,
              vcNombreUsuario: userName
            }
            let asignado = false;
            let userasignado=idUser;
            if(this.requestManagementAssistantManagerForm.get('applicationInformationForm').get('usuarioAsignado').value !="0")
            {
              asignado=true;
              userasignado=this.requestManagementAssistantManagerForm.get('applicationInformationForm').get('usuarioAsignado').value.toString().split(",")[0];
            }

            obj = {
              idSolicitud: this.idSolicitud,
              asignado: asignado,
              usuarioAsignadoId: userasignado,
              capacitadorSolicitud: capacitadoresSolicitud,
              documentoSolicitud: this.documentoSolicitud,
              seguimientoAuditoriaSolicitud: seguimiento,
              resultadoValidacion: false,
              resolucionSolicitud: {
                idResolucionSolicitud: 0,
                documentoResolucion: {
                  idDocumento: 0,
                  solicitudId: this.idSolicitud,
                  usuarioId: idUser,
                  tipoDocumentoId: 43,
                  vcNombreDocumento: "Resolución de aprobación",
                  vcPath: idUser + "_" + (("Resolución de aprobación")).replaceAll(" ","_")+".pdf"
                }
              }
            }

          }

          if (this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value == 1) {


            //Aprobar y firmar

            let aprobado = true;

            //console.log("aprobar y firmar");
            //console.log(this.solicitud.resultadoValidacionId);

            if (this.solicitud.resultadoValidacionId == 6) {
              //Aprobar solicitud

              console.log("Aprobar solicitud");
              aprobado = true;
            }

            capacitadoresSolicitud = this.trainersCopy.map((e:any) => {
              return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
            })

            if(capacitadoresSolicitud.length==0)
            {
              capacitadoresSolicitud.push({
                idCapacitadorSolicitud: this.usuariointerno,
                blIsValid: true
              })
            }

            let documentosArray: any[] = [];
            this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
              documentosArray = documentosArray.concat(element.documentosSolicitud);
            });

            this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

            //console.log("crazy...");
            //console.log(documentosArray);
            //console.log(this.documentoSolicitud);

            let seguimiento = {
              idObservacion: this.observacionId,
              vcObservacion: this.requestManagementAssistantManagerForm.get('validationSectionForm').get('observaciones').value,
              usuarioId: idUser,
              vcNombreUsuario: userName
            }

            obj = {
              idSolicitud: this.idSolicitud,
              asignado: false,
              usuarioAsignadoId: idUser,
              capacitadorSolicitud: capacitadoresSolicitud,
              documentoSolicitud: this.documentoSolicitud,
              seguimientoAuditoriaSolicitud: seguimiento,
              resultadoValidacion: aprobado,
              resolucionSolicitud: {
                idResolucionSolicitud: 0,
                documentoResolucion: {
                  idDocumento: 0,
                  solicitudId: this.idSolicitud,
                  usuarioId: idUser,
                  tipoDocumentoId: 43,
                  vcNombreDocumento: "Resolución de aprobación",
                  vcPath: idUser+"_Resolución_de_aprobación.pdf"
                }
              }
            }
          }

          //----------------------------------------------
      let resolutionNumber = "";
      let resolutionDate:Date = null;
      let cancellationResolutionNumber = "";
      let cancellationResolutionDate:Date = null;
      this.isLoading = true;

      console.log(this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value );

      if(this.solicitud.resultadoValidacionId == 6)
      {
        obj.resolucionSolicitud.documentoResolucion.vcNombreDocumento="Resolución de aprobación";
        obj.resolucionSolicitud.documentoResolucion.vcPath=idUser+"_Resolución_de_aprobación.pdf";
      }
      if(this.solicitud.resultadoValidacionId == 8)
      {
        obj.resolucionSolicitud.documentoResolucion.vcNombreDocumento="Resolución de negación";
        obj.resolucionSolicitud.documentoResolucion.vcPath=idUser+"_Resolución_de_negación.pdf";
      }



      this.requestService.checkAssistantManager(obj).subscribe((res) => {

        console.log(res);
        if (res.code == 201) {

          if (this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value == 1) {
            console.log('va aca')
            this.requestService.GetDataResolution(this.solicitud.idSolicitud).subscribe((e:any) => {
              console.log(this.solicitud.idSolicitud)
              console.log('llego')
              console.log(e.data)
              if (e.data !=null) {
                resolutionNumber = e.data.vcNumeroResolucion;
                resolutionDate = new Date(e.data.fechaResolucion);
                if(e.data.cancellationResolution != null){
                console.log(e.data.cancellationResolution);
                console.log(e.data.cancellationResolution.fechaResolucion);
                cancellationResolutionNumber = e.data.cancellationResolution.vcNumeroResolucion;
                cancellationResolutionDate = new Date(e.data.cancellationResolution.fechaResolucion);
                }


                let observacioncancelacion = e.data.cancelacionPorIncumplimiento;
                console.log(resolutionDate);
                console.log(resolutionNumber);

                let template = "";

                let keysRA= [
                  "~:~nombre_del_solicitante~:~",
                  "~:~numero_de_la_resolucion~:~",
                  "~:~dia_de_la_resolucion~:~",
                  "~:~mes_de_la_resolucion~:~",
                  "~:~año_de_la_resolucion~:~"
                ];

                let valuesRA = [
                  this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                  resolutionNumber,
                  (resolutionDate.getDate()).toString().padStart(2, "0"),
                  this.meses[resolutionDate.getMonth()],
                  resolutionDate.getFullYear().toString(),
                ];

                console.log(this.solicitud.resultadoValidacionId == 6)
                if (this.solicitud.resultadoValidacionId == 6) {
                  //Aprobar solicitud

                  let template = "";
                  let ClaseConversor = conversor.conversorNumerosALetras;
                  let miConversor = new ClaseConversor();
                  let capacitaciones = "";

                  if (this.isNatural) {

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
                      capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
                    }

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
                      capacitaciones = capacitaciones + " Leche cruda "
                    }

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
                      capacitaciones = capacitaciones + " Alimentos en vía publica "
                    }

                  }

                  let keysA:any;
                  let valuesA:any;
                  console.log(this.isNatural);
                  if (this.isNatural) {
                    keysA = [
                      "~:~numero_de_la_resolucion~:~",
                      "~:~dia_de_la_resolucion~:~",
                      "~:~mes_de_la_resolucion~:~",
                      "~:~año_de_la_resolucion~:~",
                      "~:~resol_carne~:~",
                      "~:~decre_leche~:~",
                      "~:~resol_alimentos~:~",
                      "~:~nombre_solicitante~:~",
                      "~:~tipo_id_sol~:~",
                      "~:~num_id_sol_pn~:~",
                      "~:~tipo_capacitaciones~:~",
                      "~:~id_solicitud~:~",
                      "~:~fecha_id_solicitud~:~",
                      "~:~num_id_sol~:~",
                      "~:~titulo_profesional~:~",
                      "~:~num_tarj_profesional~:~",
                      "~:~año_letra_de_la_resolucion~:~",
                      "~:~firma_subdirector~:~",
                      "~:~nombre_validador~:~",
                      "~:~nombre_coordinador~:~",
                      "~:~capacitaciones_seleccionadas~:~"
                    ];

                    let capacitacioncompleta="";
                    let selected=false;

                    if(this.trainingDataTypes[2].selected)
                    {
                      capacitacioncompleta+=this.trainingDataTypes[2].name
                    }


                    for (let i = 0; i < 2; i++) {
                      if(this.trainingDataTypes[i].selected)
                      {
                        if(capacitacioncompleta!="")
                        { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

                        }
                        else
                        {
                          capacitacioncompleta+= this.trainingDataTypes[i].name
                        }

                      }
                    }
                    selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

                    valuesA = [
                      resolutionNumber,
                      (resolutionDate.getDate()).toString().padStart(2, "0"),
                      this.meses[resolutionDate.getMonth()],
                      resolutionDate.getFullYear().toString(),
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
                      this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected && !this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
                      this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                      this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      capacitaciones,
                      this.fileNumber,
                      this.solicitud.vcFechaSolicitud,
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                      miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                      this.Signature,
                      this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                      this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                      capacitacioncompleta
                    ];
                    this.requestService.GetTemplate(10).subscribe((e:any) => {
                      console.log(e);
                      template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

                      this.requestService.getDocument({html: template}).subscribe((r:any) => {
                        console.log(r);
                        console.log("Aprobar solicitud");
                        this.requestService.GetTemplate(2).subscribe(async (en: any) => {
                          console.log(en);
                          template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);


                          const formData = new FormData();
                          let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "Resolución_de_aprobación.pdf");

                          const reader = new FileReader();
                          reader.onload = (e: any) => {

                            console.log(e.target.result);
                            formData.append('NameFile', idUser+"_Resolución_de_aprobación.pdf");
                            formData.append('ContainerName', "manipalimentos");
                            formData.append('Oid', this.fileNumber);
                            formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                            this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                              this.showSuccesAlert = true;
                              this.isLoading = false;
                              console.log(respuesta);
                            })
                          };
                          reader.readAsArrayBuffer(file);
                          let email: emailDTO = {
                            to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                            body: template,
                            subject: 'Solicitud para verificación',
                            attachment: r.data,
                            attachmentTitle: "Resolución_de_aprobación.pdf"
                          }
                          this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                            console.log(r);
                          })
                        });
                      })
                    })
                  }else{
                    console.log('entra');
                    keysA = [
                      "~:~numero_de_la_resolucion~:~",
                      "~:~dia_de_la_resolucion~:~",
                      "~:~mes_de_la_resolucion~:~",
                      "~:~año_de_la_resolucion~:~",
                      "~:~resol_carne~:~",
                      "~:~decre_leche~:~",
                      "~:~resol_alimentos~:~",
                      "~:~nombre_rep_legal~:~",
                      "~:~tipo_doc_rep_legal~:~",
                      "~:~num_id_rep_legal~:~",
                      "~:~nombre_empresa~:~",
                      "~:~nit_empresa~:~",
                      "~:~tipo_capacitaciones~:~",
                      "~:~id_solicitud~:~",
                      "~:~fecha_id_solicitud~:~",
                      "~:~num_id_sol~:~",
                      "~:~titulo_profesional~:~",
                      "~:~num_tarj_profesional~:~",
                      "~:~año_letra_de_la_resolucion~:~",
                      "~:~firma_subdirector~:~",
                      "~:~nombre_validador~:~",
                      "~:~nombre_coordinador~:~",
                      "~:~capacitadores~:~",
                      "~:~capacitaciones_seleccionadas~:~"
                    ];
                    let capacitacioncompleta="";
                    let selected=false;

                    if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
                    {
                      capacitacioncompleta="Alimentos en vía publica";
                    }


                    for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
                      if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
                      {
                        if(capacitacioncompleta!="")
                        {
                          capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

                        }
                        else
                        {
                          capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
                        }
                      }
                    }
                    selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

                    valuesA = [
                      resolutionNumber,
                      (resolutionDate.getDate()).toString().padStart(2, "0"),
                      this.meses[resolutionDate.getMonth()],
                      resolutionDate.getFullYear().toString(),
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica") && !this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                      this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
                      capacitaciones,
                      this.fileNumber,
                      this.solicitud.vcFechaSolicitud,
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                      miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                      this.Signature,
                      this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                      this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                      this.getTrainers(this.solicitud.capacitadoresSolicitud),
                      capacitacioncompleta
                    ];
                    console.log('va por aca')
                    this.requestService.GetTemplate(9).subscribe((e:any) => {
                      console.log(e);
                      console.log('entro?');
                      template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

                      this.requestService.getDocument({html: template}).subscribe((r:any) => {
                        console.log(r);
                        console.log("Aprobar solicitud");
                        this.requestService.GetTemplate(2).subscribe(async (en: any) => {
                          console.log(en);
                          template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);
                          const formData = new FormData();
                          let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "Resolución_de_aprobación.pdf");
                          const reader = new FileReader();
                          reader.onload = (e: any) => {
                            console.log('entro documento');

                            console.log(e.target.result);
                            console.log('consola');
                            formData.append('NameFile', idUser+"_Resolución_de_aprobación.pdf");
                            formData.append('ContainerName', "manipalimentos");
                            formData.append('Oid', this.fileNumber);
                            formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                            console.log(formData);
                            this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                              this.showSuccesAlert = true;
                              this.isLoading = false;
                              console.log(respuesta);
                            })
                          };
                          reader.readAsArrayBuffer(file);
                          console.log(obj);
                          console.log(obj.resolucionSolicitud.documentoResolucion);


                          console.log('paso documento')
                          let email: emailDTO = {
                            to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                            body: template,
                            subject: 'Solicitud para verificación',
                            attachment: r.data,
                            attachmentTitle: "Resolución_de_aprobación.pdf"
                          }
                          this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                            console.log(r);
                          })
                        });
                      })
                    })
                  }
                }



                if(this.solicitud.resultadoValidacionId == 7){
                  this.showSuccesAlert = true;
                  this.isLoading = false;
                }

                if (this.solicitud.resultadoValidacionId == 8) {
                  //Negar solicitud
                  let template = "";
                  let ClaseConversor = conversor.conversorNumerosALetras;
                  let miConversor = new ClaseConversor();
                  let capacitaciones = "";

                  let keysN:any;
                  let valuesN:any;

                  //Negar solicitud
                  if (this.solicitud.resultadoValidacionId == 8) {
                    console.log("Negado");
                    if (this.isNatural) {
                      keysN= [
                        "~:~numero_de_la_resolucion~:~",
                        "~:~dia_de_la_resolucion~:~",
                        "~:~mes_de_la_resolucion~:~",
                        "~:~año_de_la_resolucion~:~",
                        "~:~resol_carne~:~",
                        "~:~decre_leche~:~",
                        "~:~resol_alimentos~:~",
                        "~:~nombre_solicitante~:~",
                        "~:~tipo_id_sol~:~",
                        "~:~num_id_sol_pn~:~",
                        "~:~tipo_capacitaciones~:~",
                        "~:~id_solicitud~:~",
                        "~:~fecha_id_solicitud~:~",
                        "~:~num_id_sol~:~",
                        "~:~titulo_profesional_capacitador~:~",
                        "~:~num_tar_profesional~:~",
                        "~:~año_letra_de_la_resolucion~:~",
                        "~:~firma_subdirector~:~",
                        "~:~nombre_validador~:~",
                        "~:~nombre_coordinador~:~",
                        "~:~capacitaciones_seleccionadas~:~"
                      ];
                      let capacitacioncompleta="";
                      let selected=false;

                      if(this.trainingDataTypes[2].selected)
                      {
                        capacitacioncompleta+=this.trainingDataTypes[2].name
                      }


                      for (let i = 0; i < 2; i++) {
                        if(this.trainingDataTypes[i].selected)
                        {
                          if(capacitacioncompleta!="")
                          { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

                          }
                          else
                          {
                            capacitacioncompleta+= this.trainingDataTypes[i].name
                          }

                        }
                      }
                      selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

                      valuesN = [
                        resolutionNumber,
                        (resolutionDate.getDate()).toString().padStart(2, "0"),
                        this.meses[resolutionDate.getMonth()],
                        resolutionDate.getFullYear().toString(),
                        this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
                        this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
                        this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                        this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        capacitaciones,
                        this.fileNumber,
                        this.solicitud.vcFechaSolicitud,
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                        miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                        this.Signature,
                        this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                        this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                        capacitacioncompleta
                      ];
                      this.requestService.GetTemplate(14).subscribe((e:any) => {
                        console.log(e);
                        template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);



                        this.requestService.getDocument({html: template}).subscribe((r:any) => {
                          console.log(r);
                          this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                          this.requestService.GetTemplate(4).subscribe(async (en: any) => {
                            console.log(en);
                            template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);

                            const formData = new FormData();
                            let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "_Resolución_de_negación.pdf");

                            const reader = new FileReader();
                            reader.onload = (e: any) => {

                              console.log(e.target.result);
                              formData.append('NameFile', idUser+"_Resolución_de_negación.pdf");
                              formData.append('ContainerName', "manipalimentos");
                              formData.append('Oid', this.fileNumber);
                              formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                              this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                                this.showSuccesAlert = true;
                                this.isLoading = false;
                                console.log(respuesta);
                              })
                            };
                            reader.readAsArrayBuffer(file);


                            let email: emailDTO = {
                              to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                              body: template,
                              subject: 'Solicitud para verificación',
                              attachment: r.data,
                              attachmentTitle: "Resolución_de_negación.pdf"
                            }
                            this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                              console.log(r);
                            })
                          });
                        })
                      })
                    }else{

                      keysN = [
                        "~:~numero_de_la_resolucion~:~",
                        "~:~dia_de_la_resolucion~:~",
                        "~:~mes_de_la_resolucion~:~",
                        "~:~año_de_la_resolucion~:~",
                        "~:~resol_carne~:~",
                        "~:~decre_leche~:~",
                        "~:~resol_alimentos~:~",
                        "~:~nombre_rep_legal~:~",
                        "~:~tipo_doc_rep_legal~:~",
                        "~:~num_id_rep_legal~:~",
                        "~:~nombre_empresa~:~",
                        "~:~nit_empresa~:~",
                        "~:~tipo_capacitaciones~:~",
                        "~:~id_solicitud~:~",
                        "~:~fecha_id_solicitud~:~",
                        "~:~num_id_sol~:~",
                        "~:~titulo_profesional~:~",
                        "~:~num_tarj_profesional~:~",
                        "~:~año_letra_de_la_resolucion~:~",
                        "~:~firma_subdirector~:~",
                        "~:~nombre_validador~:~",
                        "~:~nombre_coordinador~:~",
                        "~:~capacitadores~:~",
                        "~:~capacitaciones_seleccionadas~:~"
                      ];
                      let capacitacioncompleta="";
                      let selected=false;

                      if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
                      {
                        capacitacioncompleta="Alimentos en vía publica";
                      }


                      for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
                        if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
                        {
                          if(capacitacioncompleta!="")
                          {
                            capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

                          }
                          else
                          {
                            capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
                          }
                        }
                      }
                      selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");
                      valuesN = [
                        resolutionNumber,
                        (resolutionDate.getDate()).toString().padStart(2, "0"),
                        this.meses[resolutionDate.getMonth()],
                        resolutionDate.getFullYear().toString(),
                        this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
                        this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
                        this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                        this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
                        capacitaciones,
                        this.fileNumber,
                        this.solicitud.vcFechaSolicitud,
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                        miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                        this.Signature,
                        this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                        this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                        this.getTrainers(this.solicitud.capacitadoresSolicitud),
                        capacitacioncompleta
                      ];
                      this.requestService.GetTemplate(13).subscribe((e:any) => {
                        console.log(e);
                        template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);;

                        this.requestService.getDocument({html: template}).subscribe((r:any) => {
                          console.log(r);
                          this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                          this.requestService.GetTemplate(4).subscribe(async (en: any) => {
                            console.log(en);
                            template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);

                            const formData = new FormData();
                            let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "Resolución_de_negación.pdf");


                            const reader = new FileReader();
                            reader.onload = (e: any) => {

                              console.log(e.target.result);
                              formData.append('NameFile', idUser+"_Resolución_de_negación.pdf");
                              formData.append('ContainerName', "manipalimentos");
                              formData.append('Oid', this.fileNumber);
                              formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                              this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                                this.showSuccesAlert = true;
                                this.isLoading = false;
                                console.log(respuesta);
                              })
                            };
                            reader.readAsArrayBuffer(file);



                            let email: emailDTO = {
                              to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                              body: template,
                              subject: 'Solicitud para verificación',
                              attachment: r.data,
                              attachmentTitle: "Resolución_de_negación.pdf"
                            }
                            this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                              console.log(r);
                            })
                          });
                        })
                      })
                    }
                  }
                }

                if (this.solicitud.resultadoValidacionId == 9) {
                  //para subsanación
                  this.requestService.GetTemplate(8).subscribe((en:any) => {
                    console.log(en);
                    this.requestService.GetSubsanacion(this.solicitud.idSolicitud).subscribe((e:any) => {

                      if(e.data != null){

                        let KeysS = [
                          "~:~subsanacion~:~"
                        ];

                        let valuesS = [
                          e.data.vcSubsanacion
                        ];

                        template = this.getTemplate(en.data.vcPlantilla, KeysS, valuesS);

                        let email: emailDTO = {
                          to:this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                          body: template,
                          subject: 'Solicitud para verificación',
                        }
                        this.requestService.sendNotification(email).subscribe((r) => {
                          this.showSuccesAlert = true;
                          this.isLoading = false;
                          console.log(r);
                        })

                      }
                    })
                  });
                }
                if (this.solicitud.resultadoValidacionId == 2) {
                  //devolucion
                  this.requestService.GetTemplate(19).subscribe((en:any) => {
                    console.log(en);

                    template = en.data.vcPlantilla.replaceAll("~:~body~:~","Tiene una devolución de solicitud por parte del Subdirector del trámite Autorización para capacitadores de manipulación de alimentos")

                    this.registerService.getRoleByIdUser(this.solicitud.usuarioAsignadoCoordinadorId).subscribe((resp) => {

                      let email: emailDTO = {
                        to: resp.data[0].email+"",
                        body: template,
                        subject: 'Solicitud para verificación',
                      }
                      this.requestService.sendNotification(email).subscribe((r) => {
                        this.showSuccesAlert = true;
                        this.isLoading = false;
                        console.log(r);
                      })

                    })
                  });
                }

                if (this.solicitud.resultadoValidacionId == 10) {
                  //cancelacion por incumplimiento
                  let template = "";
                  let ClaseConversor = conversor.conversorNumerosALetras;
                  let miConversor = new ClaseConversor();
                  let capacitaciones = "";

                  if(this.isNatural){
                    if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
                      capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
                    }

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
                      capacitaciones = capacitaciones + " Leche cruda "
                    }

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
                      capacitaciones = capacitaciones + " Alimentos en vía publica "
                    }
                  }

                  let keysCI: string[] = [];
                  let valuesCI: string[] = [];

                  //Negar solicitud
                  if (this.solicitud.resultadoValidacionId == 10) {
                    console.log("Negado");
                    if (this.isNatural) {
                      keysCI= [
                        "~:~fecha_vigencia~:~",
                        "~:~num_res_autoriza~:~",
                        "~:~fecha_res_autoriza~:~",
                        "~:~numero_de_la_resolucion~:~",
                        "~:~dia_de_la_resolucion~:~",
                        "~:~mes_de_la_resolucion~:~",
                        "~:~año_de_la_resolucion~:~",
                        "~:~resol_carne~:~",
                        "~:~decre_leche~:~",
                        "~:~resol_alimentos~:~",
                        "~:~nombre_solicitante~:~",
                        "~:~tipo_id_sol~:~",
                        "~:~num_id_sol_pn~:~",
                        "~:~tipo_capacitaciones~:~",
                        "~:~id_solicitud~:~",
                        "~:~fecha_id_solicitud~:~",
                        "~:~num_id_sol~:~",
                        "~:~titulo_profesional_capacitador~:~",
                        "~:~num_tar_profesional~:~",
                        "~:~año_letra_de_la_resolucion~:~",
                        "~:~firma_subdirector~:~",
                        "~:~nombre_validador~:~",
                        "~:~nombre_coordinador~:~",
                        "~:~motivo_cancelacion_incumplimiento_pn~:~"
                        ];

                      let capacitacioncompleta="";
                      let selected=false;

                      if(this.trainingDataTypes[2].selected)
                      {
                        capacitacioncompleta+=this.trainingDataTypes[2].name
                      }


                      for (let i = 0; i < 2; i++) {
                        if(this.trainingDataTypes[i].selected)
                        {
                          if(capacitacioncompleta!="")
                          { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

                          }
                          else
                          {
                            capacitacioncompleta+= this.trainingDataTypes[i].name
                          }

                        }
                      }
                      selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

                      valuesCI = [
                        (resolutionDate.getDate()).toString().padStart(2, "0")+"/"+(resolutionDate.getMonth()+1).toString().padStart(2, "0")+"/"+(resolutionDate.getFullYear()+1).toString(),
                        this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
                        (resolutionDate.getDate()).toString().padStart(2, "0")+"/"+(resolutionDate.getMonth()+1).toString().padStart(2, "0")+"/"+resolutionDate.getFullYear().toString(),
                        resolutionNumber,
                        (resolutionDate.getDate()).toString().padStart(2, "0"),
                        this.meses[resolutionDate.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
                        resolutionDate.getFullYear().toString(),
                        this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
                        this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
                          this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
                          this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                        this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        capacitaciones,
                        this.fileNumber,
                        this.solicitud.vcFechaSolicitud,
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                        miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                        this.Signature,
                        this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                        this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                        this.observacionesCancelacion
                      ];

                      this.requestService.GetTemplate(18).subscribe((e:any) => {
                        console.log(e);
                        template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);



                        this.requestService.getDocument({html: template}).subscribe((r:any) => {
                          console.log(r);
                          this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                          this.requestService.GetTemplate(18).subscribe(async (en: any) => {
                            console.log(en);
                            template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);

                            const formData = new FormData();
                            let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "_Resolución_de_cancelación.pdf");

                            const reader = new FileReader();
                            reader.onload = (e: any) => {

                              console.log(e.target.result);
                              formData.append('NameFile', idUser+"_Resolución_de_cancelación.pdf");
                              formData.append('ContainerName', "manipalimentos");
                              formData.append('Oid', this.fileNumber);
                              formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                              this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                                this.showSuccesAlert = true;
                                this.isLoading = false;
                                console.log(respuesta);
                              })
                            };
                            reader.readAsArrayBuffer(file);


                            let email: emailDTO = {
                              to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                              body: template,
                              subject: 'Solicitud para verificación',
                              attachment: r.data,
                              attachmentTitle: "Resolución_de_cancelación.pdf"
                            }
                            this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                              console.log(r);
                            })
                          });
                        })
                      })
                    }else{

                      keysCI = [
                        "~:~fecha_vigencia~:~",
                        "~:~num_res_autoriza~:~",
                        "~:~fecha_res_autoriza~:~",
                        "~:~numero_de_la_resolucion~:~",
                        "~:~dia_de_la_resolucion~:~",
                        "~:~mes_de_la_resolucion~:~",
                        "~:~año_de_la_resolucion~:~",
                        "~:~resol_carne~:~",
                        "~:~decre_leche~:~",
                        "~:~resol_alimentos~:~",
                        "~:~nombre_rep_legal~:~",
                        "~:~tipo_doc_rep_legal~:~",
                        "~:~num_id_rep_legal~:~",
                        "~:~nombre_empresa~:~",
                        "~:~nit_empresa~:~",
                        "~:~tipo_capacitaciones~:~",
                        "~:~id_solicitud~:~",
                        "~:~fecha_id_solicitud~:~",
                        "~:~num_id_sol~:~",
                        "~:~titulo_profesional~:~",
                        "~:~num_tarj_profesional~:~",
                        "~:~año_letra_de_la_resolucion~:~",
                        "~:~firma_subdirector~:~",
                        "~:~nombre_validador~:~",
                        "~:~nombre_coordinador~:~",
                        "~:~capacitadores~:~",
                        "~:~motivo_cancelacion_incumplimiento~:~"
                        ];

                      let capacitacioncompleta="";
                      let selected=false;

                      if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
                      {
                        capacitacioncompleta="Alimentos en vía publica";
                      }


                      for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
                        if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
                        {
                          if(capacitacioncompleta!="")
                          {
                            capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

                          }
                          else
                          {
                            capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
                          }
                        }
                      }
                      selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

                      valuesCI = [
                        (resolutionDate.getDate()).toString().padStart(2, "0")+"/"+(resolutionDate.getMonth()+1).toString().padStart(2, "0")+"/"+(resolutionDate.getFullYear()+1).toString(),
                        this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
                        (resolutionDate.getDate()).toString().padStart(2, "0")+"/"+(resolutionDate.getMonth()+1).toString().padStart(2, "0")+"/"+resolutionDate.getFullYear().toString(),
                        resolutionNumber,
                        (resolutionDate.getDate()).toString().padStart(2, "0"),
                        this.meses[resolutionDate.getMonth()],
                        resolutionDate.getFullYear().toString(),
                        this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
                        this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
                          this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
                          this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                        this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
                        capacitaciones,
                        this.fileNumber,
                        this.solicitud.vcFechaSolicitud,
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                        this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                        miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                        this.Signature,
                        this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                        this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                        this.getTrainers(this.solicitud.capacitadoresSolicitud),
                        this.observacionesCancelacion
                        ];

                      this.requestService.GetTemplate(17).subscribe((e:any) => {
                        console.log(e);
                        template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);;

                        this.requestService.getDocument({html: template}).subscribe((r:any) => {
                          console.log(r);
                          this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                          this.requestService.GetTemplate(17).subscribe(async (en: any) => {
                            console.log(en);
                            template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);

                            const formData = new FormData();
                            let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "Resolución_de_cancelación.pdf");


                            const reader = new FileReader();
                            reader.onload = (e: any) => {

                              console.log(e.target.result);
                              formData.append('NameFile', idUser+"_Resolución_de_cancelación.pdf");
                              formData.append('ContainerName', "manipalimentos");
                              formData.append('Oid', this.fileNumber);
                              formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                              this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                                this.showSuccesAlert = true;
                                this.isLoading = false;
                                console.log(respuesta);
                              })
                            };
                            reader.readAsArrayBuffer(file);



                            let email: emailDTO = {
                              to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                              body: template,
                              subject: 'Solicitud para verificación',
                              attachment: r.data,
                              attachmentTitle: "Resolución_de_cancelación.pdf"
                            }
                            this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                              console.log(r);
                            })
                          });
                        })
                      })
                    }
                  }
                }

                if(this.solicitud.resultadoValidacionId == 7){

                  // Cancelación

                  console.log("cancelar solicitud");
                  this.documentVisualizationTitle = "cancelación";

                  let template = "";
                  let ClaseConversor = conversor.conversorNumerosALetras;
                  let miConversor = new ClaseConversor();
                  let capacitaciones = "";

                  let dateResolucion1 = new Date(this.solicitud.resolucionSolicitudes[0].fechaResolucion);

                  let keysC: string[] = [];
                  let valuesC: string[] = [];

                  if (this.isNatural) {

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
                      capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
                    }

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
                      capacitaciones = capacitaciones + " Leche cruda "
                    }

                    if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
                      capacitaciones = capacitaciones + " Alimentos en vía publica "
                    }

                    console.log( this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion);
                    console.log( this.solicitud);
                    keysC= [
                      "~:~fecha_vigencia~:~",
                      "~:~num_res_autoriza~:~",
                      "~:~fecha_res_autoriza~:~",
                      "~:~numero_de_la_resolucion~:~",
                      "~:~dia_de_la_resolucion~:~",
                      "~:~mes_de_la_resolucion~:~",
                      "~:~año_de_la_resolucion~:~",
                      "~:~resol_carne~:~",
                      "~:~decre_leche~:~",
                      "~:~resol_alimentos~:~",
                      "~:~nombre_solicitante~:~",
                      "~:~tipo_id_sol~:~",
                      "~:~num_id_sol_pn~:~",
                      "~:~tipo_capacitaciones~:~",
                      "~:~id_solicitud~:~",
                      "~:~fecha_id_solicitud~:~",
                      "~:~num_id_sol~:~",
                      "~:~titulo_profesional~:~ ",
                      "~:~num_tarj_profesional~:~",
                      "~:~año_letra_de_la_resolucion~:~",
                      "~:~firma_subdirector~:~",
                      "~:~nombre_validador~:~",
                      "~:~nombre_coordinador~:~"
                      ];
                    let capacitacioncompleta="";
                    let selected=false;

                    if(this.trainingDataTypes[2].selected)
                    {
                      capacitacioncompleta+=this.trainingDataTypes[2].name
                    }


                    for (let i = 0; i < 2; i++) {
                      if(this.trainingDataTypes[i].selected)
                      {
                        if(capacitacioncompleta!="")
                        { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

                        }
                        else
                        {
                          capacitacioncompleta+= this.trainingDataTypes[i].name
                        }

                      }
                    }
                    selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
                      valuesC = [
                      (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion1.getFullYear()+1).toString(),
                      this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
                      (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion1.getFullYear().toString(),
                      cancellationResolutionNumber,
                      (cancellationResolutionDate.getDate()).toString().padStart(2, "0"),
                      this.meses[cancellationResolutionDate.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
                      cancellationResolutionDate.getFullYear().toString(),
                      this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
                      this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
                      this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                      this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      capacitaciones,
                      this.fileNumber,
                      this.solicitud.vcFechaSolicitud,
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                      miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                      this.Signature,
                      this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                      this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                    ];
                    this.requestService.GetTemplate(12).subscribe((e:any) => {
                      console.log(e);
                      template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);

                      this.requestService.getDocument({html: template}).subscribe((r:any) => {
                        console.log(r);
                        //this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                        //this.isLoadingDocument = false;
                        this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                        //this.isLoadingDocument = false;
                        //this.isLoading = false;
                        //this.showModal = true;

                        this.requestService.GetTemplate(3).subscribe(async (en: any) => {
                          console.log(en);
                          template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);

                          const formData = new FormData();
                          let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "_Resolución_de_cancelación.pdf");

                          const reader = new FileReader();
                          reader.onload = (e: any) => {

                            console.log(e.target.result);
                            formData.append('NameFile', idUser+"_Resolución_de_cancelación.pdf");
                            formData.append('ContainerName', "manipalimentos");
                            formData.append('Oid', this.fileNumber);
                            formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                            this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                              this.showSuccesAlert = true;
                              this.isLoading = false;
                              console.log(respuesta);
                            })
                          };
                          reader.readAsArrayBuffer(file);


                          let email: emailDTO = {
                            to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                            body: template,
                            subject: 'Solicitud para verificación',
                            attachment: r.data,
                            attachmentTitle: "Resolución_de_cancelación.pdf"
                          }
                          this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                            console.log(r);
                          })
                        });
                      })
                    })
                  }else{
                    keysC = [
                      "~:~fecha_vigencia~:~",
                      "~:~num_res_autoriza~:~",
                      "~:~fecha_res_autoriza~:~",
                      "~:~numero_de_la_resolucion~:~",
                      "~:~dia_de_la_resolucion~:~",
                      "~:~mes_de_la_resolucion~:~",
                      "~:~año_de_la_resolucion~:~",
                      "~:~resol_carne~:~",
                      "~:~decre_leche~:~",
                      "~:~resol_alimentos~:~",
                      "~:~nombre_rep_legal~:~",
                      "~:~tipo_doc_rep_legal~:~",
                      "~:~num_id_rep_legal~:~",
                      "~:~nombre_empresa~:~",
                      "~:~nit_empresa~:~",
                      "~:~tipo_capacitaciones~:~",
                      "~:~id_solicitud~:~",
                      "~:~fecha_id_solicitud~:~",
                      "~:~num_id_sol~:~",
                      "~:~titulo_profesional~:~",
                      "~:~num_tarj_profesional~:~",
                      "~:~año_letra_de_la_resolucion~:~",
                      "~:~firma_subdirector~:~",
                      "~:~nombre_validador~:~",
                      "~:~nombre_coordinador~:~",
                      "~:~capacitadores~:~"
                      ];
                    let capacitacioncompleta="";
                    let selected=false;

                    if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
                    {
                      capacitacioncompleta="Alimentos en vía publica";
                    }


                    for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
                      if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
                      {
                        if(capacitacioncompleta!="")
                        {
                          capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

                        }
                        else
                        {
                          capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
                        }
                      }
                    }
                    selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

                      valuesC = [
                      (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion1.getFullYear()+1).toString(),
                      this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
                      (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion1.getFullYear().toString(),
                      cancellationResolutionNumber,
                      ( cancellationResolutionDate.getDate()).toString().padStart(2, "0"),
                      this.meses[cancellationResolutionDate.getMonth()],
                      cancellationResolutionDate.getFullYear().toString(),
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
                        this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
                        this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
                      this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
                      capacitaciones,
                      this.fileNumber,
                      this.solicitud.vcFechaSolicitud,
                      this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
                      this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                      miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                      this.Signature,
                      this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
                      this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
                      this.getTrainers(this.solicitud.capacitadoresSolicitud),
                      ];


                    this.requestService.GetTemplate(11).subscribe((e:any) => {
                      console.log(e);
                      template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);;

                      this.requestService.getDocument({html: template}).subscribe((r:any) => {
                        console.log(r);
                        //this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                        //this.isLoadingDocument = false;
                        this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                        //this.isLoadingDocument = false;
                        //this.isLoading = false;
                        //this.showModal = true;
                        this.requestService.GetTemplate(3).subscribe(async (en: any) => {
                          console.log(en);
                          template = this.getTemplate(en.data.vcPlantilla, keysRA, valuesRA);

                          const formData = new FormData();
                          let file: File = this.documentService.convertBase64ToPdfFile(r.data, this.fileNumber + "Resolución_de_cancelación.pdf");


                          const reader = new FileReader();
                          reader.onload = (e: any) => {

                            console.log(e.target.result);
                            formData.append('NameFile', idUser+"_Resolución_de_cancelación.pdf");
                            formData.append('ContainerName', "manipalimentos");
                            formData.append('Oid', this.fileNumber);
                            formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                            this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                              this.showSuccesAlert = true;
                              this.isLoading = false;
                              console.log(respuesta);
                            })
                          };
                          reader.readAsArrayBuffer(file);



                          let email: emailDTO = {
                            to: this.requestManagementAssistantManagerForm.get('basicDataForm').get('correoElectronico').value,
                            body: template,
                            subject: 'Solicitud para verificación',
                            attachment: r.data,
                            attachmentTitle: "Resolución_de_cancelación.pdf"
                          }
                          this.requestService.sendNotificationWithAttachment(email).subscribe((r) => {

                            console.log(r);
                          })
                        });
                      })
                    })
                  }
                }



              }
            })
          }

          if(this.requestManagementAssistantManagerForm.get('validationSectionForm').get("resultadoValidacion").value == 2){
            this.showSuccesAlert = true;
            this.isLoading = false;
          }

        }else{
          Swal.fire({
            icon: 'error',
            title: '<h5>Error en el servicio</h5>',
            text: 'Ocurrio un error en el guardado de la solicitud',
          })
        }
      })


      //------------------------------

        console.log(obj);


    }else{
      Swal.fire({
        icon: 'error',
        title: '<h5>Resultado de la validación no selecionado</h5>',
        text: 'El resultado de la validación no ha sido seleccionado',
      })
    }
  }





  viwDocumentAction = () => {
    console.log("ver el documento");

    let template = "";
    const date = new Date();
    let ClaseConversor = conversor.conversorNumerosALetras;
    let miConversor = new ClaseConversor();

    console.log(this.trainingDataTypes);
    console.log();

    let keysA: string[] = [];
    let valuesA: string[] = [];
    let keysN: string[] = [];
    let valuesN: string[] = [];
    let keysC: string[] = [];
    let valuesC: string[] = [];

    let capacitaciones = "";

    if (this.isNatural) {

      if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
        capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
        capacitaciones = capacitaciones + " Leche cruda "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
        capacitaciones = capacitaciones + " Alimentos en vía publica "
      }

    }

    //Aprobar solicitud
    if (this.solicitud.resultadoValidacionId == 6) {
      console.log("Aprobado");
      this.documentVisualizationTitle = "aprobación";
      if (this.isNatural) {
          keysA = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitaciones_seleccionadas~:~"
          ];

        let capacitacioncompleta="";
        let selected=false;

        if(this.trainingDataTypes[2].selected)
        {
          capacitacioncompleta+=this.trainingDataTypes[2].name
        }


        for (let i = 0; i < 2; i++) {
          if(this.trainingDataTypes[i].selected)
          {
            if(capacitacioncompleta!="")
            { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

            }
            else
            {
              capacitacioncompleta+= this.trainingDataTypes[i].name
            }

          }
        }
        selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

          valuesA = [
              "numero de resolución",
              (date.getDate()).toString().padStart(2, "0"),
              this.meses[date.getMonth()],
              date.getFullYear().toString(),
              this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
              this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected && !this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
              this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
              this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
              capacitaciones,
              this.fileNumber,
              this.solicitud.vcFechaSolicitud,
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
              this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
              this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
              miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
              this.Signature,
              this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
              this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
            capacitacioncompleta
            ];
            this.requestService.GetTemplate(10).subscribe((e:any) => {
              console.log(e);
              template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

              this.requestService.getDocument({html: template}).subscribe((r:any) => {
                console.log(r);
                this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                this.base64PDF = r.data;
                //this.isLoadingDocument = false;
              })
            })
      }else{
        keysA = [
          "~:~numero_de_la_resolucion~:~",
          "~:~dia_de_la_resolucion~:~",
          "~:~mes_de_la_resolucion~:~",
          "~:~año_de_la_resolucion~:~",
          "~:~resol_carne~:~",
          "~:~decre_leche~:~",
          "~:~resol_alimentos~:~",
          "~:~nombre_rep_legal~:~",
          "~:~tipo_doc_rep_legal~:~",
          "~:~num_id_rep_legal~:~",
          "~:~nombre_empresa~:~",
          "~:~nit_empresa~:~",
          "~:~tipo_capacitaciones~:~",
          "~:~id_solicitud~:~",
          "~:~fecha_id_solicitud~:~",
          "~:~num_id_sol~:~",
          "~:~titulo_profesional~:~",
          "~:~num_tarj_profesional~:~",
          "~:~año_letra_de_la_resolucion~:~",
          "~:~firma_subdirector~:~",
          "~:~nombre_validador~:~",
          "~:~nombre_coordinador~:~",
          "~:~capacitadores~:~",
          "~:~capacitaciones_seleccionadas~:~"
          ];
        let capacitacioncompleta="";
        let selected=false;

        if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
        {
          capacitacioncompleta="Alimentos en vía publica";
        }


        for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
          if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
          {
            if(capacitacioncompleta!="")
            {
              capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

            }
            else
            {
              capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
            }
          }
        }
        selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");
        valuesA = [
          "numero de resolución",
          (date.getDate()).toString().padStart(2, "0"),
          this.meses[date.getMonth()],
          date.getFullYear().toString(),
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica") && !this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
          this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
          this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
          capacitaciones,
          this.fileNumber,
          this.solicitud.vcFechaSolicitud,
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
          this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
          this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
          miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
          this.Signature,
          this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
          this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
          this.getTrainers(this.solicitud.capacitadoresSolicitud),
            capacitacioncompleta
          ];
        this.requestService.GetTemplate(9).subscribe((e:any) => {
          console.log(e);
          template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

          this.requestService.getDocument({html: template}).subscribe((r:any) => {
            console.log(r);
            this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
            this.base64PDF = r.data;
            //this.isLoadingDocument = false;
          })
        })
      }
      this.viewDocument = true;
    }

    if(this.solicitud.resultadoValidacionId == 7){

        // Cancelación

        console.log("cancelar solicitud");
        this.documentVisualizationTitle = "cancelación";

        let dateResolucion1 = new Date(this.solicitud.resolucionSolicitudes[0].fechaResolucion);

        if (this.isNatural) {

          console.log( this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion);
          console.log( this.solicitud);
          keysC= [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~ ",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
            valuesC = [
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion1.getFullYear()+1).toString(),
            this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion1.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            this.Signature,
            this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
            this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
          ];
          this.requestService.GetTemplate(12).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              //this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.isLoadingDocument = false;
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.isLoadingDocument = false;
              //this.isLoading = false;
              //this.showModal = true;
            })
          })
        }else{
          keysC = [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

            valuesC = [
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion1.getFullYear()+1).toString(),
            this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion1.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
              this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            this.Signature,
            this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
            this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
            this.getTrainers(this.solicitud.capacitadoresSolicitud),
            ];
          this.requestService.GetTemplate(11).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              //this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.isLoadingDocument = false;
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.isLoadingDocument = false;
              //this.isLoading = false;
              //this.showModal = true;
            })
          })
        }
        this.viewDocument = true;
      }

    //Negar solicitud
    if (this.solicitud.resultadoValidacionId == 8) {
      console.log("Negado");
      this.documentVisualizationTitle = "negación";
      if (this.isNatural) {
        keysN= [
          "~:~numero_de_la_resolucion~:~",
          "~:~dia_de_la_resolucion~:~",
          "~:~mes_de_la_resolucion~:~",
          "~:~año_de_la_resolucion~:~",
          "~:~resol_carne~:~",
          "~:~decre_leche~:~",
          "~:~resol_alimentos~:~",
          "~:~nombre_solicitante~:~",
          "~:~tipo_id_sol~:~",
          "~:~num_id_sol_pn~:~",
          "~:~tipo_capacitaciones~:~",
          "~:~id_solicitud~:~",
          "~:~fecha_id_solicitud~:~",
          "~:~num_id_sol~:~",
          "~:~titulo_profesional_capacitador~:~",
          "~:~num_tar_profesional~:~",
          "~:~año_letra_de_la_resolucion~:~",
          "~:~firma_subdirector~:~",
          "~:~nombre_validador~:~",
          "~:~nombre_coordinador~:~",
          "~:~capacitaciones_seleccionadas~:~"
          ];

        let capacitacioncompleta="";
        let selected=false;

        if(this.trainingDataTypes[2].selected)
        {
          capacitacioncompleta+=this.trainingDataTypes[2].name
        }


        for (let i = 0; i < 2; i++) {
          if(this.trainingDataTypes[i].selected)
          {
            if(capacitacioncompleta!="")
            { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

            }
            else
            {
              capacitacioncompleta+= this.trainingDataTypes[i].name
            }

          }
        }
        selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

        valuesN = [
          "numero de resolución",
          (date.getDate()).toString().padStart(2, "0"),
          this.meses[date.getMonth()],
          date.getFullYear().toString(),
          this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
          this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
          this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
          this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
          capacitaciones,
          this.fileNumber,
          this.solicitud.vcFechaSolicitud,
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
          this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
          this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
          miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
          this.Signature,
          this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
          this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
          ""
        ];
        this.requestService.GetTemplate(14).subscribe((e:any) => {
          console.log(e);
          template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);

          this.requestService.getDocument({html: template}).subscribe((r:any) => {
            console.log(r);
            this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
            this.base64PDF = r.data;
            //this.isLoadingDocument = false;
          })
        })
      }else{

        keysN = [
          "~:~numero_de_la_resolucion~:~",
          "~:~dia_de_la_resolucion~:~",
          "~:~mes_de_la_resolucion~:~",
          "~:~año_de_la_resolucion~:~",
          "~:~resol_carne~:~",
          "~:~decre_leche~:~",
          "~:~resol_alimentos~:~",
          "~:~nombre_rep_legal~:~",
          "~:~tipo_doc_rep_legal~:~",
          "~:~num_id_rep_legal~:~",
          "~:~nombre_empresa~:~",
          "~:~nit_empresa~:~",
          "~:~tipo_capacitaciones~:~",
          "~:~id_solicitud~:~",
          "~:~fecha_id_solicitud~:~",
          "~:~num_id_sol~:~",
          "~:~titulo_profesional~:~",
          "~:~num_tarj_profesional~:~",
          "~:~año_letra_de_la_resolucion~:~",
          "~:~firma_subdirector~:~",
          "~:~nombre_validador~:~",
          "~:~nombre_coordinador~:~",
          "~:~capacitadores~:~",
          "~:~capacitaciones_seleccionadas~:~"
          ];
        let capacitacioncompleta="";
        let selected=false;

        if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
        {
          capacitacioncompleta="Alimentos en vía publica";
        }


        for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
          if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
          {
            if(capacitacioncompleta!="")
            {
              capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

            }
            else
            {
              capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
            }
          }
        }
        selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");
        valuesN = [
          "numero de resolución",
          (date.getDate()).toString().padStart(2, "0"),
          this.meses[date.getMonth()],
          date.getFullYear().toString(),
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
          this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
          this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
          capacitaciones,
          this.fileNumber,
          this.solicitud.vcFechaSolicitud,
          this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
          this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
          this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
          miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
          this.Signature,
          this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
          this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
          this.getTrainers(this.solicitud.capacitadoresSolicitud),
          capacitacioncompleta
          ];
        this.requestService.GetTemplate(13).subscribe((e:any) => {
          console.log(e);
          template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);;

          this.requestService.getDocument({html: template}).subscribe((r:any) => {
            console.log(r);
            this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
            this.base64PDF = r.data;
            //this.isLoadingDocument = false;
          })
        })
      }

      this.viewDocument = true;
    }

    //Cancelar solicitud por incumplimiento
    if(this.solicitud.resultadoValidacionId == 10){
        console.log(date);

        this.documentVisualizationTitle = "cancelación por incumplimiento";

        if(this.isNatural){
          if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
            capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
            capacitaciones = capacitaciones + " Leche cruda "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
            capacitaciones = capacitaciones + " Alimentos en vía publica "
          }
        }

        console.log(this.trainingDataTypes);
        console.log();

        let keysCI: string[] = [];
        let valuesCI: string[] = [];

        let dateResolucion = new Date(this.solicitud.resolucionSolicitudes[0].fechaResolucion);

        if (this.isNatural) {

          console.log( this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion);
          console.log( this.solicitud);
          keysCI= [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional_capacitador~:~",
            "~:~num_tar_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~motivo_cancelacion_incumplimiento_pn~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
            valuesCI = [
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
            this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
              this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            this.Signature,
            this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
            this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
            this.observacionesCancelacion
          ];
          this.requestService.GetTemplate(18).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.base64PDF = r.data;
              //this.isLoadingDocument = false;
              //this.isLoading = false;
              //this.showModal = true;
            })
          })
        }else{
          keysCI = [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~motivo_cancelacion_incumplimiento~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

            valuesCI = [
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
            this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
              this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
              this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementAssistantManagerForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.requestManagementAssistantManagerForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementAssistantManagerForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementAssistantManagerForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            this.Signature,
            this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
            this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
            this.getTrainers(this.solicitud.capacitadoresSolicitud),
            this.observacionesCancelacion
            ];
          this.requestService.GetTemplate(17).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.base64PDF = r.data;
              //this.isLoadingDocument = false;
              //this.isLoading = false;
              //this.showModal = true;
            })
          })
        }

        this.viewDocument = true;
    }
  }

  closeDocument = () => {
    this.viewDocument = false;
    this.viewTrainerDetailModal = false;
  }

  getTemplate = (template:string, keys:string[], values:string[]) => {

    let res = template;

    for (let index = 0; index < keys.length; index++) {
      res = res.replaceAll(keys[index], values[index]);
    }
    return res;
  }

  /*
  viewDetailTrainer = (e:any) => {
    console.log('Trainer Detail');
    console.log(e);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("primerNombre").setValue(e.firstName);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("segundoNombre").setValue(e.secondName);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("primerApellido").setValue(e.surname);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("segundoApellido").setValue(e.secondSurname);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("tipoDocumento").setValue(e.documentType);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("numeroDocumento").setValue(e.identificationNumber);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("tituloProfesional").setValue(e.professionalTitle);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("numeroTarjetaProfesional").setValue(e.professionalCardNumber);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("numeroContacto").setValue(e.telephoneContact);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("correoElectronico").setValue(e.emailAddress);
    this.viewTrainerDetailModal = true;

  }
  */

  isDecree = (capacitadores:any[], value:string):boolean => {
    let flag = false;
    capacitadores.forEach((e) => {
      if (e.capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
        flag = true;
      }
    })

    return flag;
  }

  decree = (capacitadorTipoCapacitacion:any[], value:string):boolean => {
    let flag = false;
      if (capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
        flag = true;
      }

    return flag;
  }

  getTrainers = (capacitadores:any[]):string =>{

    let original:string = "";

    capacitadores.forEach((e) => {

      let capacitaciones = "";

      this.decree(e.capacitadorTipoCapacitacion, "Carnes y productos cárnicos comestibles")?capacitaciones=capacitaciones+" Carnes y productos cárnicos comestibles,":null;
      this.decree(e.capacitadorTipoCapacitacion, "Leche cruda")?capacitaciones=capacitaciones+" Leche cruda,":null;
      this.decree(e.capacitadorTipoCapacitacion, "Alimentos en vía publica")?capacitaciones=capacitaciones+" Alimentos en vía publica":null;

      console.log("Capacitadores");
      console.log(capacitaciones);
      let cadena:string;
      if (e.vcPrimerNombre != "") {
        cadena = +cadena+"<tr>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+e.vcPrimerNombre+" "+e.vcPrimerApellido+"</td>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+"<div>"+"<p>"+e.vcTipoIdentificacion+"</p>"+"<p>"+e.intNumeroIdentificacion+"</p></td>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+ e.vcTituloProfesional +"</td>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+ e.vcNumeroTarjetaProfesional +"</td>";
        cadena = cadena+"<td style='width: 100px;word-break: break-all;'>"+ capacitaciones +"</td>"
        cadena = cadena + "</tr>";
      }
      original = original + cadena;
      console.log("aqui");
      console.log(e);
    })

    console.log(original.replaceAll("NaN",""));

    return original.replaceAll("NaN","");
  }

  viewDetailTrainer = (e:any) => {
    this.canBeApprovedObj = e;
    this.isLoadingTrainer = true;
    console.log('Trainer Detail');
    this.detailDocumentation = [];
    this.detailTrainingDataTypes = [];
    console.log(e);
    this.viewTrainerDetailModal = true;

    this.requestService.GetDocumentTypes('bTipoIdentificacion').subscribe((result) =>{
      this.documentTypes22 = result.data;
    })


    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("primerNombre").setValue(e.firstName);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("segundoNombre").setValue(e.secondName);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("primerApellido").setValue(e.surname);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("segundoApellido").setValue(e.secondSurname);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("tipoDocumento").setValue(e.documentType);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("numeroDocumento").setValue(e.identificationNumber);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("tituloProfesional").setValue(e.professionalTitle);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("numeroTarjetaProfesional").setValue(e.professionalCardNumber);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("numeroContacto").setValue(e.telephoneContact);
    this.requestManagementAssistantManagerForm.get("modalTrainerForm").get("correoElectronico").setValue(e.emailAddress);

    this.requestService.GetRequest(parseInt(this.activateRoute.snapshot.paramMap.get('idRequest'))).subscribe((result) => {
      console.log("datos de la solicitud para capacitador")
      console.log(result);

      //Datos de la solicitud

      let obj = null;

      let capacitador:any = null;

      this.requestService.GetTraining().subscribe((result2) => {
        console.log(result2);
        result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

          obj = {
            id: r.idTipoCapacitacion,
            name: r.vcDescripcion,
            selected: false
          }

          this.detailTrainingDataTypes.push(obj);
        });

        this.documentationSolicitud.capacitadoresSolicitud.forEach((cs:any) => {
          if(cs.intNumeroIdentificacion == e.identificationNumber){
            console.log("I find it");
            capacitador = cs;
          }
        });

        console.log(capacitador);

        this.detailTrainingDataTypes.forEach((t) => {
          console.log("forEach trainings");

          let aux = capacitador.capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

          if (aux != undefined) {
            t.selected = true;
          }
        })

        //Documentation

      let doc = null;

      capacitador.documentosSolicitud.forEach((ds: any) => {

        console.log(ds);
        doc = {
          id: ds.idDocumento,
          descripcion: ds.vcTipoDocumento,
          path: ds.vcPath,
          aprovado: ds.blIsValid,
          fileNumber: this.fileNumber,
          mandatory:ds.vcTipoDocumento!='Pensum de pregrado'
        }
        this.detailDocumentation.push(doc);
      });

      console.log(this.detailDocumentation);

      this.isLoadingTrainer = false;
      console.log("encontre los datos");
      })
    });

  }

  approvedTrainer = (e:any) => {
    console.log('approved Trainer');
    console.log(e);

    this.trainersCopy.forEach((element:any) => {

      if(element.identificationNumber == e.record.identificationNumber){
          element.approved = e.value == "si";
      }
    });
  }

  addApprovedDocumentationTrainer = (e:any) => {
    console.log("lista de documentacion a asignar modal");
    console.log(e);
    console.log("antes");
    console.log(this.documentationSolicitud);

    let capacitador = this.documentationSolicitud.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == e.id);
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (element2.idDocumento == e.record.id) {
        element2.blIsValid = e.value == "si";
      }
    });

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element3:any) => {
      if (element3.intNumeroIdentificacion == capacitador.intNumeroIdentificacion) {
        element3 = capacitador;
      }
    });

    console.log("despues");
    console.log(this.documentationSolicitud);

    this.updateDocumentation = false;
  }

  acceptSelectedTrainer= (e:any) => {
    console.log("guardar seleccion de trainer");
    console.log(e);
    this.viewTrainerDetailModal = false;

    this.trainers.forEach((element:any) => {

      if(element.identificationNumber == this.canBeApprovedObj.identificationNumber){
          element.disabledFlag = this.canBeApproved();
      }

    });

    this.canBeApprovedFlag = this.allApproved();
    console.log(this.allApproved());
  }

  addModalSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar del modal");
    console.log(e);
  }

  getIdNumber = (id:number) => {
    let idNumber: string = "";
    this.idCapacitadorJ.forEach((e) => {
      if (id == e.intNumeroIdentificacion) {
        idNumber = e.idCapacitadorSolicitud;
      }
    })
    return idNumber;
  }

  canBeApproved(){

    console.log(this.canBeApprovedObj);

    let bandera = true;

    let capacitador:any = [];

    capacitador = this.documentationSolicitud.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == this.canBeApprovedObj.identificationNumber);

    console.log(capacitador);
    if(capacitador == undefined){
      bandera = false;
    }
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (!element2.blIsValid) {
        console.log("es falso");
        bandera = false;
      }
    });

    return bandera;
  }


  allBeApproved(id:any){

    let bandera = true;

    let capacitador: any = null;

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element: { intNumeroIdentificacion: any; }) => {
      if(element.intNumeroIdentificacion == id){
        console.log("Oui");
        capacitador = element;
      }
    });

    if(capacitador == null){
      bandera = false;
      return bandera;
    }

    capacitador.documentosSolicitud.forEach((element2: { blIsValid: any; }) => {
        console.log(element2);
        if (!element2.blIsValid) {
          console.log("es falso");
          bandera = false;
        }
      });

    return bandera;
  }

  temporarySave = () =>{
    console.log("guardado temporal");

      this.isLoading = true;

      let idUser:string = JSON.parse(localStorage.getItem('currentUser')).userId;
      const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;

      let obj:any = null;

        console.log("persona juridica");
        let capacitadoresSolicitud: any[] = [];

        //Aprobar y firmar

        let aprobado = true;

        //console.log("aprobar y firmar");
        //console.log(this.solicitud.resultadoValidacionId);

        if (this.solicitud.resultadoValidacionId == 6) {
          //Aprobar solicitud

          console.log("Aprobar solicitud");
          aprobado = true;
        }


        let data:any = null;


    console.log(JSON.parse(localStorage.getItem('currentUser')));

        if(this.isNatural){
          this.documentoSolicitud = this.documentationSend.map((e) => { return {idDocumento: e.id, blIsValid: e.aprovado}});

          let seguimiento = {
            idObservacion: this.observacionId,
            vcObservacion: this.requestManagementAssistantManagerForm.get('validationSectionForm').get('observaciones').value,
            usuarioId: idUser,
            vcNombreUsuario: userName
          }

          obj = {
            idSolicitud: this.idSolicitud,
            asignado: false,
            usuarioAsignadoId: idUser,
            capacitadorSolicitud: [
              {
                idCapacitadorSolicitud: this.solicitud.capacitadoresSolicitud[0].idCapacitadorSolicitud,
                blIsValid: true
              }
            ],
            documentoSolicitud: this.documentoSolicitud,
            seguimientoAuditoriaSolicitud: seguimiento,
            resultadoValidacion: aprobado
          }

        }else{

          capacitadoresSolicitud = this.trainersCopy.map((e:any) => {
            return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
          })

          if(capacitadoresSolicitud.length==0)
          {
            capacitadoresSolicitud.push({
              idCapacitadorSolicitud: this.usuariointerno,
              blIsValid: true
            })
          }

          let documentosArray: any[] = [];
          this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
            documentosArray = documentosArray.concat(element.documentosSolicitud);
          });

          this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

          //console.log("crazy...");
          //console.log(documentosArray);
          //console.log(this.documentoSolicitud);

          let seguimiento = {
            idObservacion: this.observacionId,
            vcObservacion: this.requestManagementAssistantManagerForm.get('validationSectionForm').get('observaciones').value,
            usuarioId: idUser,
            vcNombreUsuario: userName
          }

          obj = {
            idSolicitud: this.idSolicitud,
            asignado: false,
            usuarioAsignadoId: idUser,
            capacitadorSolicitud: capacitadoresSolicitud,
            documentoSolicitud: this.documentoSolicitud,
            seguimientoAuditoriaSolicitud: seguimiento,
            resultadoValidacion: aprobado
          }

        }

        this.isLoading = true;

        this.usuario.temporarySaveCoordinatorAssistantManager(obj).subscribe(

          (response) => {

            this.showSuccesAlert = true;
            this.isLoading = false;
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: '<h5>Error en el servicio</h5>',
              text: 'Ocurrio un error en el guardado de la solicitud',
            })
          }
        )
  //------------------------------

    console.log(obj);

  }

  allApproved(){

    console.log(this.canBeApprovedObj);

    let bandera = true;

    console.log(this.documentationSolicitud);

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
      console.log(element);
      element.documentosSolicitud.forEach((element2: { blIsValid: any; }) => {
        console.log(element2);
        if (!element2.blIsValid) {
          console.log("es falso");
          bandera = false;
        }
      });
    });

    //.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == this.canBeApprovedObj.identificationNumber);

    /*
    console.log(capacitador);
    if(capacitador == undefined){
      bandera = false;
    }
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (!element2.blIsValid) {
        console.log("es falso");
        bandera = false;
      }
    });

    return bandera;
  */

    return bandera;
  }

}
