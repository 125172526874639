import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from '@core-app/enums';
import Swal from "sweetalert2";

@Component({
  selector: 'app-success-request',
  templateUrl: './success-request.component.html',
  styleUrls: ['./success-request.component.scss']
})
export class SuccessRequestComponent {

  fileNumber:string = "";


  constructor(private router: Router, private activateRoute: ActivatedRoute) {
    this.fileNumber = this.activateRoute.snapshot.paramMap.get('fileNumber');
  }
  exit() {
    Swal.fire({
        title: "¿Estás seguro?",
        text: "Si cancelas, perderás la información ingresada.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
    }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.Menu}`);
        }
    });
}
advanceLineData = [
  {
    name: 'Inicio',
    link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.Menu
  },
  {
    name: 'Hago mi solicitud',
    link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.REQUEST_CITIZEN
  },
  {
    name: 'Procesan mi solicitud',
    link: ''
  },
  {
    name: 'Respuesta',
    link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.SearchRequest
  }
]
}
