<nz-modal *ngIf="type=='retrieval'" nzWidth="500px" [nzBodyStyle]="{height:'250px'}" [(nzVisible)]="isVisible"
  nzCancelText={{null}} nzClosable="false">
  <ng-container *nzModalContent>
    <div class="w-100 h-100">
      <span class="headline-m-govco">SUBSANACIÓN</span>
      <div class="form-group gov-co-form-group mt-3">
        <textarea [(ngModel)]="observacionesSubsanacion" class="form-control" rows="5"></textarea>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="d-flex justify-content-center mb-3">
    <button (click)="closeButton()" class="btn btn-round btn-middle mx-3"
      style="color: white; font-size: 17px;padding:5px 40px;border: 1px solid #3366cc;">
      CERRAR
    </button>
    <button (click)="acceptButton(observacionesSubsanacion)" class="btn btn-round btn-high mx-3"
      style="color: white; font-size: 17px;padding:5px 40px;">
      GUARDAR
    </button>
  </div>
</nz-modal>

<nz-modal *ngIf="type=='cancellation'" nzWidth="500px" [nzBodyStyle]="{height:'250px'}" [(nzVisible)]="isVisible"
  nzCancelText={{null}} nzClosable="false">
  <ng-container *nzModalContent>
    <div class="w-100 h-100">
      <span class="headline-m-govco" style="text-transform: uppercase;">Motivo de cancelación</span>
      <div class="form-group gov-co-form-group mt-3">
        <textarea [(ngModel)]="observacionesCancelacion" class="form-control" rows="5"></textarea>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="d-flex justify-content-center mb-3">
    <button (click)="closeButton()" class="btn btn-round btn-middle mx-3"
      style="color: white; font-size: 17px;padding:5px 40px;border: 1px solid #3366cc;">
      CERRAR
    </button>
    <button (click)="acceptButton(observacionesCancelacion)" class="btn btn-round btn-high mx-3"
      style="color: white; font-size: 17px;padding:5px 40px;">
      GUARDAR
    </button>
  </div>
</nz-modal>

<nz-modal *ngIf="type=='trainerInformation'" nzWidth="1000px"
  [nzBodyStyle]="{height:'1000px', overflow: 'visible scroll'}" [(nzVisible)]="isVisible" (nzOnCancel)="closeButton()">
  <div>
    <ng-container *nzModalContent>
      <div class="w-100 h-100">
        <span class="headline-m-govco">Información personal del capacitador</span>
        <nz-spin style="display: block;width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'" nzTip="Se estan cargando los datos, por favor espere un momento....">
          <div class="form-group gov-co-form-group mt-3">
            <span><strong>Campos obligatorios *</strong></span>
            <form class="mt-4" [formGroup]="modalTrainerForm">
              <div class="row">
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="primerNombre">Primer Nombre *</label>
                  <input class="form-control gov-co-form-control" formControlName="primerNombre" type="text"
                    id="primerNombre" placeholder="Primer nombre" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="segundoNombre">Segundo Nombre *</label>
                  <input class="form-control gov-co-form-control" formControlName="segundoNombre" type="text"
                    id="segundoNombre" placeholder="Segundo nombre" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="primerApellido">Primer Apellido</label>
                  <input class="form-control gov-co-form-control" formControlName="primerApellido" type="text"
                    id="primerApellido" placeholder="Primer apellido" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="segundoApellido">Segundo Apellido</label>
                  <input class="form-control gov-co-form-control" formControlName="segundoApellido" type="text"
                    id="segundoApellido" placeholder="Segundo apellido" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="tipoDocumento">Tipo de Identificación *</label>
                  <select formControlName="tipoDocumento" class="selectpicker form-control" id="tipoDocumento">
                    <option *ngFor="let dt of documentTypes" value={{dt.vcNombre}}>{{dt.txDescripcion}}</option>
                  </select>
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="numeroDocumento">Número de Identificación *</label>
                  <input class="form-control gov-co-form-control" formControlName="numeroDocumento" type="text"
                    id="numeroDocumento" placeholder="Número de documento" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="numeroContacto">Titulo Profesional *</label>
                  <input class="form-control gov-co-form-control" formControlName="tituloProfesional" type="text"
                    id="tituloProfesional" placeholder="Titulo profesional" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="correoElectronico">Número de Tarjeta Profesional *</label>
                  <input class="form-control gov-co-form-control" formControlName="numeroTarjetaProfesional" type="text"
                    id="numeroTarjetaProfesional" placeholder="Número de tarjeta profesional" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="numeroContacto">Teléfono de contacto *</label>
                  <input class="form-control gov-co-form-control" formControlName="numeroContacto" type="text"
                    id="numeroContacto" placeholder="Número de contacto" />
                </div>
                <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
                  <label for="correoElectronico">Correo Electrónico *</label>
                  <input class="form-control gov-co-form-control" formControlName="correoElectronico" type="text"
                    id="correoElectronico" placeholder="Correo electrónico" />
                </div>
              </div>
            </form>
            <div class="accordion-govco my-5" id="accordion">
              <div class="card border border-0">
                <div class="card-header" id="tipo-capacitador-header">
                  <h2 class="clearfix mb-0">
                    <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#tipo-capacitador"
                      aria-expanded="false" aria-controls="tipo-capacitador"><span class="headline-m-govco">
                        Tipo de capacitador
                      </span> <span class="govco-icon govco-icon-dropdown-v2"></span></a>
                  </h2>
                </div>
                <div id="tipo-capacitador" class="collapse" aria-labelledby="tipo-capacitador-header"
                  data-parent="#accordion">
                  <div class="card-body">
                    <div>
                      <div *ngFor="let tdt of trainingDataTypes;" class="d-inline-block col-sm-12 col-md-4">
                        <div class="d-flex align-items-center">
                          <input disabled="true" style="height: auto;"
                            (click)="trainingDataTypesSelected(tdt, modalTrainerForm.get('numeroDocumento').value)"
                            [checked]="tdt.selected" class="mr-2" type="checkbox" />{{tdt.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border border-0">
                <div class="card-header" id="archivos-adjuntos-header">
                  <h2 class="clearfix mb-0">
                    <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#archivos-adjuntos"
                      aria-expanded="false" aria-controls="archivos-adjuntos"><span class="headline-m-govco">Archivos
                        adjuntos</span> <span class="govco-icon govco-icon-dropdown-v2"></span></a>
                  </h2>
                </div>
                <div id="archivos-adjuntos" class="collapse" aria-labelledby="archivos-adjuntos-header"
                  data-parent="#accordion">
                  <div class="card-body" style="padding: 0px;">
                    <div class="top-content-document w-100">
                      <div *ngIf="documentation">
                        <table>
                          <tr>
                            <th class="text-center body-1-gov-co "*ngIf="!documentsdisabled"><strong>Descripción</strong></th>
                            <th class="text-center body-1-gov-co "*ngIf="!documentsdisabled"><strong>Ver</strong></th>
                            <th class="text-center body-1-gov-co "*ngIf="!documentsdisabled"><strong>¿Cumple?</strong></th>

                          </tr>
                          <tr class="space" *ngFor="let d of documentation; let i = index">
                            <td class="text-right body-2-gov-co">{{d.mandatory ? '*' : null}} {{d.descripcion}}</td>
                            <td *ngIf="!subsanacion"><span (click)="verDocumento(d.path, d.fileNumber)" class="govco-icon govco-icon-facebook-cp"><i
                                  class="bi bi-file-earmark-pdf icon"></i></span></td>
                            <td *ngIf="!documentsdisabled">
                              <div>
                                <div class="mx-1 p-1 d-inline-block">
                                  <input style="height: auto;"[disabled]="documentsdisabled || flag"
                                    (click)="approvedDocumentation(d, 'si', modalTrainerForm.get('numeroDocumento').value)"
                                    [checked]="d.aprovado" value="si" type="radio" name="approvedDocumentation{{i}}">
                                  <label>Si</label>
                                </div>
                                <div class="mx-1 p-1 ms-2 my-4 d-inline-block">
                                  <input style="height: auto;" [disabled]="documentsdisabled || flag"
                                    (click)="approvedDocumentation(d, 'no', modalTrainerForm.get('numeroDocumento').value)"
                                    [checked]="!d.aprovado" value="no" type="radio" name="approvedDocumentation{{i}}">
                                  <label>No</label>
                                </div>
                              </div>
                            </td>
                            <td class="text-right body-2-gov-co" *ngIf="!d.aprovado && subsanacion">
                              <div class="mt-3 row col-12 " >
                                <div class="mt-3 col-12 ">
                                  <span class="d-block"><b>{{d.mandatory ? '*' : null}}{{d.name}}</b></span>
                                  <input accept=".pdf" style="height: 35px;width: 100%;"
                                         (change)="approvedDocumentation(d,'a',{file:$event,document:d} )"
                                         class="form-control gov-co-form-control d-block" id="formFileLg" type="file"
                                         #replenishmentDocument>
                                </div>
                                <div class="col-4 mt-3" (click)="seeDocument(replenishmentDocument)">
                                  <i class="p-1 bi bi-file-earmark-pdf icon"></i>
                                </div>

                                <div class="col-4 ml-3 mt-3" style="cursor: pointer;" >
                    <span class="d-block">{{replenishmentDocument.files.length == 0 ? 'no file'
                      : replenishmentDocument.files[0].name}}</span>
                                  <span class="d-block">{{replenishmentDocument.files.length == 0 ? '0 Kb'
                                    : replenishmentDocument.files[0].size / 1000 + ' Kb'}}</span>
                                </div>
                                <div class="col-2 mt-4 " (click)="replenishmentDocument.value = null; approvedDocumentation(d,'delete',null)">
                                  <i class="bi bi-trash3-fill icon"></i>
                                </div>

                              </div>
                            </td>

                          </tr>
                        </table>
                      </div>
                      <div class="text-center" >
                        <div class="w-800 h-100">
                          <iframe *ngIf="mostrarIframe" [src]="urlSafe" class="w-100 h-100" frameBorder='0' scrolling='auto'></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-spin>
      </div>
    </ng-container>
    <div  *nzModalFooter  class="d-flex justify-content-center mb-3">
      <button *ngIf="!documentsdisabled || subsanacion"  (click)="acceptButtonTrainer()" class="btn btn-round btn-high mx-3"
        style="color: white; font-size: 17px;padding:5px 40px;">
        GUARDAR
      </button>
    </div>
  </div>
</nz-modal>
