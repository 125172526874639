<div class="content mt-4">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb gov-co-breadcrumb" style="background-color: white;" id="ol_element">
            <li *ngFor="let breadcrumb of mapRoutes; let s = index; let last = last" class="breadcrumb-item">
                <a id="element" [routerLink]="breadcrumb?.url" [ngClass]="{'vtab-section-label': last && isInboxPath(breadcrumb)}" [style]="{'color': last ? '#004884' : '#000000	', 'font-weight': last ? '600' : 'normal'}">
                    {{ breadcrumb?.label }}
                </a>
                <span [style.padding-left]="'5px !important'" *ngIf="!last"> > </span>
            </li>
        </ol>
    </nav>
</div>
