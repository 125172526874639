import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccesibilityComponent } from './components/layout/accesibility/accesibility.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { BreadcrumbComponent } from './components/layout/breadcrumb/breadcrumb.component';
import {RouterLink} from "@angular/router";
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AccesibilityComponent,
    FooterComponent,
    HeaderComponent,
    BreadcrumbComponent
  ],
  exports: [
    HeaderComponent,
    AccesibilityComponent,
    FooterComponent,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class CoreModule { }
