<div>
  <div class="filtros mb-5">
      <form [formGroup]="selectOptionFilterForm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-xl-12">
                <label class="d-block">Filtrar por</label>
            </div>
         </div>
          <div class="row">
              <div class="col-lg-3">
                  <select formControlName="selectOptionFilterInput" style="height: 35px;" class="form-select" aria-label="Seleccione" (change)="changestatus()">
                      <option value="0" hidden>Seleccione una opción</option>
                      <option value="1">Número de resolución</option>
                      <option value="2">Fecha de resolución</option>
                      <option value="3">Tipo de autorización</option>
                      <option value="4">Estado de autorización</option>
                  </select>
              </div>
            <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3" *ngIf="searchbar">
              <input formControlName="valueFilterInput" class="form-control search_input_icon" type="text" placeholder="Ingrese su búsqueda" />
            </div>
            <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3" *ngIf="datebar">
              <input formControlName="fecha" class="form-control search_input_icon" type="date"  />
            </div>
            <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3" *ngIf="status">
              <select formControlName="estado" style="height: 35px;" class="form-select" aria-label="Seleccione" >
                <option value="0" hidden>Seleccione una opción</option>
                <option value="Activa">Activa</option>
                <option value="Inactiva">Inactiva</option>
              </select>
            </div>
              <div class="col-lg-3">
                  <button
                      class="btn btn-round btn-middle mx-3"
                      style="padding: 10px 10px; width: 12%; color: white; font-size: 17px; padding: 5px 40px; border: 1px solid #3366cc;"
                      [disabled]="selectOptionFilterForm.get('selectOptionFilterInput').value==0 || selectOptionFilterForm.get('valueFilterInput').status == 'INVALID'"
                      (click)="filter()"
                      type="button"
                  >
                      FILTRAR
                  </button>
              </div>
          </div>
          <div class="row mt-3">
              <div class="col-lg-12">
                  <div *ngIf="selectOptionFilterForm.get('valueFilterInput').status=='INVALID'" class="alert mt-2" style="background-color: #e6effd; color: #004884; font-size: 15px; height: 100px;">
                      <p>* Solo se admiten caracteres alfanuméricos, a excepción del caracter / no ingrese caracteres especiales.</p>
                      <p>* La cantidad máxima de caracteres admitidos es 20.</p>
                  </div>
              </div>
          </div>
      </form>
  </div>

  <nz-spin style="display: block; width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'" nzTip="Se estan cargando los datos, por favor espere un momento....">
      <app-procedure-dashboard [data]="dataFilterTable" [structure]="structureTable" (multipleLinkEvent)="redirectToViewDetail($event)" (documentEvent)="viewApprovalDocument($event)"></app-procedure-dashboard>
  </nz-spin>
</div>
<div class="documentViewer">
  <app-document-viewer [isVisible]="viewDocument" [document]="urlSafeDocument" (closeButtonEvent)="closeDocument()"></app-document-viewer>
</div>
