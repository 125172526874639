import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/enums';

declare var utils: any;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private _router: Router) {
  }

  ngOnInit() {
    utils.tooltip();
  }

  returnHome() {
    this._router.navigate([ `/${ ROUTES.MainPage }` ])
  }

}
