import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentsService } from './../../../../core/services/documents.service';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-requirement-documentation',
  templateUrl: './requirement-documentation.component.html',
  styleUrls: ['./requirement-documentation.component.scss']
})
export class RequirementDocumentationComponent {

  /**
   * Listado e documentación a mostrar
   */

  urlSafe: SafeResourceUrl;

  documentacion = [
    {
      id: "cc",
      descripcion: "Cédula de ciudadanía",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "cs",
      descripcion: "Carta de solicitud",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "hv",
      descripcion: "Hoja de vida",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "ddc",
      descripcion: "Descripción detallada del curso",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "pp",
      descripcion: "Pensum de pregrado",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "dpt",
      descripcion: "Diploma profesional / tecnólogo",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "tp",
      descripcion: "Tarjeta profesional",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "rdc",
      descripcion: "Resumen didáctico del curso",
      path: "RESOLUCION_N°2023REATS000000484"
    },
    {
      id: "mi",
      descripcion: "Memorias ilustradas",
      path: "RESOLUCION_N°2023REATS000000484"
    }
  ];

  constructor(private documentService:DocumentsService, public sanitizer: DomSanitizer){
  }


  public verDocumento( path: string){
    const contenedor = "aguahumanos";
    const oid = "31ab2d61-65b8-4c7d-a793-79cdba31b235";

    this.documentService.GetBlob(contenedor, oid, path).subscribe(resp => {
      var reader = new FileReader();
      reader.readAsDataURL(resp.body);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        const documentData = base64data;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(documentData);
      };
    })
  }


}
