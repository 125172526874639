import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AppBaseComponent } from 'src/app/core/utils';
import {RequestService} from "@core-app/services/request.service";

@Component({
  selector: 'app-application-information',
  templateUrl: './application-information.component.html',
  styleUrls: ['./application-information.component.scss']
})
export class ApplicationInformationComponent extends AppBaseComponent implements OnInit {

  public applicationInformationForm: any;

  public usersbyrol:any[] = [];

  public revision: boolean=false;


  constructor(private controlContainer: ControlContainer,private requestService : RequestService) {
    super();
    var rol=localStorage.getItem("Rol");

    switch (rol)
    {
      case "validador": rol="EFC7AB5B-5B58-4862-9BB1-4B1E0D7F0243"; break;
      case "coordinador": rol="DAD11C31-3709-42CB-BCCE-4B52AE741935"; break;
      case "subdirector": rol="3EA40DA8-99B0-46A9-9F76-32FB5599A8E5"; break;
      default : rol ="B8042B94-0F7A-4585-AA8A-9B9592746219"; break;
    }


    this.requestService.GetUserbyRol(rol).subscribe((result) => {
      console.log(result);
        this.usersbyrol=result.data;
    })
  }

  ngOnInit(): void {
    this.applicationInformationForm = this.controlContainer.control;
    this.applicationInformationForm = this.applicationInformationForm.controls['applicationInformationForm'];

    if(localStorage.getItem('tipo')!=null)
    {
      this.revision=true;
    }

  }

}
