<section class="mt-4">
  <app-request-data></app-request-data>
</section>
<section class="mt-4">
  <app-request-info  (formularioVacio)="bloquearBotonEnviar($event)"></app-request-info>
</section>
<section class="mt-4">
  <app-request-person></app-request-person>
</section>
<section class="mt-4">
<app-type-training></app-type-training>
</section>
<section class="mt-4">
  <app-document-request></app-document-request>
</section>
<div class="row">
  <div class="col">
    <button class="btn btn-primary button_option">Cancelar</button>
    <button class="btn btn-primary button_option">Guardar</button>
    <button class="btn btn-primary button_option" [disabled]="botonEnviarBloqueado">Enviar</button>
  </div>
</div>
