import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AppBaseComponent } from 'src/app/core/utils';

@Component({
  selector: 'app-basic-data2',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.scss']
})
export class BasicDataComponent2  extends AppBaseComponent implements OnInit {

  public basicDataForm: any;
  @Input() mostrarDireccion: boolean;
  @Input() documentTypes: any;
  constructor(private controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    this.basicDataForm = this.controlContainer.control;
    this.basicDataForm = this.basicDataForm.controls['basicDataForm'];
  }

}
