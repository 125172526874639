<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-8">

      <form *ngIf="showDashboard==true && showDisclaimerForm==false">
        <br><h4 class="h4-tipografia-govco title-1">Bandeja de Entrada y Gestión</h4><br>

        <div class="row  justify-content-center align-items-center mx-auto " style="width: 100%">
          <ul class="nav nav-tabs ">
            <li class="nav-item">
              <a class="nav-link active">Recientes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link ">Solucionados</a>
            </li>
          </ul>
          <div class="border">

            <br>
            <p>Filtrar por:</p>
            <div class="row entradas-de-texto-govco">

              <div class="col-xxl-3 col-md-3 col-lg-3 col-sm-3 col-xs-3">
                <select class="form-select" id="selector" aria-label="Default select example">
                  <option value="" selected disabled>Seleccione</option>
                  <option value="">Id Solicitud</option>
                  <option value="">No Doc de Identidad</option>
                  <option value="">Tipo de Título</option>
                  <option value="">Fecha de Registro Solicitud</option>
                  <option value="">Estado de la Solicitud</option>
                  <option value="">Tiempo de Atención Restante</option>
                </select>
              </div>
              <div class="col-xxl-3 col-md-3 col-lg-3 col-sm-3 col-xs-3">
                <div>
                  <input type="text" id="textfilter" placeholder="Buscar por palabra"/>
                </div>
              </div>

            </div>
            <br>
            <div>
              <table class="table table-striped table-responsive" aria-describedby="dashboard-usuario">
                <thead>
                <tr>
                  <th>No. de radicado</th>
                  <th>Tipo de título</th>
                  <th>Fecha de radicación</th>
                  <th>Institución</th>
                  <th>Programa</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of allByUser; let i= index">
                  <td>{{item.filedNumber}}</td>
                  <td>{{item.titleType}}</td>
                  <td>{{item.filedDate | date: 'dd/MM/yyyy'}}</td>
                  <td>{{item.institute}}</td>
                  <td>{{item.profession}}</td>
                  <td style="background-color: {{item.colorTime}};">{{item.status}}</td>
                  <td class="text-justify">
                    <div class="">
                      <span data-bs-toggle="modal" data-bs-target="#seguimiento-auditoria" class="govco-eye"
                            style="font-size: 20px; color: #3366cc; cursor: pointer;"></span>
                      <br/>
                      Seguimiento Auditoria

                      <!-- Modal -->
                      <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
                           id="seguimiento-auditoria" tabindex="-1" aria-labelledby="exampleModalLabel"
                           aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-xl">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Tabla de seguimiento</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal"
                                      aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <table class="table" aria-describedby="modal-seguimiento">
                                <thead>
                                <tr>
                                  <th>Fecha Seguimiento</th>
                                  <th>Observación</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>{{item.filedDate | date: 'dd/MM/yyyy hh:mm a'}}</td>
                                  <td>{{item.status}}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div class="">
                      <a
                        *ngIf="item.status != 'Firmado por director' && item.status != 'Solicitar más información'"><span
                        class="govco-book-reader" style="font-size: 15px">En validación</span></a>
                      <a *ngIf="item.status == 'Solicitar más información'"><span class="govco-edit"
                                                                                  style="font-size: 15px">Editar Trámite</span></a>
                      <a *ngIf="item.status == 'Firmado por director'"><span class="govco-file-pdf"
                                                                             style="font-size: 15px">Ver documento</span></a>
                      <a (click)="showDisclaimerForm=true;showDashboard=false" *ngIf="item.status == 'Firmado por director'"><span class="govco-hand-paper"
                                                                                        style="font-size: 15px">Solicitar Aclaración</span></a>
                    </div>


                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="colum_paginator">
              <mat-paginator class="paginator" [hidePageSize]="true" [length]="10"
                             [pageSize]="10"></mat-paginator>
            </div>

          </div>
        </div>
      </form>

      <form [formGroup]="requestClarificationForm" *ngIf="showDisclaimerForm==true && showDashboard==false">
        <app-request-clarification></app-request-clarification>
        <div class="col-12" style="margin: 5% 0 5% 0; text-align: center">
          <button type="button" class="btn-govco fill-btn-govco"
                  (click)="showDashboard=true;showDisclaimerForm=false"
                  style="margin-right: 1%;">Volver</button>
          <button type="button" class="btn-govco fill-btn-govco"
                  >Guardar</button>
        </div>
      </form>


    </div>

    <div class="col-sm-12 col-md-4">
      <app-service-area></app-service-area>
    </div>
  </div>
</div>



