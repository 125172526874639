import { Component } from '@angular/core';

@Component({
  selector: 'app-request-person',
  templateUrl: './request-person.component.html',
  styleUrls: ['./request-person.component.scss']
})
export class RequestPersonComponent {
  // Variable que indica si la persona es natural o jurídica
  private isNaturalPerson: boolean = true; // Cambiar según tus necesidades

  // Función para verificar si la persona es natural
  public isPersonaNatural(): boolean {
    return this.isNaturalPerson;
  }
}
