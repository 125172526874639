<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12 col-xl-12 col-lg-12">
      <p class="title_section ml-3" (click)="link()">Reportes</p>
      <button class="btn btn-outline-primary float-right" (click)="back()" style="margin-top: -53px;">Regresar</button>
  </div>
</div>

<form [formGroup]="reportForm" class="mt-5">
  <div class="form-row ml-3 mt-5 py-3">
      <div class="form-group col-md-4 col-sm-12 col-xs-12 col-xl-4 col-lg-4 mt-4">
          <select class="form-control" formControlName="type">
              <option value="Seleccionar">Seleccionar</option>
              <option value="1">Actos administrativos generados</option>
              <option value="2">Autorizaciones canceladas</option>
              <option value="3">Seguimiento Capacitaciones</option>
              <option value="4">Listado de capacitadores autorizados INVIMA</option>
              <option value="5">Listado de capacitadores suspendidos INVIMA</option>
          </select>
      </div>
      <div class="form-group col-md-4 col-sm-12 col-xs-12 col-xl-4 col-lg-4 mt-4">
          <input type="date" class="form-control" formControlName="fechaDesde" />
      </div>
      <div class="form-group col-md-4 col-sm-12 col-xs-12 col-xl-4 col-lg-4 mt-4">
          <input type="date" class="form-control" formControlName="fechaHasta" />
      </div>
  </div>

  <button class="btn btn-primary float-right mt-5" (click)="filter()">Filtrar</button>
</form>
