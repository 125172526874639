import { map } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { Component, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PopUpService } from 'src/app/core/services';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RequestService } from '@core-app/services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@core-app/services/usuario';
import Swal from 'sweetalert2';
import { emailDTO } from '@core-app/models/emailDto';
import { ROUTES } from '@core-app/enums';
const conversor = require('conversor-numero-a-letras-es-ar');


@Component({
  selector: 'app-request-management-validator',
  templateUrl: './request-management-validator.component.html',
  styleUrls: ['./request-management-validator.component.scss']
})
export class RequestManagementValidatorComponent {

  meses = ["Enero", "Febrero", "Marzo","Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre","Octubre", "Noviembre", "Diciembre"];

  isLoading:boolean = true;

  flag:boolean = false;

  isLoadingDocument:boolean = true;

  documentVisualizationTitle:string = "";

  showSuccesAlert: boolean = false;

  canBeApprovedObj:any = null;

  canBeApprovedFlag = false;

  observacionesSubsanacion:string = "";

  observacionesCancelacion:string = "";

  documentoSolicitud: any;

  idCapacitadorN:string;

  idCapacitadorJ:any[];

  auditoria: any = [];

  idSolicitud:number;

  observacionTemporal = "";

  observacionId = 0;

  isLoadingTrainer:boolean = true;

  fileNumber: string = "";

  isNatural:boolean = false;

  isResourceReview = false;

  updateDocumentation:boolean = true;

  urlSafeDocument: SafeResourceUrl;

  template:string = "";

  solicitud:any;

  documentationSolicitud:any;

  /**
   * Ver campo de subsanación
   */
  viewRetrievalModal:boolean = false;

  /**
   * Ver campo de cancelacion
   */

  viewCancellationModal:boolean = false;

  /**
   * Ver detalle de capacitador
   */
  viewTrainerDetailModal:boolean = false;

  razonSocial:string = "";

  /**
   * Ver documento
   */
  viewDocument:boolean = false;

  /**
   * Ver documento
   */
  viewDocumentButton:boolean = true;

  /**
   * mostrar archivos adjuntos
   */
  mostrar:boolean = false;

  /**
   * Formulario principal de la interfaz de gestión de solicitudes
   */
  public requestManagementValidatorForm: FormGroup;
  /**
   * Lista de opciones de la linea de avance
   */
  advanceLineData = [
    {
      name: 'Bandeja de trámites',
      link: "aut-cap/"+ ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_VALIDATOR
    },
    {
      name: 'Revisar solicitud',
      link: "aut-cap/"+ ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_VALIDATOR
    }
  ]

  /**
   * Lista de capacitadores
   */
  trainers:any = [];

  trainersCopy:any = [];

  /**
   * Lista de tipos de documento
   */

  documentTypes:any = [];

  /**
   * Lista de tipos de documento 22
   */

  documentTypes22: any = [];

  /**
   * Listado de la documentación requerida
   */

  documentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = []

  documentationSend: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = []

  /**
   * Listado de la documentación requerida
   */

  detailDocumentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = [];

  /**
   *
   * Listado de capacitaciones y sus opciones seleccionadas PN
   */

  trainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];

  /**
   *
   * Listado de capacitaciones y sus opciones seleccionadas PJ
   */

  detailTrainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];

  /**
   *
   * Listado de los resultados de la validación
   */
  validationResults: any = [];

  constructor(private router: Router,private usuario: Usuario,private fb: FormBuilder, private documentService: DocumentsService,
    private sanitizer: DomSanitizer, private requestService:RequestService, private activateRoute: ActivatedRoute){

      this.isLoading = true;

      this.isNatural = this.activateRoute.snapshot.paramMap.get('type')=="P. Natural";

      this.requestManagementValidatorForm = this.fb.group({
      applicationInformationForm: this.fb.group({
        numeroRadicado: "",
        tipoTramite: "",
        estado: "",
        tipoSolicitud: "",
        fechaSolicitud: "",
        usuarioAsignado: "0"
      }),
      basicDataForm: this.fb.group({
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        tipoDocumento: 1,
        numeroDocumento: "",
        numeroContacto: "",
        correoElectronico: "",
        direccion: ""
      }),
      validationSectionForm: this.fb.group({
        resultadoValidacion: "",
        observaciones: ""
      }),
      applicationDataForm: this.fb.group({
        tituloProfesional: "",
        numeroTarjetaProfesional: ""
      }),
      modalTrainerForm: this.fb.group({
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        tipoDocumento: "",
        numeroDocumento: "",
        tituloProfesional: "",
        numeroTarjetaProfesional: "",
        numeroContacto: "",
        correoElectronico: "",
        revision:""
      })
    });

    this.requestService.GetDocumentTypesShared().subscribe((result) => {
      console.log(result);
      this.documentTypes = result.data;
    })

    let fileNumber:string = "";
    const idReq = this.activateRoute.snapshot.paramMap.get('idRequest');
    this.requestService.GetRequest(parseInt(idReq)).subscribe((result) => {
      console.log("datos de la solicitud")
      console.log(result);
      if (result!=null) {
        this.idSolicitud = result.data.idSolicitud;
        this.solicitud = result.data;
        this.documentationSolicitud = result.data;

        const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

        //información de la solicitud
        this.solicitud.seguimientoAuditoriaSolicitud.forEach((element: { blSeguimientoDefinitivo: any; vcObservacion: string; usuarioId: string; idObservacion: number }) => {

          if(!element.blSeguimientoDefinitivo && element.usuarioId == idUser && this.solicitud.vcEstado != "Aprobado"){
            this.observacionTemporal = element.vcObservacion;
            this.observacionId = element.idObservacion;
          }
        });

        let estados = [];
         this.requestService.getValidationResults(result.data.idSolicitud).subscribe((values) => {
          console.log("datos resultado validacion");
          console.log(values.data);
           let resultList:any;

           console.log(this.solicitud.vcEstado)
           switch (this.solicitud.vcEstado) {
             case "Aprobado":
               console.log("Solicitud - Aprobada");

              this.flag = true;

              this.canBeApprovedFlag = true;

              //localStorage.setItem('estado','cancelacion');
               resultList = [
                {
                  idParametroDetalle: 7,
                  vcNombre: "Cancelar solicitud"
                },
                {
                  idParametroDetalle: 10,
                  vcNombre: "Cancelar por incumplimiento"
                }
               ];
               break;
             default:
               localStorage.setItem('estado','flujo normal');
               console.log("Solicitud - en revisión");
               console.log(this.solicitud.vcTipoTramite);
               if (this.solicitud.vcTipoTramite == "Primera vez") {
                 console.log("Solicitud - Primera vez");

                 resultList = values.data.filter((e: { idParametroDetalle: number; }) => {
                  return e.idParametroDetalle != 7;
                 });

                 console.log(values.data);
                 console.log(resultList);
               }
               break;

           }
           this.mostrar=true;

           this.validationResults = resultList;

        })

        this.requestManagementValidatorForm.get("validationSectionForm").get("observaciones").setValue(this.observacionTemporal);
        this.auditoria = result.data.seguimientoAuditoriaSolicitud;
        this.fileNumber = result.data.vcRadicado;
        this.isResourceReview = result.data.vcEstado=='En revisión - recurso';
        this.requestManagementValidatorForm.get('applicationInformationForm').get('numeroRadicado').setValue(result.data.vcRadicado);
        this.requestManagementValidatorForm.get('applicationInformationForm').get('tipoTramite').setValue(result.data.vcTipoTramite);
        this.requestManagementValidatorForm.get('applicationInformationForm').get('estado').setValue(result.data.vcEstado);
        this.requestManagementValidatorForm.get('applicationInformationForm').get('fechaSolicitud').setValue(result.data.vcFechaSolicitud);

        console.log(result.data.usuarioId, "de donde sale este valor")
        this.usuario.getInfoperson(result.data.usuarioId).subscribe((data: any) => {
          this.usuario.getTotalperson(data['data']).subscribe((valores: any) => {

            console.log("hey!");
            console.log(valores);

            //Datos del solicitante
              this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').setValue(valores.data.primerNombre);
              this.requestManagementValidatorForm.get('basicDataForm').get('segundoNombre').setValue(valores.data.segundoNombre);
              this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').setValue(valores.data.primerApellido);
              this.requestManagementValidatorForm.get('basicDataForm').get('segundoApellido').setValue(valores.data.segundoApellido);
              this.requestManagementValidatorForm.get('basicDataForm').get('numeroContacto').setValue(valores.data.telefonoCelular);
              this.requestManagementValidatorForm.get('basicDataForm').get('correoElectronico').setValue(valores.data.email);
              this.requestManagementValidatorForm.get('basicDataForm').get('direccion').setValue(valores.data.direResi);
            if (this.isNatural) {
              this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacion);
              this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacion);
              //Datos de la solicitud

              this.idCapacitadorN = result.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;

              let obj = null;

              this.requestService.GetTraining().subscribe((result2) => {
                console.log(result2);
                result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

                  obj = {
                    id: r.idTipoCapacitacion,
                    name: r.vcDescripcion,
                    selected: false
                  }

                  this.trainingDataTypes.push(obj);
                });

                this.trainingDataTypes.forEach((t) => {
                  console.log("forEach trainings");
                  let aux = result.data.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

                  if (aux != undefined) {
                    t.selected = true;
                  }
                })
              })
              this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').setValue(result.data.capacitadoresSolicitud[0].vcTituloProfesional);
              this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').setValue(result.data.capacitadoresSolicitud[0].vcNumeroTarjetaProfesional);

              //Documentation

              let doc = null;

              result.data.capacitadoresSolicitud[0].documentosSolicitud.forEach((ds: any) => {

                console.log(ds);
                doc = {
                  id: ds.idDocumento,
                  descripcion: ds.vcTipoDocumento,
                  path: ds.vcPath,
                  aprovado: ds.blIsValid,
                  fileNumber: this.fileNumber,
                  mandatory:ds.vcTipoDocumento!='Pensum de pregrado'
                }
                this.documentation.push(doc);
                this.documentationSend.push(doc);
              });

            }else{
              this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacionRL);
              this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacionRL);
              this.idCapacitadorJ = result.data.capacitadoresSolicitud;
              //Trainers

              let t = null;
              let index = 1;


              result.data.capacitadoresSolicitud.forEach((cs: any) => {

                console.log(cs ,"capacitadore");
                t = {
                  id: index,
                  firstName: cs.vcPrimerNombre,
                  secondName: cs.vcSegundoNombre,
                  surname: cs.vcPrimerApellido,
                  secondSurname: cs.vcSegundoApellido,
                  documentType: cs.vcTipoIdentificacion,
                  identificationNumber: cs.intNumeroIdentificacion,
                  professionalTitle: cs.vcTituloProfesional,
                  professionalCardNumber: cs.vcNumeroTarjetaProfesional,
                  telephoneContact: cs.intTelefono,
                  emailAddress: cs.vcEmail,
                  approved: cs.blCapacitadorActivo,
                  disabledFlag: this.BeApproved(cs.intNumeroIdentificacion)
                }
                this.trainers.push(t);
                this.trainersCopy.push(t);
                index++
              });
            }
            this.isLoading = false;
          });
        });
      }
    })
  }

  temporarySave = () =>{
    console.log("guardado temporal");

    this.isLoading = true;

    const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;
    const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;

    let obj = null;

    let subsanacionSolicitud = null;
    let cancelacionSolicitud = null;


    if (this.isNatural) {


      this.documentoSolicitud = this.documentationSend.map((e) => { return {idDocumento: e.id, blIsValid: e.aprovado}})
      let seguimiento = {
        idObservacion: this.observacionId,
        vcObservacion: this.requestManagementValidatorForm.get('validationSectionForm').get('observaciones').value,
        usuarioId: idUser,
        vcNombreUsuario: userName
      }

      obj = {
        idSolicitud: this.idSolicitud,
        asignado: false,
        usuarioAsignadoId: idUser, //revisar que se envia
        capacitadorSolicitud: [
          {
            idCapacitadorSolicitud: this.idCapacitadorN,
            blIsValid: true
          }
        ],
        documentoSolicitud: this.documentoSolicitud,
        seguimientoAuditoriaSolicitud: seguimiento,
        resultadoValidacionId: parseInt(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value), // I'm not sure about this value
        subsanacionSolicitud: subsanacionSolicitud,
        cancelacionSolicitud: cancelacionSolicitud
      }
    }else{

      let capacitadoresSolicitud: any[] = [];

      capacitadoresSolicitud = this.trainersCopy.map((e:any) => {
        return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
      })

      let documentosArray: any[] = [];
      this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
        documentosArray = documentosArray.concat(element.documentosSolicitud);
      });

      this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

      console.log("crazy...");
      console.log(documentosArray);
      console.log(this.documentoSolicitud);

      let seguimiento = {
        idObservacion: this.observacionId,
        vcObservacion: this.requestManagementValidatorForm.get('validationSectionForm').get('observaciones').value,
        usuarioId: idUser,
        vcNombreUsuario: userName
      }

      obj = {
        idSolicitud: this.idSolicitud,
        asignado: false,
        usuarioAsignadoId: idUser, //revisar que se envia
        capacitadorSolicitud: capacitadoresSolicitud,
        documentoSolicitud: this.documentoSolicitud,
        seguimientoAuditoriaSolicitud: seguimiento,
        resultadoValidacionId: parseInt(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value), // I'm not sure about this value
        subsanacionSolicitud: subsanacionSolicitud,
        cancelacionSolicitud: cancelacionSolicitud
      }
    }
    console.log(obj);



    this.requestService.temporarySaveValidator(obj).subscribe((res) => {

      if (res.code == 200) {
        this.isLoading = false;
        this.showSuccesAlert = true;
      }else{
        Swal.fire({
          icon: 'error',
          title: '<h5>Error en el servicio</h5>',
          text: 'Ocurrio un error en el guardado de la solicitud',
        })
      }
      console.log(res);
      //this.sucessAlert.open();
    })

  }

  addSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar");
    console.log(e);
  }

  addModalSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar del modal");
    console.log(e);
  }

  addApprovedDocumentation = (e:any) => {
    console.log("lista de documentacion a asignar");
    console.log(e);
    this.documentationSend.forEach((element) => {

      if (element.id == e.record.id) {
        element.aprovado = e.value == 'si';
      }

    })
    //console.log(this.documentationSend);
    this.updateDocumentation = false;
  }

  addApprovedDocumentationTrainer = (e:any) => {
    console.log("lista de documentacion a asignar modal");
    console.log(e);
    console.log("antes");
    console.log(this.documentationSolicitud);

    let capacitador = this.documentationSolicitud.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == e.id);
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (element2.idDocumento == e.record.id) {
        element2.blIsValid = e.value == "si";
      }
    });

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element3:any) => {
      if (element3.intNumeroIdentificacion == capacitador.intNumeroIdentificacion) {
        element3 = capacitador;
      }
    });

    console.log("despues");
    console.log(this.documentationSolicitud);

    this.updateDocumentation = false;
  }

  generateDocument = (e:any) => {
    if (this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles0")) {
      console.log("es verdadero");
    }else{
      console.log("es false");
    }
    console.log(e);
    console.log(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value);
    let template = "";
    const date = new Date();
    let ClaseConversor = conversor.conversorNumerosALetras;
    let miConversor = new ClaseConversor();

    console.log(this.trainingDataTypes);
    console.log();

    let keysA: string[] = [];
    let valuesA: string[] = [];
    let keysN: string[] = [];
    let valuesN: string[] = [];

    let capacitaciones = "";

    if(this.isNatural){
      if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
        capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
        capacitaciones = capacitaciones + " Leche cruda "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
        capacitaciones = capacitaciones + " Alimentos en vía publica "
      }
    }else{

    }

    switch (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value) {
      case "6":
        console.log("aprobar solicitud");

        this.documentVisualizationTitle = "aprobación";

        if (this.isNatural) {
          keysA = [
                  "~:~numero_de_la_resolucion~:~",
                  "~:~dia_de_la_resolucion~:~",
                  "~:~mes_de_la_resolucion~:~",
                  "~:~año_de_la_resolucion~:~",
                  "~:~resol_carne~:~",
                  "~:~decre_leche~:~",
                  "~:~resol_alimentos~:~",
                  "~:~nombre_solicitante~:~",
                  "~:~tipo_id_sol~:~",
                  "~:~num_id_sol_pn~:~",
                  "~:~tipo_capacitaciones~:~",
                  "~:~id_solicitud~:~",
                  "~:~fecha_id_solicitud~:~",
                  "~:~num_id_sol~:~",
                  "~:~titulo_profesional~:~",
                  "~:~num_tarj_profesional~:~",
                  "~:~año_letra_de_la_resolucion~:~",
                  "~:~firma_subdirector~:~",
                  "~:~nombre_validador~:~",
                  "~:~nombre_coordinador~:~",
            "~:~capacitaciones_seleccionadas~:~"
                  ];


          let capacitacioncompleta="";
            let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

          valuesA = [
                      "numero de resolución",
                      (date.getDate()).toString().padStart(2, "0"),
                      this.meses[date.getMonth()],
                      date.getFullYear().toString(),
                      this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
                      this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected && !this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
                      this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
                      this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
                      this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
                      this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
                      capacitaciones,
                      this.fileNumber,
                      this.solicitud.vcFechaSolicitud,
                      this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
                      this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
                      this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
                      miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
                      "firma",
                      JSON.parse(localStorage.getItem('currentUser')).fullName,
                      "~:~nombre_coordinador~:~",
                     capacitacioncompleta
                    ];
          this.requestService.GetTemplate(10).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
            })
          })
        }else{
          console.log("hi dude");
          keysA = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~capacitaciones_seleccionadas~:~"
            ];



          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            console.log("hi dude 2");
            capacitacioncompleta="Alimentos en vía publica";
          }


          console.log(this.solicitud.capacitadoresSolicitud[0]);
          for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {

            console.log(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[0].vcTipoCapacitacion);
            if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              console.log(capacitacioncompleta);
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

          valuesA = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica") && !this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
            this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            this.getTrainers(this.solicitud.capacitadoresSolicitud),
            capacitacioncompleta
            ];
          this.requestService.GetTemplate(9).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
            })
          })
        }
      break;

      case "7":
        console.log("cancelar solicitud");
        this.urlSafeDocument=null;


        console.log(capacitaciones);

        console.log(date);

        this.documentVisualizationTitle = "cancelación";

        /*
        if(this.isNatural){
          if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
            capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
            capacitaciones = capacitaciones + " Leche cruda "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
            capacitaciones = capacitaciones + " Alimentos en vía publica "
          }
        }
        */

        console.log(this.trainingDataTypes);
        console.log();

        let keysC: string[] = [];
        let valuesC: string[] = [];

        let dateResolucion = new Date(this.solicitud.resolucionSolicitudes[0].fechaResolucion);

        if (this.isNatural) {

          console.log( this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion);
          console.log( this.solicitud);
          keysC= [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~ ",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
            valuesC = [
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
            this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
            this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            ""
          ];
          this.requestService.GetTemplate(12).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              //this.base64PDF = r.data;
              //this.isLoadingDocument = false;
              //this.isLoading = false;
              //this.showModal = true;
            })
          })
        }else{
          keysC = [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

            valuesC = [
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
            this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
              this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
              this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.razonSocial,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "",
            this.getTrainers(this.solicitud.capacitadoresSolicitud),
            ];
          this.requestService.GetTemplate(11).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              //this.base64PDF = r.data;
              //this.isLoadingDocument = false;
              //this.isLoading = false;
              //this.showModal = true;
            })
          })
        }
      break;

      case "8":
        console.log("negar solicitud");

        this.documentVisualizationTitle = "negación";

        if (this.isNatural) {
          keysN= [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional_capacitador~:~",
            "~:~num_tar_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitaciones_seleccionadas~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
          valuesN = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
            this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            capacitacioncompleta
          ];
          this.requestService.GetTemplate(14).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
            })
          })
        }else{
          keysN = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~capacitaciones_seleccionadas~:~"
            ];

          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

          valuesN = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
            this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            this.getTrainers(this.solicitud.capacitadoresSolicitud),
            capacitacioncompleta
            ];
          this.requestService.GetTemplate(13).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
            })
          })
        }
      break;
      case "9":
        console.log("subsanar solicitud");
        this.urlSafeDocument=null;
        this.viewRetrievalModal = true;
      break;

      case "10":
        this.viewCancellationModal = true;

        /*

        let resolutionDate:Date = null;
        let observacioncancelacion = "";

        console.log("Cancelar solicitud por incumplimiento");

        if(this.isNatural){
          if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
            capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
            capacitaciones = capacitaciones + " Leche cruda "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
            capacitaciones = capacitaciones + " Alimentos en vía publica "
          }
        }

        console.log("111111111111111111111111111111111111");

        /*
        if (this.isNatural) {

          console.log("222222222222222222222222222222222222");


          let keysCI: string[] = [];
          let valuesCI: string[] = [];

          keysCI= [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_alimentos~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional_capacitador~:~",
            "~:~num_tar_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~motivo_cancelacion_incumplimiento_pn~:~"
          ];


          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y ": ";"+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;

          this.requestService.GetDataResolution(this.solicitud.idSolicitud).subscribe((e:any) => {

            resolutionDate = new Date(e.data.fechaResolucion);
            observacioncancelacion = e.data.cancelacionPorIncumplimiento;

            console.log(e);
            console.log(e.data.vcNumeroResolucion);

            valuesCI = [
              (resolutionDate.getDate()).toString().padStart(2, "0")+"/"+(resolutionDate.getMonth()+1).toString().padStart(2, "0")+"/"+(resolutionDate.getFullYear()+1).toString(),
              e.data.vcNumeroResolucion,
              (resolutionDate.getDate()).toString().padStart(2, "0")+"/"+(resolutionDate.getMonth()+1).toString().padStart(2, "0")+"/"+resolutionDate.getFullYear().toString(),
              (resolutionDate.getDate()).toString().padStart(2, "0"),
              this.meses[resolutionDate.getMonth()+1],
              resolutionDate.getFullYear().toString(),
              this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
              this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
              this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
              this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
              this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
              this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
              capacitaciones,
              this.fileNumber,
              this.solicitud.vcFechaSolicitud,
              this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
              this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
              this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
              miConversor.convertToText(resolutionDate.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
              "",
              this.auditoria[(this.auditoria.length-2)].vcNombreUsuario,
              this.auditoria[(this.auditoria.length-1)].vcNombreUsuario,
              "observación"
            ];
            this.requestService.GetTemplate(18).subscribe((e:any) => {
              console.log(e);
              template = e.data.vcPlantilla;//this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);


              this.requestService.getDocument({html: template}).subscribe((r:any) => {
                console.log(r);
                this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
                this.isLoadingDocument = false;
              })
            })

          })
        }else{
          keysN = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_alimentos~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~capacitaciones_seleccionadas~:~"
            ];

          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y ": ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

          valuesN = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
            this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
            this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitud.vcFechaSolicitud,
            this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            this.getTrainers(this.solicitud.capacitadoresSolicitud),
            capacitacioncompleta
            ];
          this.requestService.GetTemplate(13).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
            })
          })
        }
        */
      break;
      default:
        break;
    }

    console.log("mensaje");
    console.log(this.requestManagementValidatorForm.get('validationSectionForm')
    .get('resultadoValidacion').value);


    if (this.requestManagementValidatorForm.get('validationSectionForm')
    .get('resultadoValidacion').value != "9") {
      this.viewDocumentButton = false;
    }else{
      this.viewDocumentButton = true;
      if(this.requestManagementValidatorForm.get('validationSectionForm')
        .get('resultadoValidacion').value==10)
      {

        if(this.observacionesCancelacion!="")
        {
          this.viewDocumentButton = false;
        }
      }
      else
      {
        if(this.observacionesSubsanacion!="")
        {
          this.viewDocumentButton = false;
        }
      }

    }


    /*
       if(this.requestManagementValidatorForm.get('validationSectionForm')
       .get('resultadoValidacion').value!="9" && this.requestManagementValidatorForm.get('validationSectionForm')
       .get('resultadoValidacion').value!=""){
         this.viewDocumentButton = false;
       }
       */
  }

  monitoring = (e:any) => {
    console.log(e);
  }

  send = () => {

    console.log(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value);
    if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value != "" ) {



        this.isLoading = true;

        const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;
        const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;

        let obj = null;

        let subsanacionSolicitud = null;
        let cancelacionSolicitud = null;

        console.log("hey people");

        if (this.isNatural) {

          if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value == "9") {
            console.log("es una subsanación");

            if(this.observacionesSubsanacion!="")

            {
              subsanacionSolicitud = {
                idSubsanacion: 0,
                vcSubsanacion: this.observacionesSubsanacion,
                usuarioId: idUser, // usuario id no guarda como uniqueidentifier
                vcNombreUsuario: userName
              }
            }
            else
            {
              Swal.fire({
                icon: 'error',
                title: '<h5>Resultado de la validación no selecionado</h5>',
                text: 'Debe adicionarse observaciones para la subsanacion',
              })
              return;
            }



          }

          if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value == "10") {
            console.log("es una cancelación por incumplimiento");
            if(this.observacionesCancelacion!="")
            {
              cancelacionSolicitud = {
                idCancelacion: 0,
                vcCancelacion: this.observacionesCancelacion,
                usuarioId: idUser, // usuario id no guarda como uniqueidentifier
                vcNombreUsuario: userName
              }
            }
            else
            {
              Swal.fire({
                icon: 'error',
                title: '<h5>Resultado de la validación no selecionado</h5>',
                text: 'Debe adicionarse observaciones para la cancelacion',
              })
              return;
            }

          }

          console.log(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value);
          if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value == "7") {
            console.log("es una cancelación");
              cancelacionSolicitud = {
                idCancelacion: 0,
                vcCancelacion: "Sin observación - cancelacion normal",
                usuarioId: idUser, // usuario id no guarda como uniqueidentifier
                vcNombreUsuario: userName
              }

          }

          this.documentoSolicitud = this.documentationSend.map((e) => { return {idDocumento: e.id, blIsValid: e.aprovado}})
          let seguimiento = {
            idObservacion: this.observacionId,
            vcObservacion: this.requestManagementValidatorForm.get('validationSectionForm').get('observaciones').value,
            usuarioId: idUser,
            vcNombreUsuario: userName
          }

          obj = {
            idSolicitud: this.idSolicitud,
            asignado: false,
            usuarioAsignadoId: idUser, //revisar que se envia
            capacitadorSolicitud: [
              {
                idCapacitadorSolicitud: this.idCapacitadorN,
                blIsValid: true
              }
            ],
            documentoSolicitud: this.documentoSolicitud,
            seguimientoAuditoriaSolicitud: seguimiento,
            resultadoValidacionId: parseInt(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value), // I'm not sure about this value
            subsanacionSolicitud: subsanacionSolicitud,
            cancelacionSolicitud: cancelacionSolicitud
          }
        }else{

          let capacitadoresSolicitud: any[] = [];

          if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value == "9") {
            console.log("es una subsanación");

            if(this.observacionesSubsanacion!="")

            {
              subsanacionSolicitud = {
                idSubsanacion: 0,
                vcSubsanacion: this.observacionesSubsanacion,
                usuarioId: idUser, // usuario id no guarda como uniqueidentifier
                vcNombreUsuario: userName
              }
            }
            else
            {
              Swal.fire({
                icon: 'error',
                title: '<h5>Resultado de la validación no selecionado</h5>',
                text: 'Debe adicionarse observaciones para la subsanacion',
              })
              return;
            }
          }

          if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value == "10") {
            console.log("es una cancelación por incumplimiento");
            if(this.observacionesCancelacion!="")
            {
              cancelacionSolicitud = {
                idCancelacion: 0,
                vcCancelacion: this.observacionesCancelacion,
                usuarioId: idUser, // usuario id no guarda como uniqueidentifier
                vcNombreUsuario: userName
              }
            }
            else
            {
              Swal.fire({
                icon: 'error',
                title: '<h5>Resultado de la validación no selecionado</h5>',
                text: 'Debe adicionarse observaciones para la cancelacion',
              })
              return;

            }

          }

          console.log(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value);
          if (this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value == "7") {
            console.log("es una cancelación");
              cancelacionSolicitud = {
                idCancelacion: 0,
                vcCancelacion: "Sin observación - cancelacion normal",
                usuarioId: idUser, // usuario id no guarda como uniqueidentifier
                vcNombreUsuario: userName
              }

          }

          capacitadoresSolicitud = this.trainersCopy.map((e:any) => {
            return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
          })

          let documentosArray: any[] = [];
          this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
            documentosArray = documentosArray.concat(element.documentosSolicitud);
          });

          this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

          console.log("crazy...");
          console.log(documentosArray);
          console.log(this.documentoSolicitud);

          let seguimiento = {
            idObservacion: this.observacionId,
            vcObservacion: this.requestManagementValidatorForm.get('validationSectionForm').get('observaciones').value,
            usuarioId: idUser,
            vcNombreUsuario: userName
          }

          obj = {
            idSolicitud: this.idSolicitud,
            asignado: false,
            usuarioAsignadoId: idUser, //revisar que se envia
            capacitadorSolicitud: capacitadoresSolicitud,
            documentoSolicitud: this.documentoSolicitud,
            seguimientoAuditoriaSolicitud: seguimiento,
            resultadoValidacionId: parseInt(this.requestManagementValidatorForm.get('validationSectionForm').get("resultadoValidacion").value), // I'm not sure about this value
            subsanacionSolicitud: subsanacionSolicitud,
            cancelacionSolicitud: cancelacionSolicitud
          }
        }
        console.log(obj);



        this.requestService.checkValidator(obj).subscribe((res) => {

          if (res.code == 201) {
            this.isLoading = false;
            this.showSuccesAlert = true;
            let template = "";
            this.requestService.GetTemplate(19).subscribe((en:any) => {
              console.log(en);
              template = en.data.vcPlantilla.replaceAll("~:~body~:~","Tiene una nueva solicitud con radicado N° "+this.fileNumber+" para su verificación del trámite 'Autorización para capacitadores de manipulación de alimentos'")

              let email: emailDTO = {
                to: this.requestManagementValidatorForm.get('basicDataForm').get('correoElectronico').value,
                body: template,
                subject: 'Solicitud para verificación'
              }
              this.requestService.sendNotification(email).subscribe((r) => {
                console.log(r);
              })
            });
          }else{
            Swal.fire({
              icon: 'error',
              title: '<h5>Error en el servicio</h5>',
              text: 'Ocurrio un error en el guardado de la solicitud',
            })
          }
          console.log(res);
          //this.sucessAlert.open();
        })



    }else{

      Swal.fire({
        icon: 'error',
        title: '<h5>Resultado de la validación no selecionado</h5>',
        text: 'El resultado de la validación no ha sido seleccionado',
      })

    }
  }

  viwDocumentAction = () => {

    this.viewDocument = true;
  }

  closeDocument = () => {
    this.viewRetrievalModal = false;
    this.viewCancellationModal = false;
    this.viewDocument = false;
    this.viewTrainerDetailModal = false;
    this.viewDocumentButton = true;
  }

  acceptRetrieval= (e:any) => {
    this.observacionesSubsanacion = e;
    this.viewRetrievalModal = false;
    console.log(e);
  }

  acceptCancellation= (e:any) => {

    this.documentVisualizationTitle = "cancelación por incumplimiento";
    let template = "";
    const date = new Date();
    console.log(date);
    let ClaseConversor = conversor.conversorNumerosALetras;
    let miConversor = new ClaseConversor();

    let capacitaciones = "";

    if(this.isNatural){
      if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
        capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
        capacitaciones = capacitaciones + " Leche cruda "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
        capacitaciones = capacitaciones + " Alimentos en vía publica "
      }
    }

    console.log(this.trainingDataTypes);
    console.log();

    let keysCI: string[] = [];
    let valuesCI: string[] = [];

    let dateResolucion = new Date(this.solicitud.resolucionSolicitudes[0].fechaResolucion);

    this.observacionesCancelacion = e;
    console.log(this.observacionesCancelacion);
    this.viewCancellationModal = false;
    console.log(e);
    if (this.isNatural) {

      console.log( this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion);
      console.log( this.solicitud);
      keysCI= [
        "~:~fecha_vigencia~:~",
        "~:~num_res_autoriza~:~",
        "~:~fecha_res_autoriza~:~",
        "~:~numero_de_la_resolucion~:~",
        "~:~dia_de_la_resolucion~:~",
        "~:~mes_de_la_resolucion~:~",
        "~:~año_de_la_resolucion~:~",
        "~:~resol_carne~:~",
        "~:~decre_leche~:~",
        "~:~resol_alimentos~:~",
        "~:~nombre_solicitante~:~",
        "~:~tipo_id_sol~:~",
        "~:~num_id_sol_pn~:~",
        "~:~tipo_capacitaciones~:~",
        "~:~id_solicitud~:~",
        "~:~fecha_id_solicitud~:~",
        "~:~num_id_sol~:~",
        "~:~titulo_profesional_capacitador~:~",
        "~:~num_tar_profesional~:~",
        "~:~año_letra_de_la_resolucion~:~",
        "~:~firma_subdirector~:~",
        "~:~nombre_validador~:~",
        "~:~nombre_coordinador~:~",
        "~:~motivo_cancelacion_incumplimiento_pn~:~"
        ];
      let capacitacioncompleta="";
      let selected=false;

      if(this.trainingDataTypes[2].selected)
      {
        capacitacioncompleta+=this.trainingDataTypes[2].name
      }


      for (let i = 0; i < 2; i++) {
        if(this.trainingDataTypes[i].selected)
        {
          if(capacitacioncompleta!="")
          { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

          }
          else
          {
            capacitacioncompleta+= this.trainingDataTypes[i].name
          }

        }
      }
      selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
        valuesCI = [
        (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
        this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
        (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
        "",
        (date.getDate()).toString().padStart(2, "0"),
        this.meses[date.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
        date.getFullYear().toString(),
        this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
        this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
          this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
          this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
        this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
        this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
        capacitaciones,
        this.fileNumber,
        this.solicitud.vcFechaSolicitud,
        this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
        this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
        this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
        miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
        "firma",
        JSON.parse(localStorage.getItem('currentUser')).fullName,
        "",
        this.observacionesCancelacion
      ];
      this.requestService.GetTemplate(18).subscribe((e:any) => {
        console.log(e);
        template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);

        this.requestService.getDocument({html: template}).subscribe((r:any) => {
          console.log(r);
          this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
          this.isLoadingDocument = false;
        })
      })
    }else{
      keysCI = [
        "~:~fecha_vigencia~:~",
        "~:~num_res_autoriza~:~",
        "~:~fecha_res_autoriza~:~",
        "~:~numero_de_la_resolucion~:~",
        "~:~dia_de_la_resolucion~:~",
        "~:~mes_de_la_resolucion~:~",
        "~:~año_de_la_resolucion~:~",
        "~:~resol_carne~:~",
        "~:~decre_leche~:~",
        "~:~resol_alimentos~:~",
        "~:~nombre_rep_legal~:~",
        "~:~tipo_doc_rep_legal~:~",
        "~:~num_id_rep_legal~:~",
        "~:~nombre_empresa~:~",
        "~:~nit_empresa~:~",
        "~:~tipo_capacitaciones~:~",
        "~:~id_solicitud~:~",
        "~:~fecha_id_solicitud~:~",
        "~:~num_id_sol~:~",
        "~:~titulo_profesional~:~",
        "~:~num_tarj_profesional~:~",
        "~:~año_letra_de_la_resolucion~:~",
        "~:~firma_subdirector~:~",
        "~:~nombre_validador~:~",
        "~:~nombre_coordinador~:~",
        "~:~capacitadores~:~",
        "~:~motivo_cancelacion_incumplimiento~:~"
        ];
      let capacitacioncompleta="";
      let selected=false;

      if(this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica"))
      {
        capacitacioncompleta="Alimentos en vía publica";
      }


      for (let i = 0; i < this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
        if(this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
        {
          if(capacitacioncompleta!="")
          {
            capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

          }
          else
          {
            capacitacioncompleta+=this.solicitud.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
          }
        }
      }
      selected=this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda");

        valuesCI = [
        (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
        this.solicitud.resolucionSolicitudes[0].vcNumeroResolucion,
        (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
        "",
        (date.getDate()).toString().padStart(2, "0"),
        this.meses[date.getMonth()],
        date.getFullYear().toString(),
        this.isDecree(this.solicitud.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
        this.isDecree(this.solicitud.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
          this.isDecree(this.solicitud.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
          this.requestManagementValidatorForm.get('basicDataForm').get('primerNombre').value +" "+this.requestManagementValidatorForm.get('basicDataForm').get('primerApellido').value,
        this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
        this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
        this.documentTypes.find((dt:any) => this.requestManagementValidatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
        this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
        capacitaciones,
        this.fileNumber,
        this.solicitud.vcFechaSolicitud,
        this.requestManagementValidatorForm.get('basicDataForm').get('numeroDocumento').value,
        this.requestManagementValidatorForm.get('applicationDataForm').get('tituloProfesional').value,
        this.requestManagementValidatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
        miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
        "firma",
        JSON.parse(localStorage.getItem('currentUser')).fullName,
        "",
        this.getTrainers(this.solicitud.capacitadoresSolicitud),
        this.observacionesCancelacion
        ];
      this.requestService.GetTemplate(17).subscribe((e:any) => {
        console.log(e);
        template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);;

        this.requestService.getDocument({html: template}).subscribe((r:any) => {
          console.log(r);
          this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
          this.isLoadingDocument = false;
        })
      })
    }
  }

  acceptSelectedTrainer= (e:any) => {
    console.log("guardar seleccion de trainer");
    console.log(e);
    this.viewTrainerDetailModal = false;

    //Recorrido
    let i = true;
    this.trainers.forEach((element:any) => {

      if(element.identificationNumber == this.canBeApprovedObj.identificationNumber){
          element.disabledFlag = this.canBeApproved();
      }

    });

    this.canBeApprovedFlag = this.allBeApproved();
    console.log(this.allBeApproved());
  }

  viewDetailTrainer = (e:any) => {

    this.canBeApprovedObj = e;
    this.isLoadingTrainer = true;
    console.log('Trainer Detail');
    this.detailDocumentation = [];
    this.detailTrainingDataTypes = [];
    console.log(e);
    this.viewTrainerDetailModal = true;

    this.requestService.GetDocumentTypes('bTipoIdentificacion').subscribe((result) =>{
      this.documentTypes22 = result.data;
    })


    this.requestManagementValidatorForm.get("modalTrainerForm").get("primerNombre").setValue(e.firstName);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("segundoNombre").setValue(e.secondName);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("primerApellido").setValue(e.surname);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("segundoApellido").setValue(e.secondSurname);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("tipoDocumento").setValue(e.documentType);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("numeroDocumento").setValue(e.identificationNumber);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("tituloProfesional").setValue(e.professionalTitle);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("numeroTarjetaProfesional").setValue(e.professionalCardNumber);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("numeroContacto").setValue(e.telephoneContact);
    this.requestManagementValidatorForm.get("modalTrainerForm").get("correoElectronico").setValue(e.emailAddress);

    this.requestService.GetRequest(parseInt(this.activateRoute.snapshot.paramMap.get('idRequest'))).subscribe((result) => {
      console.log("datos de la solicitud para capacitador")
      console.log(result);

      //Datos de la solicitud

      let obj = null;

      let capacitador:any = null;

      this.requestService.GetTraining().subscribe((result2) => {
        console.log(result2);
        result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

          obj = {
            id: r.idTipoCapacitacion,
            name: r.vcDescripcion,
            selected: false
          }

          this.detailTrainingDataTypes.push(obj);
        });

        this.documentationSolicitud.capacitadoresSolicitud.forEach((cs:any) => {
          if(cs.intNumeroIdentificacion == e.identificationNumber){
            console.log("I find it");
            capacitador = cs;
          }
        });

        console.log(capacitador);

        this.detailTrainingDataTypes.forEach((t) => {
          console.log("forEach trainings");

          let aux = capacitador.capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

          if (aux != undefined) {
            t.selected = true;
          }
        })

        //Documentation

      let doc = null;

      capacitador.documentosSolicitud.forEach((ds: any) => {

        console.log(ds.vcTipoDocumento.includes("Pensum") )
        console.log(ds);
        doc = {
          id: ds.idDocumento,
          descripcion: ds.vcTipoDocumento,
          path: ds.vcPath,
          aprovado: ds.blIsValid,
          fileNumber: this.fileNumber,
          mandatory:!ds.vcTipoDocumento.includes("Pensum")
        }
        this.detailDocumentation.push(doc);
      });

      console.log(this.detailDocumentation);

      this.isLoadingTrainer = false;
      })
    });

  }

  approvedTrainer = (e:any) => {
    console.log('approved Trainer');
    console.log(e);

    this.trainersCopy.forEach((element:any) => {

      if(element.identificationNumber == e.record.identificationNumber){
          element.approved = e.value == "si";
      }
    });
  }

  getTemplate = (template:string, keys:string[], values:string[]) => {

    let res = template;

    for (let index = 0; index < keys.length; index++) {
      res = res.replaceAll(keys[index], values[index]);
    }
    return res;
  }

  closeButton(): void {
    this.showSuccesAlert = false;
    this.router.navigateByUrl(
      ROUTES.MainPage + '/' + ROUTES.Dashboard + '/' + ROUTES.DASHBOARD_VALIDATOR
    );
  }

  acceptButton():void {
    this.showSuccesAlert = false;
  }

  isDecree = (capacitadores:any[], value:string):boolean => {
    let flag = false;
    capacitadores.forEach((e) => {
      if (e.capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
        flag = true;
      }
    })

    return flag;
  }

  decree = (capacitadorTipoCapacitacion:any[], value:string):boolean => {
    let flag = false;
      if (capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
        flag = true;
      }

    return flag;
  }

  getTrainers = (capacitadores:any[]):string =>{

    let original:string = "";

    capacitadores.forEach((e) => {

      let capacitaciones = "";

      this.decree(e.capacitadorTipoCapacitacion, "Carnes y productos cárnicos comestibles")?capacitaciones=capacitaciones+" Carnes y productos cárnicos comestibles,":null;
      this.decree(e.capacitadorTipoCapacitacion, "Leche cruda")?capacitaciones=capacitaciones+" Leche cruda,":null;
      this.decree(e.capacitadorTipoCapacitacion, "Alimentos en vía publica")?capacitaciones=capacitaciones+" Alimentos en vía publica":null;

      console.log("Capacitadores");
      console.log(capacitaciones);
      let cadena:string;
      if (e.vcPrimerNombre != "") {
        cadena = +cadena+"<tr>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+e.vcPrimerNombre+" "+e.vcPrimerApellido+"</td>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+"<div>"+"<p>"+e.vcTipoIdentificacion+"</p>"+"<p>"+e.intNumeroIdentificacion+"</p></td>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+ e.vcTituloProfesional +"</td>";
        cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+ e.vcNumeroTarjetaProfesional +"</td>";
        cadena = cadena+"<td style='width: 100px;word-break: break-all;'>"+ capacitaciones +"</td>"
        cadena = cadena + "</tr>";
      }
      original = original + cadena;
      console.log("aqui");
      console.log(e);
    })

    console.log(original.replaceAll("NaN",""));

    return original.replaceAll("NaN","");
  }

  getIdNumber = (id:number) => {
    let idNumber: string = "";
    this.idCapacitadorJ.forEach((e) => {
      if (id == e.intNumeroIdentificacion) {
        idNumber = e.idCapacitadorSolicitud;
      }
    })
    return idNumber;
  }

  canBeApproved(){

    console.log(this.canBeApprovedObj);

    let bandera = true;

    let capacitador:any = [];

    capacitador = this.documentationSolicitud.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == this.canBeApprovedObj.identificationNumber);

    console.log(capacitador);
    if(capacitador == undefined){
      bandera = false;
    }
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (!element2.blIsValid) {
        console.log("es falso");
        bandera = false;
      }
    });

    return bandera;
  }


  allBeApproved(){

    console.log(this.canBeApprovedObj);

    let bandera = true;

    console.log(this.documentationSolicitud);

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
      console.log(element);
      element.documentosSolicitud.forEach((element2: { blIsValid: any; }) => {
        console.log(element2);
        if (!element2.blIsValid) {
          console.log("es falso");
          bandera = false;
        }
      });
    });

    //.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == this.canBeApprovedObj.identificationNumber);

    /*
    console.log(capacitador);
    if(capacitador == undefined){
      bandera = false;
    }
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (!element2.blIsValid) {
        console.log("es falso");
        bandera = false;
      }
    });

    return bandera;
  */

    return bandera;
  }

  BeApproved(id:any){

    let bandera = true;

    let capacitador: any = null;

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element: { intNumeroIdentificacion: any; }) => {
      if(element.intNumeroIdentificacion == id){
        console.log("Oui");
        capacitador = element;
      }
    });

    if(capacitador == null){
      bandera = false;
      return bandera;
    }

    capacitador.documentosSolicitud.forEach((element2: { blIsValid: any; }) => {
        console.log(element2);
        if (!element2.blIsValid) {
          console.log("es falso");
          bandera = false;
        }
      });

    return bandera;
  }



}
