import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario } from '@core-app/services/usuario';
import { ROUTES } from '@core-app/enums';
import { RequestService } from '@core-app/services/request.service';

@Component({
  selector: 'app-coordinator-tab',
  templateUrl: './coordinator-tab.component.html',
  styleUrls: ['./coordinator-tab.component.scss']
})
export class CoordinatorTabComponent implements OnInit{
  public selectOptionFilterForm: FormGroup;
  isLoading:boolean = true;
  public dataFilterTable: any;
  public dataTableManagement: any = [];
  public dataFilterTableManagement: any = [];
  public dataFilterTableTrainingsFollowUp: any;

   /**
   * datos de la bandeja del validador
   */
   public searchbar:boolean=true;
  public datebar:boolean=false;

   public dataTable: any = [
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 5,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    },
    {
      radicado:'RD0010',
      nombre: 'Carlos Morales',
      numeroDocumento: 123456789,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '15/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -2,
      diasHabiles: 15
    },
    {
      radicado:'RD0020',
      nombre: 'Camilo Mendez',
      numeroDocumento: 1111580614,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '17/01/2022',
      estado: 'En verificación aprobación',
      diasRestantes: 0,
      diasHabiles: 15
    },
    {
      radicado:'RD0030',
      nombre: 'Laura Palacios',
      numeroDocumento: 1111255418,
      tipoSolicitud: 'Primera vez',
      tipoSolicitante: 'Persona natural',
      fechaRegistro: '01/02/2022',
      estado: 'En verificación aprobación',
      diasRestantes: -5,
      diasHabiles: 15
    }
   ];


   /**
    * Columnas de la bandeja del logistico
    */

   public structureTable: any= [
    {
      name: 'ID solicitud',
      type: 'texto',
      value: 'vcRadicado'
    },
    {
      name: 'Solicitante',
      type: 'texto',
      value: 'vcNombreUsuario'
    },
    {
      name: 'No. identificación',
      type: 'texto',
      value: 'intNumeroIdentificacionUsuario'
    },
   {
      name: 'Tipo de solicitud',
      type: 'texto',
      value: 'vcNombre'
    },
    {
      name: 'Tipo de solicitante',
      type: 'texto',
      value: 'vcTipoSolicitante'
    },
    {
      name: 'Fecha de solicitud',
      type: 'texto',
      value: 'dtFechaSolicitud'
    },
    {
      name: 'Estado',
      type: 'texto',
      value: 'vcTipoEstado'
    },
    {
      name: 'Acciones permitidas',
      type: 'enlace',
      value: 'Ver detalle'
    },
    {
      name: 'Tiempo de atención restante',
      type: 'remainingDays',
      value1: 'diasRestantes',
      value2: 'diasHabiles',
      color1: '#e6effd',
      color2: '#ff574d'
    }
   ];


   constructor(private requestService: RequestService,private fb:FormBuilder, private usuario : Usuario,private router: Router){
      this.isLoading = true;
      this.selectOptionFilterForm = this.fb.group({
      selectOptionFilterInput: ['0'],
      valueFilterInput: ['', [Validators.pattern('[a-zóA-Zóé0-9 /.-]*'), Validators.maxLength(50)]],
      fecha: []
    });



    const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;
       let color = true;
       this.usuario.GetAllRequestsCoordinator(idUser).subscribe((values) => {
        if(values.data !=null){

            values.data.forEach((e:any) => {

            let isNatural:boolean = e.vcTipoSolicitante == 'P. Natural';
            console.log(isNatural?'natural':'juridica');

            const [day, month, year] = e.dtFechaSolicitud.split('/');
            let date = new Date(year+"-"+month+"-"+day);
            date.setDate(date.getDate() + 1);
            let fecha = date.getFullYear() + "-"+ (date.getMonth()+1).toString().padStart(2,'0') + "-" + date.getDate().toString().padStart(2,'0');
            this.requestService.getRemainingDays('2CB35A45-12D8-4AAB-8A01-5DC5FDDAC591', fecha).subscribe((rd) => {
              e.diasRestantes = isNatural?rd.data.personaNatural.diasRestantes:rd.data.personaJuridica.diasRestantes;
              e.diasHabiles = isNatural?rd.data.personaNatural.totalANS:rd.data.personaJuridica.totalANS;
            })
          });
        };
      this.dataTableManagement = values.data;
      console.log("datos encontrados para filtrar", this.dataTableManagement);
      this.dataFilterTableManagement = this.dataTableManagement;
      this.isLoading = false;
    });
  }

  ngOnInit(){

  }
  changestatus(){
    if(this.selectOptionFilterForm.get('selectOptionFilterInput').value=="6")
    {
      this.datebar=true;
      this.searchbar =false;
    }
    else {
      this.datebar=false;
      this.searchbar =true;
    }
  }
  reports(){
    this.router.navigate([`aut-cap/request/generalReports`]);
  }
  redirectToViewDetail(e: any) {
    const nombreUsuario = e.record.vcNombreUsuario;
    const idSolicitud = e.record.idSolicitud;
    const solicitud = localStorage.setItem("id",idSolicitud);
    localStorage.setItem('Rol',"coordinador");
    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Dashboard}/${ROUTES.REQUEST_MANAGEMENT_COORDINATOR}/${idSolicitud}/${e.record.vcTipoSolicitante}?nombreUsuario=${nombreUsuario}`);
  }


  filter(){
    switch (this.selectOptionFilterForm.get('selectOptionFilterInput').value) {
      case '1':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcRadicado: any; }) => d.vcRadicado.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '2':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombreUsuario: any; }) => d.vcNombreUsuario.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '3':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { intNumeroIdentificacionUsuario: any; }) => d.intNumeroIdentificacionUsuario.toString().includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '4':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombre: any; }) => d.vcNombre .includes( this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '5':
      this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcTipoSolicitante: any; }) => d.vcTipoSolicitante .includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '6':
        let fecha="";
        if(this.selectOptionFilterForm.get('fecha').value!=null)
        {
          fecha=this.selectOptionFilterForm.get('fecha').value.toString().split('-')
          fecha=fecha[2]+"/"+fecha[1]+"/"+fecha[0]
        }
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { dtFechaSolicitud: any; }) => d.dtFechaSolicitud == fecha);
      break;
      case '7':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcTipoEstado: any; }) => d.vcTipoEstado .includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      default:
        break;
    }
  }


}
