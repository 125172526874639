  <nz-spin style="display: block;width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'"
  nzTip="Se estan cargando los datos, por favor espere un momento....">
  <div class="row mt-3">
    <div class="col-lg-10 col-sm-12">
        <app-advance-line [width]="124" [step]="2" [currentProgress]="100" [options]="advanceLineData"></app-advance-line>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-lg-12 mt-3">
      <p class="title_page">Autorización para capacitadores de manipulación de alimentos</p>
    </div>
  </div>


    <app-basic-data [formRequest]="formRequest"></app-basic-data>

    <div *ngIf="mostrarPersonaJuridica">
      <app-trainers class="mt-3" (datosTrainers)="trainer($event)" (updateTrainer)="updateTrainer($event)"></app-trainers>
    </div>

<div class="files mt-3" *ngIf="!mostrarPersonaJuridica">
  <p class="title_section mt-3">Adjuntar archivos</p>
  <span class="mb-5 d-block">* Campos obligatorios</span>
  <div class="contenedor mx-5" style="margin:10px;margin-top: -32px; margin-left: -43px !important;" [ngStyle]="{'grid-template-rows':'1fr'}">
    <div class="box1 mx-5">
      <div *ngFor="let d of documents; let i = index">
        <div class="mt-3 left_colum_file" style="display: grid;grid-template-columns: 60% 40%;">
          <div style="display: grid;grid-template-columns: 25% 75%;">
            <span class="d-block"><b>{{d.mandatory?'*':null}}{{d.name}}</b></span>
            <input accept=".pdf" (change)="onChange({file:$event,document:d}, d)" style="height: 35px;width: 70%; margin-left: 15px;"
              class="form-control gov-co-form-control d-block" id="formFileLg" type="file" #replenishmentDocument>
          </div>
          <div
            style="display: grid; grid-template-columns: 20% 50% 30%;padding-bottom: 15px; padding-top: 5px;">
            <div (dblclick)="seeDocument(replenishmentDocument)">
              <i class="bi bi-file-earmark-pdf icon"></i>
            </div>
            <div style="cursor: pointer;" class="ml-3">
              <span class="d-block">{{replenishmentDocument.files.length==0?'no
                file':replenishmentDocument.files[0].name}}</span>
              <span class="d-block">{{replenishmentDocument.files.length==0?'0
                Kb':replenishmentDocument.files[0].size/1000 +' Kb'}}</span>
            </div>
            <div (click)="replenishmentDocument.value = null; delete(d)">
              <i class="bi bi-trash3-fill icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="documentViewer">
    <app-document-viewer [isVisible]="viewDocument" [document]="urlSafeDocument"
      (closeButtonEvent)="closeDocument()"></app-document-viewer>
  </div>
</div>


<div class="row mt-5">
  <div class="col-lg-12 d-flex justify-content-center" id="actions">
    <button type="button" class="btn btn-outline-primary info" id="cancel"
      (click)="showCancelConfirmation()">Cancelar</button>
    <button type="button" class="btn btn-primary mx-2 info" id="send" (click)="enviarSolicitud()">Enviar</button>
  </div>
</div>
</nz-spin>
