<div class="row mt-3">
  <div class="col-lg-10 col-sm-12 col-md-10 col-xs-10">
      <app-advance-line [ariaSetSize]="130" [width]="116" [step]="4" [currentProgress]="100" [options]="advanceLineData"></app-advance-line>
  </div>
</div>

<div class="row mt-3">
  <div class="col-lg-8 col-sm-12 col-md-8 col-xs-12">
      <div class="heading">
          <p class="title_section">Consultar por número de radicado</p>
      </div>
      <form [formGroup]="selectOptionFilterManagementForm" class="mt-3">
          <div class="form-row">
              <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl4 col-lg-4">
                  <label class="d-block">Consultar por</label>
                  <select formControlName="selectOptionFilterGeneralInputManagement" class="form-control" aria-label="Seleccione">
                      <option value="0" hidden>Seleccione una opción</option>
                      <option value="1">Buscar todos</option>
                      <option value="2">Número de radicado</option>
                  </select>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl4 col-lg-4">
                  <input style="margin-top: 32px;"
                  [class.inactive]="selectOptionFilterManagementForm.get('selectOptionFilterGeneralInputManagement').value==1"
                  formControlName="valueFilterGeneralInputManagement"
                  class="form-control form-group gov-co-form-group mx-3 d-inline" type="text"
                  placeholder="Ingrese su búsqueda">
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl4 col-lg-4">
                  <button (click)="search()" class="btn btn-round btn-high mx-3 d-block searchinfo"
                  style="color: white; font-size: 17px;padding:5px 40px; text-transform: uppercase; margin-top:32px !important; margin-left:40px !important;"
                  [disabled]="selectOptionFilterManagementForm.get('selectOptionFilterGeneralInputManagement').value==0 || selectOptionFilterManagementForm.get('valueFilterGeneralInputManagement').status == 'INVALID'"
                  type="button">buscar</button>
              </div>
          </div>
      </form>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-2 col-xl-3 float-right">
    <div class="custom-alert">
        <a href="https://aulavirtual.saludcapital.gov.co/sicap/" style="text-decoration: none !important;" target="_blank">
            <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                Te explicamos con tutoriales
            </div>
        </a>
        <a type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                ¿Tienes dudas?
            </div>
        </a>
        <div class="collapse" id="collapseExample">
            <div class="card card-body" id="cardi_info_contact">
              <p id="colorphone" style="margin-left: 18px;">Teléfono:(571) 3649090</p>
              <p id="colorphone" style="margin-left: 18px; margin-top: -10px;">contactenos&commat;aludcapital.gov.co</p>
            </div>
        </div>
    </div>
</div>
</div>

<div class="row">
  <div class="col-lg-12">
      <div *ngIf="selectOptionFilterManagementForm.get('valueFilterGeneralInputManagement').status=='INVALID'" class="alert mt-2" style="background-color: #e6effd; color: #004884; font-size: 15px; height: 100px;">
          <p>* Solo se admiten caracteres alfanuméricos, no ingrese caracteres especiales.</p>
          <p> * La cantidad máxima de caracteres admitidos es 20.</p>
      </div>
  </div>
</div>

<div *ngIf="thereAreResults">
  <div class="row mt-3">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12">
          <div class="heading">
              <p class="title_section">Resultados</p>
          </div>
      </div>
  </div>
</div>

<div *ngIf="thereAreResults && !firsttime">
  <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12" style="width: 96%;">
          <div class="tabs-govco">
              <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <a (click)="inManagement()" class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#enGestion" role="tab" aria-controls="nav-home" aria-selected="true">
                          En gestión
                      </a>
                      <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#seguimientoCapacitaciones" role="tab" aria-controls="nav-profile-tab" aria-selected="false">
                          Registrar capacitación
                      </a>
                  </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="enGestion" role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="filtros mb-5">
                          <form [formGroup]="selectOptionFilterManagementForm">
                              <div class="form-row">
                                <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl4 col-lg-4">
                                    <label class="d-block">Filtrar por</label>
                                    <select formControlName="selectOptionFilterInputManagement" style="height: 35px;" class="form-select" aria-label="Seleccione"(change)="changestatus()">
                                      <option value="0" hidden>Seleccione una opción</option>
                                      <option value="1">ID de solicitud</option>
                                      <option value="2">Estado</option>
                                      <option value="3">Fecha de solicitud</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl-4 col-lg-4"*ngIf="searchbar">
                                    <input formControlName="valueFilterInputManagement" style="margin-top: 32px;" class="form-control mx-3 d-inline" type="text" placeholder="Ingrese su búsqueda" />
                                </div>
                                <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl-4 col-lg-4"*ngIf="datebar">
                                  <input formControlName="fecha" style="margin-top: 32px;" class="form-control mx-3 d-inline"  type="date"  />
                                </div>

                                <div class="form-group col-sm-12 col-xs-12 col-md-4 col-xl4 col-lg-4">
                                    <button
                                      class="btn btn-round btn-middle mx-3"
                                      style="margin-top: 32px !important; margin-left: 30px !important; padding: 10px 10px; width: 12%; color: white; font-size: 17px; padding: 5px 40px; border: 1px solid #3366cc;"
                                      [disabled]="selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value==0 || selectOptionFilterManagementForm.get('valueFilterInputManagement').status == 'INVALID'"
                                      (click)="filterManagement()"
                                      type="button">
                                      FILTRAR
                                    </button>
                                </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                                    <div *ngIf="selectOptionFilterManagementForm.get('valueFilterInputManagement').status=='INVALID'" class="alert mt-2" style="background-color: #e6effd; color: #004884; font-size: 15px; height: 100px;">
                                        <p> * Solo se admiten caracteres alfanuméricos, a excepción del caracter / no ingrese caracteres especiales</p>
                                        <p> * La cantidad máxima de caracteres admitidos es 20.</p>
                                    </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                      <div>
                          <app-procedure-dashboard [data]="dataFilterTableManagement" [structure]="structureTableManagement" (multipleLinkEvent)="allowedActionts($event)"></app-procedure-dashboard>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="seguimientoCapacitaciones" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <app-capacitation-tab></app-capacitation-tab>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-lg-10 col-xl-10 col-md-10 col-xs-12 col-sm-12">
      <div *ngIf="!thereAreResults" class="info_alert alert mt-2 d-flex align-items-center justify-content-center" style="font-size: 15px;">
          <p style="color: white; margin: 0px;"><i class="bi bi-x-octagon mr-3" style="color: white; font-size: 22px;"></i>Lo sentimos, no se encontrarón resultados</p>
      </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
      <div class="alerts">
          <nz-modal nzWidth="800px" [nzBodyStyle]="{ height: '200px' }" [(nzVisible)]="viewResource" nzCancelText="{{ null }}" nzClosable="false">
              <ng-container *nzModalContent>
                  <div class="w-100 h-100" style="text-align: center;">
                      <h3 style="text-align: justify;" class="headline-l-govco mb-4">ADJUNTAR RECURSO</h3>
                      <div class="files" style="display: grid; grid-template-columns: 60% 40%;">
                          <div>
                              <input accept=".pdf" (change)="onChange({ file: $event })" style="height: 50px; width: 70%;" class="form-control gov-co-form-control d-block" id="formFileLg" type="file" #replenishmentDocument />
                          </div>
                          <div>
                              <div style="display: grid; grid-template-columns: 20% 50% 30%; background-color: #f4f4f4; padding-bottom: 15px; padding-top: 5px;">
                                  <div>
                                      <i class="bi bi-file-earmark-pdf icon"></i>
                                  </div>
                                  <div style="cursor: pointer;" class="ml-3">
                                      <span class="d-block">{{ replenishmentDocument.files.length == 0 ? 'no file' : replenishmentDocument.files[0].name }}</span>
                                      <span class="d-block">{{ replenishmentDocument.files.length == 0 ? '0 Kb' : replenishmentDocument.files[0].size / 1000 + ' Kb' }}</span>
                                  </div>
                                  <div (click)="replenishmentDocument.value = null;">
                                      <i class="bi bi-trash3-fill icon"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </ng-container>
              <div *nzModalFooter class="d-flex justify-content-center mb-3">
                  <button (click)="closeButton()" class="btn btn-round btn-middle mx-3" style="color: white; font-size: 14px; padding: 5px 10px; border: 1px solid #3366cc;">
                      CERRAR
                  </button>
                  <button (click)="acceptButton()" class="btn btn-round btn-high mx-3" style="color: white; font-size: 14px; padding: 5px 10px;">
                      ADJUNTAR Y ENVIAR
                  </button>
              </div>
          </nz-modal>
      </div>
  </div>
</div>

<nz-modal nzWidth="750px" [nzBodyStyle]="{height:'250px'}" [(nzVisible)]="isVisibleResolution" nzCancelText={{null}} style="z-index: 1052;"
  nzClosable="false">
  <ng-container *nzModalContent>
    <div class="w-100">
      <h4 class="text-center" style="font-weight: bold; text-transform: uppercase;">Descargar Resolución</h4>
    </div>
    <div class="w-100 h-100">
      <a class="d-block" style="font-size: 18px;color: black" (click)="downloadResolution(false)"><i
        class="bi bi-file-earmark-pdf icon"></i> Resolución de aprobación</a>
      <a *ngIf="!isCanceled" class="d-block" style="font-size: 18px;color: black" (click)="downloadResolution(true)"><i
        class="bi bi-file-earmark-pdf icon"></i> Resolución de cancelación por incumplimiento</a>
      <a *ngIf="isCanceled" class="d-block" style="font-size: 18px;color: black" (click)="downloadResolution(true)"><i
          class="bi bi-file-earmark-pdf icon"></i> Resolución de cancelación</a>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button (click)="closeButton()" class="btn btn-round btn-high mx-3"
      style="color: white; font-size: 17px;padding:5px 40px;">
      CERRAR
    </button>
  </div>
</nz-modal>


