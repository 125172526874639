import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {requestcitizen} from '../models/requestcitizen';
import { Coordinator } from '@core-app/Interface/coordinator';
const { PROCEDUREGENERAL } = environment;
const {PROCEDURE_SECURITY_URI} = environment;
const {PROCEDURE_SHARED_URI} = environment;
const {PROCEDURE_LOCAL_URI} = environment;

@Injectable({
  providedIn: 'root'
})

export class Usuario {

  subscribe(userId: String) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  public getGeneral(usuarioId: Number): Observable<any>{
    return this.http.get(`${PROCEDUREGENERAL}/Solicitud/BandejaCiudadano/${usuarioId}`);
  }

  public getTypeDni(codigoInterno: string): Observable<any> {
    const url = `${PROCEDUREGENERAL}/ParametroDetalle/PorCodigoInterno/${codigoInterno}`;
    return this.http.get(url);
  }

  public getAllRequestCitizen(userId: number, radicado: string): Observable<any> {
    const urlGet = `${PROCEDUREGENERAL}/Solicitud/BandejaCiudadano/${userId}?radicado=${radicado}`;
    return this.http.get(urlGet);
  }

  public getTiposCapacitacion(): Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/TipoCapacitacion`);
  }

  public getInfoperson(idUser: Number){
    return this.http.get(`${PROCEDURE_SECURITY_URI}/GetCodeVentanillaByIdUser/${idUser}`);
  }

  public getTotalperson(idUser: Number){
    return this.http.get(`${PROCEDURE_SHARED_URI}/v2/Persona/GetInfoUserById/${idUser}`);
  }

  private tipoIdentificacionRLSubject = new BehaviorSubject<string>('');

  setTipoIdentificacionRL(value: string) {
    this.tipoIdentificacionRLSubject.next(value);
  }

  getTipoIdentificacionRL(): Observable<string> {
    return this.tipoIdentificacionRLSubject.asObservable();
  }

  recordNewPost(solicitud: any): Observable<any> {
    return this.http.post(`${PROCEDUREGENERAL}/Solicitud/CrearSolicitud`, solicitud);
  }

  GetAllRequestsCoordinator(UsuarioAsignadoId: string) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Solicitud/BandejaCoordinador?UsuarioAsignadoId=${UsuarioAsignadoId}`);
  }

  reviewCoordinator(modelo:Coordinator):Observable<Coordinator>{
    return this.http.post<Coordinator>(`${PROCEDUREGENERAL}/Solicitud/RevisionCoodinador`, modelo);
  }

  temporarySaveCoordinatorAssistantManager(modelo:Coordinator):Observable<Coordinator>{
    return this.http.post<Coordinator>(`${PROCEDUREGENERAL}/Solicitud/GuardadoTemporal/RevisionCoodinadorSubdirector`, modelo);
  }

  getCapacitation(id:string): Observable<any>{
    return this.http.get(`${PROCEDUREGENERAL}/CapacitacionCapacitador/BandejaRegistrarCapacitacion?UsuarioId=${id}`);
  }

  recordCapacitation(solicitud:any){
    return this.http.post(`${PROCEDUREGENERAL}/CapacitacionCapacitador/RegistrarCapacitacion`, solicitud);
  }

  getAllName(id:string){
    return this.http.get(`${PROCEDUREGENERAL}/CapacitadorSolicitud/NombreCapacitadores/${id}`);
  }

  delete(id:string){
    return this.http.get(`${PROCEDUREGENERAL}/CapacitacionCapacitador/${id}`);
  }
  UpdateCapacitador(id:string,json:any){
    return this.http.put(`${PROCEDUREGENERAL}/CapacitacionCapacitador/${id}`, json);
  }
  getRequestList(id:string){
    return this.http.get(`${PROCEDUREGENERAL}/ParametroDetalle/${id}`);
  }

  dateSearchAdmin(fechaDesde: string, fechaHasta: string){
    const url = `${PROCEDUREGENERAL}/Reporte/ActosAdministrativosGenerados/${fechaDesde}/${fechaHasta}`;
    return this.http.get(url, { responseType: 'blob', observe:'response'});
  }

  dateSeguimientoCapacitaciones(fechaDesde: string, fechaHasta: string){
    const url = `${PROCEDUREGENERAL}/Reporte/SeguimientoCapacitaciones/${fechaDesde}/${fechaHasta}`;
    return this.http.get(url, { responseType: 'blob', observe:'response'});
  }

  dateAutorizaciones(fechaDesde:Date,fechaHasta:Date){
    const url = `${PROCEDUREGENERAL}/Reporte/AutorizacionesCanceladas/${fechaDesde}/${fechaHasta}`;
    return this.http.get(url, { responseType: 'blob', observe:'response'});
  }

  dateSuspendidos(fechaDesde:Date,fechaHasta:Date){
    const url = `${PROCEDUREGENERAL}/Reporte/CapacitadoresAutorizados/${fechaDesde}/${fechaHasta}`;
    return this.http.get(url, { responseType: 'blob', observe:'response'});
  }

  dateCancel(fechaDesde:Date,fechaHasta:Date){
    const url = `${PROCEDUREGENERAL}/Reporte/CapacitadoresSuspendidos/${fechaDesde}/${fechaHasta}`;
    return this.http.get(url, { responseType: 'blob', observe:'response'});
  }

  getListCapacitationSolicitud(id:string){
    return this.http.get(`${PROCEDUREGENERAL}/CapacitacionCapacitador/CapacitacionesSolicitud/${id}`);
  }

  deleteStatusCapacitationSolicitud(id: number) {
    return this.http.put(`${PROCEDUREGENERAL}/CapacitacionCapacitador/Inactiva/${id}`, {});
  }

  getInfoOnInput(id:number){
    return this.http.get(`${PROCEDUREGENERAL}/CapacitacionCapacitador/Capacitacion/${id}`);
  }
  SaveSubsanation(id: number,json:any) {
    return this.http.put(`${PROCEDUREGENERAL}/Solicitud/ActualizacionDocumentos/${id}`, json);
  }


}
