<p class="text_header mt-4">Información de la solicitud</p>
<form [formGroup]="requestInfoForm">
  <div class="row">
    <div class="col">
      <label>Número de radicado</label>
      <input type="text" class="form-control control" placeholder="Número de radicado" aria-label="First name" formControlName="numeroRadicado">
    </div>
    <div class="col">
      <label>Tipo de trámite</label>
      <input type="text" class="form-control control" placeholder="Tipo de trámite" aria-label="Last name" formControlName="tipoTramite">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <label>Tipo de solicitud</label>
      <select class="form-select control" aria-label="Default select example" formControlName="tipoSolicitud">
        <option value="">Seleccionar</option>
        <option *ngFor="let item of elementosSelect" [value]="item.idTipoSolicitud">{{item.descripcion}}</option>
      </select>
    </div>
    <div class="col"   [class.hide-textarea]="!isPersonaNatural()">
      <label>Motivo de cancelación</label>
      <select class="form-select control" aria-label="Default select example" formControlName="motivoCancelacion">
        <option value="">Seleccionar</option>
        <option *ngFor="let item of elementosSelectCancel" [value]="item.idMotivoCancelacion">{{item.descripcion}}</option>
      </select>
    </div>
  </div>

  <div class="row mt-3"  [class.hide-textarea]="!isPersonaNatural()">
    <div class="col-6">
      <label>Número de resolución actual</label>
      <input type="text" class="form-control control" placeholder="Número de resolución actual" aria-label="Last name" formControlName="numeroResolucionActual">
    </div>
  </div>

  <div class="row mt-3" [class.hide-textarea]="!isPersonaNatural()">
    <div class="col-12">
      <label>Descripción</label>
      <textarea cols="30" rows="5" class="form-control textarea" formControlName="descripcion"></textarea>
    </div>
  </div>
</form>
