import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ProcedureRequestBackDto} from "../models/procedureRequestBack.model";
import {ProcedureResponseSaveBackDto} from "../models/procedureResponseSaveBack.model";
import { emailDTO } from '@core-app/models/emailDto';
import { getDocumetDTO } from '@core-app/models/getDocumetDTO';
const { PROCEDUREGENERAL } = environment;
const { PROCEDURE_LOCAL_URI } = environment;
const {PROCEDURE_SHARED_URI} = environment;
const {PROCEDURE_NOTIFICATIONS_URI} = environment;
const {PROCEDURE_SECURITY_URI} = environment;


/**
 * Service con los métodos relacionados al registro de una persona
 */
@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient) { }

  /**
   * Obtiene todas las solicitudes del validador
   *
   */
  GetAllRequestsValidator(UsuarioAsignadoId: string) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Solicitud/BandejaValidador?UsuarioAsignadoId=${UsuarioAsignadoId}`);
  }

  /**
   * Obtiene todas las solicitudes del ciudadano
   *
   */
  GetAllRequestsCitizen(IdCitizen: string) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Solicitud/BandejaCiudadano/${IdCitizen}`);
  }

  /**
   * Obtiene la información de la solicitud por su id
   *
   */
  GetRequest(idRequest: number) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Solicitud/${idRequest}`);
  }

  /**
   * Obtiene todas las capacitaciones
   *
   */
  GetTraining() : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/TipoCapacitacion`);
  }

  /**
   * Obtiene un parametro
   *
   */
  GetDocumentTypes(code:string) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/ParametroDetalle/PorCodigoInterno/${code}`);
  }

  /**
   * Obtiene los numeros de identificación de shared
   *
   */
  GetDocumentTypesShared() : Observable<any> {
    return this.http.get(`${PROCEDURE_SHARED_URI}/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }

  /**
   * Obtiene una plantilla
   *
   */
  GetTemplate(idPlantilla:number) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Resolucion/obtenerPlantilla/${idPlantilla}`);
  }

  /**
   * envia una notificación
   * @param emailDTO
   */
  sendNotification(emailDTO:emailDTO) : Observable<any> {
    return this.http.post(`${PROCEDURE_NOTIFICATIONS_URI}/Email/SendMail`,emailDTO);
  }

  /**
   * Generar el numero de radicado de la solicitud
   *
   */
  generateFileNumber(codigoTramite:any) : Observable<any> {
    return this.http.post(`${PROCEDURE_SHARED_URI}/v1/NumeracionTramites`,codigoTramite);
  }


  /**
   * Generar los dias restantes
   */

  getRemainingDays(idTramite:string, fecha:string): Observable<any>{
    return this.http.get(`${PROCEDURE_SHARED_URI}/v1/Holiday/CalculateRemainingDays/${fecha}/${idTramite}`)
  }

  /**
   * genera un documento
   * @param emailDTO
   */
  getDocument(html:getDocumetDTO) : Observable<any> {
    return this.http.post(`${PROCEDUREGENERAL}/Resolucion/obtenerResolucion`,html);
  }

   /**
   * obtiene los resultados de validación
   * @param idSol
   */
   getValidationResults(idSol:number) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/ParametroDetalle/ResultadoValidacion/${idSol}`);
  }

  /**
   * guarda la gestión del validador
   * @param emailDTO
   */
  checkValidator(body:any) : Observable<any> {
    return this.http.post(`${PROCEDUREGENERAL}/Solicitud/RevisionValidador`,body);
  }

  /**
   * guarda temporalmente la gestión del validador
   * @param emailDTO
   */
  temporarySaveValidator(body:any) : Observable<any> {
    return this.http.post(`${PROCEDUREGENERAL}/Solicitud/GuardadoTemporal/RevisionValidador`,body);
  }

  /**
   * Obtiene todas las solicitudes del subdirector
   *
   */
  GetAllRequestsAssistantManager(UsuarioAsignadoId: string) : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Solicitud/BandejaSubdirector?UsuarioAsignadoId=${UsuarioAsignadoId}`);
  }

  /**
   * obtener una firma
   */
  GetSignature(id:number):Observable<any>{
    return this.http.get(`${PROCEDUREGENERAL}/Resolucion/obtenerFirma/${id}`);
  }

  /**
   * guarda la gestión del subdiector
   * @param emailDTO
   */
  checkAssistantManager(body:any) : Observable<any> {
    return this.http.post(`${PROCEDUREGENERAL}/Solicitud/RevisionSubdirector`,body);
  }

   /**
   * envia una notificación con adjuntos
   * @param emailDTO
   */
   sendNotificationWithAttachment(emailDTO:emailDTO) : Observable<any> {
    return this.http.post(`${PROCEDURE_NOTIFICATIONS_URI}/Email/SendMailWithAttachment`,emailDTO);
  }

  /**
   * obtener una firma
   */
  GetDataResolution(idRequest:number):Observable<any>{
    return this.http.get(`${PROCEDUREGENERAL}/Resolucion/obtenerDatosResolucion/${idRequest}`);
  }

  /**
   * Obtener los datos de una subsanación
   */
  GetSubsanacion(idRequest:number):Observable<any>{
    return this.http.get(`${PROCEDUREGENERAL}/Resolucion/obtenerSubsanacion/${idRequest}`);
  }





  //Manialimentos

  /**
   * Obtiene todas las solicitudes
   *
   */
  GetAllRequests() : Observable<any> {
    return this.http.get(`${PROCEDUREGENERAL}/Request/GetAllRequests`);
  }

  /**
   * Obtiene los datos de la solicitud
   * @param numeroRadicado
   */
  GetRequestByFileNumber(numeroRadicado: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetRequestByFileNumber/${numeroRadicado}`);
  }

   /**
   * Obtiene todos los tipos de solicitud
   *
   */
   GetAllRequestTypes() : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetAllRequestTypes`);
  }

  /**
   * Obtiene todos los tipos de notificación
   *
   */
  GetAllNotificationTypes() : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetAllNotificationTypes`);
  }


  /**
   * Obtiene todos los tipos de notificación
   *
   */
  GetFormatById(idFormato: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Format/GetFormatById/${idFormato}`);
  }


  /**
   * Obtiene los Usuarios con el rol especificado dentro del tramite
   *
   */
  GetUserbyRol(idrol:string ) : Observable<any> {
    return this.http.get(`${PROCEDURE_SECURITY_URI}/GetUserbyIdrol/${idrol}/22`);
  }



  /**
   * Obtiene todas las capacitaciones a revisar
   *
   */
  getCapacitationdashboard(): Observable<any>{
    return this.http.get(`${PROCEDUREGENERAL}/CapacitacionCapacitador/BandejaSeguimientoCapacitacion`);
  }

  /**
   * guarda seguimiento de la capacitacion
   * @param emailDTO
   */
  RevisionCapacitacion(body:any) : Observable<any> {
    return this.http.post(`${PROCEDUREGENERAL}/CapacitacionCapacitador/RevisionCapacitacion`,body);
  }


   /**
   * Obtiene todo los estados de la solicitud
   *
   */
   GetAllRequestStatuses() : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetAllRequestStatuses`);
  }


  /**
   * Obtener todas las actividades
   *
   */
  GetAllActivities() : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetAllActivities`);
  }

  /**
   * Obtener los capacitadores de una solicitud.
   *
   */
    GetAllTrainersByIdRequest(idSolicitud:string) : Observable<any> {
      return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetAllTrainersByIdRequest/${idSolicitud}`);
    }


    /**
   * Obtener todos los titulos profesionales.
   *
   */
    GetAllProfessionalTitles() : Observable<any> {
      return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetAllProfessionalTitles`);
    }











  public saveRequest(request: ProcedureRequestBackDto): Observable<ProcedureResponseSaveBackDto> {
    return this.http.post<ProcedureResponseSaveBackDto>(`${PROCEDURE_LOCAL_URI}/Request/AddRequest`, request);
  }

  /**
   * Obtiene los datos de la solicitud
   * @param id
   */
  getRequestbyid(id: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetRequestById/${id}`);
  }
  /**
   * Obtiene la bandeja del validador
   * @param id
   */
  getDashboardValidation(finaldate: string,texttosearch:string,selectedfilter:string,pagenumber:string,pagination:string,rol:string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Request/GetDashboard/${finaldate}/${texttosearch}/${selectedfilter}/${pagenumber}/${pagination}/${rol}`);
  }

  /**
   * Obtiene los datos de la solicitud
   * @param Request
   */
  updateRequest(Request:any) : Observable<any> {
    return this.http.put(`${PROCEDURE_LOCAL_URI}/Request/UpdateRequest`, Request);
  }


}
