<form [formGroup]="applicantDetailsForm" action="">
  <div *ngIf="applicantType==null" class="solicitante-natural">
    <div class="row">
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="primerNombre">Primer Nombre </label>
        <input formControlName="primerNombre" type="text" id="primerNombre" placeholder="Primer nombre" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="segundoNombre">Segundo Nombre</label>
        <input formControlName="segundoNombre" type="text" id="segundoNombre" placeholder="Segundo nombre" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="primerApellido">Primer Apellido </label>
        <input formControlName="primerApellido" type="text" id="primerApellido" placeholder="Primer apellido" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="segundoApellido">Segundo Apellido</label>
        <input formControlName="segundoApellido" type="text" id="segundoApellido" placeholder="Segundo apellido" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="tipoDocumento">Tipo de Identificación</label>
        <input formControlName="tipoDocumento" type="text" id="tipoDocumento" placeholder="Tipo de documento" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="numeroDocumento">Número de Identificación </label>
        <input formControlName="numeroDocumento" type="text" id="numeroDocumento" placeholder="Número de documento" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="numeroContacto">Teléfono de contacto</label>
        <input formControlName="numeroContactoN" type="text" id="numeroContacto" placeholder="Número de contacto" class="form-control gov-co-form-control"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="correoElectronico">Correo electrónico</label>
        <input formControlName="correoElectronicoN" type="text" id="correoElectronico" placeholder="Correo electrónico" class="form-control gov-co-form-control"/>
      </div>
    </div>
  </div>
  <div *ngIf="applicantType!=null" class="solicitante-juridico">
    <div class="row">
      <div class="entradas-de-texto-govco col-sm-12 col-md-12">
        <label for="nombreEntidad">Nombre de la entidad</label>
        <input   formControlName="nombreEntidad" type="text" id="nombreEntidad" placeholder="Nombre de la entidad"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="nit">NIT</label>
        <input   formControlName="nit" type="text" id="nit" placeholder="NIT"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="codigoVerificacionNit">Código de verificación NIT</label>
        <input   formControlName="codigoVerificacionNit" type="text" id="codigoVerificacionNit" placeholder="Código de verificación NIT"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="correoElectronico">Correo electrónico</label>
        <input   formControlName="correoElectronicoJ" type="text" id="correoElectronico" placeholder="Correo electrónico"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="numeroContacto">Número de contacto</label>
        <input   formControlName="numeroContactoJ" type="text" id="numeroContacto" placeholder="Número de contacto"/>
      </div>
      <div class="entradas-de-texto-govco col-sm-12 col-md-6">
        <label for="direccion">Dirección</label>
        <input   formControlName="direccionJ" type="text" id="direccion" placeholder="dirección"/>
      </div>
    </div>
  </div>
</form>



