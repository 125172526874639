<form [formGroup]="validationSectionForm">
  <div class="contenedor">
      <div class="validation-results">
          <span class="headline-m-govco">Resultado de la validación</span>
          <div *ngIf="generateVisibleDocument; else withoutButtonGenerateDocument" class="form-group gov-co-form-group mt-3 contenedor2">
              <select formControlName="resultadoValidacion" style="width: 100%;" class="selectpicker form-control" id="usuarioAsignado">
                <option hidden value="">Seleccionar</option>
                <option *ngFor="let validate of validationResults" value="{{validate.idParametroDetalle}}">{{validate.vcNombre}}</option>
              </select>
              <div>
                  <button [disabled]="validationSectionForm.get('resultadoValidacion').value==''" (click)="generateDocument()" class="btn btn-round btn-high mx-3" style="color: white; font-size: 11px; padding: 2px 40px;">
                      GENERAR DOCUMENTO
                  </button>
              </div>
          </div>
          <ng-template #withoutButtonGenerateDocument>
              <div class="form-group gov-co-form-group mt-3">
                  <select formControlName="resultadoValidacion" style="width: 80%;" class="selectpicker form-control" id="usuarioAsignado">
                    <option hidden value="">Seleccionar</option>
                    <option *ngFor="let validate of validationResults" value="{{validate.idParametroDetalle}}">{{validate.vcNombre}}</option>
                  </select>
              </div>
          </ng-template>
      </div>
      <div style="margin-left: 40px;">
          <span class="headline-m-govco">Observaciones</span>
          <div class="form-group gov-co-form-group mt-3">
              <textarea formControlName="observaciones" class="form-control" rows="5"></textarea>
          </div>
      </div>
      <div class="d-flex align-items-center mt-5">
          <div data-toggle="modal" data-target="#mdConfirmation" class="text-center mx-5 seguimiento">
              <div style="border: 0.5px solid black; padding: 0px 30px;">
                  <i class="bi bi-eye-fill" style="font-size: 35px; color: #3366cc;"></i>
              </div>
              <span style="font-size: 10px;" class="headline-xs-govco" data-toggle="modal" data-target="#mdConfirmation">Seguimiento y auditoría</span>
          </div>
      </div>
  </div>
</form>

<div class="modal fade" id="mdConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title text-white font-weight-bold">Seguimiento y Auditoria</h5>
          </div>
          <div class="modal-body">
              <p class="text-table">Tabla de segumiento</p>
              <table class="table mt-2 table-bordered">
                  <thead>
                      <tr>
                          <th scope="col">Fecha de <br /> seguimiento</th>
                          <th scope="col">Usuario</th>
                          <th scope="col">Estado</th>
                          <th scope="col">Observacion</th>
                      </tr>
                  </thead>
                  <tbody  *ngFor="let a of auditoria">
                      <tr *ngIf="a.blSeguimientoDefinitivo">
                        <th scope="row">{{a.dtFechaObservacion}}</th>
                        <td>{{a.vcNombreUsuario}}</td>
                        <td>{{a.vcEstado}}</td>
                        <td>{{a.vcObservacion}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
