import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '@core-app/services/usuario';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const {PROCEDURE_SHARED_URI} = environment;


@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private http: HttpClient, private usuario: Usuario) {}

  getTiposCapacitacion() {
    return this.usuario.getTiposCapacitacion();
  }

  getUserInformation(idUser: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_SHARED_URI}/v2/Persona/GetInfoUserById/${idUser}`);
  }
}
