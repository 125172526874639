<nz-spin style="display: block;width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'"
  nzTip="Se estan cargando los datos, por favor espere un momento....">
  <app-advance-line [width]="30" [step]="2" [currentProgress]="100" [options]="advanceLineData"></app-advance-line>
  <h4 class="headline-l-govco mb-4">Autorización para capacitadores de manipulación de alimentos</h4>
  <form [formGroup]="requestManagementValidatorForm">
    <div class="container">
      <div class="accordion-govco my-5" id="accordion">
        <div class="card border border-0">
          <div class="card-header" id="informacion-solicitud-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#informacion-solicitud"
                aria-expanded="false" aria-controls="informacion-solicitud"><span class="headline-m-govco">Información
                  de
                  la solicitud</span> <span class="govco-icon govco-icon-dropdown-v2"></span></a>
            </h2>
          </div>
          <div id="informacion-solicitud" class="collapse" aria-labelledby="informacion-solicitud-header"
            data-parent="#accordion">
            <div class="card-body">
              <app-application-information></app-application-information>
            </div>
          </div>
        </div>
        <div class="card border border-0">
          <div class="card-header" id="datos-basicos-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#datos-basicos"
                 aria-expanded="false" aria-controls="datos-basicos">
                <span class="headline-m-govco">
                  {{ isNatural ? 'Datos básicos' : 'Datos del Representante Legal' }}
                </span>
                <span class="govco-icon govco-icon-dropdown-v2"></span>
              </a>
            </h2>
          </div>
          <div id="datos-basicos" class="collapse" aria-labelledby="datos-basicos-header" data-parent="#accordion">
            <div class="card-body">
              <app-basic-data2 [documentTypes]="documentTypes" [mostrarDireccion]="true"></app-basic-data2>
            </div>
          </div>
        </div>
        <div *ngIf="isNatural " class="card border border-0">
          <div class="card-header" id="datos-solicitud-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#datos-solicitud"
                aria-expanded="false" aria-controls="datos-solicitud"><span class="headline-m-govco">Datos de la
                  solicitud</span><span class="govco-icon govco-icon-dropdown-v2"></span></a>
            </h2>
          </div>
          <div id="datos-solicitud" class="collapse" aria-labelledby="datos-solicitud-header" data-parent="#accordion">
            <div class="card-body">
              <app-application-data [trainingDataTypes]="trainingDataTypes"
                (trainingDataTypesSelectedEvent)="addSelectedTraining($event)"></app-application-data>
            </div>
          </div>
        </div>
        <div *ngIf="isNatural && mostrar" class="card border border-0">
          <div class="card-header" id="archivos-adjuntos-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#archivos-adjuntos"
                aria-expanded="false" aria-controls="archivos-adjuntos"><span class="headline-m-govco">Archivos
                  adjuntos</span> <span class="govco-icon govco-icon-dropdown-v2"></span></a>
            </h2>
          </div>
          <div id="archivos-adjuntos" class="collapse" aria-labelledby="archivos-adjuntos-header"
            data-parent="#accordion">
            <div class="card-body">
              <app-attached-files [flag]="flag" [documentation]="documentation"
                (approvedDocumentationEvent)="addApprovedDocumentation($event)"></app-attached-files>
            </div>
          </div>
        </div>
        <div *ngIf="!isNatural && mostrar" class="card border border-0">
          <div class="card-header" id="listado-capacitadores-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#listado-capacitadores"
                aria-expanded="false" aria-controls="listado-capacitadores"><span class="headline-m-govco">
                  Listado de Capacitadores
                </span> <span class="govco-icon govco-icon-dropdown-v2"></span></a>
            </h2>
          </div>
          <div id="listado-capacitadores" class="collapse" aria-labelledby="listado-capacitadores-header"
            data-parent="#accordion">
            <div class="card-body">
              <app-list-of-trainers [flag]="flag" [canBeApprovedFlag]="canBeApprovedFlag" [trainers]="trainers" (approvedTrainerEvent)="approvedTrainer($event)"
                (viewDetailEvent)="viewDetailTrainer($event)"></app-list-of-trainers>
              <div *ngIf="!canBeApprovedFlag" class="alert alert-light" style="width: 100%; padding: 0px;">
                Recuerde que para indicar que un capacitador cumple, se debe previamente haber aprobado todos los documentos que este capacitador tiene en su registro.
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isResourceReview" class="card border border-0 mt-4">
          <app-replenishment-resource [withObservations]="true"></app-replenishment-resource>
        </div>
        <div *ngIf="!isResourceReview" class="card border border-0 mt-4">
          <app-validation-section [auditoria]="auditoria" [generateVisibleDocument]="true"
            (monitoringButtonEvent)="monitoring($event)" (generateDocumentButtonEvent)="generateDocument($event)"
            [validationResults]="validationResults"></app-validation-section>
        </div>
      </div>
      <div class="container">
        <div *ngIf="!isResourceReview" class="actions">
          <div class="d-flex justify-content-center w-100">
            <button
              (click)="temporarySave()"
              [disabled]="requestManagementValidatorForm.get('validationSectionForm').get('observaciones').value=='' && updateDocumentation "
              class="btn btn-round btn-high mx-3" style="color: white; font-size: 17px;padding:5px 40px;">
              GUARDAR
            </button>
          </div>
          <div class="d-flex justify-content-center w-100">
            <button (click)="viwDocumentAction()" class="btn btn-round btn-middle mx-3"
              style="color: white; font-size: 17px;padding:5px 40px;border: 1px solid #3366cc;"
              [disabled]="viewDocumentButton">
              VER DOCUMENTO
            </button>
          </div>
          <div class="d-flex justify-content-center w-100">
            <button (click)="send()" class="btn btn-round btn-high mx-3"
              style="color: white; font-size: 17px;padding:5px 40px;">
              ENVIAR
            </button>
          </div>
        </div>
        <div *ngIf="isResourceReview">
          <div class="d-flex justify-content-center w-100">
            <button (click)="send()" class="btn btn-round btn-high mx-3"
              style="color: white; font-size: 17px;padding:5px 40px;" #sucessAlert>
              ENVIAR
            </button>
          </div>
        </div>
      </div>
      <div class="alerts">
        <app-alerts type="sucess" title="Envío exitoso" description="Su solcitud se ha enviado con éxito."
          buttonName="ACEPTAR"></app-alerts>
      </div>
      <div class="alerts">
        <nz-modal nzWidth="500px" [nzBodyStyle]="{height:'250px'}" [(nzVisible)]="showSuccesAlert" nzCancelText={{null}}
          nzClosable="false">
          <ng-container *nzModalContent>
            <div class="w-100 h-100" style="text-align: center;">
              <span class="headline-m-govco">Envío exitoso</span>
              <div>
                <img style="width: 100px;height: 100px;"
                  src="https://static-00.iconduck.com/assets.00/alert-success-icon-512x512-lslkjbep.png">
              </div>
              <div class="form-group gov-co-form-group mt-3">
                <p style="text-align: center;">Su solicitud se ha enviado con éxito.</p>
              </div>
            </div>
          </ng-container>
          <div *nzModalFooter class="d-flex justify-content-center mb-3">
            <button (click)="closeButton()" class="btn btn-round btn-high mx-3"
              style="color: white; font-size: 17px;padding:5px 40px;">
              ACEPTAR
            </button>
          </div>
        </nz-modal>
      </div>
      <div class="documentViewer">
        <app-document-viewer [title]="documentVisualizationTitle" [isLoadingDocument]="isLoadingDocument" [isVisible]="viewDocument" [document]="urlSafeDocument"
          (closeButtonEvent)="closeDocument()"></app-document-viewer>
      </div>
      <div class="modals">
        <app-modals [observacionesSubsanacion]="observacionesSubsanacion" [isVisible]="viewRetrievalModal"
          [type]="'retrieval'" (closeButtonEvent)="closeDocument()"
          (acceptButtonEvent)="acceptRetrieval($event)"></app-modals>
        <app-modals [observacionesSubsanacion]="observacionesCancelacion" [isVisible]="viewCancellationModal"
          [type]="'cancellation'" (closeButtonEvent)="closeDocument()"
          (acceptButtonEvent)="acceptCancellation($event)"></app-modals>
        <app-modals [flag]="flag" [isLoading]="isLoadingTrainer"
          (approvedDocumentationEvent)="addApprovedDocumentationTrainer($event)" [documentation]="detailDocumentation"
          (trainingDataTypesSelectedEvent)="addModalSelectedTraining($event)"
          [trainingDataTypes]="detailTrainingDataTypes" [documentTypes]="documentTypes22"
          [isVisible]="viewTrainerDetailModal" [type]="'trainerInformation'" (closeButtonEvent)="closeDocument()"
          (acceptButtonTrainerEvent)="acceptSelectedTrainer($event)">
        </app-modals>
      </div>
    </div>
  </form>
</nz-spin>
