<div style="margin-right: 15px;" class="block block--gov-accessibility position-inherit" [ngStyle]="{ backgroundColor: colorBG  === 1 ? '#004884' : '#004884'}">
  <div class="block-options navbar-expanded">
    <a class="contrast-ref" (click)="moveContrast()">
      <span class="govco-icon govco-icon-contrast-n"></span>
      <label> Contraste </label>
    </a>
    <a class="min-fontsize" (click)="down()">
      <span class="govco-icon govco-icon-less-size-n"></span>
      <label class="align-middle"> Reducir letra </label>
    </a>
    <a class="max-fontsize" (click)="up()">
      <span class="govco-icon govco-icon-more-size-n"></span>
      <label class="align-middle"> Aumentar letra </label>
    </a>
  </div>
</div>
