<div class="row">
  <div class="col-12">
    <div class="info_section">
      <p>Trámite: Autorización para capacitadores de manipulación de alimentos</p>
      <p>Autrozación para desarrollar programas de educación sanitaria, que permite capcitar al personal que manipula, procesa y expande alimentos y productos cárnicos
        comestibles, alimentos comercializados en vía pública, leche cruda para consumo humano directo comercializada de manera ambulante, y objetos, envases,
        materiales y equipamientos en contacto con alimentos.
      </p>
    </div>
  </div>
</div>
