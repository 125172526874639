import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentsService } from 'src/app/core/services/documents.service';
import Swal from "sweetalert2";
import {environment} from "../../../../../environments/environment";
const { PROCEDURE_FILE_MANAGER_URI } = environment;

@Component({
  selector: 'app-attached-files',
  templateUrl: './attached-files.component.html',
  styleUrls: ['./attached-files.component.scss']
})
export class AttachedFilesComponent implements OnInit{

  urlSafe: SafeResourceUrl;

  mostrarIframe: boolean = true;

  /**
   * Listado e documentación a mostrar
   */

  @Input() documentation: any;

  @Input() flag: any = false;

  @Input() viewfinder: any = false;

  @Output() approvedDocumentationEvent = new EventEmitter<any>;

  documentsdisabled:boolean=false;

  subsanacion:boolean=false;

  constructor(private documentService:DocumentsService, public sanitizer: DomSanitizer){
  }

  ngOnInit(): void {

    console.log(localStorage.getItem('tipo'))

    if(localStorage.getItem('tipo')!=null)
    {
      if(localStorage.getItem('tipo')=="subsanacion")
      {
        this.subsanacion=true;
      }
      this.documentsdisabled=true;
    }
    else {
      console.log(localStorage.getItem('estado'));
      if(localStorage.getItem('estado')=="cancelacion")
      {

        this.documentsdisabled=true;
      }
    }


  }
  public verDocumento( path: string, fileNumber: string){
    console.log(fileNumber);
    const contenedor = "manipalimentos";
    const oid = fileNumber;

    this.mostrarIframe=true;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${oid}/${path}`);

    /*
    this.documentService.GetBlob(contenedor, oid, path).subscribe(resp => {
      var reader = new FileReader();
      reader.readAsDataURL(resp.body);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        const documentData = base64data;
        //this.urlSafe = `${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${oid}/${path}`;

        this.mostrarIframe=true;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${oid}/${path}`);
      };
    })
    */
  }

  approvedDocumentation = (e:any, v:string,f:any) => {

    if(localStorage.getItem('tipo')=='subsanacion')
    {
      if(v=="delete")
      { this.urlSafe = null;
        this.mostrarIframe=false;
        this.approvedDocumentationEvent.emit({
          document:e,
          file:f,
          delete:'si'
        });
      }
      else {

        this.approvedDocumentationEvent.emit({
          document:e,
          file:f,
          delete:'no'
        });
      }


    }
    else {
      this.approvedDocumentationEvent.emit({
        record: e,
        value: v
      });
    }



  }
  seeDocument = (e:any) => {


    console.log('entro?')
    if(e.files.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Sin archivo',
        text: 'No se ha cargado un archivo',
      })
    }else{
      if (e.files[0].type != 'application/pdf') {

        Swal.fire({
          icon: 'error',
          title: 'Tipo de archivo incorrecto',
          text: 'El archivo cargado no es un pdf',
        })

      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.files[0]);

        reader.onloadend = () => {
          const base64data = reader.result as string;
          const documentData = base64data;
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(documentData);
          this.mostrarIframe=true;
        };
      }
    }
  }


}
