<section>
  <div class="row">
    <div class="col-lg-12">
      <button class="btn btn-outline-primary" (click)="back()">Regresar</button>
    </div>
  </div>
</section>
<div class="row">
  <div class="col-lg-12 col-sm-12 col-lg-12 col-md-12 col-xs-12 mt-5">
    <table class="table border">
      <thead>
        <tr>
          <th scope="col">Capacitador</th>
          <th scope="col">Dirección</th>
          <th scope="col">Número de asistentes</th>
          <th scope="col">Acciones permitidas</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listAll.data">
          <td>{{item.vcNombreCapacitador}}</td>
          <td>{{item.vcDireccion}}</td>
          <td>{{item.intNumeroAsistentes}}</td>
          <td class="d-flex">
            <a class="link-edit" (click)="edit(item.idCapacitacion)">Editar</a>
            <i class="fa-solid fa-circle-minus icon-delete" (click)="update(item.idCapacitacion)"></i>
          </td>
        </tr>
        <tr *ngIf="!listAll.data || listAll.data.length === 0">
          <td colspan="4" class="text-center">No hay capacitaciones. Por favor, agregue una.</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row mt-4">
  <div class="col-lg-8">
    <h2>Información de capacitaciones a realizar</h2>
    <a (click)="toggleDiv()"><i class="fa-solid fa-square-plus add_icon"></i></a>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-2 col-xl-3 float-right">
    <div class="custom-alert">
      <a href="https://aulavirtual.saludcapital.gov.co/sicap/" style="text-decoration: none !important;"
        target="_blank">
        <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
          Te explicamos con tutoriales
        </div>
      </a>
      <a type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
        aria-controls="collapseExample">
        <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
          ¿Tienes dudas?
        </div>
      </a>
      <div class="collapse" id="collapseExample">
        <div class="card card-body" id="cardi_info_contact">
          <p id="colorphone" style="margin-left: 18px;">Teléfono:(571) 3649090</p>
          <p id="colorphone" style="margin-left: 18px; margin-top: -10px;">contactenos&commat;aludcapital.gov.co</p>
        </div>
      </div>
    </div>
  </div>
</div>

<section *ngIf="mostrarDiv">
  <form [formGroup]="recordCapacitation" (submit)="sendPost()">
    <h2></h2>
    <div class="row mt-4">
      <div class="col-lg-3">
        <label>Capacitador*</label>
        <select class="form-control" formControlName="trainer"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('trainer').valid?'1px solid red':''}">
          <option *ngFor="let item of listName.data" [value]="item.idCapacitadorSolicitud">
            {{item.vcNombre}}
          </option>
        </select>
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('trainer') }}
        </span>
      </div>
      <div class="col-lg-3">
        <label>Público objetivo*</label>
        <input type="text" class="form-control" placeholder="Escriba..." autocomplete="off" formControlName="assistants"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('assistants').valid?'1px solid red':''}" />
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('assistants') }}
        </span>
      </div>
      <div class="col-lg-3">
        <label>Número de asistentes*</label>
        <select class="form-control" formControlName="assistantsnumber"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('assistantsnumber').valid?'1px solid red':''}">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
        </select>
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('assistantsnumber') }}
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <label>Tema de capacitación a realizar*</label>
        <textarea name="" id="" cols="30" rows="5" class="form-control" formControlName="theme"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('theme').valid?'1px solid red':''}"></textarea>
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('theme') }}
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <label>Metodología de la capacitación*</label>
        <textarea name="" id="" cols="30" rows="5" class="form-control" formControlName="methodology"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('methodology').valid?'1px solid red':''}"></textarea>
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('methodology') }}
        </span>
      </div>
    </div>
    <h2 class="mt-4 time">Horarios capacitación</h2>
    <label>*Campos obligatorios</label>
    <form [formGroup]="recordDate" (submit)="saveDate()">
      <div class="row mt-5">
        <div class="col-lg-3">
          <label>Fecha de capacitación*</label>
          <input [ngClass]="{'error': isTouchedField(recordDate, 'date')}" type="date" class="form-control"
            formControlName="date" min="{{this.actualday}}" />

          <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
            {{ getErrorMessageDate('date') }}
          </span>
        </div>
        <div class="col-lg-2">
          <label>Hora de inicio*</label>
          <input type="time" class="form-control" formControlName="initial" />
        </div>
        <div class="col-lg-2">
          <label>Hora finalización*</label>
          <input type="time" class="form-control" formControlName="final" />
        </div>
        <div class="col-lg-4 justify-content-center">
          <button class="btn btn-primary" style="margin-top: 33px;">Registrar</button>
        </div>
      </div>
    </form>

    <div class="row mt-4">
      <div class="col-lg-9">
        <table class="table mt-4 table-bordered">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Fecha</th>
              <th scope="col">Hora Inicio</th>
              <th scope="col">Hora Fin</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let registro of registros; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ registro.fecha }}</td>
              <td>{{ registro.horaInicio }}</td>
              <td>{{ registro.horaFin }}</td>
              <td><button class="btn btn-danger ml-3" (click)="this.deleteDate($event,i)">Eliminar</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h2 class="mt-4 time">Lugar donde se realizara la capacitación</h2>
    <label>*Campos obligatorios</label>
    <div class="row mt-3">
      <div class="col-lg-2">
        <label>Via principal *</label>
        <select class="form-control" #selectOption (change)="updateAddress()">
          <option value="CL - CALLE">CL</option>
          <option value="DG - DIAGONAL">DG</option>
          <option value="AC - AVENIDA CALLE">AC</option>
          <option value="KR - CARRERA">KR</option>
          <option value="TV - TRANSVERSAL">TV</option>
          <option value="AK - AVENIDA CARRERA">AK</option>
          <option value="AV - AVENIDA">AV</option>
        </select>
      </div>
      <div class="col-lg-1">
        <label>Número*</label>
        <input type="text" class="form-control" formControlName="numero" (input)="updateAddress()" #numberInput
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('numero').valid?'1px solid red':''}" />
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('numero') }}
        </span>
      </div>
      <div class="col-lg-2">
        <label>Letra</label>
        <select class="form-control" #selectLetter (change)="updateAddress()">
          <option value=""></option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="J">J</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label>Bis</label>
        <select name="" id="" class="form-control" #selectother (change)="updateAddress()">
          <option value=""></option>
          <option value="Bis">Bis</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label>Cardinalidad</label>
        <select name="" id="" class="form-control" #selectcoordi (change)="updateAddress()">
          <option value=""></option>
          <option value="Norte">Norte</option>
          <option value="Sur">Sur</option>
          <option value="Este">Este</option>
        </select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-2">
        <label>Número*</label>
        <input type="text" class="form-control" formControlName="complemento" (input)="updateAddress()"
          #numberInputComplement
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('complemento').valid?'1px solid red':''}" />
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('complemento') }}
        </span>
      </div>
      <div class="col-lg-1">
        <label>Letra</label>
        <select class="form-control" #selectcoordilettertwo (change)="updateAddress()">
          <option value=""></option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="J">J</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label>Placa*</label>
        <input type="text" class="form-control" formControlName="placa" (input)="updateAddress()" #platenumber
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('placa').valid?'1px solid red':''}" />
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('placa') }}
        </span>
      </div>
      <div class="col-lg-2">
        <label>Cardinalidad</label>
        <select name="" id="" class="form-control" #selectcard (change)="updateAddress()">
          <option value=""></option>
          <option value="Norte">Norte</option>
          <option value="Sur">Sur</option>
          <option value="Este">Este</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <label>Dirección</label>
        <input type="text" class="form-control" #addressInput formControlName="address" readonly />
      </div>
      <div class="col-lg-9 mt-3">
        <label>Información adicional</label>
        <input type="text" class="form-control" formControlName="aditional" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-5">
        <label>Departamentos*</label>
        <select id="departamentoslist" class="form-control" formControlName="depto" (change)="obtenerMunicipios()"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('depto').valid?'1px solid red':''}">
          <option [disabled]="true" selected value="">-Seleccione-</option>
          <option *ngFor="let department of Departamentos" [ngValue]="department.idDepartamento">
            {{department.descripcion}}
          </option>
        </select>
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('depto') }}
        </span>
      </div>
      <div class="col-lg-4 col-sm-12 col-md-4">
        <label>Ciudad*</label>
        <select id="municipioslist" class="form-control" formControlName="city"
          [ngStyle]="{'border':flag && !this.recordCapacitation.get('city').valid?'1px solid red':''}">
          <option [disabled]="true" selected value="">-Seleccione-</option>
          <option *ngFor="let municipio of Municipios" [ngValue]="municipio.idMunicipio">
            {{municipio.descripcion}}
          </option>
        </select>
        <span style="color: red;" class="error-texto-govco alert-entradas-de-texto-govco" id="professionalnumber-note"
          role="alert" aria-live="assertive">
          {{ getErrorMessage('city') }}
        </span>
      </div>
    </div>
    <div class="row mt-3 justify-content-center text-center mx-auto">
      <div class="col-lg-12">
        <button class="btn btn-primary" style="margin-top: 33px;">Guardar</button>
      </div>
    </div>
  </form>
</section>
