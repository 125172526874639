import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RequestRecaptchaDto, ResponseRecaptchaDto} from "../models/recaptcha.model";

@Injectable({
  providedIn: 'root'
})
export class GoogleRecaptchaService {

  constructor(private http: HttpClient) { }

  /**
   * Valida el token dado por el recaptcha
   * @param tokenUser Token a validar
   */
  public verifyRecaptcha(tokenUser: string): Observable<ResponseRecaptchaDto> {
    let dto: RequestRecaptchaDto = {
      secret: "",
      response: tokenUser,
      remoteip: ""
    };

    return this.http.post<ResponseRecaptchaDto>(`${ environment.PROCEDUREGENERAL }/GoogleRecaptcha/verifyRecaptcha`, dto);
  }
}
