<form role="form" [formGroup]="geographicDataForm">

  <div class="row">

    <div class="col-sm-5 col-md-5">
      <div class="entradas-de-texto-govco">
        <label for="Nacionalidad">Nacionalidad<span class="required">(*)</span></label>
        <select class="form-select" id="Nacionalidad" aria-label="Default select example"
                formControlName="nacionalidad"
                [ngClass]="{'error': isTouchedField(geographicDataForm, 'nacionalidad')}"
                (change)="changepais(this.geographicDataForm.controls['nacionalidad'].value)">

          <option value="" selected disabled>Seleccione</option>
          <option *ngFor="let nacionalidad of countries" [value]="nacionalidad.idPais">{{nacionalidad.nombre}}</option>
        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" id="nacionalidad-note" role="alert"
          aria-live="assertive">
            {{ getErrorMessage('nacionalidad') }}
        </span>
      </div>
    </div>

    <div class="col-sm-5 col-md-5" *ngIf="foreign==true">
      <div class="entradas-de-texto-govco">
        <label for="Ciudad de Nacimiento">Ciudad de Nacimiento<span class="required">(*)</span></label>
        <input type="text" id="ciudadnacimientootro" placeholder="Ciudad de Nacimiento"
               formControlName="ciudadnacimientootro"
               [ngClass]="{'error': isTouchedField(geographicDataForm, 'ciudadnacimientootro')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" id="N. Documento Identidad-note" role="alert"
          aria-live="assertive">
            {{ getErrorMessage('ciudadnacimientootro') }}
        </span>
      </div>
    </div>


    <div class="col-sm-5 col-md-5" *ngIf="foreign==false">
      <div class="entradas-de-texto-govco">
        <label for="Departamento de Nacimiento">Departamento de Nacimiento<span
          class="required">(*)</span></label>
        <select class="form-select" id="Departamento de Nacimiento" aria-label="Default select example"
                formControlName="departamentoNacimiento"
                [ngClass]="{'error': isTouchedField(geographicDataForm, 'departamentoNacimiento')}"
                (change)="getMunicipiosNacimiento(this.geographicDataForm.controls['departamentoNacimiento'].value)"
        >
          <option value="" selected disabled>Seleccione</option>
          <option *ngFor="let departamento of departaments"
                  [value]="departamento.idDepartamento">{{departamento.descripcion}}</option>

        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" id="departamentoNacimiento-note"
          role="alert"
          aria-live="assertive">
                      {{ getErrorMessage('departamentoNacimiento') }}
                 </span>
      </div>

    </div>


    <div class="col-sm-5 col-md-5" *ngIf="foreign==false">
      <div class="entradas-de-texto-govco">
        <label for="Ciudad de Nacimiento">Ciudad de Nacimiento<span class="required">(*)</span></label>
        <select class="form-select" id="Ciudad de Nacimiento" aria-label="Default select example"
                formControlName="ciudadNacimiento"
                [ngClass]="{'error': isTouchedField(geographicDataForm, 'ciudadNacimiento')}">
          <option value="" disabled selected>Seleccione</option>
          <option *ngFor="let municipio of municipalitiesNacimiento"
                  [value]="municipio.idMunicipio">{{municipio.descripcion}}</option>
        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" id="ciudadNacimiento-note" role="alert"
          aria-live="assertive">
                     {{ getErrorMessage('ciudadNacimiento') }}
                   </span>
      </div>
    </div>


    <div class="col-sm-5 col-md-5">
      <div class="entradas-de-texto-govco">
        <label for="Departamento de residencia">Departamento de residencia<span
          class="required">(*)</span></label>
        <select class="form-select" id="Departamento de residencia" aria-label="Default select example"
                formControlName="departamentoResidencia"
                [ngClass]="{'error': isTouchedField(geographicDataForm, 'departamentoResidencia')}"
                (change)="getMunicipiosResidencia(this.geographicDataForm.controls['departamentoResidencia'].value)"
        >
          <option value="" selected disabled>Seleccione</option>
          <option *ngFor="let departamento of departaments"
                  [value]="departamento.idDepartamento">{{departamento.descripcion}}</option>
        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" id="departamentoResidencia-note"
          role="alert"
          aria-live="assertive">
                      {{ getErrorMessage('departamentoResidencia') }}
                 </span>
      </div>
    </div>


    <div class="col-sm-5 col-md-5">
      <div class="entradas-de-texto-govco">
        <label for="Ciudad de residencia">Ciudad de residencia<span class="required">(*)</span></label>
        <select class="form-select" id="Ciudad de residencia" aria-label="Default select example"
                formControlName="ciudadResidencia"
                [ngClass]="{'error': isTouchedField(geographicDataForm, 'ciudadResidencia')}">
          <option value="" disabled selected>Seleccione</option>
          <option *ngFor="let municipio of municipalitiesResidencia"
                  [value]="municipio.idMunicipio">{{municipio.descripcion}}</option>
        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" id="ciudadResidencia-note" role="alert"
          aria-live="assertive">
                     {{ getErrorMessage('ciudadResidencia') }}
                   </span>
      </div>
    </div>

  </div>

</form>
