<div class="d-flex flex-column general-layout">

  <div style="margin: 2% 0 1% 0;">
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="content-layout" id="content-layout-id">
    <main class="scroll" id="interno">
      <div class="information">
        <router-outlet></router-outlet>
      </div>
      <aside>
        <app-accesibility></app-accesibility>
      </aside>
      <div class="scroll-to-top show-scrollTop">
        <button (click)="letGoTop()" class="demo-seleccionado">
          <span class="govco-icon govco-icon-shortu-arrow-n btn-svg-up-hover"></span>
          <span class="label-button-star-up"> Volver a arriba </span>
        </button>
      </div>
      <div class="linea">
        <app-footer></app-footer>
      </div>
    </main>
  </div>
</div>
