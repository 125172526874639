import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RequestComponent } from './request.component';
import { ROUTES } from 'src/app/core/enums';
import { CitizenFormComponent } from './pages/citizen/citizen-form/citizen-form.component';
import { UserRequestManagementComponent } from './pages/user-request-management/user-request-management.component';
import { OptionsComponent } from './components/options/options.component';
import { SuccessRequestComponent } from './components/success-request/success-request.component';
import { RequestSearchComponent } from './components/request-search/request-search.component';
import { TrainingRecordComponent } from './components/training-record/training-record.component';
import { ReplenishmentResourceComponent } from '../dashboard/components/replenishment-resource/replenishment-resource.component';
import { RecordCapacitationComponent } from './components/record-capacitation/record-capacitation.component';
import { ReportsComponent } from './components/reports/reports.component';
import { EditCapacitationComponent } from './components/edit-capacitation/edit-capacitation.component';
import { AuthGuardService } from '@core-app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: RequestComponent,
    children: [
      {
        path: ROUTES.Request,
        component: RequestComponent,
      },
      {
        path: ROUTES.REQUEST_CITIZEN,
        component: CitizenFormComponent,
        canActivate: [() => inject(AuthGuardService).canActiveCitizen()],
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.Request + "/" + ROUTES.Menu,
            },
            {
              label: 'Autorización para capacitadores de manipulación de alimentos',
            },{
              label:'Crear Solicitud'
            }
          ]
        }
      },
      {
        path: ROUTES.User_Request,
        component: UserRequestManagementComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.Request + "/" + ROUTES.Menu
            },
            {
              label: 'Autorización para capacitadores de manipulación de alimentos',
            },{
              label:'Crear Solicitud'
            }
          ]
        }
      },{
        path: ROUTES.Menu,
        component:OptionsComponent,
        canActivate: [() => inject(AuthGuardService).canActiveCitizen()],
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.Request + "/" + ROUTES.Menu
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',

            }
          ]
        }
      },{
        path: ROUTES.Reports,
        component: ReportsComponent
      },{
        path: 'editar-capacitacion/:id',
        component: EditCapacitationComponent
      },{
        path: ROUTES.Success+"/:fileNumber",
        component:SuccessRequestComponent,
      },{
        path: ROUTES.SearchRequest,
        component:RequestSearchComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.Request + "/" + ROUTES.Menu
            },
            {
              label: 'Consultar solicitud',
              url: ROUTES.Request + "/" + ROUTES.SearchRequest

            },{
              label: 'Procesan mi solicitud',
              url: ROUTES.Request + "/" + ROUTES.SearchRequest
            }
          ]
        }
      },{
        path: ROUTES.RECORDTRAINING,
        component:TrainingRecordComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.RECORDTRAINING,
            },
            {
              label: 'Consultar solicitud',
            },{
              label:'Registro de capacitaciones'
            }
          ]
        }
      },{
        path: ROUTES.REQUEST_CAPACITATION+"/:idSolicitud",
        component:RecordCapacitationComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.RECORDTRAINING,
            },
            {
              label: 'Consultar solicitud',
            },{
              label:'Registro de capacitaciones'
            }
          ]
        }
      }
    ]
  }
]


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class RequestRoutingModule { }
