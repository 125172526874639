import {Component, OnInit} from '@angular/core';
import {AppBaseComponent} from "../core/utils";
import {Router} from "@angular/router";
import {ROUTES} from "../core/enums";
import Swal from 'sweetalert2';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { PopUpService } from '@core-app/services';
import { AuthService } from '@core-app/services/auth.service';
import { SecurityPolicyService } from '@core-app/services/security-policy.service';
import { securityPolicyDto } from '@core-app/models/securityPolicyDto';
import { SharedService } from '@core-app/services/shared.service';

/**
 * Componente que agrupa todos los feature
 */
@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent extends AppBaseComponent implements OnInit {

  public ocultarRegistroValidacion = false;

  //public isVisible:boolean = false;

  constructor(private sharedService:SharedService,private securityPolicy: SecurityPolicyService, private router: Router, public authService: AuthService, private popUpService: PopUpService,private msalService:MsalService)
  {
    super();
    /*
    console.log('clave: '+localStorage.getItem('currentUser'))
    if (localStorage.getItem('currentUser')!=null) {
      this.securityPolicy.getSecurityPolicy(JSON.parse(localStorage.getItem('currentUser')).userId).subscribe((result) => {
        console.log('----resultado----');
        console.log(result.data);
        if (result.data==null) {
          this.isVisible = true;
        }
      })
    }
    */
  }


  ngOnInit(): void {
    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Login}`);
  }

  letGoTop = () => {
    let mainInter = document.getElementById('interno');
    mainInter?.scroll(0, 0);
    let main = document.getElementById('content-layout-id');
    main?.scroll(0, 0);
  }
}
