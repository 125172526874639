import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent {

  @Input() isVisible:boolean = false;

  @Input() title:string = "";

  @Input() document: SafeResourceUrl;

  @Input() isLoadingDocument: boolean;

  @Output() closeButtonEvent = new EventEmitter<any>();

  closeButton(): void {
    this.closeButtonEvent.emit('cerrar modal');
  }
}
