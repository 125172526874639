import { Component, Output, EventEmitter } from '@angular/core';
import { Properties } from "../../../../core/services/properties";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-request-info',
  templateUrl: './request-info.component.html',
  styleUrls: ['./request-info.component.scss']
})
export class RequestInfoComponent {
  @Output() formularioVacio = new EventEmitter<boolean>();


  requestInfoForm: FormGroup;
  elementosSelect: { idTipoSolicitud: string, descripcion: string }[] = [];
  elementosSelectCancel: { idMotivoCancelacion: string, descripcion: string }[] = [];


  isNaturalPerson: boolean = true;


  isPersonaNatural(): boolean {
    return this.isNaturalPerson;
  }

  constructor(public fb: FormBuilder, public properties: Properties) {
    this.requestInfoForm = this.fb.group({
      numeroRadicado: [''],
      tipoTramite: [''],
      tipoSolicitud: [''],
      motivoCancelacion: [''],
      numeroResolucionActual: [''],
      descripcion: ['']
    });

    this.requestInfoForm.valueChanges.subscribe(() => {
      this.verificarFormularioVacio();
    });
  }

  ngOnInit() {
    this.getRequetAlltype();
    this.geRequestCancelAlltype();
  }

  verificarFormularioVacio(): void {
    const formularioEstaVacio = this.requestInfoForm.invalid;
    this.formularioVacio.emit(formularioEstaVacio);
  }

  getRequetAlltype() {
    this.properties.getRequest().subscribe((data: any) => {
      console.log(data);
      this.elementosSelect = data.data.map((item: any) => ({
        idTipoSolicitud: item.idTipoSolicitud,
        descripcion: item.descripcion
      }));
    });
  }

  geRequestCancelAlltype() {
    this.properties.getRequestCancelType().subscribe((data: any) => {
      console.log(data);
      this.elementosSelectCancel = data.data.map((item: any) => ({
        idMotivoCancelacion: item.idMotivoCancelacion,
        descripcion: item.descripcion
      }));
    });
  }

  notificarEstadoFormulario(): void {
    const formularioEstaVacio = this.requestInfoForm.invalid;
    this.formularioVacio.emit(formularioEstaVacio);
  }


}
