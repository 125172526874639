import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagingConfig } from 'src/app/core/models/pagingConfig.model';

@Component({
  selector: 'app-procedure-dashboard',
  templateUrl: './procedure-dashboard.component.html',
  styleUrls: ['./procedure-dashboard.component.scss']
})
export class ProcedureDashboardComponent {

  @Output() selectEvent = new EventEmitter<any>();

  @Output() linkEvent = new EventEmitter<any>();

  @Output() multipleLinkEvent = new EventEmitter<any>();

  @Output() documentEvent = new EventEmitter<any>();

  @Input() data: any = [];

  @Input() structure: any = [];


  private currentPage:number  = 1;
  private itemsPerPage: number = 10;
  private totalItems: number = 0;

  public tableSize: number[] = [5, 10, 15, 20, 25, 30];

  pagingConfig: PagingConfig = {} as PagingConfig;

  constructor(){

    this.pagingConfig = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      totalItems: this.totalItems
    }
  }

  onTableDataChange(event:any){
    this.pagingConfig.currentPage  = event;
    this.pagingConfig.totalItems = this.data.length;
  }
  onTableSizeChange(event:any): void {
    this.pagingConfig.itemsPerPage = event.target.value;
    this.pagingConfig.currentPage = 1;
    this.pagingConfig.totalItems = this.data.length;
  }


   select = (e:any) => {
    console.log(e);
    this.selectEvent.emit(e);
   }

   link = (e:any) => {
    console.log(e);
    this.linkEvent.emit(e);
   }

   multipleLink = (e:any) => {
    console.log(e);
    this.multipleLinkEvent.emit(e);
   }

   document = (e:any) => {
    console.log(e);
    this.documentEvent.emit(e);
   }

   getColor = (rules: any, diasRestantes:number):string => {

    let color = '';

    rules.forEach((element: { lowerLimit: number; upperLimit: number; color: string; }) => {

      if(diasRestantes>=element.lowerLimit && diasRestantes<=element.upperLimit){
        color = element.color;
      }

    });

    if(color==''){
      let colorTimeExceed = rules.filter((r: { lowerLimit: number; }) => r.lowerLimit == 0)[0].color;
      color = colorTimeExceed;
    }

    return color;
   }

   showLink = (state:string, states: string[]):boolean => {

    if(states.find((e) => e==state) == undefined){
      return false
    }else{
      return true
    }

   }
   shouldShowAction(record: any, structure: any, action: any): boolean {
    const allowedStates = action.states;

    if (allowedStates.includes(record[structure.state])) {
        return true;  // La acción está permitida según el estado
    }

    // Verifica condiciones adicionales para acciones específicas
    switch (action.name) {
        case 'Ver detalle':
            const allowedDetailStates = ['Estudio', 'En Revisión', 'En Verificación'];
            return allowedDetailStates.includes(record[structure.state]);

        case 'Subsanar':
            return record[structure.state] === 'En Subsanación';

        case 'Generar recurso':
            return ['Cancelado', 'Anulado'].includes(record[structure.state]);

        case 'No existe acción':
            return ['Devuelta por Subdirector', 'Para firma'].includes(record[structure.state]);

        default:
            return false; // Todas las demás acciones no cumplen condiciones adicionales
    }
}






}
