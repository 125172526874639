import { Component } from '@angular/core';

@Component({
  selector: 'app-documentation-associated-with-the-review',
  templateUrl: './documentation-associated-with-the-review.component.html',
  styleUrls: ['./documentation-associated-with-the-review.component.scss']
})
export class DocumentationAssociatedWithTheReviewComponent {

}
