import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalPersonComponent } from './pages/legal-person/legal-person.component';
import { NaturalPersonComponent } from './pages/natural-person/natural-person.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ScreenRegisterComponent } from './pages/screen-register/screen-register.component';
import { BasicDataCitizenComponent } from './components/basic-data-citizen/basic-data-citizen.component';
import {GeographicDataComponent} from "./components/geographic-data/geographic-data.component";
import { DocumentsValidationComponent } from './pages/documents-validation/documents-validation.component';
import { RequestDataComponent } from './components/request-data/request-data.component';
import { RequestCancelComponent } from './components/request-cancel/request-cancel.component';
import { RequestInfoComponent } from './components/request-info/request-info.component';
import { RequestPersonComponent } from './components/request-person/request-person.component';
import { DocumentRequestComponent } from './components/document-request/document-request.component';
import { FirstNaturalComponent } from './components/first-natural/first-natural.component';
import { RenewalComponent } from './components/renewal/renewal.component';
import { ModifyComponent } from './components/modify/modify.component';
import { TypeTrainingComponent } from './components/type-training/type-training.component';



@NgModule({
    declarations: [
        LegalPersonComponent,
        NaturalPersonComponent,
        ScreenRegisterComponent,
        BasicDataCitizenComponent,
        GeographicDataComponent,
        DocumentsValidationComponent,
        RequestDataComponent,
        RequestCancelComponent,
        RequestInfoComponent,
        RequestPersonComponent,
        DocumentRequestComponent,
        FirstNaturalComponent,
        RenewalComponent,
        ModifyComponent,
        TypeTrainingComponent


    ],
  exports: [
    BasicDataCitizenComponent,
    GeographicDataComponent,
    GeographicDataComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class RegisterModule { }
