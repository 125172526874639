import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvanceLineComponent } from './components/advance-line/advance-line.component';
import { ServiceAreaComponent } from './components/service-area/service-area.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ModalsComponent } from './components/modals/modals.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';



@NgModule({
    declarations: [
        AdvanceLineComponent,
        ServiceAreaComponent,
        AlertsComponent,
        DocumentViewerComponent,
        ModalsComponent,
        LoginComponent

    ],
    exports: [
        AdvanceLineComponent,
        ServiceAreaComponent,
        AlertsComponent,
        DocumentViewerComponent,
        ModalsComponent,
        LoginComponent
    ],
    imports: [
        CommonModule,
        NzModalModule,
        NzSpinModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class SharedModule { }
