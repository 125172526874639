import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AppBaseComponent } from 'src/app/core/utils';

@Component({
  selector: 'app-application-data',
  templateUrl: './application-data.component.html',
  styleUrls: ['./application-data.component.scss']
})
export class ApplicationDataComponent extends AppBaseComponent implements OnInit {

  constructor(private controlContainer: ControlContainer) {
    super();
  }

  public applicationDataForm: any;

  @Input() trainingDataTypes: any;

  @Output() trainingDataTypesSelectedEvent = new EventEmitter<any>();

  trainingDataTypesSelected = (e:any) => {
    this.trainingDataTypesSelectedEvent.emit(e);
  }

  ngOnInit(): void {
    this.applicationDataForm = this.controlContainer.control;
    this.applicationDataForm = this.applicationDataForm.controls['applicationDataForm'];
  }
}
