<section class="mt-4">
  <app-request-data></app-request-data>
</section>
<section class="mt-4">
  <app-request-info></app-request-info>
</section>
<section class="mt-4">
  <app-request-person></app-request-person>
</section>
<section class="mt-4">
<app-document-request></app-document-request>
</section>
<div class="row mt-4">
  <div class="col">
    <button class="btn btn-primary button_option">Cancelar</button>
    <button class="btn btn-primary button_option">Guardar</button>
    <button class="btn btn-primary button_option">Enviar</button>
  </div>
</div>
