import { Component } from '@angular/core';

@Component({
  selector: 'app-type-training',
  templateUrl: './type-training.component.html',
  styleUrls: ['./type-training.component.scss']
})
export class TypeTrainingComponent {

}
