<p class="text_header mt-5">Información del solicitante</p>

<div class="row">
  <div class="col">
    <label>Primer Nombre</label>
    <input type="text" class="form-control control" placeholder="Primer Nombre" aria-label="First name">
  </div>
  <div class="col">
    <label>Segundo Nombre</label>
    <input type="text" class="form-control control" placeholder="Segundo Nombre" aria-label="Last name">
  </div>
</div>

<div class="row mt-3">
  <div class="col">
    <label>Primer Apellido</label>
    <input type="text" class="form-control control" placeholder="Primer Apellido" aria-label="First name">
  </div>
  <div class="col">
    <label>Segundo Apellido</label>
    <input type="text" class="form-control control" placeholder="Segundo Apellido" aria-label="Last name">
  </div>
</div>

<div class="row mt-3">
  <div class="col">
    <label>Tipo de documento</label>
    <input type="text" class="form-control control" placeholder="Tipo de documento" aria-label="First name">
  </div>
  <div class="col">
    <label>Número de documento</label>
    <input type="text" class="form-control control" placeholder="Número de documento" aria-label="Last name">
  </div>
</div>

<div class="row mt-3">
  <div class="col">
    <label>Correo electrónico</label>
    <input type="text" class="form-control control" placeholder="Tipo de documento" aria-label="First name">
  </div>
  <div class="col">
    <label>Teléfono de contacto</label>
    <input type="text" class="form-control control" placeholder="Número de documento" aria-label="Last name">
  </div>
</div>

<section *ngIf="isPersonaNatural()">
  <div class="row mt-3" >
    <div class="col">
      <label>Dirección</label>
      <input type="text" class="form-control control" placeholder="Dirección" aria-label="Address">
    </div>
    <div class="col">
      <label>Titulo profesional</label>
      <input type="text" class="form-control control" placeholder="Dirección" aria-label="Address">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col"></div>
    <label>Número de tarjeta profesional</label>
    <input type="text" class="form-control control" placeholder="Dirección" aria-label="Address" style="width: 50%;">
  </div>
</section>
