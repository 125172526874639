import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestManagementValidatorComponent } from './pages/validator/request-management-validator/request-management-validator.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ApplicationInformationComponent } from './components/application-information/application-information.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BasicDataComponent2 } from './components/basic-data/basic-data.component';
import { ApplicationDataComponent } from './components/application-data/application-data.component';
import { AttachedFilesComponent } from './components/attached-files/attached-files.component';
import { ValidationSectionComponent } from './components/validation-section/validation-section.component';
import { RequestManagementCoordinatorComponent } from './pages/coordinator/request-management-coordinator/request-management-coordinator.component';
import { RequestManagementAssistantManagerComponent } from './pages/assistant-manager/request-management-assistant-manager/request-management-assistant-manager.component';
import { ListOfTrainersComponent } from './components/list-of-trainers/list-of-trainers.component';
import { AssistantManagerTabComponent } from './pages/assistant-manager/assistant-manager-tab/assistant-manager-tab.component';
import { ValidatorTabComponent } from './pages/validator/validator-tab/validator-tab.component';
import { ProcedureDashboardComponent } from './components/procedure-dashboard/procedure-dashboard.component';
import {NgxPaginationModule} from "ngx-pagination";
import { CoordinatorTabComponent } from './pages/coordinator/coordinator-tab/coordinator-tab.component';
import { ReplenishmentResourceComponent } from './components/replenishment-resource/replenishment-resource.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CapacitationTabComponent } from './pages/capacitation/capacitation-tab/capacitation-tab.component';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    DashboardComponent,
    RequestManagementValidatorComponent,
    ApplicationInformationComponent,
    BasicDataComponent2,
    ApplicationDataComponent,
    AttachedFilesComponent,
    ValidationSectionComponent,
    RequestManagementCoordinatorComponent,
    RequestManagementAssistantManagerComponent,
    ListOfTrainersComponent,
    AssistantManagerTabComponent,
    ProcedureDashboardComponent,
    ValidatorTabComponent,
    CoordinatorTabComponent,
    ReplenishmentResourceComponent,
    CapacitationTabComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NzSpinModule,
    NzModalModule
  ],
    exports: [
        ProcedureDashboardComponent,
        BasicDataComponent2,
        ApplicationDataComponent,
        AttachedFilesComponent,
        ListOfTrainersComponent,
        ApplicationInformationComponent,
        CapacitationTabComponent
    ]
})
export class DashboardModule { }

