import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BrowserUtils} from "@azure/msal-browser";
import {ROUTES} from "./core/enums";
import { NotFoundComponent } from './core/components/general/not-found/not-found.component';

const routes: Routes = [
  {
    path: ROUTES.MainPage,
    canActivate: [/*MsalGuard */],
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule)
  },
  { path: '', redirectTo: ROUTES.MainPage, pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
