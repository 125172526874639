import { AbstractControl,FormArray, FormGroup } from '@angular/forms';


export class AppBaseComponent {
  clearField = (form: FormGroup, field: string): void => {
    form.get(field).reset()
  }

  isDevelopment = (): boolean => {
    return window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');
  }

  openBlankPage = (url: string): void => {
    window.open(url, '_blank');
  }

  isTouchedField = (form: FormGroup, field: string): boolean => {
    return form?.get(field).touched === true && form?.get(field).invalid;
  }

  isNotPristinedField = (form: FormGroup, field: string): boolean => {
    return form?.get(field).touched === true
  }

  isValidField = (form: FormGroup, field: string): boolean => {
    return (
      form?.get(field).touched && form?.get(field).valid
    );
  }
  /**
   * Retorna todos los errores o validaciones presentes en el FormGroup
   * @param form Form a evaluar
   */
  public getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }







  isValidPhoneFn = (control: AbstractControl): { [key: string]: any } | null => {
    return /^[1-9]\d{20}$/.test(control.value) ? null :
      { invalidPhone: { valid: false, value: control.value, message: 'No es válido' } };
  }



  isDateValid = (control: AbstractControl) => {
    const date: Date | string = new Date().toString();
    // @ts-ignore
    return (date instanceof Date) ? null : { isNotDateValid: true };
  }

  keyPressNumbers = (event: { which: any; keyCode: any; preventDefault: () => void; }): boolean => {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  keyPressLetters = (event: { keyCode: number; preventDefault: () => void; }): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-ZÑÁÉÍÓÚÜáéíóúüñ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphanumeric = (event: { keyCode: number; preventDefault: () => void; }): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-ZÑÁÉÍÓÚÜáéíóúüñ0-9 #$@!%&*?¡"+,.:;='^|~_()¿{}-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }





  keyPressLettersAndNumbers = (event: { keyCode: number; preventDefault: () => void; }): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[A-Za-z0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  formatDateToSave = (customDate: string): string => {
    const expDate = customDate.split('/');
    return `${ expDate[2] }-${ expDate[1] }-${ expDate[0] }`;
  }

  formatDateToLoad = (customDate: string): string => {
    try {
      const firstExpression = customDate.split('T')[0];
      const secondExpression = firstExpression.split('-');
      return `${ secondExpression[2] }/${ secondExpression[1] }/${ secondExpression[0] }`;
    } catch (e) {
      return customDate;
    }
  }

  removeEndLine = (text: string) => (text.replace(/[^\n]/, '')).trim();
  /**
   * Valida si a un input file se le ha cargado un archivo
   * @param field
   */
  isValidInputFile(field: string): boolean {
    return (<HTMLInputElement>document.getElementById(field)).value == '';
  }
}
