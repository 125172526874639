import { Component, Input } from '@angular/core';
import {Router} from "@angular/router";
import {ROUTES} from "../../../core/enums";

/**
 * Componente encargado de visualizar una linea de avance
 */
@Component({
  selector: 'app-advance-line',
  templateUrl: './advance-line.component.html',
  styleUrls: ['./advance-line.component.scss']
})
export class AdvanceLineComponent {

  /**
   * Modela el numero a pintar
   */
  @Input() step: number;

  /**
   * Modela la barra de progreso
   */
  @Input() currentProgress: number;

  /**
   * Opciones para la linea de avance
   */

  @Input() options: any;

  /**
   * Ancho de la linea de avance
   */

  @Input() width: number;

  constructor( private router: Router) {
    console.log("construyendo aqui");
  }

  public redirectTo(link:string, step: number): void {
    console.log("entro");
    console.log(link+" linkkk")
    console.log(this.step)
    console.log(step)
      this.router.navigateByUrl("/"+link);

  }
}
