<form [formGroup]="applicationDataForm" action="">
    <div class="row">
      <div class="col-sm-12 col-md-12 mb-4">
        <div class="form-group gov-co-form-group col-sm-12 col-md-12 mb-1">
          <label for="tipoCapacitacion">Tipo de capacitación *</label>
        </div>
        <div>
          <div *ngFor="let tdt of trainingDataTypes;" class="d-inline-block col-sm-12 col-md-4">
            <div class="d-flex align-items-center">
              <input disabled="true" (click)="trainingDataTypesSelected(tdt)" [checked]="tdt.selected" class="mr-2" type="checkbox"/>{{tdt.name}}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="tituloProfesional">Titulo profesional *</label>
        <input class="form-control gov-co-form-control" formControlName="tituloProfesional" type="text" id="tituloProfesional" placeholder="Titulo profesional"/>
      </div>
      <div class="form-group gov-co-form-group inactive col-sm-12 col-md-6">
        <label for="numeroTarjetaProfesional">Número de tarjeta profesional *</label>
        <input class="form-control gov-co-form-control" formControlName="numeroTarjetaProfesional" type="text" id="numeroTarjetaProfesional" placeholder="Número de tarjeta profesional"/>
      </div>
    </div>
</form>

