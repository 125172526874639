<form [formGroup]="applicationInformationForm">
  <div class="row">
    <div class="form-group gov-co-form-group col-sm-12 col-md-6 inactive">
      <label for="numeroRadicado">Número de radicado</label>
      <input class="form-control gov-co-form-control" formControlName="numeroRadicado" type="text" id="numeroRadicado"
        placeholder="Número de radicado" />
    </div>
    <div class="form-group gov-co-form-group col-sm-12 col-md-6 inactive">
      <label for="tipoTramite">Tipo de trámite</label>
      <input class="form-control gov-co-form-control" formControlName="tipoTramite" type="text" id="tipoTramite"
        placeholder="Tipo de trámite" />
    </div>
    <div class="form-group gov-co-form-group col-sm-12 col-md-6 inactive">
      <label for="estado">Estado</label>
      <input class="form-control gov-co-form-control" formControlName="estado" type="text" id="estado"
        placeholder="Estado" />
    </div>
    <div class="form-group gov-co-form-group col-sm-12 col-md-6" *ngIf="!this.revision" >
      <label for="usuarioAsignado">Usuario asignado</label>
      <select class="selectpicker form-control" id="usuarioAsignado" formControlName="usuarioAsignado">
        <option selected value="0" hidden>Seleccione opción</option>
        <option *ngFor="let item of usersbyrol"
                [value]="item.codigoUsuario+','+item.nombreCompleto">{{item.nombreCompleto}}</option>

      </select>
    </div>
    <div class="form-group gov-co-form-group col-sm-12 col-md-6 inactive">
      <label for="fechaSolicitud">Fecha Trámite</label>
      <input class="form-control gov-co-form-control" formControlName="fechaSolicitud" type="text" id="fechaSolicitud"
        placeholder="Fecha de solicitud" />
    </div>
  </div>
</form>
