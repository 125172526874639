<p class="text_header mt-4">Adicionar documentación</p>

<form [formGroup]="requestDocument">
  <div class="row">
    <div class="col">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="document_add">
        <label class="form-check-label" for="defaultCheck1">
          Documento soporte de cancelación
        </label>
      </div>
    </div>
    <div class="col d-flex flex-column">
      <button class="btn btn-primary button_option">Adicionar</button>
      <button class="btn btn-primary mt-3 button_option">Ver archivo</button>
      <button class="btn btn-primary mt-3 button_option">Borrar</button>
    </div>
  </div>
</form>
