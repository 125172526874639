import { Component, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { ListOfTrainersComponent } from '../../../components/list-of-trainers/list-of-trainers.component';

import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '@core-app/services/request.service';
import { Usuario } from '@core-app/services/usuario';
import { Trainer } from '@core-app/Interface/trainer';
import { Coordinator } from '@core-app/Interface/coordinator';
import Swal from 'sweetalert2';
import { ROUTES } from '../../../../../core/enums/routes.enum';
import { emailDTO } from '@core-app/models/emailDto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const conversor = require('conversor-numero-a-letras-es-ar');
import * as numberToWords from 'number-to-words';
import {toBoolean} from "ng-zorro-antd/core/util";
import {lastValueFrom} from "rxjs";
import {RegisterService} from "@core-app/services/register.service";

@Component({
  selector: 'app-request-management-coordinator',
  templateUrl: './request-management-coordinator.component.html',
  styleUrls: ['./request-management-coordinator.component.scss']
})

export class RequestManagementCoordinatorComponent implements OnInit {

  advanceLineData = [
    {
      name: 'Bandeja de trámites',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_COORDINATOR
    },
    {
      name: 'Revisar solicitud',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_COORDINATOR
    },
    {
      name: 'Verificar solicitud',
      link: "aut-cap/"+ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_COORDINATOR
    }
  ]
  public coordinatorForm: FormGroup;
  info: any;

  isLoadingDocument:boolean = true;
  datos:any[]=[];
  /**
   * Lista de capacitadores
   */
  trainers:any = [];
  canBeApprovedFlag = false;
  documentVisualizationTitle:string = "";
  trainersCopy:any = [];
  idSolicitud:number;
  usuarioInfo:string;
  idCapacitadorSolicitudArray: any[];
  idocument:number;
  aproveDocument:boolean;
  capcitationId:[{ idCapacitadorSolicitud: number; blIsValid: boolean }];
  documentoSolicitud: any;
  textareaValue: string = '';
  savedData: any[] = [];
  auditlist: any[]=[];
  answerSolicitud:number;
  buttonDisabled: boolean = true;
  trainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];
  modalContent: string;
  urlSafeDocument: SafeResourceUrl;
  base64PDF:string = "";
  vcNumerosTarjetaProfesional: string[] = [];
  showModal: boolean = false;
  observacionTemporal = "";
  observacionId = 0;

  documentationSolicitud:any;

  observacionesCancelacion:string = "";

  /**
   * Ver campo de subsanación
   */
  viewRetrievalModal:boolean = false;

  /**
   * Ver campo de cancelacion
   */

  viewCancellationModal:boolean = false;

  /**
   * Ver detalle de capacitador
   */
  viewTrainerDetailModal:boolean = false;

  /**
   * Ver documento
   */
  viewDocument:boolean = false;

  /**
   * Ver documento
   */
  viewDocumentButton:boolean = true;

  canBeApprovedObj:any = null;


  meses = ["Enero", "Febrero", "Marzo","Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre","Octubre", "Noviembre", "Diciembre"];
  legalname: string = '';
  numberdocument: string = '';
  stringFeed:string ='';
  typeDocument:number =0;
  docreprelegal:number=0;
  numberOrder:string='';
  DocumentNumero:string = '';
  dateOrder:string = '';
  Signature:string = "";
  solicitudes:any;
  documentTypes:any = []
  auditoria: any = [];
  stringfullname:string = '';
  isNatural:boolean = false;

  mostrarDireccion:boolean;

  isLoading:boolean = true;

  mostrar:boolean = false;

  idCapacitadorN:string;
  idCapacitadorJ:any[];


  fileNumber: string = "";

  detailTrainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];

  isLoadingTrainer:boolean = true;

  /**
   * Listado de la documentación requerida
   */

  detailDocumentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = [];

  documentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = []

  documentationSend: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,mandatory:boolean}[] = []

  /**
   * Lista de tipos de documento 22
   */

  documentTypes22: any = [];

  updateDocumentation:boolean = true;


  usuariointerno = "";

  constructor(private sanitizer: DomSanitizer ,private router: Router,private fb: FormBuilder, private requestService: RequestService,
              private usuario: Usuario, private activateRoute: ActivatedRoute,private registerService:RegisterService){

    this.isLoading = true;
    this.isNatural = this.activateRoute.snapshot.paramMap.get('type')=="P. Natural";
  this.coordinatorForm = this.fb.group(({
    applicationInformationForm: this.fb.group({
          numeroRadicado: "",
          tipoTramite: "",
          estado: "",
          tipoSolicitud: "",
          fechaSolicitud: "",
          usuarioAsignado: "0"
        }),
    basicDataForm: this.fb.group({
          primerNombre: "",
          segundoNombre: "",
          primerApellido: "",
          segundoApellido: "",
          tipoDocumento: "",
          numeroDocumento: "",
          numeroContacto: "",
          correoElectronico: "",
          direccion:""
        }),
    applicationDataForm: this.fb.group({
      tituloProfesional: "",
      numeroTarjetaProfesional: ""
    }),
        selectanswer: [''],
        observation:"",
    modalTrainerForm: this.fb.group({
      primerNombre: "",
      segundoNombre: "",
      primerApellido: "",
      segundoApellido: "",
      tipoDocumento: "",
      numeroDocumento: "",
      tituloProfesional: "",
      numeroTarjetaProfesional: "",
      numeroContacto: "",
      correoElectronico: "",
      revision:""
    })
    }));


  }

   ngOnInit() {
      this.InfoRequest();

    this.blockarea();
    this.audit();
    console.log("trainers oninits", this.trainers);
    const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;
    this.stringfullname = userName;
    console.log("fullname sesion", this.stringfullname);
    this.isNatural = this.activateRoute.snapshot.paramMap.get('type') == "P. Natural";
    console.log("soy persona natural?", this.isNatural);
    if (this.isNatural) {
      this.mostrarDireccion = true;
    } else {
      this.mostrarDireccion = false;
    }

  }


 decree = (capacitadorTipoCapacitacion:any[], value:string):boolean => {
  let flag = false;
    if (capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
      flag = true;
    }

  return flag;
}

 getTrainers = (capacitadores:any[]):string =>{

  let original:string = "";

  capacitadores.forEach((e) => {

    let capacitaciones = "";

    this.decree(e.capacitadorTipoCapacitacion, "Carnes y productos cárnicos comestibles")?capacitaciones=capacitaciones+" Carnes y productos cárnicos comestibles,":null;
    this.decree(e.capacitadorTipoCapacitacion, "Leche cruda")?capacitaciones=capacitaciones+" Leche cruda,":null;
    this.decree(e.capacitadorTipoCapacitacion, "Alimentos en vía publica")?capacitaciones=capacitaciones+" Alimentos en vía publica":null;

    console.log("Capacitadores");
    console.log(capacitaciones);
    let cadena:string;
    if (e.vcPrimerNombre != "") {
      cadena = +cadena+"<tr>";
      cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+e.vcPrimerNombre+" "+e.vcPrimerApellido+"</td>";
      cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+"<div>"+"<p>"+e.vcTipoIdentificacion+"</p>"+"<p>"+e.intNumeroIdentificacion+"</p></td>";
      cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+ e.vcTituloProfesional +"</td>";
      cadena = cadena+"<td style='border-right: 1px solid black;width: 100px;word-break: break-all;'>"+ e.vcNumeroTarjetaProfesional +"</td>";
      cadena = cadena+"<td style='width: 100px;word-break: break-all;'>"+ capacitaciones +"</td>"
      cadena = cadena + "</tr>";
    }
      original = original + cadena;
      console.log("aqui");
      console.log(e);
  })

  console.log(original.replaceAll("NaN",""));

  return original.replaceAll("NaN","");
}




  view(){
    this.isLoading = true;
    const date = new Date();
    let template = "";
    let keysA: string[] = [];
    let valuesA: string[] = [];
    let keysN: string[] = [];
    let valuesN: string[] = [];
    let capacitaciones = "";
    let ClaseConversor = conversor.conversorNumerosALetras;
    let miConversor = new ClaseConversor();
    if (this.isNatural) {

      if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
        capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
        capacitaciones = capacitaciones + " Leche cruda "
      }

      if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
        capacitaciones = capacitaciones + " Alimentos en vía publica "
      }

    }

    console.log(this.solicitudes.resultadoValidacionId);

    switch (this.solicitudes.resultadoValidacionId)
    {

      case 6:

        //aprobado

        this.documentVisualizationTitle = "aprobación";

        if (this.isNatural) {

          keysA = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitaciones_seleccionadas~:~"
          ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }

          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: "; "+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
          valuesA = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected && !this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
            this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            capacitacioncompleta
          ];
          this.requestService.GetTemplate(10).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }else{
          keysA = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~capacitaciones_seleccionadas~:~"
          ];

          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda");



          valuesA = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica")?this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":", 3009 de 2010; 240 y 242 de 2013 y 562 de 2016":this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016, ":"3009 de 2010; 240 y 242 de 2013 y 562 de 2016":"",
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica") && !this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?", 1880 de 2011": "1880 de 2011":"",
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
            this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            this.Signature,
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            this.getTrainers(this.solicitudes.capacitadoresSolicitud),
            capacitacioncompleta
          ];
          this.requestService.GetTemplate(9).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysA, valuesA);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }
        break;

      case 7:

        // Cancelación

        console.log("cancelar solicitud");
        this.documentVisualizationTitle = "cancelación";

        console.log(this.trainingDataTypes);
        console.log();

        let keysC: string[] = [];
        let valuesC: string[] = [];

        let dateResolucion1 = new Date(this.solicitudes.resolucionSolicitudes[0].fechaResolucion);

        if (this.isNatural) {

          console.log( this.solicitudes.resolucionSolicitudes[0].vcNumeroResolucion);
          console.log( this.solicitudes);
          keysC= [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~ ",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.trainingDataTypes[i].name: ";"+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
            valuesC = [
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion1.getFullYear()+1).toString(),
            this.solicitudes.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion1.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
            this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            ""
          ];
          this.requestService.GetTemplate(12).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              //this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.isLoadingDocument = false;
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }else{
          keysC = [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda");

            valuesC = [
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion1.getFullYear()+1).toString(),
            this.solicitudes.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion1.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion1.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion1.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
              this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
              this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "",
            this.getTrainers(this.solicitudes.capacitadoresSolicitud),
            ];
          this.requestService.GetTemplate(11).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysC, valuesC);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              //this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              //this.isLoadingDocument = false;
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }
      break;

      case 8:

        console.log("sayying hello");

        this.documentVisualizationTitle = "negación";
        //negado
        if (this.isNatural) {
          keysN= [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional_capacitador~:~",
            "~:~num_tar_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitaciones_seleccionadas~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
          valuesN = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
            this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            capacitacioncompleta
          ];
          this.requestService.GetTemplate(14).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }else{

          console.log("juridico ------------------")

          keysN = [
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~capacitaciones_seleccionadas~:~"
            ];

          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: "; "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda");

          valuesN = [
            "numero de resolución",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
            this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "~:~nombre_coordinador~:~",
            this.getTrainers(this.solicitudes.capacitadoresSolicitud),
            capacitacioncompleta
            ];
          this.requestService.GetTemplate(13).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysN, valuesN);

            console.log(template);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }
      break;

      case 10:
        console.log(date);

        this.documentVisualizationTitle = "cancelación por incumplimiento";

        if(this.isNatural){
          if (this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected) {
            capacitaciones = capacitaciones + " Carnes y productos cárnicos comestibles "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected) {
            capacitaciones = capacitaciones + " Leche cruda "
          }

          if (this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected) {
            capacitaciones = capacitaciones + " Alimentos en vía publica "
          }
        }

        console.log(this.trainingDataTypes);
        console.log();

        let keysCI: string[] = [];
        let valuesCI: string[] = [];

        let dateResolucion = new Date(this.solicitudes.resolucionSolicitudes[0].fechaResolucion);

        if (this.isNatural) {

          console.log( this.solicitudes.resolucionSolicitudes[0].vcNumeroResolucion);
          console.log( this.solicitudes);
          keysCI= [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_solicitante~:~",
            "~:~tipo_id_sol~:~",
            "~:~num_id_sol_pn~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional_capacitador~:~",
            "~:~num_tar_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~motivo_cancelacion_incumplimiento_pn~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.trainingDataTypes[2].selected)
          {
            capacitacioncompleta+=this.trainingDataTypes[2].name
          }


          for (let i = 0; i < 2; i++) {
            if(this.trainingDataTypes[i].selected)
            {
              if(capacitacioncompleta!="")
              { capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.trainingDataTypes[i].name

              }
              else
              {
                capacitacioncompleta+= this.trainingDataTypes[i].name
              }

            }
          }
          selected=this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected;
            valuesCI = [
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
            this.solicitudes.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],//(date.getMonth()+1).toString().padStart(2, "0")
            date.getFullYear().toString(),
            this.trainingDataTypes.find((t:any) => t.name == 'Carnes y productos cárnicos comestibles').selected?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.trainingDataTypes.find((t:any) => t.name == 'Leche cruda').selected?"1880 de 2011":"",
              this.trainingDataTypes.find((t:any) => t.name == 'Alimentos en vía publica').selected? "604 de 1993":"",
              this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "",
            this.observacionesCancelacion
          ];
          this.requestService.GetTemplate(18).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }else{
          keysCI = [
            "~:~fecha_vigencia~:~",
            "~:~num_res_autoriza~:~",
            "~:~fecha_res_autoriza~:~",
            "~:~numero_de_la_resolucion~:~",
            "~:~dia_de_la_resolucion~:~",
            "~:~mes_de_la_resolucion~:~",
            "~:~año_de_la_resolucion~:~",
            "~:~resol_carne~:~",
            "~:~decre_leche~:~",
            "~:~resol_alimentos~:~",
            "~:~nombre_rep_legal~:~",
            "~:~tipo_doc_rep_legal~:~",
            "~:~num_id_rep_legal~:~",
            "~:~nombre_empresa~:~",
            "~:~nit_empresa~:~",
            "~:~tipo_capacitaciones~:~",
            "~:~id_solicitud~:~",
            "~:~fecha_id_solicitud~:~",
            "~:~num_id_sol~:~",
            "~:~titulo_profesional~:~",
            "~:~num_tarj_profesional~:~",
            "~:~año_letra_de_la_resolucion~:~",
            "~:~firma_subdirector~:~",
            "~:~nombre_validador~:~",
            "~:~nombre_coordinador~:~",
            "~:~capacitadores~:~",
            "~:~motivo_cancelacion_incumplimiento~:~"
            ];
          let capacitacioncompleta="";
          let selected=false;

          if(this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica"))
          {
            capacitacioncompleta="Alimentos en vía publica";
          }


          for (let i = 0; i < this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.length; i++) {
            if(this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion!="Alimentos en vía publica")
            {
              if(capacitacioncompleta!="")
              {
                capacitacioncompleta+= capacitacioncompleta.includes(';') ?" y "+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion: ";"+this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion

              }
              else
              {
                capacitacioncompleta+=this.solicitudes.capacitadoresSolicitud[0].capacitadorTipoCapacitacion[i].vcTipoCapacitacion
              }
            }
          }
          selected=this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda");

            valuesCI = [
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+(dateResolucion.getFullYear()+1).toString(),
            this.solicitudes.resolucionSolicitudes[0].vcNumeroResolucion,
            (dateResolucion.getDate()).toString().padStart(2, "0")+"/"+(dateResolucion.getMonth()+1).toString().padStart(2, "0")+"/"+dateResolucion.getFullYear().toString(),
            "",
            (date.getDate()).toString().padStart(2, "0"),
            this.meses[date.getMonth()],
            date.getFullYear().toString(),
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Carnes y productos cárnicos comestibles")?"3009 de 2010; 240 y 242 de 2013 y 562 de 2016,":"",
            this.isDecree(this.solicitudes.capacitadoresSolicitud, "Leche cruda")?"1880 de 2011":"",
              this.isDecree(this.solicitudes.capacitadoresSolicitud, "Alimentos en vía publica")? "604 de 1993" :"",
              this.coordinatorForm.get('basicDataForm').get('primerNombre').value +" "+this.coordinatorForm.get('basicDataForm').get('primerApellido').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.documentTypes.find((dt:any) => this.coordinatorForm.get('basicDataForm').get('tipoDocumento').value == dt.idTipoIdentificacion).descripcion.toLowerCase(),  // revisar que datos se colocan
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value, // revisar que datos se colocan
            capacitaciones,
            this.fileNumber,
            this.solicitudes.vcFechaSolicitud,
            this.coordinatorForm.get('basicDataForm').get('numeroDocumento').value,
            this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').value,
            this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').value,
            miConversor.convertToText(date.getFullYear()).toString().replace(/^\w/, (c:any) => c.toUpperCase()),
            "firma",
            JSON.parse(localStorage.getItem('currentUser')).fullName,
            "",
            this.getTrainers(this.solicitudes.capacitadoresSolicitud),
            this.observacionesCancelacion
            ];
          this.requestService.GetTemplate(17).subscribe((e:any) => {
            console.log(e);
            template = this.getTemplate(e.data.vcPlantilla, keysCI, valuesCI);;

            this.requestService.getDocument({html: template}).subscribe((r:any) => {
              console.log(r);
              this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+r.data);
              this.isLoadingDocument = false;
              this.isLoading = false;
              this.showModal = true;
            })
          })
        }
      break;
    }

  }


  isDecree = (capacitadores:any[], value:string):boolean => {
    let flag = false;
    capacitadores.forEach((e) => {
      if (e.capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
        flag = true;
      }
    })

    return flag;
  }

  getTemplate = (template:string, keys:string[], values:string[]) => {
      let res = template;
      for (let index = 0; index < keys.length; index++) {
        res = res.replaceAll(keys[index], values[index]);
      }
      return res;
  }

  blockarea(){
    this.coordinatorForm.get('observation').valueChanges.subscribe(value => {
      this.textareaValue = value;
    });
  }

  closeDocument = () => {
    this.viewRetrievalModal = false;
    this.viewCancellationModal = false;
    this.viewDocument = false;
    this.showModal = false;
    this.viewTrainerDetailModal = false;
    this.viewDocumentButton = true;
  }

   public InfoRequest() {
    this.requestService.GetDocumentTypesShared().subscribe((result) => {
      console.log(result);
      this.documentTypes = result.data;
    })

    const idReq = this.activateRoute.snapshot.paramMap.get('idRequest');
    this.requestService.GetRequest(parseInt(idReq)).subscribe((Solicitud)=> {

    const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

    Solicitud.data.seguimientoAuditoriaSolicitud.forEach((element: { blSeguimientoDefinitivo: any; vcObservacion: string; usuarioId: string; idObservacion: number }) => {

      if(!element.blSeguimientoDefinitivo && element.usuarioId == idUser && Solicitud.data.vcEstado != "Aprobado"){
        this.observacionTemporal = element.vcObservacion;
        this.observacionId = element.idObservacion;
      }
    });


    this.solicitudes = Solicitud.data;
    this.documentationSolicitud = Solicitud.data;

    console.log("esta es aqui solicitudes", this.solicitudes);
    console.log("listado info", this.solicitudes);
    this.dateOrder = Solicitud.data.vcFechaSolicitud;
    localStorage.setItem("date",this.dateOrder);
    console.log("datos importantes", Solicitud);
    this.numberOrder = Solicitud.data.vcRadicado
    localStorage.setItem("order",this.numberOrder);
    this.idSolicitud = Solicitud.data.idSolicitud;
    this.usuarioInfo = Solicitud.data.usuarioId;
    const capacitadoresSolicitud = Solicitud.data.capacitadoresSolicitud;
    capacitadoresSolicitud.forEach((capacitador: any) => {
    const capacitadorTipoCapacitacion = capacitador.capacitadorTipoCapacitacion;
    capacitadorTipoCapacitacion.forEach((tipoCapacitacion: any) => {
    const vcTipoCapacitacion = tipoCapacitacion.vcTipoCapacitacion;
    const idCapacitadorSolicitud = tipoCapacitacion.idCapacitadorSolicitud;
    localStorage.setItem('vcTipoCapacitacion', vcTipoCapacitacion);
    localStorage.setItem('idCapacitadorSolicitud', idCapacitadorSolicitud)
      });
    });


    if (Solicitud.data.resultadoValidacionId == 6 || Solicitud.data.resultadoValidacionId == 8 || Solicitud.data.resultadoValidacionId == 9 || Solicitud.data.resultadoValidacionId == 10 || Solicitud.data.resultadoValidacionId == 7) {
    this.buttonDisabled = false;
    this.requestService.GetSignature(1).subscribe((result:any) => {
    this.Signature = result.data.vcFirma;
      localStorage.setItem('estado','flujo normal')
      this.mostrar=true;
      })
    }

    this.coordinatorForm.get("observation").setValue(this.observacionTemporal);

    if(Solicitud.data.resultadoValidacionId == 10){
      this.requestService.GetDataResolution(this.solicitudes.idSolicitud).subscribe((e:any) => {
        console.log(e.data.cancelacionPorIncumplimiento);
        this.observacionesCancelacion = e.data.cancelacionPorIncumplimiento;

        //localStorage.setItem('estado','cancelacion')
        this.mostrar=true;
      });
    }

    if (Solicitud.data.capacitadoresSolicitud && Solicitud.data.capacitadoresSolicitud.length > 0) {
    Solicitud.data.capacitadoresSolicitud.forEach((capacitador: { vcNumeroTarjetaProfesional: string }) => {
    this.vcNumerosTarjetaProfesional.push(capacitador.vcNumeroTarjetaProfesional);
      });
    }
      this.fileNumber = Solicitud.data.vcRadicado;
    this.usuariointerno=Solicitud.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;
    this.coordinatorForm.get('applicationInformationForm').get('numeroRadicado').setValue(Solicitud.data.vcRadicado);
    this.coordinatorForm.get('applicationInformationForm').get('tipoTramite').setValue(Solicitud.data.vcTipoTramite);
    this.coordinatorForm.get('applicationInformationForm').get('estado').setValue(Solicitud.data.vcEstado);
    this.coordinatorForm.get('applicationInformationForm').get('tipoSolicitud').setValue(Solicitud.data.vcRadicado);
    this.coordinatorForm.get('applicationInformationForm').get('fechaSolicitud').setValue(Solicitud.data.vcFechaSolicitud);
      const idReq = this.activateRoute.snapshot.paramMap.get('idRequest');
      console.log(idReq);
      this.usuario.getInfoperson(Solicitud.data.usuarioId).subscribe((data: any) => {
        this.usuario.getTotalperson(data['data']).subscribe((valores: any) => {
        console.log(valores.data);
      this.docreprelegal = valores.data.numeroIdentificacionRL;
      localStorage.setItem("doclegal", this.docreprelegal ? this.docreprelegal+"": "");
      console.log("encargado", valores);
      this.legalname = valores.data.primerNombre + ' ' + valores.data.primerApellido;
      localStorage.setItem("nombre", this.legalname);
      this.numberdocument = valores.data.numeroIdentificacion;
      localStorage.setItem("document", this.numberdocument);
      let newValueTxt;
      this.typeDocument = valores.data.tipoIdentificacion;
      if (this.typeDocument === 5) {
        valores.data.razonSocial = "Cédula de ciudadanía";
        newValueTxt = valores.data.razonSocial;
        localStorage.setItem("txt", newValueTxt);
      } else {
        valores.data.razonSocial = "Sin Nombre";
      }

      this.DocumentNumero = valores.data.numeroIdentificacion;
      localStorage.setItem("textDocument",this.DocumentNumero)

      this.coordinatorForm.get('basicDataForm').get('primerNombre').setValue(valores.data.primerNombre);
      this.coordinatorForm.get('basicDataForm').get('segundoNombre').setValue(valores.data.segundoNombre);
      this.coordinatorForm.get('basicDataForm').get('primerApellido').setValue(valores.data.primerApellido);
      this.coordinatorForm.get('basicDataForm').get('segundoApellido').setValue(valores.data.segundoApellido);
      this.coordinatorForm.get('basicDataForm').get('numeroContacto').setValue(valores.data.telefonoCelular);
      this.coordinatorForm.get('basicDataForm').get('correoElectronico').setValue(valores.data.email);
      this.coordinatorForm.get('basicDataForm').get('direccion').setValue(valores.data.direResi);

        if (this.isNatural) {
          console.log("noooo entro acaaa");
          console.log(valores.data)
          this.coordinatorForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacion);
          this.coordinatorForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacion);
          //Datos de la solicitud

          this.idCapacitadorN = Solicitud.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;

          let obj = null;

          this.requestService.GetTraining().subscribe((result2) => {
            console.log(result2);
            result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

              obj = {
                id: r.idTipoCapacitacion,
                name: r.vcDescripcion,
                selected: false
              }

              this.trainingDataTypes.push(obj);
            });

            this.trainingDataTypes.forEach((t) => {
              console.log("forEach trainings");
              let aux = Solicitud.data.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

              if (aux != undefined) {
                t.selected = true;
              }
            })
          })
          this.coordinatorForm.get('applicationDataForm').get('tituloProfesional').setValue(Solicitud.data.capacitadoresSolicitud[0].vcTituloProfesional);
          this.coordinatorForm.get('applicationDataForm').get('numeroTarjetaProfesional').setValue(Solicitud.data.capacitadoresSolicitud[0].vcNumeroTarjetaProfesional);

          //Documentation

          let doc = null;

          console.log("documentacion")
          Solicitud.data.capacitadoresSolicitud[0].documentosSolicitud.forEach((ds: any) => {

            console.log(ds);
            doc = {
              id: ds.idDocumento,
              descripcion: ds.vcTipoDocumento,
              path: ds.vcPath,
              aprovado: ds.blIsValid,
              fileNumber: this.fileNumber,
              mandatory:ds.vcTipoDocumento!='Pensum de pregrado'
            }
            console.log(doc)
            this.documentation.push(doc);
            this.documentationSend.push(doc);
          });

        }else{
          console.log("entro acaaa");
          console.log(valores.data)
          this.coordinatorForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacionRL);
          this.coordinatorForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacionRL);
          this.idCapacitadorJ = Solicitud.data.capacitadoresSolicitud;
          //Trainers

          let t = null;
          let index = 1;

console.log(Solicitud.data.capacitadoresSolicitud)
          Solicitud.data.capacitadoresSolicitud.forEach((cs: any) => {

            console.log(cs ,"capacitadore");
            t = {
              id: index,
              firstName: cs.vcPrimerNombre,
              secondName: cs.vcSegundoNombre,
              surname: cs.vcPrimerApellido,
              secondSurname: cs.vcSegundoApellido,
              documentType: cs.vcTipoIdentificacion,
              identificationNumber: cs.intNumeroIdentificacion,
              professionalTitle: cs.vcTituloProfesional,
              professionalCardNumber: cs.vcNumeroTarjetaProfesional,
              telephoneContact: cs.intTelefono,
              emailAddress: cs.vcEmail,
              approved: cs.blCapacitadorActivo,
              disabledFlag: this.allBeApproved(cs.intNumeroIdentificacion)
            }
            this.trainers.push(t);
            this.trainersCopy.push(t);
            index++
          });
        }

      });
      });
      this.getData();
    });
  }



  getData() {
    const idReq = parseInt(this.activateRoute.snapshot.paramMap.get('idRequest'));
    this.requestService.GetRequest(idReq).subscribe((Solicitud) => {
      console.log("Holaaaa")
      console.log(this.solicitudes)

    const idCapacitadorSolicitudArray = this.solicitudes.capacitadoresSolicitud.map((capacitador: any) => { return capacitador.idCapacitadorSolicitud; });


    const documentAndStateArray = Solicitud.data.capacitadoresSolicitud.flatMap((capacitador: any) => {
    return capacitador.documentosSolicitud.map((documento: any) => {
      return {
          idDocumento: documento.idDocumento,
          blIsValid: documento.blIsValid
          };
      });
     });

    const documentoSolicitud = documentAndStateArray.map((documentState: any) => {
        return {
          idDocumento: documentState.idDocumento,
          blIsValid: documentState.blIsValid
        };
    });

      this.idCapacitadorSolicitudArray = idCapacitadorSolicitudArray;
      this.documentoSolicitud = documentoSolicitud;
    });
    this.isLoading = false;
  }

  sendReview() {

    if (this.coordinatorForm.get('selectanswer').value != "")
    {
      const idSolicitud = this.idSolicitud;
      const usuarioid = JSON.parse(localStorage.getItem('currentUser')).userId;
      const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;
      const form = this.coordinatorForm.get('observation').value;
      const booolResult:boolean = toBoolean(this.coordinatorForm.get('selectanswer').value) ;

      let data:any = null;


      console.log(JSON.parse(localStorage.getItem('currentUser')));

      if(this.isNatural){
        this.documentoSolicitud = this.documentationSend.map((e) => { return {idDocumento: e.id, blIsValid: e.aprovado}});
        let asignado = false;
        let userasignado=usuarioid;



        data = {
          idSolicitud: idSolicitud,
          asignado: asignado,
          usuarioAsignadoId: userasignado,
          capacitadorSolicitud: [
            {
              idCapacitadorSolicitud: this.idCapacitadorN,
              blIsValid: true
            }
          ],
          documentoSolicitud: this.documentoSolicitud,
          seguimientoAuditoriaSolicitud: {
            idObservacion: this.observacionId,
            vcObservacion: form,
            usuarioId: usuarioid,
            vcNombreUsuario: userName,
          },
          resultadoValidacion: booolResult,
        };

      }else{

        let capacitador:any[]=[];

        /*
        for (let i = 0; i <idCapacitadorSolicitudArray.length ; i++) {
          capacitador.push({idCapacitadorSolicitud: idCapacitadorSolicitudArray[i],
            blIsValid: booolResult,})
        }

          */
        capacitador = this.trainersCopy.map((e:any) => {
          return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
        })

        let documentosArray: any[] = [];
        this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
          documentosArray = documentosArray.concat(element.documentosSolicitud);
        });

        this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

        let asignado = false;
        let userasignado=usuarioid;

        data = {
          idSolicitud: idSolicitud,
          asignado: asignado,
          usuarioAsignadoId: userasignado,
          capacitadorSolicitud: capacitador,
          documentoSolicitud: this.documentoSolicitud,
          seguimientoAuditoriaSolicitud: {
            idObservacion: this.observacionId,
            vcObservacion: form,
            usuarioId: usuarioid,
            vcNombreUsuario: userName,
          },
          resultadoValidacion: booolResult,
        };

      }

      this.usuario.reviewCoordinator(data).subscribe(
        (response) => {
          if(!booolResult)
          {
            this.template();
          }

          Swal.fire({
            icon: 'success',
            text: 'Su solicitud se ha enviado con éxito.',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl(
                ROUTES.MainPage + '/' + ROUTES.Dashboard + '/' + ROUTES.DASHBOARD_COORDINATOR
              );
            }
          });
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            text: 'Su solicitud no se ha podido enviar.',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl(
                ROUTES.MainPage + '/' + ROUTES.Dashboard + '/' + ROUTES.DASHBOARD_COORDINATOR
              );
            }
          });
        }
      );
    }
    else
    {
      Swal.fire({
        icon: 'error',
        title: '<h5>Resultado de la validación no selecionado</h5>',
        text: 'El resultado de la validación no ha sido seleccionado',
      })

    }


  }
  save() {
    const selectValue = this.coordinatorForm.get('selectanswer').value;
    const observationValue = this.coordinatorForm.get('observation').value;
    const savedDataItem = { selectanswer: selectValue, observation: observationValue };
    this.savedData.push(savedDataItem);

    const idSolicitud = this.idSolicitud;
    const usuarioid = JSON.parse(localStorage.getItem('currentUser')).userId;
    const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;
    const form = this.coordinatorForm.get('observation').value;
    const booolResult:boolean = toBoolean(this.coordinatorForm.get('selectanswer').value) ;

    let data:any = null;


    console.log(JSON.parse(localStorage.getItem('currentUser')));

    if(this.isNatural){
      this.documentoSolicitud = this.documentationSend.map((e) => { return {idDocumento: e.id, blIsValid: e.aprovado}});
      let asignado = false;
      let userasignado=usuarioid;



      data = {
        idSolicitud: idSolicitud,
        asignado: asignado,
        usuarioAsignadoId: userasignado,
        capacitadorSolicitud: [
          {
            idCapacitadorSolicitud: this.idCapacitadorN,
            blIsValid: true
          }
        ],
        documentoSolicitud: this.documentoSolicitud,
        seguimientoAuditoriaSolicitud: {
          idObservacion: this.observacionId,
          vcObservacion: form,
          usuarioId: usuarioid,
          vcNombreUsuario: userName,
        },
        resultadoValidacion: booolResult,
      };

    }else{

      let capacitador:any[]=[];

      /*
      for (let i = 0; i <idCapacitadorSolicitudArray.length ; i++) {
        capacitador.push({idCapacitadorSolicitud: idCapacitadorSolicitudArray[i],
          blIsValid: booolResult,})
      }

        */
      capacitador = this.trainersCopy.map((e:any) => {
        return {idCapacitadorSolicitud: this.getIdNumber(e.identificationNumber), blIsValid: e.approved};
      })

      let documentosArray: any[] = [];
      this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
        documentosArray = documentosArray.concat(element.documentosSolicitud);
      });

      this.documentoSolicitud = documentosArray.map((e) => { return {idDocumento: e.idDocumento, blIsValid: e.blIsValid}});

      let asignado = false;
      let userasignado=usuarioid;

      data = {
        idSolicitud: idSolicitud,
        asignado: asignado,
        usuarioAsignadoId: userasignado,
        capacitadorSolicitud: capacitador,
        documentoSolicitud: this.documentoSolicitud,
        seguimientoAuditoriaSolicitud: {
          idObservacion: this.observacionId,
          vcObservacion: form,
          usuarioId: usuarioid,
          vcNombreUsuario: userName,
        },
        resultadoValidacion: booolResult,
      };

    }



    this.usuario.temporarySaveCoordinatorAssistantManager(data).subscribe(
      (response) => {

        Swal.fire({
          icon: 'success',
          text: 'Su solicitud se ha enviado con éxito.',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl(
              ROUTES.MainPage + '/' + ROUTES.Dashboard + '/' + ROUTES.DASHBOARD_COORDINATOR
            );
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          text: 'Su solicitud no se ha podido enviar.',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl(
              ROUTES.MainPage + '/' + ROUTES.Dashboard + '/' + ROUTES.DASHBOARD_COORDINATOR
            );
          }
        });
      }
    );

  }




  template(){

    // moficiar solo en false y colocar correo
    let template = "";
      this.requestService.GetTemplate(19).subscribe((en:any)=> {
        template = en.data.vcPlantilla.replaceAll("~:~body~:~","Tiene una devolución de solicitud por parte del Coordinador del trámite Autorización para capacitadores de manipulación de alimentos")


        this.registerService.getRoleByIdUser( this.solicitudes.usuarioAsignadoValidadorId).subscribe((resp) => {

          let email: emailDTO = {
            to:resp.data[0].email+"",
            //to: 'ca4rodriguez@saludcapital.gov.co',
            body: template,
            subject: 'Solicitud devuelta'
          }
          this.requestService.sendNotification(email).subscribe((r) => {
          })



        })
    });
  }

  audit() {
    const idReq = parseInt(this.activateRoute.snapshot.paramMap.get('idRequest'));
    this.requestService.GetRequest(idReq).subscribe((validate) => {
      this.auditlist = validate.data.seguimientoAuditoriaSolicitud;
    });
  }
  approvedTrainer = (e:any) => {
    console.log('approved Trainer');
    console.log(e);

    this.trainersCopy.forEach((element:any) => {

      if(element.identificationNumber == e.record.identificationNumber){
        element.approved = e.value == "si";
      }
    });
  }

  viewDetailTrainer = (e:any) => {
    this.canBeApprovedObj = e;
    this.isLoadingTrainer = true;
    console.log('Trainer Detail');
    this.detailDocumentation = [];
    this.detailTrainingDataTypes = [];
    console.log(e);
    this.viewTrainerDetailModal = true;

    this.requestService.GetDocumentTypes('bTipoIdentificacion').subscribe((result) =>{
      this.documentTypes22 = result.data;
    })


    this.coordinatorForm.get("modalTrainerForm").get("primerNombre").setValue(e.firstName);
    this.coordinatorForm.get("modalTrainerForm").get("segundoNombre").setValue(e.secondName);
    this.coordinatorForm.get("modalTrainerForm").get("primerApellido").setValue(e.surname);
    this.coordinatorForm.get("modalTrainerForm").get("segundoApellido").setValue(e.secondSurname);
    this.coordinatorForm.get("modalTrainerForm").get("tipoDocumento").setValue(e.documentType);
    this.coordinatorForm.get("modalTrainerForm").get("numeroDocumento").setValue(e.identificationNumber);
    this.coordinatorForm.get("modalTrainerForm").get("tituloProfesional").setValue(e.professionalTitle);
    this.coordinatorForm.get("modalTrainerForm").get("numeroTarjetaProfesional").setValue(e.professionalCardNumber);
    this.coordinatorForm.get("modalTrainerForm").get("numeroContacto").setValue(e.telephoneContact);
    this.coordinatorForm.get("modalTrainerForm").get("correoElectronico").setValue(e.emailAddress);

    this.requestService.GetRequest(parseInt(this.activateRoute.snapshot.paramMap.get('idRequest'))).subscribe((result) => {
      console.log("datos de la solicitud para capacitador")
      console.log(result);

      //Datos de la solicitud

      let obj = null;

      let capacitador:any = null;

      this.requestService.GetTraining().subscribe((result2) => {
        console.log(result2);
        result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

          obj = {
            id: r.idTipoCapacitacion,
            name: r.vcDescripcion,
            selected: false
          }

          this.detailTrainingDataTypes.push(obj);
        });
console.log(this.documentationSolicitud);
console.log(e.identificationNumber);
        this.documentationSolicitud.capacitadoresSolicitud.forEach((cs:any) => {
          if(cs.intNumeroIdentificacion == e.identificationNumber){
            console.log("I find it");
            capacitador = cs;
          }
        });

        console.log(capacitador);

        this.detailTrainingDataTypes.forEach((t) => {
          console.log("forEach trainings");

          let aux = capacitador.capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

          if (aux != undefined) {
            t.selected = true;
          }
        })

        //Documentation

        let doc = null;

        capacitador.documentosSolicitud.forEach((ds: any) => {

          console.log(ds);
          doc = {
            id: ds.idDocumento,
            descripcion: ds.vcTipoDocumento,
            path: ds.vcPath,
            aprovado: ds.blIsValid,
            fileNumber: this.fileNumber,
            mandatory:ds.vcTipoDocumento!='Pensum de pregrado'
          }
          this.detailDocumentation.push(doc);
        });

        console.log(this.detailDocumentation);

        this.isLoadingTrainer = false;
      })
    });

  }

  addSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar");
    console.log(e);
  }

  addModalSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar del modal");
    console.log(e);
  }
  acceptSelectedTrainer= (e:any) => {
    console.log("guardar seleccion de trainer");
    console.log(e);
    this.viewTrainerDetailModal = false;

    this.trainers.forEach((element:any) => {

      if(element.identificationNumber == this.canBeApprovedObj.identificationNumber){
          element.disabledFlag = this.canBeApproved();
      }

    });

    this.canBeApprovedFlag = this.allApproved();
    console.log(this.allApproved());
  }
  addApprovedDocumentation = (e:any) => {
    console.log("lista de documentacion a asignar");
    console.log(e);
    this.documentationSend.forEach((element) => {

      if (element.id == e.record.id) {
        element.aprovado = e.value == 'si';
      }

    })
    //console.log(this.documentationSend);
    this.updateDocumentation = false;

  }
  addApprovedDocumentationTrainer = (e:any) => {
    console.log("lista de documentacion a asignar modal");
    console.log(e);
    console.log("antes");
    console.log(this.documentationSolicitud);

    let capacitador = this.documentationSolicitud.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == e.id);
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (element2.idDocumento == e.record.id) {
        element2.blIsValid = e.value == "si";
      }
    });

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element3:any) => {
      if (element3.intNumeroIdentificacion == capacitador.intNumeroIdentificacion) {
        element3 = capacitador;
      }
    });

    console.log("despues");
    console.log(this.documentationSolicitud);

    this.updateDocumentation = false;
  }
  getIdNumber = (id:number) => {
    let idNumber: string = "";
    this.idCapacitadorJ.forEach((e) => {
      if (id == e.intNumeroIdentificacion) {
        idNumber = e.idCapacitadorSolicitud;
      }
    })
    return idNumber;
  }

  canBeApproved(){

    console.log(this.canBeApprovedObj);

    let bandera = true;

    let capacitador:any = [];

    capacitador = this.documentationSolicitud.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == this.canBeApprovedObj.identificationNumber);

    console.log(capacitador);
    if(capacitador == undefined){
      bandera = false;
    }
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (!element2.blIsValid) {
        console.log("es falso");
        bandera = false;
      }
    });

    return bandera;
  }


  allBeApproved(id:any){

    let bandera = true;

    let capacitador: any = null;

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element: { intNumeroIdentificacion: any; }) => {
      if(element.intNumeroIdentificacion == id){
        console.log("Oui");
        capacitador = element;
      }
    });

    if(capacitador == null){
      bandera = false;
      return bandera;
    }

    capacitador.documentosSolicitud.forEach((element2: { blIsValid: any; }) => {
        console.log(element2);
        if (!element2.blIsValid) {
          console.log("es falso");
          bandera = false;
        }
      });

    return bandera;
  }

  allApproved(){

    console.log(this.canBeApprovedObj);

    let bandera = true;

    console.log(this.documentationSolicitud);

    this.documentationSolicitud.capacitadoresSolicitud.forEach((element:any) => {
      console.log(element);
      element.documentosSolicitud.forEach((element2: { blIsValid: any; }) => {
        console.log(element2);
        if (!element2.blIsValid) {
          console.log("es falso");
          bandera = false;
        }
      });
    });

    //.capacitadoresSolicitud.find((element:any)=> element.intNumeroIdentificacion == this.canBeApprovedObj.identificationNumber);

    /*
    console.log(capacitador);
    if(capacitador == undefined){
      bandera = false;
    }
    capacitador.documentosSolicitud.forEach((element2:any) => {

      if (!element2.blIsValid) {
        console.log("es falso");
        bandera = false;
      }
    });

    return bandera;
  */

    return bandera;
  }

  /*

  changestatus=()  => {

    console.log(this.buttonDisabled)
    if(this.coordinatorForm.get('selectanswer').value=="true")
    {
      this.buttonDisabled=false;
    }
    else {
      this.buttonDisabled=true;
    }
  }

  */

}
