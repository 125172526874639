<div>
  <div class="header-document row">
    <div class="col-xm-6 col-sm-6 col-md-6">
      <div class="d-inline-block col-xm-4 col-sm-4 col-md-4 text-center"><strong>Descripción</strong></div>
    <div class="d-inline-block col-xm-4 col-sm-4 col-md-4 text-center"><strong>Ver</strong></div>
    <div class="d-inline-block col-xm-4 col-sm-4 col-md-4 text-center"><strong>¿Cumple?</strong></div>
    <div *ngFor="let d of documentacion" class="content-document my-3">
      <div class="d-inline-block col-xm-4 col-sm-4 col-md-4 text-center">* {{d.descripcion}}</div>
      <div class="d-inline-block col-xm-4 col-sm-4 col-md-4 text-center">
        <span (click)="verDocumento(d.path)" class="govco-file-pdf display-6" style="color: red;"></span>
      </div>
      <div class="d-inline-block col-xm-4 col-sm-4 col-md-4 text-center">
        <div class="radio-seleccion-govco d-inline-block">
          <input value="si" formControlName="revisionAprobada{{d.id}}" type="radio" id="revisionAprobada1{{d.id}}"
            name="revisionAprobada{{d.id}}">
          <label for="revisionAprobada1{{d.id}}">Si</label>
        </div>
        <div class="radio-seleccion-govco ms-2 my-4 d-inline-block">
          <input value="no" formControlName="revisionAprobada{{d.id}}" type="radio" id="revisionAprobada2{{d.id}}"
            name="revisionAprobada{{d.id}}">
          <label for="revisionAprobada2{{d.id}}">No</label>
        </div>
      </div>
    </div>
    </div>
    <div class="text-center col-xm-6 col-sm-6 col-md-6">
      <div class="w-100 h-100">
        <iframe [src] ="urlSafe"  class="w-100 h-100" frameBorder='0' scrolling='auto'></iframe>
      </div>

    </div>
  </div>
</div>
