<div class="row justify-content-center mt-2">
  <div class="col-sm-6 mt-5 d-flex justify-content-center align-items-center">
      <div class="card" style="width: 60%; padding: 20px; border-radius:10px;border:2px solid rgb(203, 242, 255)">
          <div class="card-body">
              <h5 class="card-title text-center" >Inicio de sesión</h5>
              <form [formGroup]="loginForm" class="mt-3 responsive-form">
                  <div class="mt-4 text-center button-container">
                      <button class="btn-round btn-high mr-3" (click)="submitLoginFormB2C()" type="button">Iniciar Sesión</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>
