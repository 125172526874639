<section>
  <div class="row">
    <div class="col-lg-12">
      <button class="btn btn-outline-primary" (click)="back()">Regresar</button>
    </div>
  </div>
</section>

<div class="row mt-4">
  <div class="col-lg-8">
    <h2>Editar capacitación</h2>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-2 col-xl-3 float-right">
    <div class="custom-alert">
      <a href="https://aulavirtual.saludcapital.gov.co/sicap/" style="text-decoration: none !important;"
        target="_blank">
        <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
          Te explicamos con tutoriales
        </div>
      </a>
      <a type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
        aria-controls="collapseExample">
        <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
          ¿Tienes dudas?
        </div>
      </a>
      <div class="collapse" id="collapseExample">
        <div class="card card-body" id="cardi_info_contact">
          <p id="colorphone" style="margin-left: 18px;">Teléfono:(571) 3649090</p>
          <p id="colorphone" style="margin-left: 18px; margin-top: -10px;">contactenos&commat;aludcapital.gov.co</p>
        </div>
      </div>
    </div>
  </div>
</div>

<section>
  <form [formGroup]="recordCapacitation" (submit)="sendPost()">
    <h2></h2>
    <div class="row mt-4">
      <div class="col-lg-3">
        <label>Capacitador*</label>
        <select class="form-control" formControlName="trainer">
          <option *ngFor="let item of listName.data" [value]="item.idCapacitadorSolicitud">
            {{item.vcNombre}}
          </option>
        </select>
      </div>
      <div class="col-lg-3">
        <label>Público objetivo*</label>
        <input type="text" class="form-control" [readonly]="this.visualizar" placeholder="Escriba..." autocomplete="off"
          formControlName="assistants" />
      </div>
      <div class="col-lg-3">
        <label>Número de asistentes</label>
        <select class="form-control" formControlName="assistantsnumber">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <label>Tema de capacitación a realizar</label>
        <textarea name="" id="" cols="30" rows="5" [readonly]="this.visualizar" class="form-control"
          formControlName="theme"></textarea>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <label>Metodología de la capacitación</label>
        <textarea name="" id="" cols="30" rows="5" [readonly]="this.visualizar" class="form-control"
          formControlName="methodology"></textarea>
      </div>
    </div>
    <h2 class="mt-4 time">Horarios capacitación</h2>
    <label>*Campos obligatorios</label>
    <form [formGroup]="recordDate" (submit)="saveDate()" *ngIf="!this.visualizar">
      <div class="row mt-5">
        <div class="col-lg-3">
          <label>Fecha de capacitación*</label>
          <input type="date" class="form-control" formControlName="date" max="{{this.actualday}}" />
        </div>
        <div class="col-lg-2">
          <label>Hora de inicio*</label>
          <input type="time" class="form-control" formControlName="initial" />
        </div>
        <div class="col-lg-2">
          <label>Hora finalización*</label>
          <input type="time" class="form-control" formControlName="final" />
        </div>
        <div class="col-lg-4 justify-content-center">
          <button class="btn btn-primary" style="margin-top: 33px;">Editar</button>
        </div>
      </div>
    </form>

    <div class="row mt-4">
      <div class="col-lg-9">
        <table class="table mt-4 table-bordered">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Fecha</th>
              <th scope="col">Hora Inicio</th>
              <th scope="col">Hora Fin</th>
              <th scope="col" *ngIf="!this.visualizar">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let registro of registros; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ registro.fecha }}</td>
              <td>{{ registro.horaInicio }}</td>
              <td>{{ registro.horaFin }}</td>
              <td *ngIf="!this.visualizar"><button class="btn btn-danger ml-3"
                  (click)="this.deleteDate($event,i)">Eliminar</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h2 class="mt-4 time">Lugar donde se realizara la capacitación</h2>
    <label>*Campos obligatorios</label>
    <div class="row mt-3" *ngIf="!this.visualizar">
      <div class="col-lg-2">
        <label>Via principal *</label>
        <select class="form-control" #selectOption (change)="updateAddress()">
          <option value="CL - CALLE">CL</option>
          <option value="DG - DIAGONAL">DG</option>
          <option value="AC - AVENIDA CALLE">AC</option>
          <option value="KR - CARRERA">KR</option>
          <option value="TV - TRANSVERSAL">TV</option>
          <option value="AK - AVENIDA CARRERA">AK</option>
          <option value="AV - AVENIDA">AV</option>
        </select>
      </div>
      <div class="col-lg-1">
        <label>Número*</label>
        <input type="text" class="form-control" (input)="updateAddress()" #numberInput />
      </div>
      <div class="col-lg-2">
        <label>Letra</label>
        <select class="form-control" #selectLetter (change)="updateAddress()">
          <option value=""></option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="J">J</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label>Bis</label>
        <select name="" id="" class="form-control" #selectother (change)="updateAddress()">
          <option value=""></option>
          <option value="Bis">Bis</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label>Cardinalidad</label>
        <select name="" id="" class="form-control" #selectcoordi (change)="updateAddress()">
          <option value=""></option>
          <option value="Norte">Norte</option>
          <option value="Sur">Sur</option>
          <option value="Este">Este</option>
        </select>
      </div>
    </div>

    <div class="row mt-3" *ngIf="!this.visualizar">
      <div class="col-lg-2">
        <label>Número*</label>
        <input type="text" class="form-control" (input)="updateAddress()" #numberInputComplement />
      </div>
      <div class="col-lg-1">
        <label>Letra</label>
        <select class="form-control" #selectcoordilettertwo (change)="updateAddress()">
          <option value=""></option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="J">J</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label>Placa*</label>
        <input type="text" class="form-control" (input)="updateAddress()" #platenumber />
      </div>
      <div class="col-lg-2">
        <label>Cardinalidad</label>
        <select name="" id="" class="form-control" #selectcard (change)="updateAddress()">
          <option value=""></option>
          <option value="Norte">Norte</option>
          <option value="Sur">Sur</option>
          <option value="Este">Este</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <label>Dirección</label>
        <input type="text" class="form-control" #addressInput formControlName="address" readonly />
      </div>
      <div class="col-lg-9 mt-3" *ngIf="!this.visualizar">
        <label>Información adicional</label>
        <input type="text" class="form-control" formControlName="aditional" />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-5">
        <label>Departamentos*</label>
        <select id="departamentoslist" class="form-control" formControlName="depto" (change)="obtenerMunicipios()">
          <option [disabled]="true" selected value="">-Seleccione-</option>
          <option *ngFor="let department of Departamentos" [ngValue]="department.idDepartamento">
            {{department.descripcion}}
          </option>
        </select>
      </div>
      <div class="col-lg-4 col-sm-12 col-md-4">
        <label>Ciudad*</label>
        <select id="municipioslist" class="form-control" formControlName="city">
          <option [disabled]="true" selected value="">-Seleccione-</option>
          <option *ngFor="let municipio of Municipios" [ngValue]="municipio.idMunicipio">
            {{municipio.descripcion}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-3 justify-content-center text-center mx-auto" *ngIf="!visualizar">
      <div class="col-lg-12">
        <button class="btn btn-primary" style="margin-top: 33px;">Actualizar</button>
      </div>
    </div>



    <!--Seccion adjuntar documentos-->
    <br>
    <div>
      <div class="documentation-btns-revision div_load_file mt-3" *ngIf="visualizar && !vista"
        style="display: grid; grid-template-columns: 15% 85%;">
        <label for="fileInpitControl" class="add-file-btn-revision float-left">
          Cargar archivo
        </label>
        <input [disabled]="maximo" id="fileInpitControl" type="file" [accept]="'application/pdf'"
          (change)="getFile($event)" style="height: 35px;width: 70%; margin-left: 15px;"
          class="form-control gov-co-form-control d-block">
      </div>
      <div *ngIf="maximo" class="alert mt-4"
          style="padding-bottom: 5px; background-color: #e6effd;color: #004884;font-size: 15px;width: 75%; height: 50px; grid-row: 2;grid-column: 1 / span 2;">
          <p>* La cantidad máxima de documentos admitidos es 5.</p>
      </div>

    </div>

    <br>
    <div class="row d-block" *ngIf="visualizar && !vista">

      <table>
        <thead>
          <tr>
            <th class="text-center body-1-gov-co col-1"><strong>No.</strong></th>
            <th class="text-center body-1-gov-co col-3"><strong>Descripcion</strong></th>
            <th class="text-center body-1-gov-co col-5"><strong>Acciones</strong></th>

          </tr>
        </thead>

        <tbody>
          <tr class="space" *ngFor="let file of listFiles; let i = index">
            <td class="text-center body-2-gov-co ">{{i + 1}}</td>
            <td class="text-center body-2-gov-co">{{file?.NombreDocumento}}</td>
            <td class="text-center body-2-gov-co">
              <div class="mt-3 row col-12 ">

                <div class="col-4 mt-3" (click)="seeDocument(file?.fileDocument)">
                  <i class="p-1 bi bi-file-earmark-pdf add_icon"></i>
                </div>

                <div class="col-4 ml-3 mt-3" style="cursor: pointer;">
                  <span class="d-block">{{file?.name}}</span>
                  <span class="d-block">{{file?.size+ ' Kb'}}</span>
                </div>
                <div class="col-2 mt-4 " (click)="removeDocument(i)">
                  <i class="bi bi-trash3-fill icon_delete"></i>
                </div>

              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>



    <div class="documentation-btns-revision div_load_file" *ngIf="vista">
      <label for="fileInpitControl" class="add-file-btn-revision float-left">
        Cargar archivo
      </label>
    </div>
    <br>


    <div class="row d-block" *ngIf="vista">

      <table>
        <thead>
          <tr>
            <th class="text-center body-1-gov-co col-1"><strong>No.</strong></th>
            <th class="text-center body-1-gov-co col-7"><strong>Descripcion</strong></th>


          </tr>
        </thead>
        <tr *ngFor="let file of listFiles; let i = index">
          <td class="text-center body-2-gov-co ">{{i + 1}}</td>
          <td class="text-center body-2-gov-co">

            <div class="mt-3 row col-12 ">

              <div class="col-4 mt-3" (click)="openDocument(file?.vcPath)">
                <i class="p-2 bi bi-file-earmark-pdf icon"></i>
              </div>

              <div class="col-6 ml-3 mt-3" (click)="openDocument(file?.vcPath)" style="cursor: pointer;">
                <span class="d-block">{{file?.vcTipoDocumento }}</span>

              </div>


            </div>

          </td>


        </tr>
      </table>
    </div>
    <div class="row pt-3">
      <div [style.display]="this.isError == false ? 'none' : 'block'" class="col-12 alert gov-co-alert-error"
        style="font-size: small; height: auto;">
        <span><img src="assets/icons/popup-error.png" alt="Warning" /></span>
        {{this.msgError}}
      </div>
    </div>
    <div class="documentViewer">
      <app-document-viewer [isLoadingDocument]="isLoadingDocument" [isVisible]="viewDocument"
        [document]="urlSafeDocument" (closeButtonEvent)="closeDocument()"></app-document-viewer>
    </div>

    <div class="d-flex align-items-center justify-content-center row mt-3 mx-auto " *ngIf="visualizar">
      <div class="col-4"><label for="fileInpitControl" class="add-file-btn-revision float-left ">
          ¿Se realizó seguimiento?
        </label></div>

      <div class="col-6">
        <div class="mx-1 p-1 d-inline-block ">
          <input [disabled]="seguimiento" style="height: auto;" [checked]="aprobado" value="si" type="radio" name="seguimiento" (change)="change(true)">
          <label>Si</label>
        </div>
        <div class="mx-1 p-1 ms-2 my-4 d-inline-block ">
          <input [disabled]="seguimiento" style="height: auto;" [checked]="!aprobado" value="no" type="radio" name="seguimiento" (change)="change(false)">
          <label>No</label>
        </div>

      </div>

    </div>

    <div class="row mt-3 justify-content-center text-center mx-auto" *ngIf="visualizar && !vista">
      <div class="col-lg-12">
        <button type="button" class="btn btn-primary" (click)="Guardar()" style="margin-top: 33px;">Guardar</button>
      </div>
    </div>

  </form>
</section>
