<div>
  <div class="d-flex justify-content-end mb-4">
    <button type="button" class="btn-govco fill-btn-govco ms-4">Cargar archivo</button>
  </div>
  <div class="review-document">
    <div class="col-xm-4 col-sm-6 col-md-12">
      <table class="table table-general table-basic" aria-describedby="tableDescBasic">
        <thead class="encabezado-tabla">
          <tr>
            <th scope="col">
              No.
            </th>
            <th scope="col">
              Archivo soporte de revisión
            </th>
            <th scope="col">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody class="contenido-tablas" style="background-color: white;">
          <tr>
            <td class="contenido-numerico">
              1
            </td>
            <td>
              Acta de revisión en campo.pdf
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <span class="govco-file-pdf display-6" style="color: rgb(221, 221, 221);"></span>
                  <span class="govco-times-circle display-6" style="color: red;"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
