import { Component, ViewChild } from '@angular/core';
import { RequestInfoComponent } from '../request-info/request-info.component';
import { FormBuilder } from '@angular/forms';
import { Properties } from 'src/app/core/services/properties';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-cancel',
  templateUrl: './request-cancel.component.html',
  styleUrls: ['./request-cancel.component.scss']
})
export class RequestCancelComponent {

  botonEnviarBloqueado = true;
  isNaturalPerson: boolean;

  @ViewChild(RequestInfoComponent) formularioHijo: RequestInfoComponent;

  constructor(public fb: FormBuilder, public properties: Properties) {
  }

  bloquearBotonEnviar(formularioEstaVacio: boolean): void {
    this.botonEnviarBloqueado = formularioEstaVacio;
  }

  enviarFormulario(): void {
    if (this.botonEnviarBloqueado) {
      Swal.fire('Campos vacíos');
    } else {
      Swal.fire('Campos completos');
      const valoresFormulario = this.formularioHijo.requestInfoForm.value;

      if (this.isNaturalPerson) {
        this.properties.recordCancelForm(valoresFormulario).subscribe(
          response => {
            Swal.fire('Solicitud de cancelación enviada correctamente:', response);
          },
          error => {
            Swal.fire('Error al enviar la solicitud de cancelación:', error);
          }
        );
      } else {
        this.properties.recordCancelFormJ(valoresFormulario).subscribe(
          response => {
            Swal.fire('Solicitud de cancelación (Jurídica) enviada correctamente:', response);
          },
          error => {
            Swal.fire('Error al enviar la solicitud de cancelación (Jurídica):', error);
          }
        );
      }
    }
  }

  manejarEstadoFormulario(): void {
    this.formularioHijo.notificarEstadoFormulario();
  }
}
