<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-8">

        <h3 class="h3-tipografia-govco">Trámite: Registro y autorización de títulos en el área de la salud.</h3>
        <br>

        <p class="text2-tipografia-govco" style="text-align: justify" *ngIf="!showRequestForm && !showResumeRequestSaved">
          El propósito de este trámite es obtener autorización para el ejercicio de la profesión y ocupación, válida
          para todo el territorio nacional, en el área de la salud como técnico, tecnólogo, o universitario, de los
          títulos obtenido en la Ciudad de Bogotá D.C, excepto convalidaciones de titulación Extranjera.
          Nota: Las profesiones exceptuadas son: Medicina, Jefe de Enfermería, bacteriología, químicos farmacéuticos,
          fisioterapia, fonoaudiología, instrumentadores quirúrgicos, optometría, terapia respiratoria, ondotología,
          nutrición y dietética y terapia ocupacional. Lo anterior teniendo en cuenta que el registro y autorización de
          título de dichas profesiones, fue delegada a sus respectivos cuerpos colegiados.</p>
        <br>

        <!-- Acciones tramite -->
        <div class="row" *ngIf="!showRequestForm && !showResumeRequestSaved">
          <div class="col-sm-12 col-md-6 col-lg-4 btn-act" style="padding-left: 5%;">
            <!-- Tarjeta tipo módulo -->
            <a class="module-tarjeta-govco" title="Crea una solicitud" (click)="showRequestForm=true">
              <div class="header-tarjeta-govco">
                <h5>Crear solicitud</h5>
              </div>
              <hr>
              <div class="body-tarjeta-govco">
                <p>Primera vez</p>
              </div>
            </a>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4 btn-act" style="padding-left: 5%" >
            <!-- Tarjeta tipo módulo -->
            <a class="module-tarjeta-govco" title="Bandeja con los trámites realizados" (click)="redirectionDashboard()">
              <div class="header-tarjeta-govco">
                <h5>Consultar solicitud</h5>
              </div>
              <hr>
              <div class="body-tarjeta-govco" >
                <p>Consulte el estado de su solicitud con su ID de solicitud (Número de radicado)</p>
              </div>
            </a>
          </div>

        </div>
        <!-- Acciones tramite -->

        <!-- Crear solicitud -->
        <form role="form" [formGroup]="requestForm" *ngIf="showRequestForm && !showResumeRequestSaved">
          <h4 class="h4-tipografia-govco title-1">Datos de la solicitud</h4>
          <br>

          <div class="accordion-govco" id="accordionParent">

            <h2 class="accordion-header" id="accordionIconoPanels-childOne">
              <button class="button-accordion-govco collapsed"
                      type="button"
                      data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseOne"
                      aria-expanded="false" aria-controls="accordionIconoPanels-collapseOne">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Registro de Información</h4>
                  </span>
              </button>
            </h2>
            <div id="accordionIconoPanels-collapseOne" class="accordion-collapse collapse"
                 aria-labelledby="accordionIconoPanels-childOne" data-bs-parent="#accordionParent">
              <div class="body-accordion-govco">
                <app-request-data></app-request-data>
              </div>
            </div>

            <h2 class="accordion-header" id="accordionIconoPanels-childTwo">
              <button class="button-accordion-govco collapsed"
                      type="button"
                      data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseTwo"
                      aria-expanded="false" aria-controls="accordionIconoPanels-collapseTwo">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Documentos adjuntos</h4>
                  </span>
              </button>
            </h2>
            <div id="accordionIconoPanels-collapseTwo" class="accordion-collapse collapse"
                 aria-labelledby="accordionIconoPanels-childTwo" data-bs-parent="#accordionParent">
              <div class="body-accordion-govco">
                <app-attachments></app-attachments>
              </div>
            </div>

          </div>

          <div class="col-12" style="margin: 5% 0 5% 0; text-align: center">
            <button type="button" class="btn-govco fill-btn-govco"
                    (click)="showRequestForm=false" [disabled]="sending"
                    style="margin-right: 1%;">Volver</button>
            <button type="button" class="btn-govco fill-btn-govco"
                    (click)="saveRequest()" [disabled]="sending">Guardar</button>
          </div>

        </form>

        <!-- Pantalla solicitud creada exitosamente -->
        <div *ngIf="showResumeRequestSaved">
          <app-resume-request-saved [numberFiled]="numberFiled"></app-resume-request-saved>
        </div>

      </div>

      <div class="col-sm-12 col-md-4">
        <app-service-area></app-service-area>
      </div>
    </div>
  </div>
</div>
