<form [formGroup]="reportsform" class="mt-3">

  <br>
  <div class="col-xxl-6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
    <h2>Filtro Consulta y Descarga Excel Trámites Aprobados</h2>
  </div>
  <div class="col-xxl-6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
    <h3>Autorización de Títulos en Área de la Salud</h3>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12 col-md-3">
      <div class="entradas-de-texto-govco">
        <label for="begindate">Fecha Inicial</label>
        <input type="date" id="begindate"
               placeholder="Fecha Seguimiento Inicial" formControlName="begindate"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-3">
      <div class="entradas-de-texto-govco">
        <label for="enddate">Fecha Final</label>
        <input type="date" id="enddate"
               placeholder="Fecha Seguimiento Final" formControlName="enddate"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="entradas-de-texto-govco">
        <label>Tipo reporte</label>
        <select class="form-select" id="selector" aria-label="Default select example"
                formControlName="selector"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="">Consultar trámites por N° de Identificación</option>
          <option value="aprobado">Consultar trámites Aprobados</option>
          <option value="aclarado">Consultar trámites Aclarados</option>
          <option value="negado">Consultar trámites Rechazados</option>
          <option value="aclaración">Consultar trámites en Recurso Reposición o Aclaración</option>
          <option value="negado">Consultar trámites Negados</option>
          <option value="anular">Consultar trámites Anulados</option>
          <option value="Registro de Solicitud">Consultar trámites Solicitados</option>
          <option value="">Resolucion 3030 de 2014 s</option>
          <option value="">Resolucion 3030 de 2014 Aclarados</option>
        </select>
      </div>
    </div>

  </div>

  <div class="row mt-3 justify-content-center" style="text-align: center">
    <div class="col-sm-5 col-md-6 col-lg-3">
      <button     type="button" style="margin-left: 7rem;" class="btn-govco fill-btn-govco">Consultar</button>
    </div>
    <div class="col-sm-5 col-md-7 col-lg-7">
      <button (click)="getDashboard('filtro')" type="button" style="margin-left: 7rem;" class="btn-govco fill-btn-govco"> Descargar Excel (CSV)</button>
    </div>

  </div>
  <br><br>

  <div class="col-10">
    <div class="alert alert-primary text3-tipografia-govco" role="alert">
      <h1>¡Apreciado Usuario!</h1>
      <p>La consulta y el reporte de trámites en estado de Aprobación, es por rango de fecha de acuerdo
        de la resolución generada por el sistema.
      </p>
      <p>Los trámites visualizados corresponde a los últimos 30 dias desde la fecha actual.</p>
    </div>
  </div>

  <br>
  <div class="row col-xxl-7 col-md-7 col-lg-7 col-sm-7 col-xs-7 entradas-de-texto-govco">
    <div class="col-xxl-1 col-md-1 col-lg-1 col-sm-1 col-xs-1">
      <p>Buscar:</p>
    </div>
    <div class="col-xxl-4 col-md-4 col-lg-4 col-sm-4 col-xs-4">
      <input type="text" id="textfilter" formControlName="textfilter" />
    </div>
    <div class="col-xxl-2 col-md-2 col-lg-2 col-sm-2 col-xs-2">
      <button (click)="getDashboard('text')" type="button" class="btn-govco fill-btn-govco">Filtrar</button>
    </div>
  </div>
  <br>

  <div class="row">


    <div class="row mx-auto ">
      <div class="table ">
        <div class="table-responsive">
          <table class="table table-striped " aria-describedby="tabla-reportes">
            <tr>
              <th> Id Solicitud </th>
              <th> No Doc de Identidad</th>
              <th> Tipo de Documento</th>
              <th>Nombres y Apellidos</th>
              <th>Tipo de Título</th>
              <th>Fecha de Registro Solicitud</th>
              <th>Acciones Permitidas</th>
              <th>Estado de la Solicitud</th>
              <th>PDF</th>
            </tr>
            <tbody>
            <tr *ngFor="let item of tableFilter; let i= index">
              <td>{{item.idfiled}}</td>
              <td> {{item.idnumber}}  </td>
              <td>{{item.iddoctype}}</td>
              <td>{{item.aplicantname}}</td>
              <td>{{item.titletype}}</td>
              <td>{{item.fileddate| date: 'dd-MM-yyyy'}}</td>
              <td class="text-justify">
                <a> <u class="govco-edit-1">Validar</u> </a>
              </td>
              <td *ngIf="item.statusstring != null">{{item.statusstring}}</td>
              <td *ngIf="item.resolutionpath == ''"></td>
              <td *ngIf="item.resolutionpath != ''">
                <img alt="icono-pdf-external" [src]="urlIconExternalWindow" class="img-fluid icon">
                <img alt="icono-pdf-actual" [src]="urlIconActualWindow" class="img-fluid icon">

              </td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>

  </div>


</form>


