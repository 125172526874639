<form role="form" [formGroup]="legalForm">
  <div class="row">
    <legend>
      <h3>Registro Persona Jurídica</h3>
    </legend>

    <div class="alert alert alert-danger" role="alert">
      <p [innerHTML]="cadenaInicioRegistro"></p>
    </div>

    <div class="accordion-govco" id="accordionParent">

      <div class="item-accordion-govco">
        <h2 class="accordion-header" id="accordionIconoPanels-childOne">
          <button class="button-accordion-govco collapsed"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseOne"
                  aria-expanded="false" aria-controls="accordionIconoPanels-collapseOne">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Datos básicos</h4>
                  </span>
          </button>
        </h2>
        <div id="accordionIconoPanels-collapseOne" class="accordion-collapse collapse"
             aria-labelledby="accordionIconoPanels-childOne" data-bs-parent="#accordionParent">
          <div class="body-accordion-govco">

            <div class="col-sm-12 col-md-9">
              <div class="entradas-de-texto-govco">
                <label for="razonsocial">Razón Social <span class="required">(*)</span></label>
                <input type="text" id="razonsocial" placeholder="RAZÓN SOCIAL"
                       aria-describedby="input-razonsocial"/>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="razon-social-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('razonsocial') }}
               </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-9">
              <div class="entradas-de-texto-govco">
                <label for="tipoIdedentificacion">Tipo de Identificación <span class="required">(*)</span></label>
                <select class="form-select" id="tipoIdedentificacion" aria-label="Default select example">
                  <option value="" selected>NIT</option>
                </select>
              </div>
            </div>

            <div class="col-sm-12 col-md-9">
              <div class="entradas-de-texto-govco">
                <label for="nit">NIT <span class="required">(*)</span></label>
                <input type="text" id="nit" placeholder="NÚMERO DE NIT"
                       aria-describedby="input-numero-nit"/>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="nit-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('nit') }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="item-accordion-govco">
        <h2 class="accordion-header" id="accordionIconoPanels-childTwo">
          <button class="button-accordion-govco collapsed"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseTwo"
                  aria-expanded="false" aria-controls="accordionIconoPanels-collapseTwo">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Representante Legal</h4>
                  </span>
          </button>
        </h2>
        <div id="accordionIconoPanels-collapseTwo" class="accordion-collapse collapse"
             aria-labelledby="accordionIconoPanels-childTwo" data-bs-parent="#accordionParent">
          <div class="body-accordion-govco">
            <app-basic-data-citizen></app-basic-data-citizen>

            <div id="div_enviar" class="col-12 col-md-12 pl-4 mt-4">
              <div class="col-sm-12">
                <input type="checkbox" name="acepta_terminos" id="acepta_terminos"
                       formControlName="checkBoxDatosPersonales"
                       [ngClass]="{'error': isTouchedField(legalForm, 'checkBoxDatosPersonales')}">
                He leído y acepto la politica de protección y tratamiento de datos personales
                <span class="required">(*)</span>
              </div>
              <span
                class="error-texto-govco alert-entradas-de-texto-govco" id="checkBoxDatosPersonales-note" role="alert"
                aria-live="assertive">
            {{ getErrorMessage('checkBoxDatosPersonales') }}
        </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row mt-3 ">
    <div class="col-sm-12 col-md-9 col-lg-3">
      <button  (click)="cancelar()" type="button" style="margin-left: 7rem;" class="btn-govco fill-btn-govco">Cancelar</button>
    </div>
    <div class="col-sm-12 col-md-9 col-lg-3">
      <button  (click)="guardar()" type="button" style="margin-left: 1rem;" class="btn-govco fill-btn-govco">Guardar</button>
    </div>
  </div>

</form>
