import { CoordinatorTabComponent } from './pages/coordinator/coordinator-tab/coordinator-tab.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { ROUTES } from 'src/app/core/enums';
import { RequestManagementValidatorComponent } from './pages/validator/request-management-validator/request-management-validator.component';
import { RequestManagementCoordinatorComponent } from './pages/coordinator/request-management-coordinator/request-management-coordinator.component';
import { RequestManagementAssistantManagerComponent } from './pages/assistant-manager/request-management-assistant-manager/request-management-assistant-manager.component';
import { AssistantManagerTabComponent } from './pages/assistant-manager/assistant-manager-tab/assistant-manager-tab.component';
import { ValidatorTabComponent } from './pages/validator/validator-tab/validator-tab.component';
import { ReplenishmentResourceComponent } from './components/replenishment-resource/replenishment-resource.component';
import { CapacitationTabComponent } from './pages/capacitation/capacitation-tab/capacitation-tab.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: ROUTES.Dashboard,
        component: DashboardComponent,
      },
      {
        path: ROUTES.REQUEST_MANAGEMENT_VALIDATOR+"/:idRequest/:type",
        component: RequestManagementValidatorComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites'
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_VALIDATOR
            },
            {
              label: 'Revisar solicitud'
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_MANAGEMENT_COORDINATOR+"/:idRequest/:type",
        component: RequestManagementCoordinatorComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites'
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos'
            },
            {
              label: 'Verificar solicitud'
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_MANAGEMENT_ASSISTANT_MANAGER+"/:idRequest/:type",
        component: RequestManagementAssistantManagerComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites'
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_ASSISTANT_MANAGER
            },
            {
              label: 'Para firma'
            }
          ]
        }
      },
      {
        path: ROUTES.DASHBOARD_ASSISTANT_MANAGER,
        component: AssistantManagerTabComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_ASSISTANT_MANAGER,
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',

            }
          ]
        }
      },
      {
        path: ROUTES.Replenishment,
        component:ReplenishmentResourceComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_VALIDATOR,
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',

            }
          ]
        }
      },
      {
        path: ROUTES.DASHBOARD_VALIDATOR,
        component: ValidatorTabComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_VALIDATOR,
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',

            }
          ]
        }
      },
      {
        path: ROUTES.DASHBOARD_COORDINATOR,
        component: CoordinatorTabComponent,
        data: {
          breadcrumb: [
            {
              label: 'Bandeja de trámites',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_COORDINATOR,
            },
            {
              label: 'Autorizacion para capacitadores de manipulación de alimentos',

            }
          ]
        }
      },{
        path: ROUTES.DASHBOARD_CAPACITATION,
        component: CapacitationTabComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_CAPACITATION,
            },
            {
              label: 'Consultar solicitud',

            },{
              label: 'Registro de capacitaciones'
            }
          ]
        }
      },

    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [ RouterModule ]
})
export class DashboardRoutingModule { }
