import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ROUTES } from '@core-app/enums';
import { Usuario } from '@core-app/services/usuario';
import * as saveAs from 'file-saver';
import Swal from 'sweetalert2';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit{
  selectedTypeName: string = '';

  reportForm: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private usuario:Usuario){
    this.reportForm = this.fb.group({
        fechaDesde: [''],
        fechaHasta: [''],
        type: ['Seleccionar']
    });
  }

  ngOnInit(): void {
  }
  back(){
    window.history.back();

  }


  link(){
    this.router.navigateByUrl(
      ROUTES.MainPage + '/' + ROUTES.Request + '/' + ROUTES.SearchRequest
    );
  }

  filter() {
      const fechaDesde = this.reportForm.get('fechaDesde').value;
      const fechaHasta = this.reportForm.get('fechaHasta').value;
      const valorSeleccionado = this.reportForm.get('type').value;

      if (valorSeleccionado === '1') {
          this.usuario.dateSearchAdmin(fechaDesde, fechaHasta).subscribe(response => {
          this.downloadFile(response, 'Actos administrativos generados');
          this.showSuccessAlert('Búsqueda exitosa', 'Autorizaciones canceladas');
          this.reportForm.reset();
        });
      }
      else if (valorSeleccionado === '2'){
          this.usuario.dateAutorizaciones(fechaDesde, fechaHasta).subscribe(response => {
          this.downloadFile(response, 'Autorizaciones canceladas');
          this.showSuccessAlert('Búsqueda exitosa', 'Autorizaciones canceladas');
          this.reportForm.reset();
        });
      }
      else if (valorSeleccionado === '3') {
          this.usuario.dateSeguimientoCapacitaciones(fechaDesde, fechaHasta).subscribe(response => {
          this.downloadFile(response, 'Seguimiento Capacitaciones');
          this.showSuccessAlert('Búsqueda exitosa', 'Autorizaciones canceladas');
          this.reportForm.reset();
        });
      }
      else if (valorSeleccionado === '4') {
          this.usuario.dateSuspendidos(fechaDesde, fechaHasta).subscribe(response => {
          this.downloadFile(response, 'Listado de capacitadores autorizados INVIMA');
          this.showSuccessAlert('Búsqueda exitosa', 'Autorizaciones canceladas');
          this.reportForm.reset();
        });
      }
      else if (valorSeleccionado === '5') {
        this.usuario.dateCancel(fechaDesde, fechaHasta).subscribe(response => {
        this.downloadFile(response, 'Listado de capacitadores suspendidos INVIMA');
        this.showSuccessAlert('Búsqueda exitosa', 'Autorizaciones canceladas');
        this.reportForm.reset();
      });

      this.reportForm.reset();
    }
  }

  showSuccessAlert(title: string, message: string) {
    Swal.fire({
      icon: 'success',
      text: "Se descargo su archivo excel",
      confirmButtonText: 'Aceptar'
    });
  }

  private downloadFile(response: any, typeName: string) {
    let blob: Blob = response.body as Blob;
    const fileName = `${typeName}.xlsx`;
    let a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    a.click();
  }
}
