<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12">
      <button class="btn btn-round back">Regresar</button>
  </div>
</div>

<div class="row mt-5">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12 d-flex align-items-center justify-content-between">
      <p class="text_info">Información de capacitaciones a realizar</p>
      <a (click)="toggleDiv()">
          <i class="fa-solid" [ngClass]="{'fa-square-plus': !showDiv, 'fa-square-minus': showDiv}" [class.icon-styling]="true"></i>
      </a>
  </div>
</div>

<div class="mt-5 transition-div" [class.hidden]="!showDiv">
  <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12">
          <p class="text_info">Información de la capacitación</p>
          <p class="text">* Campos obligatorios</p>
      </div>
  </div>

  <form>
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-122">
              <div class="form-row">
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xxl-4">
                      <label for="inputEmail4">* Capacitador</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xxl-4">
                      <label for="inputEmail4">* Público objetivo</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xxl-4">
                      <label for="inputEmail4">* Número de asistentes</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
              </div>
              <div class="form-row mt-3">
                  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xxl-12">
                      <label>Tema de capacitación a realizar</label>
                      <textarea name="" id="" cols="30" rows="3" class="form-control"></textarea>
                  </div>
                  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xxl-12 mt-3">
                      <label>* Metodología </label>
                      <textarea name="" id="" cols="30" rows="3" class="form-control"></textarea>
                  </div>
              </div>
          </div>
      </div>

      <div class="row mt-5">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12">
              <p class="text_info">Horarios de capacitación</p>
              <p class="text">* Campos obligatorios</p>
          </div>
      </div>

      <div class="form-row">
          <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xxl-3">
              <label for="inputEmail4">*Fecha de capacitación</label>
              <input type="date" class="form-control" id="inputEmail4" />
          </div>
          <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xxl-3">
              <label for="inputEmail4">*Hora de inicio</label>
              <input type="time" class="form-control" id="inputEmail4" />
          </div>
          <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xxl-3">
              <label for="inputEmail4">*Hora de finalización</label>
              <input type="time" class="form-control" id="inputEmail4" />
          </div>
          <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xxl-3">
              <button class="btn btn-round btn-high text-white mt-3 record">Registrar</button>
          </div>
      </div>
      <div class="row mt-5">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-8">
              <table class="table mt-4" id="table_capacitation" [class.hidden]="!showDivHour">
                  <tr>
                      <th>No.</th>
                      <th>Fecha</th>
                      <th>Hora inicio</th>
                      <th>Hora fin</th>
                      <th>Acciones permitidas</th>
                  </tr>
                  <tbody>
                      <tr>
                          <td>Descargar resolución</td>
                          <td>Ver detalle</td>
                          <td>Ver detalle</td>
                          <td>aaa</td>
                          <td>
                              <a href=""><i class="fa-solid fa-square-xmark remove_icon"></i></a>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xxl-4">
              <a (click)="toggleDivHour()">
                  <i class="fa-solid" [ngClass]="{'fa-square-minus': !showDivHour, 'fa-square-plus': showDivHour}" [class.icon-styling]="true"></i>
              </a>
          </div>
      </div>
      <div class="row mt-5">
          <div class="col-lg-12">
              <p class="text_info">Lugar donde se realizará la capacitación</p>
              <p class="text">* Campos obligatorios</p>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-122">
              <div class="form-row">
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Vipa Parincipal</label>
                      <select id="selector-simple" class="selectpicker form-control" title="Escoger">
                        <option>Opción 1</option>
                        <option>Opción 2</option>
                        <option>Opción 3</option>
                        <option>Opción 4</option>
                        <option>Opción 5</option>
                      </select>
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Número</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Letra</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Bis</label>
                      <select id="selector-simple" class="selectpicker form-control" title="Escoger">
                        <option>Opción 1</option>
                        <option>Opción 2</option>
                        <option>Opción 3</option>
                        <option>Opción 4</option>
                        <option>Opción 5</option>
                      </select>
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Card</label>
                      <select id="selector-simple" class="selectpicker form-control" title="Escoger">
                        <option>Opción 1</option>
                        <option>Opción 2</option>
                        <option>Opción 3</option>
                        <option>Opción 4</option>
                        <option>Opción 5</option>
                      </select>
                  </div>
              </div>
              <div class="form-row mt-3">
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Complemento</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Letra</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Número</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Placa</label>
                      <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xxl-2">
                      <label for="inputEmail4">* Card</label>
                      <select id="selector-simple" class="selectpicker form-control" title="Escoger">
                        <option>Opción 1</option>
                        <option>Opción 2</option>
                        <option>Opción 3</option>
                        <option>Opción 4</option>
                        <option>Opción 5</option>
                      </select>
                  </div>
              </div>
              <div class="form-row mt-4">
                  <div class="form-group col-lg-10 col-md-10 col-sm-12 col-xxl-10">
                      <label for="">Dirección</label>
                      <input type="text" class="form-control" readonly>
                  </div>
                  <div class="form-group col-lg-10 col-md-10 col-sm-12 col-xxl-10 mt-2">
                      <label for="">Información adicional</label>
                      <input type="text" class="form-control">
                  </div>
              </div>
              <div class="form-row mt-3">
                  <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xxl-4">
                      <label for="">* Departamento</label>
                      <select id="selector-simple" class="selectpicker form-control" title="Escoger">
                        <option>Opción 1</option>
                        <option>Opción 2</option>
                        <option>Opción 3</option>
                        <option>Opción 4</option>
                        <option>Opción 5</option>
                      </select>
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xxl-4">
                      <label for="">* Ciudad</label>
                      <select id="selector-simple" class="selectpicker form-control" title="Escoger">
                        <option>Opción 1</option>
                        <option>Opción 2</option>
                        <option>Opción 3</option>
                        <option>Opción 4</option>
                        <option>Opción 5</option>
                      </select>
                  </div>
              </div>
          </div>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-5">
          <button class="btn btn-round btn-high text-white text-uppercase">Guardar</button>
      </div>
  </form>
</div>

<div class="row mt-5">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12">
      <table class="table mt-5">
          <tr>
              <th>Capacitador</th>
              <th>Dirección</th>
              <th>Número de asistantes</th>
              <th>Acciones permitidas</th>
          </tr>
          <tbody>
              <tr>
                  <td>Descargar resolución</td>
                  <td>Ver detalle</td>
                  <td>Ver detalle</td>
                  <td>
                      <a href=""><i class="fa-solid fa-square-xmark remove_icon"></i></a>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
