import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {FeatureComponent} from "./feature.component";
import {ROUTES} from "../core/enums";
import {AuthGuardService} from '../core/guards/auth-guard.service'
import { LoginComponent } from '../shared/pages/login/login.component';

const routes: Routes = [
  {
    path: '',
    component: FeatureComponent,
    children: [
      {
        path: ROUTES.Login,
        component: LoginComponent,
        canActivate: [() => inject(AuthGuardService).canActiveLogin()],
      },
      {
        path: ROUTES.Dashboard,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: ROUTES.Request,
        loadChildren: () => import('./request/request.module').then(m => m.RequestModule)
      },

    ]
  }
];

/*
const routes: Routes = [
  {
    path: '',
    component: FeatureComponent,
    data: {
      breadcrumb: [
        {
          label: 'Inicio',
          url: ''
        }
      ],
    },
    children: [
      {
        path: ROUTES.REGISTER,
        component: ScreenRegisterComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Registro',
              url: ROUTES.REGISTER
            }
          ]
        },
      },
      {
        path: ROUTES.REQUEST_DASHBOARD,
        component: UserRequestDashboardComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Bandeja de trámites',
              url: ROUTES.REQUEST_DASHBOARD
            }
          ]
        },
      },
      {
        path: ROUTES.REQUEST_DASHBOARD + "/" + ROUTES.REQUEST_MANAGEMENT,
        component: UserRequestManagementComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Bandeja de trámites',
              url: ROUTES.REQUEST_DASHBOARD
            },
            {
              label: 'Autorización para capacitadores de manipulación de alimentos'
            },
            {
              label: 'Validar de solicitud',
              url: ROUTES.REQUEST_MANAGEMENT
            }
          ]
        },
      },
      {
        path: ROUTES.REGISTER + "/" + ROUTES.REGISTRATION_NATURAL,
        component: NaturalPersonComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Registro',
              url: ROUTES.REGISTER
            },
            {
              label: 'Persona natural',
              url: ROUTES.REGISTER + "/" + ROUTES.REGISTRATION_NATURAL
            }
          ]
        },
      },
      {
        path: ROUTES.REGISTER + "/" + ROUTES.REGISTRATION_LEGAL,
        component: LegalPersonComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Registro',
              url: ROUTES.REGISTER
            },
            {
              label: 'Persona jurídica',
              url: ROUTES.REGISTER + "/" + ROUTES.REGISTRATION_LEGAL,
            }
          ]
        },
      },
      {
        path: ROUTES.DOCUMENTS_VALID,
        component: DocumentsValidationComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            }
          ]
        },
      },
      {
        path: ROUTES.CITIZEN,
        component: UserDeclarationComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización títulos área de la salud',
              url: ROUTES.CITIZEN
            }
          ]
        }
      },
      {
        path: ROUTES.CITIZEN + "/" +ROUTES.CREATE_REQUEST,
        component: UserRequestComponent,
        canDeactivate: [],
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización títulos área de la salud',
              url: ROUTES.CITIZEN
            },
            {
              label: 'Crear solicitud',
              url: ROUTES.CITIZEN + "/" +ROUTES.CREATE_REQUEST
            }
          ]
        },
      },
      {
        path: ROUTES.CITIZEN + "/" + ROUTES.PERSON_DASHBOARD,
        component: UserDashboardComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización títulos área de la salud',
              url: ROUTES.CITIZEN
            },
            {
              label: 'Bandeja de entrada y gestión',
              url: ROUTES.CITIZEN + "/" +ROUTES.PERSON_DASHBOARD
            }
          ]
        },
      },
      {
        path: ROUTES.Validation,
        component: ValidationScreenComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Validar documentos',
              url: ''
            }
          ]
        },
      },
      {
        path: ROUTES.ValidatorDashboard,
        component: ValidatorsDashboardComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Bandeja de Entrada',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.ReportsDashboard,
        component: ReportPageComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_DATA,
        component:RequestDataComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_CANCEL,
        component:RequestCancelComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_INFO,
        component:RequestInfoComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_PERSON,
        component:RequestPersonComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.REQUEST_DOCUMENT,
        component:DocumentRequestComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.FIRST_NATURAL,
        component:FirstNaturalComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.RENEWAL_REQUEST,
        component:RenewalComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.MODIFY_REQUEST,
        component:ModifyComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      },
      {
        path: ROUTES.TRAINING,
        component:TypeTrainingComponent,
        data: {
          breadcrumb: [
            {
              label: 'Inicio',
              url: ''
            },
            {
              label: 'Solicitar autorización area de la salud',
              url: ''
            },
            {
              label: 'Menú Reportes',
              url: ''
            }
          ]
        }
      }
    ]
  }
];
*/

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule { }
