import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Usuario } from '@core-app/services/usuario';
import Swal from "sweetalert2";
import { ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '@core-app/enums';
import {  Router } from '@angular/router';
import { Location } from '@angular/common';
import {RegisterService} from "@core-app/services/register.service";
import {RequestService} from "@core-app/services/request.service";
import { AppBaseComponent } from '@core-app/utils';
import { CustomValidators } from '@core-app/utils/custom-validators';

@Component({
  selector: 'app-record-capacitation',
  templateUrl: './record-capacitation.component.html',
  styleUrls: ['./record-capacitation.component.scss']
})

export class RecordCapacitationComponent extends AppBaseComponent implements OnInit{
  recordCapacitation: FormGroup;
  recordDate: FormGroup;
  @ViewChild('selectOption') selectOption: ElementRef;
  @ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;
  @ViewChild('selectLetter') selectLetter: ElementRef;
  @ViewChild('selectother') selectother: ElementRef;
  @ViewChild('selectcoordi') selectcoordi: ElementRef;
  @ViewChild('numberInputComplement') numberInputComplement: ElementRef<HTMLInputElement>;
  @ViewChild('selectcoordilettertwo') selectcoordilettertwo: ElementRef;
  @ViewChild('selectcard') selectcard: ElementRef;
  //@ViewChild('numberInputComplementNumber') numberInputComplementNumber: ElementRef<HTMLInputElement>;
  @ViewChild('platenumber') platenumber: ElementRef<HTMLInputElement>;

  @ViewChild('addressInput') addressInput: ElementRef;

  public listName: any = [];
  public idTrainer: any;
  registros: any[] = [];
  public listAll: any = [];
  mostrarDiv: boolean = false;
  idlist:string;
  public Departamentos: any[] = [];
  public Municipios: any[] = [];
  flag: boolean = false;

  public actualday:string="";
  constructor(private usuario: Usuario, private form: FormBuilder,private router: Router,
              private location: Location,
              private requestservice: RequestService,
              private RegisterService: RegisterService) {
                super();
    this.recordCapacitation = this.form.group({
      trainer: ["", Validators.required],
      assistants: ["", Validators.required],
      assistantsnumber: ["", Validators.required],
      theme: ["", Validators.required],
      methodology: ["", Validators.required],
      date: [""],
      initial: [""],
      final: [""],
      address: [""],
      aditional: [""],
      depto: ["", Validators.required],
      city: ["", Validators.required],
      numero: ["", Validators.required],
      //numero2: ["", Validators.required],
      complemento: ["", Validators.required],
      placa: ["", Validators.required],
    });

    this.recordDate = this.form.group({
      date: ["", CustomValidators.numberDateFuture],
      initial: [""],
      final: [""],
    });



  }

  toggleDiv() {
    this.mostrarDiv = !this.mostrarDiv;
  }

  back() {
    window.history.back();
  }

  allName() {
    const idSolicitudGuardado = localStorage.getItem('idSolicitud');
    this.usuario.getAllName(idSolicitudGuardado).subscribe((values) => {
      this.listName = values;
      console.log("datos capacitación", this.listName);
    });
    this.requestservice.GetRequest(Number.parseInt( idSolicitudGuardado)).subscribe((values) => {


      //let datefinal:Date = new Date(values.data.vcFechaSolicitud);
      let datefinal:Date = new Date();

      datefinal.setDate(datefinal.getDate()+10);

      // Get year, month, and day part from the date
      let yearfinal = datefinal.toLocaleString("default", {year: "numeric"});
      let monthfinal = datefinal.toLocaleString("default", {month: "2-digit"});
      let dayfinal = datefinal.toLocaleString("default", {day: "2-digit"});
      // Generate yyyy-mm-dd date string
      let formattedDatefinal = yearfinal + "-" + monthfinal + "-" + dayfinal;


      this.actualday=formattedDatefinal;
    });
  }

  bloqueo = (e:any) => {
    console.log(e);
    return false;
  }

  allListById() {
    const idSolicitudGuardado = localStorage.getItem('idSolicitud');
    console.log(idSolicitudGuardado);
    if(idSolicitudGuardado!=null)
    {
      this.usuario.getListCapacitationSolicitud(idSolicitudGuardado).subscribe(
        (values) => {
          this.listAll = values;
          console.log("datos", this.listAll);
          this.idlist = this.listAll.data[0].idCapacitacion;
          console.log("neuvo array" , this.idlist);
        },
        (error) => {
          console.error("Error al obtener datos de capacitación", error);
        }
      );
    }

    this.RegisterService.getDepartamentos().subscribe(
      (values) => {
        this.Departamentos=values.data;
      },
      (error) => {
        console.error("Error al obtener datos de capacitación", error);
      }
    );
  }


  ngOnInit(): void {
    this.allName();
    this.allListById();
  }

  recargarPagina() {
    this.allListById();
    this.recordCapacitation.get('depto').setValue("");
    this.Municipios=[];
    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.REQUEST_CAPACITATION}/${localStorage.getItem('idSolicitud')}`);
    //this.location.go(this.location.path()); // Recarga la página actual
  }
  updateAddress(): string {
    const selectOption = this.selectOption.nativeElement.value;
    const numberInput = this.numberInput.nativeElement.value;
    const selectLetter = this.selectLetter.nativeElement.value;
    const selectother = this.selectother.nativeElement.value;
    const selectcoordi = this.selectcoordi.nativeElement.value;
    const numberInputComplement = this.numberInputComplement.nativeElement.value;
    const selectcoordilettertwo = this.selectcoordilettertwo.nativeElement.value;
    //const numberInputComplementNumber = this.numberInputComplementNumber.nativeElement.value;
    const platenumber = this.platenumber.nativeElement.value;

    const selectcard = this.selectcard.nativeElement.value;
    const address = selectOption + ' ' + numberInput + ' ' + selectLetter + ' # ' + selectother + ' ' + selectcoordi + ' ' + numberInputComplement + ' ' + selectcoordilettertwo + ' ' /*+ numberInputComplementNumber + ' '*/ + platenumber + ' ' + selectcard;
    this.addressInput.nativeElement.value = address;
    return address;
  }
  obtenerMunicipios() {

    this.recordCapacitation.get('city').setValue("");
    this.RegisterService.getMunicipios(this.recordCapacitation.get('depto').value).subscribe(
      (values) => {
        this.Municipios=values.data;
      },
      (error) => {
        console.error("Error al obtener municipios", error);
      }
    );
  }

  saveDate() {
    const date = this.recordDate.get('date').value;
    const initial = this.recordDate.get('initial').value;
    const final = this.recordDate.get('final').value;

    if (!date || !initial || !final) {
      Swal.fire({
        icon: 'error',
        text: 'Completa todos los campos antes de registrar.',
      });
      return;
    }

    if(!this.recordDate.valid){
      Swal.fire({
        icon: 'error',
        text: 'La fecha es invalida.',
      });
      return;
    }

    let finaltime:Date = new Date(`2000-01-01 ${final}`);;
    let initialltime:Date = new Date(`2000-01-01 ${initial}`);initial;

    if(finaltime<initialltime)
    {
      Swal.fire({
        icon: 'error',
        text: 'La hora final no puede ser menor que la inicial.',
      });
    }
    else {
      const nuevoHorario = {
        fecha: date,
        horaInicio: initial,
        horaFin: final,
      };
      console.log(nuevoHorario)
      this.registros.push(nuevoHorario);

      this.recordDate.reset();

      Swal.fire({
        icon: 'success',
        text: 'El horario se ha registrado correctamente.',
      });
    }


  }

  deleteDate(event: Event,registro:any) {
    event.preventDefault();
    this.registros.splice(registro,1);
    console.log(registro)



  }



  update(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo',
      cancelButtonText:'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.usuario.deleteStatusCapacitationSolicitud(id).subscribe(
          response => {
            console.log(response);
            Swal.fire({
              title: 'Eliminado',
              text: 'La capacitación ha sido eliminada',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.recargarPagina();
          },
          error => {
            console.error(error);
            Swal.fire({
              title: 'Eliminado',
              text: 'La capacitación no pudo ser eliminada',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.recargarPagina();
          }
        );
      }
    });
  }

  edit(id: number){
    localStorage.setItem('bandeja','ciudadano');
    this.router.navigate([`aut-cap/request/editar-capacitacion/${id}`]);
  }


  sendPost() {
    console.log(this.recordCapacitation)

    this.flag = true;

    if(this.recordCapacitation.valid)
    {

      if(this.registros.length==0)
      {
        Swal.fire({
          text: 'Debe agregar almenos 1 horario',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar',
        });
      }
      else {
        const horariosCapacitacionSolicitud = this.registros.map(registro => ({
          dtFechaCapacitacion: registro.fecha,
          horaInicio: registro.horaInicio,
          horaFin: registro.horaFin
        }));
        console.log('entro otra clase');

        const solicitud = {
          idCapacitacionSolicitud: 0,
          capacitadorId: this.recordCapacitation.get('trainer').value,
          vcPublicoObjetivo: this.recordCapacitation.get('assistants').value,
          intNumeroAsistentes: this.recordCapacitation.get('assistantsnumber').value,
          vcTemaCapacitacion: this.recordCapacitation.get('theme').value,
          vcMetodologiaCapacitacion: this.recordCapacitation.get('methodology').value,
          vcDireccion: this.updateAddress(),
          vcInformacionAdicional: this.recordCapacitation.get('aditional').value,
          departamentoId: this.recordCapacitation.get('depto').value,
          ciudadId: this.recordCapacitation.get('city').value,
          horariosCapacitacionSolicitud: horariosCapacitacionSolicitud
        };
        console.log(solicitud);

        this.usuario.recordCapacitation(solicitud).subscribe(
          response => {
            this.registros = [];
            this.recordCapacitation.reset();
            this.recargarPagina();
            console.log(response, "respuesta del servidor");
            this.flag = false;
            Swal.fire({
              text: 'Capacitación registrada con éxito.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar',
            }).then((result) => {
              if (result.isConfirmed) {

              }
            });
          },
          error => {
            console.error("Error en la solicitud:", error);
            Swal.fire({
              text: 'La capacitación no pudo ser registrada',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar',
            }).then((result) => {
              if (result.isConfirmed) {
                this.recordCapacitation.reset();
                this.recordDate.reset();
              }
            });
          }
        );
      }



    }
    else {
   this.recordCapacitation.markAsTouched()
      Swal.fire({
        text: 'Debe llenar todos los campos',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      });
    }


  }

  public getErrorMessageDate(field: string): string {
    let message = "";
    console.log(field);
    console.log(this.recordDate.get(field));
    if (this.isTouchedField(this.recordDate,field)) {
      console.log(this.recordDate?.get(field));
      if (this.recordDate?.get(field).hasError('invalidDate')) {
          message = "La fecha es invalida ";
      }
    }

    return message;

  }

  public getErrorMessage(field: string): string {



    let message;
    const onlyLetters: Array<string> = [];
    const onlyNumbers: Array<string> = [];

    if (this.isTouchedField(this.recordCapacitation,field)) {

      if (this.recordCapacitation?.get(field).hasError('required')) {
        message = 'Es Requerido';
      } else if (onlyLetters.includes(field)) {

        if (this.recordCapacitation?.get(field).hasError('pattern')) {
          message = 'Solo se admiten letras';
        } else if (this.recordCapacitation?.get(field).hasError('maxlength')) {
          message = `Excede el numero de caracteres permitidos`;
        }
        else if (this.recordCapacitation?.get(field).hasError('minlength')) {
          message = `Debe tener al menos 10 caracteres`;
        }


      } else if (onlyNumbers.includes(field)) {
        if (this.recordCapacitation?.get(field).hasError('pattern')) {
          message = 'Solo se admiten números';
        } else if (this.recordCapacitation?.get(field).hasError('minlength')) {
          message = `Debe tener al menos 10 caracteres`;
        } else if (this.recordCapacitation?.get(field).hasError('maxlength')) {
          message = `Permite hasta 11 caracteres`;
        }

      } else if (this.recordCapacitation?.get(field).hasError('email')) {
        message = 'No tiene el formato de un email';
      }  else if (this.recordCapacitation?.get(field).hasError('pattern')) {
        message = `Caracter no permitido ` ;
      }

    }

    return message;
  }


}
