import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureRoutingModule } from './feature-routing.module';
import {CoreModule} from "../core/core.module";
import { FeatureComponent } from './feature.component';
import {RegisterModule} from "./register/register.module";
import {RequestModule} from "./request/request.module";
import {SharedModule} from "../shared/shared.module";
import {ReportsModule} from "./reports/reports.module";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DashboardModule } from './dashboard/dashboard.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {environment} from "../../environments/environment";

@NgModule({
  declarations: [
    FeatureComponent,
  ],
  imports: [
    CommonModule,
    FeatureRoutingModule,
    CoreModule,
    SharedModule,
    RegisterModule,
    RequestModule,
    ReportsModule,
    PdfViewerModule,
    DashboardModule,
    NzModalModule,
    RecaptchaV3Module
  ], exports: [
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.KEY_RECAPTCHA_SITE_WEB }]


})
export class FeatureModule { }
