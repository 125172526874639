<div class="contenedor" [ngStyle]="{'grid-template-rows':withObservations?'1fr 1fr':'1fr'}">
  <div class="box1">
    <div>
      <span class="headline-m-govco">Recurso de reposición y apelación</span>
      <div (dblclick)="seeDocument(replenishmentDocument)" class="mt-3"
        style="cursor: pointer;display: grid; grid-template-columns: 20% 80%;background-color: #F4F4F4;width: 70%;padding-bottom: 15px; padding-top: 5px;">
        <div>
          <i class="bi bi-file-earmark-pdf icon"></i>
        </div>
        <div class="ml-3">
          <span class="d-block">{{replenishmentDocument.files.length==0?'no
            file':replenishmentDocument.files[0].name}}</span>
          <span class="d-block">{{replenishmentDocument.files.length==0?'0 Kb':replenishmentDocument.files[0].size/1000
            +' Kb'}}</span>
        </div>
      </div>
    </div>
    <div>
      <span class="headline-m-govco">Respuesta a recurso</span>
      <div class="mt-3" style="display: grid;grid-template-columns: 60% 40%;">
        <input accept=".pdf" (change)="onChange($event)" style="height: 50px;width: 90%;"
          class="form-control gov-co-form-control" id="formFileLg" type="file" #replenishmentDocument>
        <div
          style="display: grid; grid-template-columns: 20% 50% 30%;background-color: #F4F4F4;padding-bottom: 15px; padding-top: 5px;">
          <div>
            <i class="bi bi-file-earmark-pdf icon"></i>
          </div>
          <div style="cursor: pointer;" class="ml-3">
            <span class="d-block">{{replenishmentDocument.files.length==0?'no
              file':replenishmentDocument.files[0].name}}</span>
            <span class="d-block">{{replenishmentDocument.files.length==0?'0
              Kb':replenishmentDocument.files[0].size/1000 +' Kb'}}</span>
          </div>
          <div (click)="replenishmentDocument.value = null">
            <i class="bi bi-trash3-fill icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="withObservations" class="box2">
    <div>
      <div class="form-group gov-co-form-group">
        <span class="headline-m-govco">Observaciones</span>
        <textarea class="form-control" rows="5"></textarea>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center mt-5">
        <div data-toggle="modal" data-target="#mdConfirmation" class="text-center mx-5 seguimiento">
          <div style="border: 0.5px solid black; padding: 0px 30px;">
            <i class="bi bi-eye-fill" style="font-size: 35px; color: #3366cc;"></i>
          </div>
          <span style="font-size: 10px;" class="headline-xs-govco" data-toggle="modal"
            data-target="#mdConfirmation">Seguimiento y auditoría</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="documentViewer">
  <app-document-viewer [isVisible]="viewDocument" [document]="urlSafeDocument"
    (closeButtonEvent)="closeDocument()"></app-document-viewer>
</div>
