import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Usuario } from '@core-app/services/usuario';
import Swal from "sweetalert2";
import { ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {formatDate, Location, Time} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { id } from 'date-fns/locale';
import {RegisterService} from "@core-app/services/register.service";
import {ROUTES} from "@core-app/enums";
import {RequestService} from "@core-app/services/request.service";
import {timer} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DocumentsService} from "@core-app/services/documents.service";
import {environment} from "../../../../../environments/environment";
const { PROCEDURE_FILE_MANAGER_URI } = environment;

@Component({
  selector: 'app-edit-capacitation',
  templateUrl: './edit-capacitation.component.html',
  styleUrls: ['./edit-capacitation.component.scss']
})

export class EditCapacitationComponent {
  recordCapacitation: FormGroup;
  recordDate: FormGroup;
  @ViewChild('selectOption') selectOption: ElementRef;
  @ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;
  @ViewChild('selectLetter') selectLetter: ElementRef;
  @ViewChild('selectother') selectother: ElementRef;
  @ViewChild('selectcoordi') selectcoordi: ElementRef;
  @ViewChild('numberInputComplement') numberInputComplement: ElementRef<HTMLInputElement>;
  @ViewChild('selectcoordilettertwo') selectcoordilettertwo: ElementRef;
  @ViewChild('selectcard') selectcard: ElementRef;
  //@ViewChild('numberInputComplementNumber') numberInputComplementNumber: ElementRef<HTMLInputElement>;
  @ViewChild('platenumber') platenumber: ElementRef<HTMLInputElement>;

  @ViewChild('addressInput') addressInput: ElementRef;

  public listName: any = [];
  public idTrainer: any;
  registros: any[] = [];
  public listAll: any = [];
  visualizar: boolean = false;
  idlist: string;
  editList: any = [];

  listFiles: any[] = [];

  filenumber: any = "";
  idUser: any = "";

  msgError : string = "";
  isError : boolean = false;
  viewDocument:boolean =false;

  urlSafeDocument: SafeResourceUrl;
  isLoadingDocument:boolean = false;

  maximo:boolean = false;
  vista:boolean = false;
  seguimiento: boolean = false;
  aprobado: boolean = false;
  public actualday:string="";
  public Departamentos: any[] = [];
  public Municipios: any[] = [];

  constructor(private usuario: Usuario, private form: FormBuilder,private router: Router,private location: Location,
              private route: ActivatedRoute,
              private requestservice: RequestService,
              private sanitizer: DomSanitizer,
              private documentService: DocumentsService,
              private RegisterService: RegisterService) {




    this.recordCapacitation = this.form.group({
      trainer: ["", Validators.required],
      assistants: ["", Validators.required],
      assistantsnumber: ["", Validators.required],
      theme: ["", Validators.required],
      methodology: ["", Validators.required],
      date: [""],
      initial: [""],
      final: [""],
      address: [""],
      aditional: [""],
      depto: ["", Validators.required],
      city: ["", Validators.required]
    });

    this.recordDate = this.form.group({
      date: [""],
      initial: [""],
      final: [""],
    });





    if(localStorage.getItem('bandeja')=='validador')
    {
      this.visualizar=true;
      this.recordCapacitation.get('city').disable();
      this.recordCapacitation.get('depto').disable();
      this.recordCapacitation.get('assistantsnumber').disable();
      this.recordCapacitation.get('trainer').disable();

    }
  }

  back() {
    window.history.back();
  }

  allName() {
    const idSolicitudGuardado = localStorage.getItem('idSolicitud');
    this.usuario.getAllName(idSolicitudGuardado).subscribe((values) => {
      this.listName = values;
      console.log("datos capacitación", this.listName);
    });
    this.requestservice.GetRequest(Number.parseInt( idSolicitudGuardado)).subscribe((values) => {
      this.filenumber=values.data.vcRadicado;
      this.idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

      let datefinal:Date = new Date(values.data.vcFechaSolicitud);

      datefinal.setDate(datefinal.getDate()+12);

      // Get year, month, and day part from the date
      let yearfinal = datefinal.toLocaleString("default", {year: "numeric"});
      let monthfinal = datefinal.toLocaleString("default", {month: "2-digit"});
      let dayfinal = datefinal.toLocaleString("default", {day: "2-digit"});
      // Generate yyyy-mm-dd date string
      let formattedDatefinal = yearfinal + "-" + monthfinal + "-" + dayfinal;

      this.actualday=formattedDatefinal;
    });
  }

  allListById() {
    const idSolicitudGuardado = localStorage.getItem('idSolicitud');
    this.usuario.getListCapacitationSolicitud(idSolicitudGuardado).subscribe(
      (values) => {
        this.listAll = values;
        console.log("datos", this.listAll);
        this.idlist = this.listAll.data[0].idCapacitacion;
        console.log("nuevo array" , this.idlist);
      },
      (error) => {
        console.error("Error al obtener datos de capacitación", error);
      }
    );

    this.RegisterService.getDepartamentos().subscribe(
      (values) => {
        this.Departamentos=values.data;
      },
      (error) => {
        console.error("Error al obtener datos de capacitación", error);
      }
    );

  }


  ngOnInit(): void {
    this.allName();
    this.allListById();
    this.leadInput();
  }


  updateAddress(): string {
    const selectOption = this.selectOption.nativeElement.value;
    const numberInput = this.numberInput.nativeElement.value;
    const selectLetter = this.selectLetter.nativeElement.value;
    const selectother = this.selectother.nativeElement.value;
    const selectcoordi = this.selectcoordi.nativeElement.value;
    const numberInputComplement = this.numberInputComplement.nativeElement.value;
    const selectcoordilettertwo = this.selectcoordilettertwo.nativeElement.value;
    //const numberInputComplementNumber = this.numberInputComplementNumber.nativeElement.value;
    const platenumber = this.platenumber.nativeElement.value;

    const selectcard = this.selectcard.nativeElement.value;
    const address = selectOption + ' ' + numberInput + ' ' + selectLetter + ' # ' + selectother + ' ' + selectcoordi + ' ' + numberInputComplement + ' ' + selectcoordilettertwo + ' ' /*+ numberInputComplementNumber + ' '*/ + platenumber + ' ' + selectcard;
    this.addressInput.nativeElement.value = address;
    return address;
  }
  obtenerMunicipios() {

    this.recordCapacitation.get('city').setValue("");
    this.RegisterService.getMunicipios(this.recordCapacitation.get('depto').value).subscribe(
      (values) => {
        this.Municipios=values.data;
      },
      (error) => {
        console.error("Error al obtener municipios", error);
      }
    );
  }

  saveDate() {
    const date = this.recordDate.get('date').value;
    const initial = this.recordDate.get('initial').value;
    const final = this.recordDate.get('final').value;

    if (!date || !initial || !final) {
      Swal.fire({
        icon: 'error',
        text: 'Completa todos los campos antes de registrar.',
      });
      return;
    }

    let finaltime:Date = new Date(`2000-01-01 ${final}`);;
    let initialltime:Date = new Date(`2000-01-01 ${initial}`);initial;

    if(finaltime<initialltime)
    {
      Swal.fire({
        icon: 'error',
        text: 'La hora final no puede ser menor que la inicial.',
      });
    }
    else {
      const nuevoHorario = {
        fecha: date,
        horaInicio: initial,
        horaFin: final,
      };
      console.log(nuevoHorario)
      this.registros.push(nuevoHorario);

      this.recordDate.reset();

      Swal.fire({
        icon: 'success',
        text: 'El horario se ha registrado correctamente.',
      });
    }


  }
  deleteDate(event: Event,registro:any) {
    event.preventDefault();
    this.registros.splice(registro,1);
    console.log(registro)



  }
  sendPost() {
    console.log(this.recordCapacitation);

    if(this.recordCapacitation.valid)
    {
       if(this.registros.length==0)
       {
         Swal.fire({
           text: 'Debe agregar almenos 1 horario',
           icon: 'error',
           showCancelButton: false,
           confirmButtonColor: '#3085d6',
           confirmButtonText: 'Aceptar',
         });
       }
       else {
         const horariosCapacitacionSolicitud = this.registros.map(registro => ({
           dtFechaCapacitacion: registro.fecha,
           horaInicio: registro.horaInicio,
           horaFin: registro.horaFin
         }));

         console.log('entro por aca?')
         const solicitud = {
           idCapacitacionSolicitud: this.idlist,
           capacitadorId: this.recordCapacitation.get('trainer').value,
           vcPublicoObjetivo: this.recordCapacitation.get('assistants').value,
           intNumeroAsistentes: this.recordCapacitation.get('assistantsnumber').value,
           vcTemaCapacitacion: this.recordCapacitation.get('theme').value,
           vcMetodologiaCapacitacion: this.recordCapacitation.get('methodology').value,
           vcDireccion: this.updateAddress(),
           vcInformacionAdicional: this.recordCapacitation.get('aditional').value == null? "":this.recordCapacitation.get('aditional').value,
           departamentoId: this.recordCapacitation.get('depto').value,
           ciudadId: this.recordCapacitation.get('city').value,
           horariosCapacitacionSolicitud: horariosCapacitacionSolicitud
         };

         this.usuario.UpdateCapacitador(this.idlist,solicitud).subscribe(
           response => {
             this.registros = [];
             this.recordCapacitation.reset();
             this.recordCapacitation.get('depto').setValue("");
             this.Municipios=[];
             console.log(response, "respuesta del servidor");
             Swal.fire({
               text: 'Capacitación registrada con éxito.',
               icon: 'success',
               showCancelButton: false,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Aceptar',
             }).then((result) => {
               if (result.isConfirmed) {
                 this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.REQUEST_CAPACITATION}/${localStorage.getItem('idSolicitud')}`);
                 window.history.back();
               }
             });
           },
           error => {
             console.error("Error en la solicitud:", error);
             Swal.fire({
               text: 'La capacitación no pudo ser registrada',
               icon: 'error',
               showCancelButton: false,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Aceptar',
             }).then((result) => {
               if (result.isConfirmed) {
                 this.recordCapacitation.reset();
                 this.recordDate.reset();
               }
             });
           }
         );
       }
    }
    else {
      this.recordCapacitation.markAsTouched()
      Swal.fire({
        text: 'Debe llenar todos los campos',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      });
    }

  }

  leadInput(){
    this.route.params.subscribe(params => {
        const id = +params['id'];
        this.usuario.getInfoOnInput(id).subscribe((values)=> {

          this.editList  = values;
          this.aprobado = this.editList.data.blSeguimiento;

          console.log("editar", this.editList);
          if(this.editList.data.documentosCapacitacion.length>0)
          {
            this.seguimiento = true;
            this.vista=true;
            this.listFiles=this.editList.data.documentosCapacitacion;
          }

          for (let i = 0; i <this.editList.data.horariosCapacitacion.length  ; i++) {
            const fechabd = this.editList.data.horariosCapacitacion[i].fechaCapacitacion.split('/');
            let fechaFormateada='';
            // Verifica si hay tres partes y son numéricas
            if (fechabd.length === 3 && fechabd.every((part: any) => !isNaN(Number(part)))) {
              const [dia, mes, anio] = fechabd;

              // Construye la nueva cadena en el formato deseado
              fechaFormateada = `${dia}-${mes}-${anio}`;

              const nuevoHorario = {
                fecha: fechaFormateada,
                horaInicio: this.editList.data.horariosCapacitacion[i].horaInicio,
                horaFin: this.editList.data.horariosCapacitacion[i].horaFin,
              };
              console.log(nuevoHorario)
              this.registros.push(nuevoHorario);
            }
          }


        this.recordCapacitation.get('trainer').setValue(this.editList.data.idCapacitador);
        this.recordCapacitation.get('assistants').setValue(this.editList.data.vcPublicoObjetivo);
        this.recordCapacitation.get('assistantsnumber').setValue(this.editList.data.intNumeroAsistentes);
        this.recordCapacitation.get('theme').setValue(this.editList.data.vcTemaCapacitacon);
        this.recordCapacitation.get('methodology').setValue(this.editList.data.vcMetodologiaCapacitacion);
        this.recordCapacitation.get('aditional').setValue(this.editList.data.vcInformacionAdicional);
        this.recordCapacitation.get('depto').setValue(this.editList.data.departamentoId);
        this.obtenerMunicipios();
        this.recordCapacitation.get('city').setValue(this.editList.data.ciudadId);
        this.recordCapacitation.get('address').setValue(this.editList.data.vcDireccion);

      });
    });
  }

  change = (e:any) =>{
    this.aprobado = e;
  }

  Guardar = () => {


    if(this.listFiles.length>0)
    {
      let documentos:any=[];

      for (let i = 0; i < this.listFiles.length ; i++) {

        documentos.push(this.listFiles[i].documento);


        const formData = new FormData();
        const reader = new FileReader();
        reader.onload = (e: any) => {

          console.log(e.target.result);
          formData.append('NameFile', this.idUser+"_"+this.listFiles[i].documento.vcNombreDocumento+".pdf");
          formData.append('ContainerName', "manipalimentos");
          formData.append('Oid', this.filenumber);
          formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
          this.documentService.addBlob(formData).subscribe((respuesta: any) => {
            console.log(respuesta);

          })
        };
        reader.readAsArrayBuffer(this.listFiles[i].fileDocument);
      }


      const revision=
        {
          capacitacionId: this.idlist,
          blSeguimiento: this.aprobado,
          usuarioSeguimientoId: this.idUser,
          documentos: documentos
        }

        console.log(revision);

      console.log(revision);
      this.requestservice.RevisionCapacitacion(revision).subscribe(
        response => {
          this.registros = [];
          this.recordCapacitation.reset();
          this.recordCapacitation.get('depto').setValue("");
          this.Municipios=[];
          this.listFiles=[];
          console.log(response, "respuesta del servidor");
          Swal.fire({
            text: 'Seguimiento Guardado.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl("aut-cap/"+ ROUTES.Dashboard + "/" + ROUTES.DASHBOARD_VALIDATOR);
              window.history.back();
            }
          });
        },
          error => {
            console.error("Error en la solicitud:", error);
            Swal.fire({
              text: 'La capacitación no pudo ser registrada',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar',
            }).then((result) => {
              if (result.isConfirmed) {
                this.recordCapacitation.reset();
                this.recordDate.reset();
              }
            });
          }

      );

    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Debe de agregar un documento',
      });
    }
    }








  addDoc = (tempFile: File): void => {
    console.log(tempFile)

    if(this.listFiles.length==0)
    {
      const payload: any = {

        documento:{
          idDocumento: 0,
          solicitudId: 0,
          usuarioId: this.idUser,
          tipoDocumentoId: 44,
          vcNombreDocumento: `Acta_de_revisión_de_la_capacitación_1`,
          vcPath: this.idUser+"_Acta_de_revisión_de_la_capacitación_1",
        },
        name:tempFile.name,
        NombreDocumento : `Acta de revisión de la capacitación 1`,
        size:tempFile.size/ 1000,
        fileDocument: tempFile
      };
      console.log("TEMP FILE: " + tempFile.name);

      this.listFiles.push(payload);
    }
    else {
      let index= this.listFiles.length+1;

      const payload: any = {

        documento:{
          idDocumento: 0,
          solicitudId: 0,
          usuarioId: this.idUser,
          tipoDocumentoId: 44,
          vcNombreDocumento : `Acta_de_revisión_de_la_capacitación_${index}`,
          vcPath: this.idUser+`_Acta_de_revisión_de_la_capacitación_${index}`
        },
        name:tempFile.name,
        NombreDocumento : `Acta de revisión de la capacitación ${index}`,
        size:tempFile.size/ 1000,
        fileDocument: tempFile
      };
      console.log("TEMP FILE: " + tempFile.name);

      this.listFiles.push(payload);
    }
    if(this.listFiles.length>=5)
    {
      this.maximo=true;
    }

    console.log(this.listFiles);
  }
  getFile(event: any) {
    console.log("FILES: " , event);

    if(event.target.files.length==0)
    {
      this.isError = true;
      this.msgError = `Seleccione un documento.`;
      this.setOffDocumentMessages();
    }
    else
    {
      const tempFile = event.target.files[0];
      console.log("TEMP FILE: ", tempFile);
      const sizeFile = 3 * 1024 * 1024; // 3MB
      if (tempFile.size > sizeFile) {
        this.isError = true;
        this.msgError = `Seleccione un documento menor a 3MB.`;
        this.setOffDocumentMessages();
        return;
      }
      const extension = tempFile.type.split('/')[1];
      if (extension !== 'pdf') {
        this.isError = true;
        this.msgError = `Seleccione un documento válido.`;
        this.setOffDocumentMessages();
        return;
      }
      this.addDoc(tempFile);
    }



  }


  openDocument = (e:any) => {
    this.isLoadingDocument=true;
      const contenedor = "manipalimentos";
      this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${this.filenumber}/${e}.pdf`);
    this.viewDocument=true;
    this.isLoadingDocument=false;
  }

  seeDocument = (e:any) => {
    this.isLoadingDocument=true;
    var reader = new FileReader();
    reader.readAsDataURL(e);

    reader.onloadend = () => {

      const base64data = reader.result as string;
      const documentData = base64data;
      this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(documentData);
      this.viewDocument=true;
      this.isLoadingDocument=false;
    }
  }
  removeDocument = (e:any) => {

    this.listFiles.splice(e,1);

    for (let i = 0; i < this.listFiles.length; i++) {
      this.listFiles[i].NombreDocumento=`Acta de revisión de la capacitación ${i+1}`;
      this.listFiles[i].documento.vcNombreDocumento=`Acta_de_revisión_de_la_capacitación_${i+1}`;
      this.listFiles[i].documento.vcPath=this.idUser+`_Acta_de_revisión_de_la_capacitación_${i+1}`;
    }

    if(this.listFiles.length<5)
    {
      this.maximo=false;
    }


  }
  closeDocument = () => {

    this.viewDocument = false;

  }



  setOffDocumentMessages() {
    timer(4000)
      .pipe(x => x)
      .subscribe(dm => {
        this.isError = false;
      });
  }



}
