<div>
  <div class="numero-paginas mb-2">
    <div class="numero-paginas-seccion">
      <div class="d-flex align-items-center justify-content-end">
        <label for="noOfRows" class="text_info"
          style="font-size: 12px;width: 15%;text-align: center; margin: 0px;">Número de
          Registros por pagina</label>
        <div class="d-inline p-2" style="height: 100%;">
          <select style="height: 50px;" name="noOfRows" (change)="onTableSizeChange($event)"
            class="form-select form-select-sm">
            <option [selected]="size==10" *ngFor="let size of tableSize" [ngValue]="size">
              {{ size }}
            </option>
          </select>
        </div>
        <div class="d-inline mx-2">
          <label class="control-label" for="noOfRows">Mostrando
            {{pagingConfig.currentPage}}-{{pagingConfig.itemsPerPage}} de {{data.length}} resultados</label>
        </div>
      </div>
    </div>
  </div>
  <!-- Tabla con solicitudes -->
  <div class="table-responsive dataTables_wrapper no-footer table table-bordered" style="border-color: #F4F4F4;">
    <table class="table-govco table-sm dataTable no-footer" aria-label="Solicitudes recientes" style="width: 100%;"
      role="grid">
      <thead>
        <tr role="row">
          <th *ngFor="let s of structure" id={{s.name}} aria-controls="" rowspan="1" colspan="1" class="table-state"
            style="width: 20px; text-align: center; text-align: -moz-center">
            {{s.name}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr role="row" class="odd ng-star-inserted" style="transition: all 0.5s ease-out;"
          *ngFor="let d of data | paginate : pagingConfig; let i = index">
          <td *ngFor="let sd of structure" class="columna"
            style="vertical-align: middle;text-align: center; text-align: -moz-center">
            <div class="texto" *ngIf="sd.type=='texto'">
              {{d[sd.value]}}
            </div>
            <div class="lista" *ngIf="sd.type=='lista'">
              <select class="form-select" aria-label="Seleccione"
                (change)="select({record: d, selectedValue: selectInput.value})" #selectInput>
                <option *ngFor="let values of sd.data" value={{values.value}}
                  [selected]="values.value===d[sd.value]?true:false">{{values.name}}</option>
              </select>
            </div>
            <div class="enlace d-flex justify-content-center"
              style="text-decoration: underline;color: #2596be;font-size: 10px;" *ngIf="sd.type=='enlace'">
              <a *ngIf="!d[sd.flag]" style="text-align: center;" (click)="link({record:d})">{{sd.value}}</a>
            </div>
            <div class="enlaceMultiple" style="text-decoration: underline;color: #2596be;font-size: 10px;"
              *ngIf="sd.type=='enlaceMultiple'">
              <span *ngFor="let l of sd.links">
                <a [hidden]="!showLink(d[sd.state], l.states)" *ngIf="shouldShowAction(d, sd, l)"
                  [attr.data-target]="l.modal" data-toggle="modal" class="d-block w-100"
                  style="text-align: center; margin-bottom: 5px; color:#2596be !important;"
                  (click)="multipleLink({ record: d, option: l })">{{ l.name }}</a>
              </span>
            </div>
            <div *ngIf="sd.type=='remainingDays'" class="diasRestantes" id="colorbasic">
              <div class="p-3 prueba"
                [ngStyle]="{'background': (d[sd.value1] >= 0 || d['vcTipoEstado'] == 'Aprobado') ? sd.color1 : sd.color2}">
                <span *ngIf="d['vcTipoEstado'] == 'Aprobado'" style="color: black;">
                  La solicitud ha sido atendida.
                </span>
                <div *ngIf="d['vcTipoEstado'] != 'Aprobado'"
                  [ngStyle]="{'color': (d[sd.value1] >= 0) ? 'black' : 'white'}">
                  {{d[sd.value1] == 1 ? 'Queda' : 'Quedan'}} {{d[sd.value1] >= 0 ? d[sd.value1] : 0}} {{d[sd.value1] ==
                  1 ? 'día' : 'días'}}, estos son los días transcurridos
                  {{d[sd.value2] - d[sd.value1]}}
                </div>
              </div>
            </div>
            <div *ngIf="sd.type=='remainingDays2'" class="diasRestantes2" id="colorbasic">
              <div class="p-3" [ngStyle]="{'background':sd.color1}">
                <span *ngIf="d['vcEstado'] == 'Aprobado'">
                  La solicitud ha sido atendida.
                </span>
                <span *ngIf="d['vcEstado'] != 'Aprobado'">
                  {{d[sd.value1]==1?'Queda':'Quedan'}} {{d[sd.value1]>=0?d[sd.value1]:0}}
                  {{d[sd.value1]==1?'día':'días'}}, estos son los días transcurridos
                  {{d[sd.value2]-d[sd.value1]}}
                </span>
              </div>
            </div>
            <div class="documento d-flex justify-content-center"
              style="text-decoration: underline;color: #2596be;font-size: 10px;" *ngIf="sd.type=='documento'">
              <span (click)="document({record: d})"><i class="bi bi-file-earmark-pdf icon"></i></span>
            </div>
          </td>
        </tr>
        <tr class="odd" *ngIf="data?.length === 0">
          <td colspan="8" class="dataTables_empty" style="text-align: center; vertical-align: top; color: red">
            No se encontraron registros
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="paginacion d-flex justify-content-center mt-3">
        <div>
          <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="onTableDataChange($event)">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
